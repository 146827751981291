import React, { useEffect } from "react";
import { Link,useLocation,Navigate } from "react-router-dom";
import * as apis from '../repository/api_calls.js';
import { useNavigate } from "react-router-dom";
import CircleProgressBar from "../circle/circle.jsx";
import * as images from '../pages/images.jsx'; 
// import * as con from '../repository/urls.js';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import LenderHeader from "./component/header.jsx";


export default function UserDetial() {


  const location = useLocation();
  const userData = location.state;
  const role_id = localStorage.getItem("role_id");
  const creditScoreStatus = localStorage.getItem("creditScore_status");

  const [lenderLoanDetails, setlenderLoanDetails] = React.useState({
    "apportment_number":"",
    "brief_summary":"",
    "city":"",
    "cnae_sector":"",
    "company_cif":"",
    "company_creation_date":"",
    "company_id":"",
    "company_name":"",
    "company_ran_id":"",
    "company_status":"",
    "corporation_tax":"",
    "expenses":"",
    "financial_statements":"",
    "floor":"",
    "income":"",
    "legal_name":"",
    "legal_nif":"",
    "lender_id":"",
    "loan_amount":"",
    "loan_created_at":"",
    "loan_description":"",
    "loan_id":"",
    "loan_ran_id":"",
    "loan_status":"",
    "loan_term":"",
    "loan_title":"",
    "main_status":"",
    "meeting":"",
    "nif":"",
    "partner_file":"",
    "partner_name":"",
    "payment_and_obligations":"",
    "percentage":"",
    "phone_number":"",
    "previous_year_model":"",
    "score_created_at":"",
    "score_id":"",
    "score_ran_id":"",
    "score_status":"",
    "status":"",
    "street":"",
    "user_id":"",
    "user_score":"",
    "vat_declarations":"",
    "website":"",
    "zip_code":"",
  });


  const myFunction = () => {
    navigate('/lender/lender_offer',{ state: userData });
  }

  useEffect( () =>  {
    // console.log(userData);
    getLenderLoanDetails();
  },[]);


  async function getLenderLoanDetails () {
    const response = await apis.getLenderLoanOffers(token);
      if (response['status'] === true) {
        for(let item of response['data']){
          if(item['user_id']===userData['user_id']){
            setlenderLoanDetails(item);
          }
        }
        
      }
  }


    
  const navigate = useNavigate();

  async function logout() {
    const response = await apis.getLogout();
    console.log(response)
    if (response['status'] === true) {
      toast.success(response['message'], {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo_bar_log_Success'
      });
      setTimeout(() => {
        localStorage.clear();
        window.location.reload();
        navigate('/dashboard');
      }, 800);

    } else {
      toast.error(response['message'], {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo_bar_log_error'
      });
    }
}

  // function logout() {
  //   fetch(`${process.env.REACT_APP_BASE_URL}${con.logout}`, {
  //     method: 'POST',
  //     headers: {
  //       "Authorization": `Bearer ${token}`,
  //     }
  //   })
  //     .then(async response => {
  //       const result = await response.json();
  //       if (result['status'] === true) {
  //         toast.success(result['message'], {
  //           position: toast.POSITION.TOP_RIGHT,
  //           className: 'foo_bar_log_Success'
  //         });
  //         setTimeout(() => {
  //           localStorage.clear();
  //           window.location.reload();
  //           navigate('/dashboard');
  //         }, 800);

  //       } else {
  //         toast.error(result['message'], {
  //           position: toast.POSITION.TOP_RIGHT,
  //           className: 'foo_bar_log_Success'
  //         });
  //       }
  //     })
  //     .catch(error => {
  //     });
  // }



  function formatDate(data){
    // const datetime = new Date('2023-06-26 03:16:00');
    // const formattedDate = format(datetime, 'dd/MM/yyyy');
    const split = data.split(" ");
    return split[0];
    }



  async function rejectLoan() {
    const response = await apis.usersLoanStatusUpdate(userData['loan_id'], "Reject", token);
      if (response['status'] === true) {
        toast.success(response['message'], {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo_bar_log_Success'
      });
      setTimeout(() => {
        navigate('/lender/lenderdashbo');
      }, 700);        
      }
      else {
        toast.error(response['message'], {
            position: toast.POSITION.TOP_RIGHT,
            className: 'foo_bar_log_error'
        });
    }
  }

  const openDocument = (url) => {
    window.open(url, '_blank');
  };


  const token = localStorage.getItem("token");

 

  return (
    ((role_id !== null && role_id === "3") && (creditScoreStatus !== null)) ?
    <React.Fragment>
      <div>
      <LenderHeader />
      </div>


      <ToastContainer autoClose={3000} />
      <section>

        <div className="row">
          <div className="col-md-2" style={{ backgroundColor: "white"}}>
          <div  className="card"
              style={{
                height: "90vh",
                width: "100%",
                zIndex: 1,
                position: "sticky",
                top: 55,
              }}>
{/*           
            <Link to="/">
              
              <img  className="logo" src={images.logo2} alt='logo2' style={{  display: "block", margin: "0 auto" }} />
            </Link>
            <br /> */}

           
               <img  className="" src={images.profile} alt='profile'    style={{width: "90px", height: "90px", display: "block", margin: "0 auto" }} />
            <p style={{ textAlign: "center", fontWeight: "500" }}>Nombre del inversor</p>
            <p style={{ textAlign: "center", marginTop: "-10px", fontSize: "13px" }}>{localStorage.getItem("firstname") + " " + localStorage.getItem("lastname")}</p>


            <div style={{ display: "flex", justifyContent: "center", marginTop: "30px", marginLeft: "7px" }}>
             
              <img  className="" src={images.pro_icon} alt='pro_icon' style={{ fontSize: "13px" }}  />
              <a href="/lender/lenderdashbo">
              <p style={{ color: "#828282", fontSize: "13px", marginTop: "4px", marginLeft: "7px", paddingTop: "9px" }}>
              Panel
              </p>
              </a>
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              
              <img  className="" src={images.pro_icon} alt='pro_icon' style={{ fontSize: "13px" }}  />
              <Link to="/lender/lender_profile">
              <p style={{ color: "#828282", fontSize: "13px", marginTop: "4px", paddingLeft: "9px", paddingTop: "9px" }}>
              Mi perfil
              </p>
              </Link>
            </div>
            <div style={{ display: "flex", justifyContent: "center", marginLeft: "10px", marginTop: "90px" }}>
             
              <button  type="submit" className="lender_logbut" onClick={logout}>Cerrar sesión</button>
            </div>



            {/* </div> */}

          </div>
          </div>

          <div className="col-md-10" >
            <div className="container" style={{ backgroundColor: "white", height: "100%", width: "95%", marginLeft: "-8px", marginTop: "5px" }}>


              <div className="row" style={{ marginTop: "30px" }}>
                <div className="col-md-2" >
                 
                   <img  className="" src={images.profile} alt='profile'    style={{width: "90px", height: "90px", display: "block", margin: "0 auto" }} />
                </div>
                <div className="col-md-2" >
              
                  <p style={{ marginTop: "40px", marginLeft: "-30px", fontWeight: "600" }}>Nombre de empresa</p>
                 
                  {(userData===null) ?
                 null: <p style={{ marginTop: "-9px", marginLeft: "-30px" }}>{userData['company_name']}</p>}
                </div>
                <div className="col-md-4" ></div>
                <div className="col-md-4" >
                

<div style={{ marginTop: "30px" }}>
{(userData===null) ?
        null:<CircleProgressBar percentage={userData['user_score']} />}
                             </div>

                </div>


                {/*========== two button */}

                <div style={{ display: "flex" }}>

                <div id="myDIV">
                   <h4>Your Offer has been Successfully send out</h4>
                </div>
            
                  <button  onClick={myFunction} id="btn_myscore" type="submit"  style={{ marginLeft: "30px", height: "38px", width: "120px", marginTop: 0, fontSize: "14px", fontWeight: "600" }}>
                  Asignar oferta
                  </button>
                
                  <button id="btn_myscore" type="button" onClick={rejectLoan}
                   style={{ marginLeft: "30px", height: "38px", width: "120px", marginTop: 0, fontSize: "14px", fontWeight: "600" }}>
                    No interesado
                  </button>
                  
                </div>
                {(userData===null) ?
                null:
                <div style={{ backgroundColor: "#f2f2f2", height: "130px", width: "80%", marginTop: "20px", display: "flex", justifyContent: "space-around" }}>


                  <div style={{ marginTop: "30px" }}><p>Cantidad:</p><b style={{ fontSize: "20px" }}>{userData['loan_amount']} EUR</b></div>
                  <div style={{ marginTop: "30px" }}><p>Término:</p><b style={{ fontSize: "20px" }}>{userData['loan_term']}</b></div>
                  <div style={{ marginTop: "30px" }}><p>Fecha:</p><b style={{ fontSize: "20px" }}>{formatDate(userData['loan_created_at'])}</b></div>
                  <div style={{ marginTop: "40px" }}><p>ID de préstamo : {userData['loan_ran_id']}</p></div>


                </div>}

                <div style={{ marginTop: "50px", marginLeft: "15px" }}>
                  <h4 > Descripcion de la empresa</h4>
                  <p>This is my first offer to you and some description text for you Amet minim mollit<br />
                    non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat<br />
                    duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
                </div>
                <div style={{ marginTop: "50px", marginLeft: "15px" }}>
                  <h4 > Pare que queremos el prestamo</h4>
                  <p>Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt<br />
                    nostrud amet. This is my first offer to you and some description text for you.<br />
                    Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet<br />
                    minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet minim <br />
                    mollit non deserunt ullamco est sit aliqua dolor do amet sint. </p>
                </div>
                <div style={{ marginTop: "30px", marginLeft: "15px" }}>
                  <h4 > Website</h4>
                  < a href={lenderLoanDetails['website']}>{lenderLoanDetails['website']}</a>
                </div>
                <div style={{ marginTop: "30px", marginLeft: "45px" }}>
                  <h4 > Financial Data</h4>
                  <ul style={{ marginTop: "20px", marginLeft: "30px", color: "#1f7bd1", listStyle: "none" }}>
                    <li className="point_cur" style={{ marginBottom: "15px" }} onClick={()=>{openDocument(userData['partner_file'])}}>CIRBE actualizada y detalle de pool bancario</li>
                    <li className="point_cur" style={{ marginBottom: "15px" }} onClick={()=>{openDocument(userData['corporation_tax'])}}>Impuesto de Sociedades</li>
                    <li className="point_cur" style={{ marginBottom: "15px" }} onClick={()=>{openDocument(userData['financial_statements'])}}>Estados financieros ultimos 3 ejercicios</li>
                    <li className="point_cur" style={{ marginBottom: "15px" }} onClick={()=>{openDocument(userData['previous_year_model'])}}>Modelo 347 del año previo</li>
                    <li className="point_cur" style={{ marginBottom: "15px" }} onClick={()=>{openDocument(userData['vat_declarations'])}}>Declaraciones IVA últimos dos años</li>
                    <li className="point_cur" style={{ marginBottom: "15px" }} onClick={()=>{openDocument(userData['brief_summary'])}}>Breve Resumen de como afecto su actividad la situación del COVID y las medidas tomadas</li>
                    <li className="point_cur" style={{ marginBottom: "15px" }} onClick={()=>{openDocument(userData['meeting'])}}>Minutas de Junta de accionistas o reunion de directorio</li>

                  </ul>
                </div>
                {/* <div style={{ marginTop: "30px", marginLeft: "45px" }}>
                  <h4 > More Details</h4>
          
                  <ul style={{ marginTop: "20px", marginLeft: "30px", color: "#1f7bd1", listStyle: "none" }}>
                    <li style={{ marginBottom: "15px" }}>{lenderLoanDetails['company_name']}</li>
                    <li style={{ marginBottom: "15px" }}>{lenderLoanDetails['legal_name']}</li>
                  </ul>
                </div> */}



              </div>


            </div>










          </div>

        </div>




      </section>









    </React.Fragment>
     : <Navigate to="/" replace />
  )
}








// $(document).ready(function () {
//   $(".circle_percent").each(function () {
//     var $this = $(this),
//       $dataV = $this.data("percent"),
//       $dataDeg = $dataV * 3.6,
//       $round = $this.find(".round_per");
//     $round.css("transform", "rotate(" + parseInt($dataDeg + 180) + "deg)");
//     $this.append('<div class="circle_inbox"><span class="percent_text"></span></div>');
//     $this.prop('Counter', 0).animate({ Counter: $dataV },
//       {
//         duration: 2000,
//         easing: 'swing',
//         step: function (now) {
         
//           $this.find(".percent_text").text(Math.ceil(now) + "");
//         }
//       });
//     if ($dataV >= 51) {
//       $round.css("transform", "rotate(" + 360 + "deg)");
//       setTimeout(function () {
//         $this.addClass("percent_more");
//       }, 1000);
//       setTimeout(function () {
//         $round.css("transform", "rotate(" + parseInt($dataDeg + 180) + "deg)");
//       }, 1000);
//     }
//   });
// });
import React from "react";
import { Link, Navigate } from "react-router-dom";
import Logo from '../assets/img/logo.svg';
import UserHeader from "../user/component/header";
export default function GuestConform() {


  const type = localStorage.getItem("guest_conformation");


  return (
    ((type !== null)) ?
      <React.Fragment>

        <UserHeader />

        <section style={{ backgroundColor: "#202634", height: "92vh" }} >
          <div className="container" style={{ paddingTop: "30px" }} >
            <div style={{ marginLeft: 300 }}>
              <p style={{ color: "#FAFAFD", fontSize: 18 }}>
                ¡Enhorabuena! Tu solicitud se ha enviado correctamente
              </p>
              <p
                style={{
                  fontSize: 32,
                  color: "#FAFAFD",
                  fontWeight: 600,

                }}
              >
                Nuestras entidades financieras estudiarán tu solicitud

              </p>
              <p style={{ color: "#FAFAFD", fontSize: 18 }}>
                En un plazo de 48 horas recibirás una respuesta en nuestra plataforma

              </p>
              <div style={{ color: "#FAFAFD", fontSize: 16 }}>
                <p>
                  <span>
                    <i className="fa fa-check-circle conf_icon" aria-hidden="false" />
                  </span>{" "}
                  Registro
                </p>
                <p>
                  <span>
                    <i className="fa fa-check-circle conf_icon" aria-hidden="true" />
                  </span>{" "}
                  <span style={{ textTransform: "capitalize" }}>    Registre</span>   su empresa
                </p>
                <p>
                  <span>
                    <i className="fa fa-check-circle conf_icon" aria-hidden="true" />
                  </span>{" "}
                  Consigue tu ZSCORE
                </p>
                <p>
                  <span>
                    <i className="fa fa-check-circle conf_icon" aria-hidden="true" />
                  </span>{" "}
                  <span style={{ textTransform: "capitalize" }}> Aplicar</span>  un préstamo
                </p>
              </div>


              <a href="/auth/login">
                <button
                  type="submit"
                  style={{
                    background:
                      "linear-gradient(90deg, #FC8518 0%, rgba(233, 75, 25, 0.78) 100%)",
                    border: "none",
                    width: 323,
                    height: 50,
                    color: "#FAFAFD",
                    borderRadius: 12,
                    marginTop: 15
                  }}
                >
                  PANEL DE CONTROL
                </button>
              </a>
            </div>
          </div>
        </section>

      </React.Fragment>
      : <Navigate to="/" replace />
  )
}
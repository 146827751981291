import React from "react";
import { Link } from "react-router-dom";
import { Nav, NavDropdown } from "react-bootstrap";
import { useNavigate, Navigate } from "react-router-dom";
import { useState, useEffect } from "react";
import * as con from '../repository/urls.js';
import * as images from '../pages/images.jsx';
import * as apis from '../repository/api_calls.js';
import Select, { components } from 'react-select';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import UserHeader from "./component/header.jsx";


export default function UserProfile() {

  const role_id = localStorage.getItem("role_id");
  const token = localStorage.getItem("token");
  const user_type = localStorage.getItem("user_type")
  const navigate = useNavigate();
  const currentDate = new Date().toISOString().split('T')[0];


  // function logout() {
  //   fetch(`${process.env.REACT_APP_BASE_URL}${con.logout}`, {
  //     method: 'POST',
  //     headers: {
  //       "Authorization": `Bearer ${token}`,
  //     }
  //   })
  //     .then(async response => {
  //       const result = await response.json();
  //       if (result['status'] === true) {
  //         toast.success(result['message'], {
  //           position: toast.POSITION.TOP_RIGHT,
  //           className: 'foo_bar_log_Success'
  //         });
  //         setTimeout(() => {
  //           localStorage.clear();
  //           window.location.reload();
  //           navigate('/dashboard');
  //         }, 800);

  //       } else {
  //         toast.error(result['message'], {
  //           position: toast.POSITION.TOP_RIGHT,
  //           className: 'foo_bar_log_Success'
  //         });
  //       }
  //     })
  //     .catch(error => {
  //     });
  // }

  const handlekeydown = (e) =>{
    const restrictedkeys = new Set(['-', '+', '*', '/', 'e'])
    if(restrictedkeys.has(e.key)){
      e.preventDefault();
    }
  }

  const company_nameRef = React.useRef();
  const company_cifRef = React.useRef();
  const company_creation_dateRef = React.useRef();
  const cnae_sectorRef = React.useRef();
  const nameRef = React.useRef();
  const surnameRef = React.useRef();
  const phone_numberRef = React.useRef();

  //=========================================  Profile Edit API (onload)
  const [date, setDate] = useState([])
  const [sectorsList, setSectorsList] = React.useState([]);
  const [sector_id, setSector_id] = React.useState();


  useEffect(() => {
  if (user_type === "guest_user" || user_type === null) {
    guestProfile();
    async function guestProfile() {
      
      const response = await apis.getGestuserProfile( JSON.stringify({
        "user_id": localStorage.getItem("id")
      }),);
      console.log(response)
  setDate(response.data);
  }
  
  } else {
    ReisterProfile();
    console.log('response')
    async function ReisterProfile() {
      const response = await apis.getRegisteruserProfile();
      if(response.status===true){
        setDate(response.data);
        getSectors(response.data);
      }
    
      console.log(response)
  }
  }
}, [])

  // useEffect(() => {
  //   if (user_type === "guest_user" || user_type === null) {
  //     fetch(`${process.env.REACT_APP_BASE_URL}${con.guest_profile}`, {
  //       method: 'post',
  //       body: JSON.stringify({
  //         "user_id": localStorage.getItem("id")
  //       }),
  //       headers: {
  //         'Content-Type': 'application/json',
  //       }
  //     }).then(res => res.json())
  //       .then(res => {
  //         setDate(res.data);
  //       });
  //   } else {
  //     fetch(`${process.env.REACT_APP_BASE_URL}${con.user_profile}`, {
  //       method: 'post',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         "Authorization": `Bearer ${token}`,
  //       }
  //     })
  //       .then(res => res.json())
  //       .then(res => {
  //         setDate(res.data);
  //         getSectors(res.data);
  //       });
  //   }
  // }, [])

  async function getSectors(dataa) {
    //get loan fill details
    const response = await apis.getComponySectorsList();

    if (response['status'] === true) {
      var listLocal = [];
      for (let i = 0; i < response['data'].length; i++) {
        if (dataa['cnae_sector'] === response['data'][i]['sector_id']) {
          listLocal.push(response['data'][i]);
        }
      }
      for (let i = 0; i < response['data'].length; i++) {
        if (dataa.cnae_sector !== response['data'][i]['sector_id']) {
          listLocal.push(response['data'][i]);
        }
      }
      var d1 = dataa;
      d1['sector'] = listLocal[0].sector;
      setSector_id(listLocal[0].sector_id)
      setDate(d1);
      setSectorsList(listLocal);
      // console.log(dataa);
    }
  }
      // useEffect(() => {
      //   UpdateProfile();
      // }, [])
  //=========================================  Profile Update API
  const profileupdate = (event) => {
    event.preventDefault();
    const company_name = company_nameRef.current.value
    const company_cif = company_cifRef.current.value
    const company_creation_date = company_creation_dateRef.current.value
    // const cnae_sector = cnae_sectorRef.current.value
    const name = nameRef.current.value
    const surname = surnameRef.current.value
    const phone_number = phone_numberRef.current.value

    if (sector_id === '' || sector_id === undefined) {
      toast.error("Seleccione cualquier detalle de un sector en el menú desplegable.", {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo_bar_log_error'
      });
    } else {

      // console.log({
      //   "company_id": localStorage.getItem('company_id'),
      //   "company_name": company_name,
      //   "company_cif": company_cif,
      //   "company_creation_date": company_creation_date,
      //   "cnae_sector": sector_id,
      //   "phone_number": phone_number,
      //   "name": name,
      //   "surname": surname
      // })

      // console.log("asfgas =", sector_id)

      UpdateProfile();
    
      async function UpdateProfile() {
          const response = await apis.UpdateUserProfile(JSON.stringify({
            "company_id": localStorage.getItem('company_id'),
            "company_name": company_name,
            "company_cif": company_cif,
            "company_creation_date": company_creation_date,
            "cnae_sector": sector_id,
            "phone_number": phone_number,
            "name": name,
            "surname": surname
          }),);
          console.log(response)
          if (response['status'] === true) {
            localStorage.setItem("firstname", name);
            localStorage.setItem("lastname", surname)
            toast.success("Perfil actualizado", {
              position: toast.POSITION.TOP_RIGHT,
              className: 'foo_bar_log_Success'
            });
  
            swal.fire({
              position: 'Center',
              icon: 'success',
              title: 'Perfil actualizado con éxito',
              showConfirmButton: false,
              timer: 1500
            })
            setTimeout(() => {
              navigate('/user/user_profile');
            }, 700);
  
          }
          else {
            toast.error("Algo error", {
              position: toast.POSITION.TOP_RIGHT,
              className: 'foo_bar_log_error'
            });
          }
      }

      // fetch(`${process.env.REACT_APP_BASE_URL}${con.profileupdate}`, {
      //   method: 'post',
      //   body: JSON.stringify({
      //     "company_id": localStorage.getItem('company_id'),
      //     "company_name": company_name,
      //     "company_cif": company_cif,
      //     "company_creation_date": company_creation_date,
      //     "cnae_sector": sector_id,
      //     "phone_number": phone_number,
      //     "name": name,
      //     "surname": surname
      //   }),
      //   headers:
      //   {
      //     "Authorization": `Bearer ${token}`,
      //     'Content-Type': 'application/json',
      //   }
      // }
      // ).then(async response => {
      //   var res = await response.json();
      //   console.log(res)
      //   console.log(sector_id)

      //   if (res['status'] === true) {
      //     localStorage.setItem("firstname", name);
      //     localStorage.setItem("lastname", surname)
      //     toast.success("Perfil actualizado", {
      //       position: toast.POSITION.TOP_RIGHT,
      //       className: 'foo_bar_log_Success'
      //     });

      //     swal.fire({
      //       position: 'Center',
      //       icon: 'success',
      //       title: 'Perfil actualizado con éxito',
      //       showConfirmButton: false,
      //       timer: 1500
      //     })
      //     setTimeout(() => {
      //       navigate('/user/user_profile');
      //     }, 700);

      //   }
      //   else {
      //     toast.error("Algo error", {
      //       position: toast.POSITION.TOP_RIGHT,
      //       className: 'foo_bar_log_error'
      //     });
      //   }
      // }).catch(error => {
      //   console.error('There was an error!', error);
      // });
    }
  }

  // --------------------------- select box dropdown icon---------
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <span style={{ paddingRight: '5px' }}>&#9662;</span>
      </components.DropdownIndicator>
    );
  };


  return (
    (role_id !== null && role_id === "2") ?
      <React.Fragment>

        <UserHeader />

        <section style={{ backgroundColor: "#FFFFFF", height: "65px", top: "50px", position: "sticky", zIndex: 2 }}>
          <div className="container">
            <div className="row" style={{ padding: 10 }}>
              <div className="col-md-3" style={{ display: "flex" }}>
                <a href="/dashboard" style={{ textDecoration: "none" }}>
                  <p style={{ color: "#183F4B", fontSize: "18px" }}>
                    <span>
                      <img className="" src={images.dash_dark} alt='applica_dark' />
                    </span>
                    Panel
                  </p>
                </a>
                <div
                  style={{
                    borderLeft: "2px solid #E0E0E0",
                    height: 45,
                    marginLeft: 80
                  }}
                ></div>
              </div>

              <div className="col-md-3" style={{ display: "flex" }}>
                <a href="/myscore" style={{ textDecoration: "none" }}>
                  <p style={{ color: "#183F4B", fontSize: "16px" }}>
                    <span>
                      <img className="" src={images.z_score_dark} alt='z_score_dark' />
                    </span>{" "}
                    Mi puntaje
                  </p>
                </a>
                <div
                  style={{
                    borderLeft: "2px solid #E0E0E0",
                    height: 45,
                    marginLeft: 80
                  }}
                ></div>
              </div>
              <div className="col-md-3" style={{ display: "flex" }}>
                <a href="/dashboardapp" style={{ textDecoration: "none" }}>
                  <p style={{ color: "#183F4B", fontSize: "18px", marginTop: "2px", marginLeft:"-20px" }}>
                    <span>
                      <img className="" src={images.applica_dark} alt='applica_dark' style={{ marginRight: 7 }} />
                    </span>{" "}
                    Solicitudes enviadas
                  </p>
                </a>
                <div
                  style={{
                    borderLeft: "2px solid #E0E0E0",
                    height: 45,
                    marginLeft: 80
                  }}
                ></div>
              </div>
              <div className="col-md-3">
                <a href="/dashboardloan" style={{ textDecoration: "none" }}>
                  <p style={{ color: "#183F4B", fontSize: "18px", }}>
                    <span>
                      <img className="" src={images.loan_offer_dark} alt='loan_offer_dark' />
                    </span>
                    Ofertas recibidas
                  </p>
                </a>
              </div>
            </div>
          </div>
        </section>



        <section className="" style={{ backgroundColor: "#fafafa", }}>
          <div className="container py-5" style={{ display: "flex" }}>
            <Link to="/user_profile/profile" className="tip_1" style={{ textDecoration: "none" }}>
              <h4>información del perfil</h4>
            </Link>
            {/* 
          <Link to="/user_profile/profile" className="ms-5 tip_2" style={{ textDecoration: "none" }} >
            <h4> Profile Settings</h4>
          </Link> */}
          </div>
        </section>



        <section style={{ backgroundColor: "#fafafa", }}>
          <div className="container">
            <ToastContainer autoClose={3000} />
            <form onSubmit={profileupdate} className="formguest" >
              <div className="row" >
                <h3 style={{ color: "#183f4b" }}>Informacion de le Empresa</h3>
                <div className="col-md-4">

                  <div >
                    <label style={{ fontSize: "16px", fontWeight: "semibold", marginTop: "30px" }} >Nombre de la Compania </label>
                  </div>
                  <div>
                    <label style={{ fontSize: "16px", fontWeight: "semibold", marginTop: "30px" }}>Company CIF </label>
                  </div>
                  <div>
                    <label style={{ fontSize: "16px", fontWeight: "semibold", marginTop: "30px" }}>Fecha de creacion de la </label>
                  </div>
                  <div>
                    <label style={{ fontSize: "16px", fontWeight: "semibold", marginTop: "30px" }}>Sector </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <input
                    style={{ marginTop: "24px", width: "300px" }}
                    type="text"
                    className="form-control"
                    minLength={3} maxLength={40}
                    placeholder=""
                    id=""
                    defaultValue={date.company_name}
                    required
                    ref={company_nameRef}
                  />
                  <input
                    style={{ marginTop: "24px", width: "300px" }}
                    type="text"
                    className="form-control "
                    placeholder=""
                    id=""
                    defaultValue={date.company_cif}
                    required
                    minLength={3} maxLength={20}
                    ref={company_cifRef}
                  />
                  <input
                    style={{ marginTop: "24px", width: "300px" }}
                    type="date"
                    className="form-control "
                    placeholder=""
                    id=""
                    defaultValue={date.company_creation_date}
                    required
                    ref={company_creation_dateRef}
                    max={currentDate}
                  />
                  {(sectorsList != null) ?
                    // <select
                    //   className="form-select"
                    //   style={{ marginTop: "24px", width: "300px", height: "35px", fontSize: "14px" }}
                    //   defaultValue={date.cnae_sector}
                    //   name="sellist1"
                    //   ref={cnae_sectorRef}
                    // >
                    //   {sectorsList.map((list, index) =>
                    //     <option value={list['sector_id']}>{list['sector']}</option>
                    //   )}
                    // </select> 


                    <Select
                      // id="sel10"
                      options={sectorsList.map((list) => ({
                        value: list['sector_id'],
                        label: list['sector'],
                      }))}
                      components={{ DropdownIndicator }}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          width: '56%',
                          marginTop: "24px",
                          fontSize: 14,
                          height: "auto",
                        }),
                        menu: (provided) => ({
                          ...provided,
                          maxWidth: '580px',
                        }),
                      }}
                      ref={cnae_sectorRef}
                      onChange={(selectedOption) => setSector_id(selectedOption.value)}
                      // defaultValue={sectorsList.sector}
                      name="sellist1"
                      placeholder={date.sector}
                      isDisabled
                    />
                    : null}

                </div>
              </div>

              <div className="row">
                <h3 style={{ marginTop: "60px", marginBottom: "20px", color: "#183f4b " }}>Persona Responsable</h3>
                <div className="col-md-4">
                  <div>
                    <label style={{ fontSize: "16px", fontWeight: "semibold", marginTop: "30px" }} >Nombre de la persona Encargada </label>
                  </div>
                  <div>
                    <label style={{ fontSize: "16px", fontWeight: "semibold", marginTop: "30px" }}>Apellidos de la persona Encargada  </label>
                  </div>
                  <div >
                    <label style={{ fontSize: "16px", fontWeight: "semibold", marginTop: "30px" }}>Numero de telefono </label>
                  </div>
                  <button type="submit" className="btn cresc_bt1 my-5" style={{ width: "auto" }} >
                    Actualización del perfil
                  </button>
                </div>

                <div className="col-md-6">
                  <input
                    style={{ marginTop: "24px", width: "300px" }}
                    type="text"
                    className="form-control "
                    placeholder=""
                    id=""
                    minLength={3} maxLength={25}
                    defaultValue={date.name}
                    required
                    ref={nameRef}
                  />

                  <input
                    style={{ marginTop: "24px", width: "300px" }}
                    type="text"
                    className="form-control "
                    placeholder=""
                    id=""
                    minLength={1} maxLength={10}
                    defaultValue={date.surname}
                    required
                    ref={surnameRef}
                  />

                  <input
                    style={{ marginTop: "24px", width: "300px" }}
                    type="number"
                    className="form-control "
                    placeholder=""
                    id=""
                    defaultValue={date.phone_number}
                    required
                    ref={phone_numberRef}
                     onKeyDown={handlekeydown}
                            min="0"
                  />
                </div>
              </div>
            </form>
          </div>
        </section>



      </React.Fragment>
      : <Navigate to="/" replace />
  )
}


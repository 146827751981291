import React from "react";
import { Link } from "react-router-dom";
import * as images from './images.jsx';
import UserHeader from "../user/component/header.jsx";


export default function Visitor() {


  return (
    <React.Fragment>

      <UserHeader />

      <section style={{ backgroundColor: "#212529", height: "93vh" }}>
        {/* <img src="img/bgimg.jpg" style="width:100%;height:1000px"> */}
        <div className="container">
          <div style={{ marginTop: "20%" }}>
            <p style={{ textAlign: "center", color: "white", fontSize: 32 }}>
              Gracias por tu interés. Un agente se pondrá en contacto contigo con más
              información por correo electrónico en breve.
            </p>
          </div>
        </div>
      </section>

    </React.Fragment>
  )
}
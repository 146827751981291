import React from "react";
import { Link, Navigate } from "react-router-dom";
import $ from 'jquery';
import { useNavigate } from "react-router-dom";
import * as images from '../pages/images.jsx';
// import * as con from '../repository/urls.js';
import * as apis from '../repository/api_calls.js'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import LenderHeader from "./component/header.jsx";

export default function OfferConfirmed() {


  const role_id = localStorage.getItem("role_id");
  const creditScoreStatus = localStorage.getItem("creditScore_status");

  const token = localStorage.getItem("token");

  const navigate = useNavigate();

  // function logout() {
  //   fetch(`${process.env.REACT_APP_BASE_URL}${con.logout}`, {
  //     method: 'POST',
  //     headers: {
  //       "Authorization": `Bearer ${token}`,
  //     }
  //   })
  //     .then(async response => {
  //       const result = await response.json();
  //       if (result['status'] === true) {
  //         toast.success(result['message'], {
  //           position: toast.POSITION.TOP_RIGHT,
  //           className: 'foo_bar_log_Success'
  //         });
  //         setTimeout(() => {
  //           localStorage.clear();
  //           window.location.reload();
  //           navigate('/dashboard');
  //         }, 800);

  //       } else {
  //         toast.error(result['message'], {
  //           position: toast.POSITION.TOP_RIGHT,
  //           className: 'foo_bar_log_Success'
  //         });
  //       }
  //     })
  //     .catch(error => {
  //     });
  // }

  async function logout() {
    const response = await apis.getLogout();
    console.log(response)
    if (response['status'] === true) {
      toast.success(response['message'], {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo_bar_log_Success'
      });
      setTimeout(() => {
        localStorage.clear();
        window.location.reload();
        navigate('/dashboard');
      }, 800);

    } else {
      toast.error(response['message'], {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo_bar_log_error'
      });
    }
}



  return (
    ((role_id !== null && role_id === "3") && (creditScoreStatus !== null)) ?
      <React.Fragment>
        <div>
          <LenderHeader />
        </div>


        <ToastContainer autoClose={3000} />
        <section>

          <div className="row">
            <div className="col-md-2" style={{ backgroundColor: "white" }}>
              <img className="" src={images.profile} alt='profile' style={{ width: "90px", height: "90px", display: "block", margin: "0 auto" }} />
              <p style={{ textAlign: "center", fontWeight: "500" }}>Nombre del inversor</p>
              <p style={{ textAlign: "center", marginTop: "-10px", fontSize: "13px" }}>{localStorage.getItem("firstname") + " " + localStorage.getItem("lastname")}</p>


              <div style={{ display: "flex", justifyContent: "center", marginTop: "30px", marginLeft: "17px" }}>
                <img className="" src={images.pro_icon} alt='pro_icon' style={{ fontSize: "13px" }} />
                <a href="/lender/lenderdashbo">
                  <p style={{ color: "#828282", fontSize: "13px", marginTop: "4px", marginLeft: "7px", paddingTop: "9px" }}>
                  Panel
                  </p>
                </a>
              </div>

              <div style={{ display: "flex", justifyContent: "center", marginLeft: "10px" }}>
                <img className="" src={images.pro_icon} alt='pro_icon' style={{ fontSize: "13px" }} />
                <Link to="/lender/lender_profile">
                  <p style={{ color: "#828282", fontSize: "13px", marginTop: "4px", paddingLeft: "9px", paddingTop: "9px" }}>
                  Mi perfil
                  </p>
                </Link>
              </div>
              <div style={{ display: "flex", justifyContent: "center", marginLeft: "10px", marginTop: "90px" }}>
                <button type="submit" className="lender_logbut" onClick={logout}>Cerrar sesión</button>
              </div>
            </div>

            <div className="col-md-10" >

              <div className="container">
                <div style={{ backgroundColor: "white", height: "300px", width: "60%", margin: "100px" }}>
                  <img className="" src={images.Mys_mail} alt='pro_icon' style={{ fontSize: "13px", marginTop: "50px", marginLeft: "283px" }} />
                  <h4 style={{ textAlign: "center", marginTop: "20px" }}>Gracias, uno de nuestros representantes</h4>
                  <h4 style={{ textAlign: "center" }}>nos pondremos en contacto contigo en breve</h4>
                  <p style={{ textAlign: "center", marginTop: "10px" }}>Recibirás una notificación por correo electrónico y en tu perfil.<br />cuando tendrás acceso a más información</p>
                </div>
              </div>

            </div>

          </div>

        </section>




      </React.Fragment>
      : <Navigate to="/" replace />
  )
}








$(document).ready(function () {
  $(".circle_percent").each(function () {
    var $this = $(this),
      $dataV = $this.data("percent"),
      $dataDeg = $dataV * 3.6,
      $round = $this.find(".round_per");
    $round.css("transform", "rotate(" + parseInt($dataDeg + 180) + "deg)");
    $this.append('<div class="circle_inbox"><span class="percent_text"></span></div>');
    $this.prop('Counter', 0).animate({ Counter: $dataV },
      {
        duration: 2000,
        easing: 'swing',
        step: function (now) {
          // $this.find(".percent_text").text(Math.ceil(now)+"%"); you want percent uncommand  this in below line command
          $this.find(".percent_text").text(Math.ceil(now) + "");
        }
      });
    if ($dataV >= 51) {
      $round.css("transform", "rotate(" + 360 + "deg)");
      setTimeout(function () {
        $this.addClass("percent_more");
      }, 1000);
      setTimeout(function () {
        $round.css("transform", "rotate(" + parseInt($dataDeg + 180) + "deg)");
      }, 1000);
    }
  });
});
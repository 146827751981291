import React, {useRef, useMemo } from "react";
// import drawChart from "./char.jsx";
import * as d3 from "d3";
// import styles from "../assets/css/circle_css/circle.css";
// import Tooltip from "@material-ui/core/Tooltip";
// import { green } from "@material-ui/core/colors";

const MARGIN_X = 150;
const MARGIN_Y = 50;
const INFLEXION_PADDING = 0; // space between donut and label inflexion point

// const colors = [
//   "#e0ac2b",
//   "#e85252",
//   "#6689c6",
//   "#9a6fb0",
//   "#a53253",
//   "#69b3a2",
// ];


const DonutChart = ({ width, height, data, color }) => {

  // export const DonutChart = ({ width, height, data }) => {
  const ref = useRef(null);

  const radius = Math.min(width - 2 * MARGIN_X, height - 2 * MARGIN_Y) / 2;
  const innerRadius = radius / 2;
  const [reff,
    //  setReff
    ] = React.useState("");

  const pie = useMemo(() => {
    const pieGenerator = d3.pie().value((d) => d.value);
    return pieGenerator(data);
  }, [data]);

  const arcGenerator = d3.arc().padAngle(0.02);


  const shapes = pie.map((grp, i) => {
    // First arc is for the donut
    const sliceInfo = {
      innerRadius,
      outerRadius: radius,
      startAngle: grp.startAngle,
      endAngle: grp.endAngle,
    };
    // const centroid = arcGenerator.centroid(sliceInfo);
    const slicePath = arcGenerator(sliceInfo);

    // Second arc is for the legend inflexion point
    const inflexionInfo = {
      innerRadius: radius + INFLEXION_PADDING,
      outerRadius: radius + INFLEXION_PADDING,
      startAngle: grp.startAngle,
      endAngle: grp.endAngle,
    };
    const inflexionPoint = arcGenerator.centroid(inflexionInfo);

    const isRightLabel = inflexionPoint[0] > 0;
    const labelPosX = inflexionPoint[0] + 5 * (isRightLabel ? 1 : -1)
    const textAnchor = isRightLabel ? "start" : "end";
    const label = grp.data.name;

    return (
      <g
        key={i}
        className={"slice tooltip"}
        onMouseOver={() => {
          if (ref.current) {
            ref.current.classList.add('hasHighlight');
          }
        }}
        onMouseLeave={() => {
          if (ref.current) {
            ref.current.classList.remove('hasHighlight');
          }
        }}
      >
        <path d={slicePath} fill={color[i]} />

        {/* <circle cx={centroid[0]} cy={centroid[1]} r={2} className={"tooltiptext"} />
        <line
          x1={centroid[0]}
          y1={centroid[1]}
          x2={inflexionPoint[0]}
          y2={inflexionPoint[1]}
          stroke={"black"}
          fill={"black"}
          className={"tooltiptext"}
        />
        <line
          x1={inflexionPoint[0]}
          y1={inflexionPoint[1]}
          x2={labelPosX}
          y2={inflexionPoint[1]}
          stroke={"black"}
          fill={"black"}
          className={"tooltiptext"}
        /> */}


        <text
          x={labelPosX}
          y={inflexionPoint[1]}
          textAnchor={textAnchor}
          dominantBaseline="middle"
          fontSize={14}
          className={"tooltiptext"}
          fontWeight="bold" fill="black"
          enableBackground={"green"}
        >
          {label}
        </text>

      </g>
    );
  });

  return (
    <div style={{ display: "inline-block" }}>
      <svg style={{
        display: "inline-block", width: "20%",
        height: "30%"
      }}>
        <g
          transform={`translate(140, 100)`}
          className={'container'}
          ref={ref}
        >
          {shapes}
        </g>
      </svg>
      <p>{reff}</p>
    </div>
  );

};

export default React.memo(DonutChart);
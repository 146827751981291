import React from "react";
import { Link } from "react-router-dom"
import * as apis from '../repository/api_calls.js';
import * as images from '../pages/images.jsx';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';



export default function Login() {


  const emailRef = React.useRef();
  const passwordRef = React.useRef();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const email = emailRef.current.value
    const password = passwordRef.current.value
    const pattern = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;
    const result = pattern.test(email);
    if (result === true) {
      const response = await apis.loginFunc(email, password);
      if (response['status'] === true) {
        console.log(response)
        localStorage.setItem("userdata", JSON.stringify(response));
        localStorage.setItem("token", response.data['token']);
        localStorage.setItem("can_access", true);
        localStorage.setItem("cname", response.data['user']['company_name']);
        // role 3
        localStorage.setItem("bname", response.data['user']['business_name']);
        localStorage.setItem("registerDate", response.data['user']['date_of_registration']);
        localStorage.setItem("tax_number", response.data['user']['tax_number']);
        localStorage.setItem("lender_email", response.data['user']['email']);
        localStorage.setItem("lender_cmy_reg_number", response.data['user']['company_registration_number']);
        //
        localStorage.setItem("user_type", response.data['user']['type'])
        localStorage.setItem("id", response.data['user']['id'])
        localStorage.setItem("firstname", response.data['user']['name'])
        localStorage.setItem("lastname", response.data['user']['surname'])
        localStorage.setItem("role_id", response.data['user']['role_id'])
        localStorage.setItem("creditScore_status", "done");
        if (response.data['user']['role_id'] === '2' || 2) {

          toast.success(response['message'], {
            position: toast.POSITION.TOP_RIGHT,
            className: 'foo_bar_log_Success'
          });
          window.location.href = "/dashboard";
        }
        if (response.data['user']['role_id'] === '3' || 3) {

          toast.success(response['message'], {
            position: toast.POSITION.TOP_RIGHT,
            className: 'foo_bar_log_Success'
          });
          window.location.href = "/lender/lenderdashbo";
        }
      } else {
        toast.error(response['message'], {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo_bar_log_error'
        });
      }
    } else {
      toast.warn("Email inválido", {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo_bar_log_eil_wa'
      });
    }
  }

  const handleChange = (e) => {
    if (emailRef.current.value === 'email') {
      validateEmail(e.target.value);
    }
  }

  const validateEmail = (email) => {
    const pattern = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;
    const result = pattern.test(email);
    if (result === true) {
      this.setState({
        emailError: false,
        email: email
      })
    } else {
      this.setState({
        emailError: true
      })
    }
  }


  return (
    <React.Fragment>
      <section className="lo_x">
        <div className="d-md-flex vh-100">
          <div className="col-md-7 order-2 my-auto">
            <div className="d-md-flex align-items-center p-3 justify-content-center">
              <div data-aos="fade-up" style={{ width: 420 }}>
                <div className="row mb-3">
                  <div className="col-md-8 mb-2">
                    <h3 className="">¡Bienvenido!</h3>
                  </div>
                  <div className="col-md-4 align-self-end mb-3 text-end">
                    <Link to="/auth/Register">
                      <span>
                        <i className="fa fa-long-arrow-right me-3" aria-hidden="true"></i>
                      </span>
                      <span style={{ textTransform: "capitalize" }}> Registrarme</span>
                    </Link>
                  </div>
                </div>

                <ToastContainer autoClose={3000} />
                <form onSubmit={handleSubmit} method="post" className="form-signin">
                  <div className="form-label-group">

                    <input
                      className="form-control"
                      id="email"
                      name="email"
                      placeholder="Dirección de correo"
                      required
                      type="email"
                      onChange={(e) => { handleChange(e) }}
                      ref={emailRef}
                      style={{ height: 45 }}
                    />
                    <label>Dirección de correo</label>
                  </div>

                  <div className="form-label-group">

                    <input
                      id="confirm_password"
                      type="password"
                      className="form-control password-field my-5"
                      name="confirm_password"
                      placeholder="Contraseña"
                      required
                      minLength={6} maxLength={12}
                      ref={passwordRef}
                      style={{ height: 45 }}
                    />
                    <label>Contraseña</label>
                  </div>

                  <div className="checkbox mb-5" style={{ marginLeft: 20 }}>
                    <input
                      className="mr-2"
                      id="termsandconditions"
                      name="termsandconditions"
                      type="checkbox"
                      defaultValue="y"
                    // required
                    />
                    Recuérdame{" "}
                  </div>

                  <div className="form-group d-grid"  >
                    <input
                      className="btn  btn-primary-orange btn-block mb-3"
                      id="submit"
                      name="submit"
                      type="submit"
                      value="Iniciar Sesión"
                      style={{ width: "480px", fontSize: "18px", textTransform:"capitalize" }}                     
                    />
                  </div>





                </form>

                <Link to="/auth/resetpasswordmail" className="text-primary text-decoration-none">
                  Restablecer la contraseña
                </Link>
              </div>
            </div>
          </div>

          {/* <!-- First Half --> */}

          <div className="col-md-4  leftsplit order-1 vh-100 bg-dark text-light reg-img-bg">

            <div className="d-flex flex-column align-items-center p-5 loginmargin-top">
              <img className="" src={images.Logo} alt='Logo' height="40px" />
              <h3 className="mt-4 fw-bold log_p" >Registrate Ahora</h3>
              <p className="fw-light log_p">Accede a las mejores ofertas de financiación en el mercado</p>
              <a href="/auth/Register" className="btn btn-primary-orange my-3" role="button">
                <span style={{ textTransform: "capitalize" }}> Registrarme</span>
              </a>
            </div>

          </div>

        </div>
      </section>


    </React.Fragment>
  )
}
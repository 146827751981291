

export const login = "postlogin"                     //login api
export const register = "postregister"               //user registration api
export const lenderRegister = "lender_register"      //lender registration api


export const subscription = "store_subscription_user"   //mail subscription
export const guestid = "guest_user_id"                 // guest user credit form api 


export const storeUserCompanyUrl = "store_user_company"
export const creditscoreguest = "store_guest_user_score"
export const creditscoreguestupdate = "store_guest_user_score_update"

export const creditscoreregister = "post_save_score"
export const creditscoreregisterupdate = "post_save_score_update"

//company insert(gust and register user)
export const getloan_get2 = "store_guest_user_company"
export const company_register2 = "post_save_company"

export const getloan_get2_update = "store_guest_user_company_update"
export const getloan_register2 = "post_save_company_update"


export const getloan_register = "post_save_loan"
export const getloan_get = "store_guest_user_loan"
export const guest1 = "store_guest_user"
export const guest2 = "store_guest_user_company_creation"


export const credit_user = "user_credit_score"
export const loan_offer = "user_offer"
export const guest_score = 'guest_user_my_score';

// profile update  
export const profileupdate = 'update_user_profile';
export const visitor = 'storevisitor';
// export const user_my_score = 'user_my_score';

// Forgot Password
export const forget = "forgot";
// Confirm Password
export const confirm_pwd = "change_password";
// Dashboard Score 
export const dashboard_scoreid = "guest_user_my_score";
// Get Loan
export const getloan_user = "user_loan"
// Lender
export const loanOfferList = "lender_loan_users_details"

export const Score_user = "user_my_score"
export const lender_loan_offer = "loan_offer"
export const lenderOfferStatus = "lender_offer_status";
export const lenderOfferList = "lender_offer_list";
export const userOfferDetails = "offers_details";
export const loanFilledStatus = "loancompany";
export const selfReject = "self_reject";

export const usersLoanStatus = "users_loan_status";
export const loanOfferStatus = "loan_offer_status";

export const companySectorURL = "company_sector";
export const updateLenderProfile = 'update_lender_profile';

export const doccorporation_tax = 'corporation_tax';
export const guest_profile = 'guest_user_profile';
export const user_profile = 'user_profile';
export const logout = 'logout';

export const ScoretipCalculation = "calculation_category"

// export const loanOfferList = "lender_offer_list"
// export const loanOfferList1 = "lender_loan_users_details"
import React from "react";
import { Link, Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import * as con from '../repository/urls.js';
import * as apis from '../repository/api_calls.js';
import CircleProgressBar1 from "../circle/dashboard_circle.jsx";
import * as images from './images.jsx';
import { useNavigate } from "react-router-dom";
import DonutChart from './charts.jsx';
import CircleProgressBar from "../circle/circle.jsx";
import UserHeader from "../user/component/header.jsx";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { useReducer } from 'react';




function reducer(state, action) {
  if (action.type === 'ACCEPT') {

    let list1 = state.userLoanOffers;
    if (action.act === 'Accept') {
      list1[action.index]['status'] = "accepted";
      state.userLoanOffers = list1;
      return {
        userLoanOffers: state.userLoanOffers
      };
    } else {
      list1[action.index]['status'] = "rejected";
      state.userLoanOffers = list1;
      return {
        userLoanOffers: state.userLoanOffers
      };
    }


  }
  if (action.type === 'SET') {
    return {
      userLoanOffers: action.value
    };
  }
  throw Error('Unknown action.');
}




export default function Home() {

  const [state, dispatch] = useReducer(reducer, { userLoanOffers: [] })
  // const [userOfferData, setUserLoanData] = React.useState([]);
  const [userscore, setuserscore] = useState()
  const [loanScore, setloanScore] = useState()

  const [colors, setColors] = useState();

  const user_id = localStorage.getItem("id");
  const role_id = localStorage.getItem("role_id");
  const token = localStorage.getItem("token");
  const creditScoreStatus = localStorage.getItem("creditScore_status");


  function convertStringToList(item) {
    // console.log('convert to list ********** ')
    // console.log(item)
    if (item !== 'null' && item != undefined) {
      var data = JSON.parse(item);
      // console.log(item);
      // console.log(data[0])
      return data;
    } else {
      return null;
    }
  }

  // function convertStringToList2(item) {
  //   var data2 = JSON.parse(item)
  //   console.log("asfgafsgafsgf")
  //   console.log(item)
  //   return data[0];
  // }



  //=========================================  cs Edit API (onload)

  const stop = React.useRef(true);
  const [loanFilled, setLoanFilled] = useState({
    "loan": "not_Filled",
    "company_one": "not_Filled",
    "company_two": "not_Filled",
    "company_three": "not_Filled",
    "company_four": "not_Filled"
  });

  const data = [
    { name: "STEP 1", value: 20 },
    { name: "STEP 2", value: 20 },
    { name: "STEP 3", value: 20 },
    { name: "STEP 4", value: 20 },
    { name: "STEP 5", value: 20 },
  ];


  useEffect(() => {
    getUserLo();
    getLoanFilled();
    GetUserofferd();
    getuser();
  }, [])

  async function getUserLo() {
    const response = await apis.getUserLoan();
    console.log(response)
    if (response['status'] === true) {
      for (let i = 0; i < response['data'].length; i++) {
        if (response['data'][i]['main_status'] === "Active") {
          setloanScore(response['data'][i]);
        }
      }
      localStorage.setItem("loanScore", response.data.loan_ran_id);
      localStorage.setItem("status", response.data.status);
    }
  }

  // useEffect(() => {
  //   fetch(`${process.env.REACT_APP_BASE_URL}${con.getloan_user}`, {
  //     method: 'post',
  //     headers: {
  //       "Authorization": `Bearer ${token}`,
  //     }
  //   })
  //     .then(res => res.json())
  //     .then(res => {
  //       if (res['status'] === true) {
  //         for (let i = 0; i < res['data'].length; i++) {
  //           if (res['data'][i]['main_status'] === "Active") {
  //             setloanScore(res['data'][i]);
  //           }
  //         }
  //         localStorage.setItem("loanScore", res.data.loan_ran_id);
  //         localStorage.setItem("status", res.data.status);
  //       }
  //     });


  //   getLoanFilled();
  //   showData();

  //   getuser()
  // }, [])



  async function GetUserofferd() {
    const response = await apis.GetUserofferdetail();
    console.log(response)
    if (response['status'] === true) {
      for (let i = 0; i < response['data'].length; i++) {
        response['data'][i]['modelo_variable1'] = JSON.parse(response['data'][i]['modelo_variable'])
      }
      if (response['data'].length <= 3) {
        dispatch({ type: 'SET', value: response['data'] })
        // console.log("api result")
        // console.log(response['data']);
      } else {
        let localData = [];
        for (let i = 0; i < 3; i++) {
          localData.push(response['data'][i]);
        }
        dispatch({ type: 'SET', value: response['data'] })
      }
    }
  }


  async function getuser() {
    const response = await apis.getDashboardScore(JSON.stringify({
      "user_id": user_id,
    }),);
    console.log(response)
    if (response['status'] === true) {
      for (let i = 0; i < response['data'].length; i++) {
        if (response['data'][i]['status'] === "Active") {
          setuserscore(response['data'][i]);
        }
      }
      localStorage.setItem("userscore", response.data.user_score);
    } else {
      localStorage.setItem("userscore", '-');
    }
  }



  function getLoanPercent(score) {
    var percentage = '';
    if (score < 0) {
      percentage = 'Error - Data Incompleta';
    }
    else if (score > 0 && score <= 50) {
      percentage = 'Bajo';
    }
    else if (score > 50 && score <= 66) {
      percentage = 'Regular';
    }
    else if (score > 66 && score <= 79) {
      percentage = 'Bueno';
    }
    else if (score >= 80 && score < 90) {
      percentage = 'Muy Bueno';
    }
    else if (score >= 90 && score <= 100) {
      percentage = 'Excelente';
    } else {
      percentage = 'Indefinida';
    }
    return percentage;
  }





  async function getLoanFilled() {
    //get loan fill details
    const response = await apis.getLoanFilledData(token);
    var colo = ["#C4C4C4", "#C4C4C4", "#C4C4C4", "#C4C4C4", "#C4C4C4"];
    if (response['status'] === true) {
      setLoanFilled(response['data'])

      if (response['data']['loan'] === 'Fill') {
        colo[0] = "#27AE60";
      }
      if (response['data']['company_one'] === 'Fill') {
        colo[1] = "#27AE60";
      }
      if (response['data']['company_two'] === 'Fill') {
        colo[2] = "#27AE60";
      }
      if (response['data']['company_three'] === 'Fill') {
        colo[3] = "#27AE60";
      }
      if (response['data']['company_four'] === 'Fill') {
        colo[4] = "#27AE60";
      }
      // console.log(colo);
      setColors([...colo]);
    } else {
      setColors([...colo]);
    }
  }

  async function getOfferStatus(id, status, reason, index) {
    if (stop.current) {
      return null;
    } else {
      const response = await apis.offerStatus(id.offer_id, status, reason, token);
      console.log(response)
      if (response['status'] === true) {
        // let list1 = userOfferData;
        // dispatch({ type: 'ACCEPT' , act:status , index:index })
        window.location.reload();
        // if (status === 'Accept') {
        //   list1[index]['status'] = "accepted";
        //   setUserLoanData([...list1])
        // } else {
        //   list1[index]['status'] = "rejected";
        //   setUserLoanData([...list1])
        // }    Line 291:  'userOfferData' is not defined  no-undef  
      } else {
        window.location.reload();
      }
    }
  }


  function navToGetloan(index) {
    // navigate('/getloan', { state: index });
    window.location.href = `/getloan?id=${index}`
  }
  const navigate = useNavigate();

  function moveToScoreTips(data) {
    localStorage.setItem("score_tips", JSON.stringify(data));
    navigate('/score_tips');
  }

  return (
    ((role_id !== null && role_id === "2") && (creditScoreStatus !== null)) ?
      <React.Fragment>
        <ToastContainer autoClose={3000} />

        <UserHeader />


        <section style={{ backgroundColor: "#FFFFFF", height: "65px", top: "50px", position: "sticky", zIndex: 2 }}>
          <div className="container">
            <div className="row" style={{ padding: 10 }}>
              <div className="col-md-3" style={{ display: "flex" }}>
                <a href="/dashboard" style={{ textDecoration: "none" }}>
                  <p style={{ color: "#0069CB", fontSize: "18px" }}>
                    <span>
                      <img className="" src={images.dash_blue} alt='dash_blue' />
                    </span>
                    <span style={{ textTransform: "capitalize" }}>Panel</span>
                  </p>
                </a>
                <div
                  style={{
                    borderLeft: "2px solid #E0E0E0",
                    height: 45,
                    marginLeft: 80
                  }}
                ></div>
              </div>

              <div className="col-md-3" style={{ display: "flex", marginLeft: "-38px" }}>
                <a href="/myscore" style={{ textDecoration: "none" }}>
                  <p style={{ color: "#183F4B", fontSize: "16px" }}>
                    <span>
                      <img className="" src={images.z_score_dark} alt='z_score_dark' />
                    </span>{" "}
                    <span style={{ textTransform: "capitalize" }}>Mi puntaje</span>
                  </p>
                </a>
                <div
                  style={{
                    borderLeft: "2px solid #E0E0E0",
                    height: 45,
                    marginLeft: 80
                  }}
                ></div>
              </div>
              <div className="col-md-3" style={{ display: "flex" }}>
                <a href="/dashboardapp" style={{ textDecoration: "none" }}>
                  <p style={{ color: "#183F4B", fontSize: "18px", marginTop: "2px", marginLeft: "-20px" }}>
                    <span>
                      <img className="" src={images.applica_dark} alt='applica_dark' style={{ marginRight: 7 }} />
                    </span>{" "}
                    <span style={{ textTransform: "capitalize" }}> Solicitudes enviadas</span>
                  </p>
                </a>
                <div
                  style={{
                    borderLeft: "2px solid #E0E0E0",
                    height: 45,
                    marginLeft: 80
                  }}
                ></div>
              </div>
              <div className="col-md-3">
                <a href="/dashboardloan" style={{ textDecoration: "none" }}>
                  <p style={{ color: "#183F4B", fontSize: "18px", }}>
                    <span>
                      <img className="" src={images.loan_offer_dark} alt='loan_offer_dark' />
                    </span>
                    <span style={{ textTransform: "capitalize" }}> Ofertas recibidas</span>
                  </p>
                </a>
              </div>




            </div>
          </div>
        </section>


        {/* {(userscore === undefined) ? */}

        {((userscore === undefined) || (userscore['user_score'] === null)) ?
          (
            <section className="container" style={{ marginTop: 20 }}>
              <div className="card myscorecrd" >

                <div style={{ display: "flex" }}>
                  <div style={{ display: "grid", marginLeft: 5 }}>
                    <h4 style={{ color: "white", fontWeight: 800, fontSize: "21px" }}>¿Puede tu negocio ahora recibir financiamiento? </h4>
                    <p style={{ color: "white", fontSize: "11px" }}>
                      Completa todos los formularios y descubre tu puntaje ahora mismo
                    </p>
                    <Link to="/creditscore">
                      <button
                        type="button"
                        className="btn1 btn_das"
                        style={{ color: "white", height: "38px", width: "auto", marginTop: 0, fontSize: "14px", fontWeight: "600" }}>

                        CALCULA TU PUNTAJE
                      </button>
                    </Link>
                  </div>
                  {/* <div style={{marginLeft:"500px"}}>
                        {((userscore===undefined)||(userscore['user_score']===null))? 
                        <div style={{display:"flex"}}>
                        <CircleProgressBar1 percentage={0}  /> 
                                    
              </div>: null} 
              </div> */}

                  <div style={{ marginLeft: "450px" }}>

                    <CircleProgressBar />
                  </div>
                </div>
              </div>
            </section>) :



          <section className="container" style={{ marginTop: "40px" }}>
            <div className="scorecard">
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    backgroundColor: "#293042",
                    width: 300,
                    display: "flex",
                    padding: 20,
                    borderBottomLeftRadius: 6,
                    borderTopLeftRadius: 6
                  }}
                >


                  {/* {(userscore===undefined)? <CircleProgressBar1 percentage={0}  /> :
              <div style={{display:"flex"}}>
               
                <CircleProgressBar1 percentage={userscore['user_score']} scolor={getLoanPercent(userscore['user_score'])} /> */}

                  {((userscore === undefined) || (userscore['user_score'] === null)) ?
                    <div style={{ display: "flex" }}>
                      <CircleProgressBar1 percentage={0} />
                      <p style={{ marginTop: 30, marginLeft: 25 }}>
                        <br />
                        <span style={{ color: "#FAFAFD" }}>Puntaje de crédito</span>{" "}

                      </p>
                    </div>

                    :
                    <div style={{ display: "flex" }}>
                      <CircleProgressBar1 percentage={userscore['user_score']} scolor={getLoanPercent(userscore['user_score'])} />


                      <p style={{ marginTop: 30, marginLeft: 25 }}>
                        <i style={{ color: "#F2994A", fontSize: 18 }}>{getLoanPercent(userscore['user_score'])}</i>
                        <br />
                        <span style={{ color: "#FAFAFD" }}>Puntaje de crédito</span>{" "}

                      </p>
                    </div>

                  }
                </div>

                <div style={{ marginLeft: 60, padding: 20, marginTop: "8px" }}>

                  <div><p><b>ID DE PUNTUACIÓN DE CRÉDITO : </b>
                    {(userscore === undefined) ? ' - ' : <span className="badge bg-primary" style={{ marginTop: "-4px" }}>{userscore['score_ran_id']}</span>}
                  </p></div>

                  <p style={{ marginTop: "-7px" }}>Obtenga sugerencias para mejorar su puntaje de crédito. </p>
                  <button onClick={() => { moveToScoreTips(userscore); }} className="das_sco" style={{ marginLeft: "-4px", paddingTop: "20px" }}>
                    Ver detalles
                    <span style={{ marginLeft: "10px" }}>
                      <i className="fa fa-long-arrow-right" aria-hidden="true" />
                    </span>
                  </button>
                  <br />

                  {(userscore === undefined) ? (
                    <a href="/creditscore" >  <button type="button" className="btn1 btn-cta" style={{ height: 32, marginTop: 10, fontSize: 17 }} >Verifique el puntaje de crédito</button></a>
                  ) : null}
                </div>

                <img className="" src={images.rockt} alt='rockt' style={{ marginLeft: 250, padding: 10, height: 100, marginTop: 20 }} />
              </div>
            </div>
          </section>
        }



        <section className="container" style={{ marginTop: 40 }}>

          <div className="loancard" style={{ padding: 20 }}>
            <div style={{ display: "flex" }}>
              <div>
                <h3 style={{ fontWeight: 600 }}>Exprime al máximo Credit Store completando<br /> la mayor información posible sobre tu negocio
                </h3>

                {(loanScore !== undefined) ?
                  <div style={{ marginTop: "20px" }}><p><b>ID DE PRÉSTAMO : </b>
                    <span className="badge bg-primary">  {loanScore['loan_ran_id']}</span>
                  </p></div> :
                  null}

                {/* {(loanScore !== undefined) ?
                  <div><p><b>ESTADO :  </b> <span className="badge bg-success" style={{ marginLeft: "8px" }}>  {loanScore['status']}</span>
                  </p></div> :
                  null} */}

                {(loanScore !== undefined) ?
                  <div><p><b>ESTADO <span style={{ marginLeft: "60px" }}>:</span>  </b> <span className="badge bg-success" style={{ marginLeft: "3px" }}>
                    {(loanScore['status'] === 'Open') ?
                      <span > Abierta</span> : (loanScore['status'] === 'Reject') ?
                        <span > Desestimar</span> : (loanScore['status'] === 'Close') ?
                          <span > Cerrar</span> : (loanScore['status'] === 'Pending') ?
                            <span >Pendiente</span> :
                            <span >Revisar</span>
                    }</span>
                  </p></div> :
                  null}

              </div>
              <div style={{ marginLeft: "353px", marginTop: "-50px", }}>
                {(colors !== undefined) ?
                  //  <DonutChart data={data} color={colors} />
                  <DonutChart data={data} width={400} height={400} color={colors} />
                  : null}
              </div>

            </div>

            <div className="row" style={{ marginTop: "35px", marginLeft: "-50px" }} >
              <div style={{ display: "flex", justifyContent: "space-evenly" }}>

                <div className="col-md-2" style={{ cursor: "pointer" }} onClick={() => { navToGetloan("0") }}>
                  {/* <div className="col-md-2" > */}
                  {/* <a href="/getloan" style={{ textDecoration: "none" }}> */}
                  <div className="loancard2"
                    style={(loanFilled['loan'] === 'Fill') ? { backgroundColor: '#27AE60' } :
                      {}}>

                    <div className="loanCardImg">
                      <img alt='' src={(loanFilled['loan'] === 'Fill') ? images.loan_appli_w
                        : images.loan_appli_d} />
                    </div>

                    <div className="loanCardTitle">
                      <h5 className="center" style={(loanFilled['loan'] === 'Fill') ? {
                        color: "white"
                      } : {
                        color: "#0069CB"
                      }}
                      >Solicitud de  <br />
                        <span style={{ textTransform: "capitalize" }}>  préstamo</span>
                      </h5>
                    </div>





                    {(loanFilled['loan'] === 'Fill') ?
                      <div className="loanCardFooter">
                        <i className="fa fa-check-circle das_fo" aria-hidden="true" >
                        </i>
                        <span style={{ textTransform: "capitalize" }}>Formulario llenado</span>
                      </div>
                      : null}


                  </div>
                  {/* </a> */}
                </div>

                <div className="col-md-2" style={{ cursor: "pointer" }} onClick={() => { navToGetloan("1") }}>
                  {/* <div className="col-md-2"> */}
                  {/* <Link to="/getloan" style={{ textDecoration: "none" }}> */}
                  <div className="loancard2"
                    style={(loanFilled['company_one'] === 'Fill') ? { backgroundColor: '#27AE60' } :
                      {}}
                  >
                    <div className="loanCardImg">
                      <img alt='' src={loanFilled['company_one'] === 'Fill' ? images.company_info_w
                        : images.company_info_d} />
                    </div>

                    {/* <img alt='' src={loanFilled['company_one'] === 'Fill' ? images.company_info_w
                        : images.company_info_d} style={{ fontSize: "3rem", paddingTop: "20px", paddingLeft: "70px" }} /> */}

                    <div className="loanCardTitle">
                      <h5 className="center" style={(loanFilled['company_one'] === 'Fill') ? {
                        color: "white"
                      } : {
                        color: "#0069CB"
                      }}>
                        Información de la <br />Empresa
                      </h5>
                    </div>

                    {(loanFilled['loan'] === 'Fill') ?
                      <div className="loanCardFooter">
                        <i className="fa fa-check-circle das_fo" aria-hidden="true" >
                        </i>
                        <span style={{ textTransform: "capitalize" }}>Formulario llenado</span>
                      </div>
                      : null}

                  </div>
                  {/* </Link> */}
                </div>

                <div className="col-md-2" style={{ cursor: "pointer" }} onClick={() => { navToGetloan("2") }}>
                  {/* <div className="col-md-2" > */}
                  {/* <Link to="/getloan" style={{ textDecoration: "none" }}> */}
                  <div className="loancard2"
                    style={loanFilled['company_two'] === 'Fill' ? { backgroundColor: '#27AE60' } : {}}>

                    <div className="loanCardImg">
                      <img alt='' src={(loanFilled['company_two'] === 'Fill') ? images.partners_w
                        : images.partners_d} />
                    </div>

                    <div className="loanCardTitle">
                      <h5 className="center" style={(loanFilled['company_two'] === 'Fill') ? {
                        color: "white",
                      } : {
                        color: "#0069CB"
                      }}>
                        Socios y Representante Legal
                      </h5>
                    </div>

                    {(loanFilled['loan'] === 'Fill') ?
                      <div className="loanCardFooter">
                        <i className="fa fa-check-circle das_fo" aria-hidden="true" >
                        </i>
                        <span style={{ textTransform: "capitalize" }}>Formulario llenado</span>
                      </div>
                      : null}


                  </div>
                  {/* </Link> */}
                </div>

                <div className="col-md-2" style={{ cursor: "pointer" }} onClick={() => { navToGetloan("3") }}>
                  {/* <div className="col-md-2" onClick={() => { navToGetloan("3") }}> */}
                  {/* <Link to="/getloan" style={{ textDecoration: "none" }}> */}
                  <div className="loancard2"
                    style={loanFilled['company_three'] === 'Fill' ? { backgroundColor: '#27AE60' } : {}}>

                    <div className="loanCardImg">
                      <img alt='' src={loanFilled['company_three'] === 'Fill' ? images.legal_w
                        : images.legal_d} />
                    </div>

                    <div className="loanCardTitle">
                      <h5 className="center" style={(loanFilled['company_three'] === 'Fill') ? {
                        color: "white"
                      } : {
                        color: "#0069CB"
                      }}>
                        Representante <br /> Legal
                      </h5>
                    </div>


                    {(loanFilled['loan'] === 'Fill') ?
                      <div className="loanCardFooter">
                        <i className="fa fa-check-circle das_fo" aria-hidden="true" >
                        </i>
                        <span style={{ textTransform: "capitalize" }}>Formulario llenado</span>
                      </div>
                      : null}

                  </div>
                  {/* </Link> */}
                </div>
                <div className="col-md-2" style={{ cursor: "pointer" }} onClick={() => { navToGetloan("4") }}>
                  {/* <div className="col-md-2" > */}
                  {/* <Link to="/getloan" style={{ textDecoration: "none" }}> */}
                  <div className="loancard2"
                    style={loanFilled['company_four'] === 'Fill' ? { backgroundColor: '#27AE60' } :
                      {}}>

                    <div className="loanCardImg">
                      <img alt='' src={loanFilled['company_four'] === 'Fill' ? images.documents_w
                        : images.documents_d} />
                    </div>
                    <div className="loanCardTitle">
                      <h5 className="center" style={(loanFilled['company_four'] === 'Fill') ? {
                        color: "white"
                      } : {
                        color: "#0069CB"
                      }}>
                        Documentos <br />Suplementarios
                      </h5>
                    </div>


                    {(loanFilled['loan'] === 'Fill') ?
                      <div className="loanCardFooter">
                        <i className="fa fa-check-circle das_fo" aria-hidden="true" >
                        </i>
                        <span style={{ textTransform: "capitalize" }}>Formulario llenado</span>
                      </div>
                      : null}



                  </div>
                  {/* </Link> */}
                </div>

              </div>

            </div>
          </div>
        </section>

        <br /><br />

        <section className="container">
          {(state.userLoanOffers.length >= 2) ?

            <div>

              <div>

                <Link to="/dashboardloan">

                  <button
                    type="submit"
                    className="btn1 btn-cta mb-5 me-5"
                    style={{ height: 35, marginTop: 0, fontSize: 17, float: "right" }}
                  >
                    Más oferta
                  </button>
                </Link>


              </div>
            </div> : null
          }
        </section>



        {/* {state.userLoanOffers.slice(0, 3).map((list, index) => {

})} */}


        <section className="container mb-5">
          <div className="row">

            {/* {state.userLoanOffers.map((list, index) => { */}
            {state.userLoanOffers.slice(0, 3).map((list, index) => {

              return (
                <div className="col-md-4" >
                  <div className="card dashcard" style={{ height: "540px", overflow:"auto" }}>
                    <br></br>

                    <div className="smallcard" style={{ marginBottom: "20px" }}>
                      <img className="offerimg" src={images.offer_e} alt='Logo' />
                      <p className="offertext">Oferta : {list.offer_id}</p>
                    </div>
                    <div>
                      {(list['offer_status'] !== null) ? (list.offer_status === "Accept") ?
                        <p style={{ textAlign: "right", padding: "10px", marginTop: "-50px" }}> <span className="badge_use bg-success" style={{ marginTop: "-15px", borderRadius: "6px", height: "25px" }}>Aceptar</span> </p> :
                        <p style={{ textAlign: "right", padding: "10px", marginTop: "-50px" }}> <span className="badge_use bg-danger" style={{ marginTop: "-15px", borderRadius: "6px", height: "25px" }}>Desestimar</span> </p>
                        : null
                      }
                    </div>
                    {/* <p class="loanoffertxt" style={{marginTop:"-49px"}}> {list.type}</p> */}
                    <p className="loanoffertxt" style={{ marginTop: "0px" }}> {(list.type === "Loan") ?
                      <span>Prestamo</span> : (list.type === "Credit Line") ?
                        <span>Linea de Credito</span> :
                        <span>Factoring</span>}</p>
                    <p className="loanoffertxt2" style={{ textDecoration: "underline" }}> Titulo del prestamo: {list.loan_title}</p>

                    <div className="innercard" style={{ paddingBottom: "1px", marginTop: "7px" }}>

                      <div style={{ display: "flex" }}>
                        <p className="cardtextn" style={{ marginLeft: "5px" }} > IMPORTE: {list.imported}</p>
                        <p className="cardtextn" style={{ marginLeft: "51px" }}>FECHA : {list.created_at} </p>
                      </div>
                      <div style={{ display: "flex" }}>
                        <p className="cardtextn" style={{ marginLeft: "5px" }}> TIN: {list.interest_rate}</p>
                        {(list.duration !== null && list.duration !== undefined) &&
                          <p className="cardtextn" style={{ marginLeft: "76px" }}> DISPONINILIDAD: <span className="cardtextb">{list.duration}</span></p>
                        }
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>
                          {(list.permanent !== null && list.permanent !== undefined) &&
                            <p className="cardtextn" style={{ marginLeft: "5px" }}>  FIJO: <span className="cardtextb">{list.permanent}</span></p>
                          }
                          {(list.resource !== null && list.resource !== undefined) &&
                            <p className="cardtextn" style={{ marginLeft: "5px" }}>  Recurso: <span className="cardtextb">{list.resource}</span></p>
                          }
                          <p className="cardtextn" style={{ marginLeft: "5px" }}> GARANTIAS: <span className="cardtextb">{list.guarantees}</span></p>
                        </div>
                        <p className="cardtextn" style={{ marginLeft: "37px", marginTop: "10px" }}> FRECUENCIA DE<br /> REEMBOLSO: <span className="cardtextb" style={{ marginLeft: "25px" }}> {list.payment_frequency}</span></p>
                      </div>

                      <div style={{ display: "flex" }}>




                        {(list.lack === "Yes") ? (list.lack !== "No") ?
                          <div style={{ display: "flex" }}>
                            {(list.modelo_variable1 !== null && list.modelo_variable1 !== undefined) &&
                              <p className="cardtextn MODULO " style={{ marginLeft: "5px" }}> MODULO</p>
                            }

                            <p>{(list.modelo_variable1 !== null && list.modelo_variable1 !== undefined) && list.modelo_variable1.map((list12, index) => {

                              return (
                                <div>
                                  <p className="cardtextn meses1">
                                    {(list12['percentage']!=null && list12['percentage']!=undefined) ? list12['percentage'] : "0" }%
                                    {   (list12['from_month']!=null && list12['from_month']!=undefined) ? list12['from_month'].match(/\d+/g) : ''}  -   
                                     {(list12['to_month']!=null && list12['to_month']!=undefined) ? list12['to_month'].match(/\d+/g) : ''  }messes

                                  </p>

                                </div>

                              )
                            })}</p> </div> :
                          <p>No data</p>
                          : null
                        }

                        {/* <p className="cardtextn" style={{marginLeft:"20px",paddingTop:"9px"}}> COBRO POR<br /> RENOVACION: <span className="cardtextb" style={{ marginLeft: "20px" }}>  Si</span></p> */}

                      </div>


                      <div style={{ display: "flex", marginTop: "-7px" }}>
                        <p className="cardtextb" style={{ marginLeft: "23px" }}> EUR</p>
                        <div className="eur1" style={{ marginLeft: "5px", textAlign: "center" }} >{list.imported_sub} </div>

                        <p className="cardtextb" style={{ marginLeft: "81px" }}> EUR</p>
                        <input type="text" className="eur1" value={list.lack_number} style={{ marginLeft: "6px", textAlign: "center" }}></input>


                      </div>
                      <p className="cardtextb" style={{ marginLeft: "5px", marginTop: "10px", marginBottom: "10px" }}><u> OTROS CARGOS:</u></p>

                      {(convertStringToList(list.add_more_field) !== null) ?

                        convertStringToList(list.add_more_field).map((list1, index) => {
                          return (

                           
                            <div>
                              <div className="row container-fluid" style={{marginLeft:"-30px"}}>
                                <div className="col-md-6">
                                  <tr>
                                    <td>
                                    <p className="MODULO" >APERTURA: </p>
                                    </td>
                                    <td>
                                    <div className="eur2" > {(list1.opening) !== null ?
                                 list1.opening  : ' - '}</div>
                               
                                    </td>
                                    <td>
                                    <p className="MODULO"> EUR</p>
                                    </td>
                                  </tr>
                                  <tr>
                                   <td>
                                   <p className="MODULO" >RETRASO: </p>
                                   </td>
                                   <td>
                                   <div className="eur2" > {(list1.delay) !== null ?
                                  list1.delay : ' - '}</div>
                                   </td>
                                   <td>
                                   <p className="MODULO"> EUR</p>
                                   </td>
                                  </tr>
                                  
                                  </div>
                                  <div className="col-md-6" >
                                    <tr>
                                      <td>
                                      <p className="MODULO" >CANCELACION: </p>
                                      </td>
                                      <td>
                                      <div className="eur2"  > {(list1.early_cancellation) !== null ?
                                  list1.early_cancellation : ' - '}</div>
                                      </td>
                                      <td>
                                      <p className="MODULO"> EUR</p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                      <p className="MODULO" >MANTENIMIENTO: </p>
                                      </td>
                                      <td>
                                      <div className="eur2" > {(list1.maintenance) !== null ?
                                  list1.maintenance : ' - '}</div>
                                      </td>
                                      <td>
                                      <p className="MODULO"> EUR</p>
                                      </td>
                                    </tr>
                                  
                                  </div>
                                </div>
                              {/* <div style={{ display: "flex", marginTop: "-7px" }}>
                                <p className="MODULO" style={{ marginLeft: "5px" }}>APERTURA: </p>
                                <div class="eur2" style={{ marginLeft: "10px", textAlign: "center" }} > {(list1.opening) !== null ?
                                 list1.opening  : ' - '}</div>
                                <p className="MODULO"> EUR</p>

                                <p className="MODULO" style={{ marginLeft: "42px" }}>CANCELACION: </p>
                                <div class="eur2" style={{ marginLeft: "10px", textAlign: "center" }} > {(list1.early_cancellation) !== null ?
                                  list1.early_cancellation : ' - '}</div>
                                <p className="MODULO"> EUR</p>

                              </div>
                              <div style={{ display: "flex", marginTop: "-12px" }}>
                                <p className="MODULO" style={{ marginLeft: "5px" }}>RETRASO: </p>
                                <div class="eur2" style={{ marginLeft: "10px", textAlign: "center" }} > {(list1.delay) !== null ?
                                  list1.delay : ' - '}</div>
                                <p className="MODULO"> EUR</p>
                                <p className="MODULO" style={{ marginLeft: "22px" }}>MANTENIMIENTO: </p>
                                <div class="eur2" style={{ marginLeft: "10px", textAlign: "center" }} > {(list1.maintenance) !== null ?
                                  list1.maintenance : ' - '}</div>
                              <p className="MODULO"> EUR</p>

                              </div> */}
                            </div>
                          )
                        }) : null
                      }



                      <div style={{ display: "flex", marginBottom: "20px", justifyContent: "space-around", marginLeft: "-11px" }}>
                        <button
                          type="button"
                          onClick={() => {
                            stop.current = false;
                            getOfferStatus(list, "Accept", "", index);
                            toast.success("oferta aceptada con éxito", {
                              onClose: () => {
                                // window.location.reload();
                              },
                            });
                          }}
                          className="btnacepta"
                        >
                          Aceptar Oferta
                        </button>
                        <button style={{ margin_left: "22px" }} type="button" onClick={() => {
                          stop.current = false;


                          swal.fire({
                            title: 'Motivo del rechazo',
                            icon: 'info',
                            input: 'textarea',
                            // inputLabel: 'Reason for reject',
                            showCancelButton: true,
                            confirmButtonText: 'OK',
                            inputAttributes: {
                              required: true
                            },
                            inputValidator: (value) => {
                              if (value.length < 10 || value.length > 100) {
                                return 'Please enter a value between 10 and 100 characters.';
                              }
                            }
                          }).then((result) => {
                            if (result.isConfirmed) {
                              const userInput = result.value;
                              getOfferStatus(list, "Reject", userInput, index);
                              toast.error("Oferta rechazada con éxito", {
                                onClose: () => {
                                  // window.location.reload();
                                },
                              });
                            }
                          });
                        }}
                          className="btnacepta2"

                        >Rechazar Oferta</button>
                        {/* : null  } */}


                      </div>


                    </div>
                  </div>
                </div>
              )
            })
            }


          </div>
        </section>





      </React.Fragment>
      : <Navigate to="/" replace />
  )
}
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import $ from 'jquery';
import * as con from '../repository/urls.js';
import { useNavigate } from "react-router-dom";
import * as images from './images.jsx';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import * as apis from '../repository/api_calls.js';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

export default function Home() {

  useEffect(() => {

    // guestusercredit();
  }, [])

  const navigate = useNavigate();

  // const companyRef = React.useRef();
  // const telephoneRef = React.useRef();
  // const mailRef = React.useRef();

  const [companyRef, setcompanyRef] = useState('');
  // const [telephoneRef, settelephoneRef] = useState('');
  const [mailRef, setmailRef] = useState('');


  const companyRefs = React.useRef();
  const telephoneRefs = React.useRef();
  const mailRefs = React.useRef();
  const [email, setEmail] = React.useState("");




  const visit = (event) => {
    event.preventDefault();
    const value1 = companyRef;
    const value2 = values.field1;
    const value3 = mailRef;
    // if (validateEmail(value3) === true) {
    localStorage.setItem("company_name", value1);
    localStorage.setItem("telephone_no", value2);
    localStorage.setItem("mail_id", value3);
    toast.success("Detalles recopilados", {
      position: toast.POSITION.TOP_RIGHT,
    });
    setTimeout(() => {
      navigate('/cta');
    }, 800);

    // window.location.href = "/cta";

    // } 
    // else {
    //   toast.error("Invalid Email!", {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    // }

  }
  const handlekeydown = (e) => {
    const restrictedkeys = new Set(['-', '+', '*', '/', 'e'])
    if (restrictedkeys.has(e.key)) {
      e.preventDefault();
    }
  }



  {/*============= book apppointment section 2 api integration */ }
  const visit2 = (event) => {
    event.preventDefault();
    const value1 = companyRefs.current.value;
    const value2 = telephoneRefs.current.value;
    const value3 = mailRefs.current.value;

    if (validateEmail(value3) === true) {
      localStorage.setItem("company_name", value1);
      localStorage.setItem("telephone_no", value2);
      localStorage.setItem("mail_id", value3);
      toast.success("Detalles recopilados", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setTimeout(() => {
        navigate('/cta');
      }, 800);

      // window.location.href = "/cta";

    } else {
      toast.error("¡Email inválido!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

  }


  const validateEmail = (email) => {
    // const pattern = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;
    const result = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
    console.log(result)
    return result;
  }




  // ===========blog page api integration 
  // ===========blog page api integration  
  const blogsub = async () => {
    console.log(validateEmail(email))
    if (validateEmail(email) === true) {
      const response = await apis.sendSubscribeMail(email);
      if (response['status'] === true) {
        toast.success(response['message'], {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(response['message'], {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo_bar_log_error'
        });
      }
    } else {
      toast.error("¡Identificación de correo electrónico no válida!", {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo_bar_log_error'
      });
    }
  }




  // -----------------------------------------API integration for guest user credit
  // -----------------------------------------API integration for guest user credit

  const guestusercredit = async () => {
    const response = await apis.gusercredit();
    console.log(response)
    if (response.status === true) {
      localStorage.setItem("userdata", JSON.stringify(response));
      localStorage.setItem("id", response.data['id']);
      localStorage.setItem("user_type", response.data['type'])
      localStorage.setItem("role_id", response.data['role_id'])
      localStorage.setItem("score_ran_id", response.data['score_ran_id'])
      window.location.href = "/creditscore";
    }
  }

  // -----------------------------------------API integration for guest user loan


  const guestloan = async () => {
    const response = await apis.gusercredit();
    console.log(response)
    if (response.status === true) {
      localStorage.setItem("userdata", JSON.stringify(response));
      localStorage.setItem("id", response.data['id']);
      localStorage.setItem("user_type", response.data['type'])
      localStorage.setItem("role_id", response.data['role_id'])
      // localStorage.setItem("ran_id", response.data['ran_id'])
      // localStorage.setItem("loan_ran_id", response.data['loan_ran_id'])
      window.location.href = "/getloan";
    }
  }

  // const guestloan = async () => {
    
  //   let guest = await fetch(`${process.env.REACT_APP_BASE_URL}${con.guestid}`, {
  //     method: 'post',
  //     headers: {
  //       'Content-Type': 'application/json'
  //     }
  //   });
  //   guest = await guest.json();
  //   console.log(guest);
  //   if (guest.status === true) {
  //     localStorage.setItem("userdata", JSON.stringify(guest));
  //     localStorage.setItem("id", guest.data['id']);
  //     localStorage.setItem("user_type", guest.data['type'])
  //     localStorage.setItem("role_id", guest.data['role_id'])
  //     // localStorage.setItem("ran_id", guest.data['ran_id'])
  //     // localStorage.setItem("loan_ran_id", guest.data['loan_ran_id'])
  //     window.location.href = "/getloan";
  //   }
  // }


  useEffect(() => {
    const handleScroll = () => {
      const subject = document.querySelector('.subject');
      if (subject !== null) {
        const subjectPosition = subject.getBoundingClientRect().top;
        const screenPosition = window.innerHeight / 1.2;

        if (subjectPosition < screenPosition) {
          subject.classList.add('animate');
        } else {
          subject.classList.remove('animate');
        }
      }

    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // ----------------------- for number validation start-------

  const [values, setValues] = useState({
    field1: '', field2: '',
    // Add more fields as needed
  });

  const handleChange1 = (event) => {
    const { name, value } = event.target;
    if (value.length <= 10) {
      setValues((prevValues) => ({
        ...prevValues,
        [name]: value
      }));
    }
  };

  return (
    <React.Fragment>
      <header className="header-area">
        <div className="navbar-area" >
          <div className="container">
            <nav className="site-navbar">
              <Link to="/">
                <img className="logo" src={images.Logo} alt='Logo' />
              </Link>

              <ul>
                <Link to="/auth/Login" className="hed_bu1">  <button type="button" className="btn1 btn-a1 head_nv" >Iniciar sesión <span> <i className="fa fa-sign-in" aria-hidden="true"></i></span></button> </Link>
                <Link to="/auth/Register" className="hed_bu2">  <button type="button" className="btn1 btn-cta hed_but_2">Crear cuenta</button> </Link>
                <Link to="/blog" className="hed_bu2">  <button type="button" className="btn1 btn-cta hed_but_2">Blog</button> </Link>
              </ul>

              <button className="nav-toggler">
                <span></span>
              </button>
            </nav>
          </div>
        </div>
      </header>

      <ToastContainer autoClose={3000} />

      <section className=" herosection sec1_bg" >
        <div className="container" >
          <div className="row se1_top">
            <div className="col-md-6">
              <p className="herotext1">Calcula la salud financiera de tu empresa y solicita financiación en un clic</p>


              <p className="herotext2">Accede a diferentes vías de financiación bancaria o alternativa y conoce sus condiciones, todo desde un mismo lugar.</p>



              <form onSubmit={visit}>
                <div className="te_flex se1_top2">


                  <TextField id="c_name" value={companyRef} onChange={(e) => { setcompanyRef(e.target.value) }}
                    placeholder="Nombre de la empresa" required />


                  <TextField type="number" id="he_tle"

                    placeholder="Teléfono"
                    className="ms-2"
                    required name="field1"
                    value={values.field1}
                    onChange={handleChange1}
                    maxLength={8}
                    onKeyDown={handlekeydown}
                    min="0" />
                </div>

                <TextField type="email" id="ho_email"
                  value={mailRef}
                  onChange={(e) => { setmailRef(e.target.value) }}
                  placeholder="Correo electrónico"
                  name="mailid" className="mt-3" required />


                <Button className=" btn-cta mt-4 mb-5 infobnt" type="submit" name="submit">QUIERO MÁS INFORMACIÓN</Button>
              </form  >




              <div className="he_icf">
                <img className="he_ico1" src={images.Segura} alt='Segura' />
                <img className="he_ico2" src={images.Protected} alt='Protected' />
                <p className="se1_p1">Datos <span className="se1_p2">protegidos</span></p>
              </div>
            </div>
            <div className="col-md-6">
            </div>
          </div>

          <div className="row se1_col1">
            <div className="col-md-3">
              <div className="sec_1grid">
                <i className="fa fa-check her_icon1" aria-hidden="true" ></i>
                <p className="se1_p3">Conoce el credit store de tu empresa </p>
              </div>

            </div>
            <div className="col-md-3">
              <div className="sec_1grid">
                <i className="fa fa-check her_icon2" aria-hidden="true" ></i>
                <p className="se1_p3">Obtén sugerencias para conseguir tu mejor financiación</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="sec_1grid">
                <i className="fa fa-check her_icon3" aria-hidden="true" ></i>
                <p className="se1_p3">Compara las alternativas de financiación</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="sec_1grid">
                <i className="fa fa-check her_icon4" aria-hidden="true" ></i>
                <p className="se1_p3">Elige la solución adaptada a tu negocio</p>
              </div>
            </div>
          </div>
        </div>

      </section>

      <section className="sec2_bgc">
        <div className="container">
          <div className="row ise2_row">
            <div className="col-md-6">
              <h1 className="se_2">Elige la mejor opción de financiación para tu empresa</h1>
              <ul className="fin_ul" style={{ textTransform: "capitalize" }}>
                <li>Línea de crédito</li>
                <li>Factoring</li>
                <li>Préstamo</li>
                <li>Confirming</li>
                <p className="pse_2">  ... y más </p>
              </ul>
            </div>
            <div className="col-md-6">
              <div className="subject">
                <div className="creditcard">
                  <div className="ise_2_pa">
                    <div className="card ise_2_car1">
                      <div className="se2_fle">
                        <img className="se2_img_1" src={images.Check} alt='Check' />

                        <p className="se2_p1">Oferta</p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <p className="crdtext"><span className="se2_of_1"> Oferta 1 </span> - Crédito para empresa manufacturera</p>
                    <p className="crdtext2">PLAZO: 24 meses &nbsp;&nbsp;|&nbsp;&nbsp;   Importe: 90.000,00  &nbsp;&nbsp; |&nbsp;&nbsp;   Fecha 27/02/2023
                    </p>
                  </div>

                </div>
                <div className="creditcard2 ">
                  <div className="is_pa">
                    <div className="card ise_2_ca">
                      <div className="se2_fle">
                        <img className="se2_img_1" src={images.Check} alt='Check' />

                        <p className="ise_2_p5">Oferta</p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <p className="crdtext"><span className="se2_of_1"> Oferta 2 </span> - Crédito para empresa manufacturera</p>
                    <p className="crdtext2">PLAZO: 24 meses &nbsp;&nbsp;|&nbsp;&nbsp;   Importe: 100.000,00 &nbsp; |&nbsp;&nbsp;  Fecha 23/02/2023</p>
                  </div>

                </div>
                <div className="creditcard3 ">

                  <div className="is_pa">
                    <div className="card ise_2_ca">
                      <div className="se2_fle">
                        <img className="se2_img_1" src={images.Check} alt='Check' />

                        <p className="ise_2_p5">Oferta</p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <p className="crdtext"><span className="se2_of_1"> Oferta 3 </span> - Crédito para empresa manufacturera</p>
                    <p className="crdtext2">PLAZO: 18 meses &nbsp;&nbsp;|&nbsp;&nbsp;   Importe: 55.000,00  &nbsp;&nbsp;&nbsp; |&nbsp;&nbsp;  Fecha 15/02/2023</p>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="sec3_bg">
        <div className="container">
          <h2 className="se_3_h1">Los datos de tu empresa están 100% protegidos</h2>
          <div className="row text-white ise_3_d">
            <div className="col-md-4 ise_pa_3">
              <div className="sec_1grid">
                <img className="comp_img1" src={images.Datos} alt='Datos' />

                <p className="ise_3p2">1. Legislación GDPR según la ley europea</p>
              </div>
            </div>
            <div className="col-md-4 ise_pa_3">
              <div className="sec_1grid">
                <img className="comp_img1" src={images.Ofertas} alt='Ofertas' />
                <p className="ise_3p2">2. Conexión de protocolo SSL</p>
              </div>
            </div>
            <div className="col-md-4 ise_pa_3">
              <div className="sec_1grid">
                <img className="comp_img3" src={images.Financiera} alt='Financiera' />
                <p className="ise_3p2">3. Datos encriptados en nuestros servidores</p>
              </div>
            </div>
            <Link to="/">
              <button className="btn1 btn-cta mb-5 creditbtn button-center" type="submit" name="submit" aria-pressed="true" onClick={guestloan}>Encuentra tu préstamo aquí</button>
            </Link>

          </div>
        </div>
      </section>


      {/*===================guest user  credit score loan section */}
      {/*===================guest user  credit score loan section */}

      <section className="bacgco ise_4bg">
        <div className="container">
          <div className="row text-white">
            <div className="col-md-6">
              {/* <img alt='' src={require('../assets/img/comp_icon.svg').default} className="isec_4img" /> */}
              <img className="isec_4img" src={images.Comp_icon} alt='Comp_icon' />
              <div className="ise_4d">
                <h1 className="seh1 ise4_h1">¿Cómo encontrar la financiación que tu empresa necesita? </h1>
                <ul className="ise4_uf">
                  <li >Indica la cantidad, para qué lo necesitas, y nuestro buscador te ofrecerá las distintas alternativas disponibles en el mercado.
                  </li>
                  <li>Para conseguir mejores resultados, utiliza nuestra herramienta y conoce la salud financiera de tu empresa.</li>
                  <li>Recibe sugerencias prácticas y personalizadas para mejorar tus opciones para acceder a financiación. </li>
                </ul>
              </div>
            </div>


            <div className="col-md-6">
              <img className="compareimg1 ise4_img2" src={images.Compar_1} alt='Compar_1' />
              <img className="compareimg2" src={images.Compar_2} alt='Compar_2' />
            </div>
          </div>
        </div>
        <Link to="/" ><button className="btn1 btn-cta mt-5  loanbtn button-center" type="submit" name="submit" aria-pressed="true" onClick={guestusercredit}>Descubre cuál es tu salud financiera</button>
        </Link>

      </section>



      {/*===================guest user  credit score end */}


      <section className="bacgco">
        <div className="container">
          <div className="row text-white isec5_di">
            <div className="col-md-6">
              <img className="our_das" src={images.Macbook} alt='Macbook' />

            </div>

            <div className="col-md-6">
              <img className="ise5_im1" src={images.Our_das} alt='Our_das' />

              <div className="ise5_co2">
                <h1 className="seh1 ise5_cl2">Inmediato, seguro y en un clic</h1>
                <p className="sep1 ise5_p2" >Encuentra las mejores alternativas de financiación con opciones a corto, mediano y largo plazo. </p>

                {/* <button  className="btn1 btn-cta " role="button" type="submit" name="submit" aria-pressed="true" onClick={guestlogin}>Check Credit</button>
                <button  className="btn1 btn-cta " role="button" type="submit" name="submit" aria-pressed="true" onClick={guestloan}>Check Loan</button>
        */}

              </div>
            </div>

          </div>
        </div>
        <Link to="/blog">

          <button className="btn1 btn-cta button-center" type="submit" name="submit" aria-pressed="true" style={{ width: "100px", padding: 12 }} >Blog</button>
        </Link>
        <div className="pt-5">

        </div>
      </section>

      {/*============= book apppointment section 2 */}
      {/*============= book apppointment section 2 */}
      <section className="isec_6">
        <div className="container">
          <h2 className="text-white ise6_h4" >Descubre la revolución de la financiación para las empresas</h2>
          <p className="text-white ise6_p">¡Empieza hoy!  Reserva una reunión de 15 minutos para saber más</p>
          <form onSubmit={visit2}>
            <div className="te_flex ise6_di">
              <input type="text" ref={companyRefs} id="c_name2" name="company" placeholder="Compania" required className="re_nom" />
              <input type="text" ref={telephoneRefs} minLength={10} maxLength={10} id="" name="phone" placeholder="Telefono" required className="re_tle" />
            </div>
            <div className="button-center">
              <input type="email" ref={mailRefs} id="email" name="mailid" placeholder="Correo electrónico" required className="re_tle1" />
            </div>
            <button className="btn1 btn-cta re_but_h button-center my-5" type="submit" name="submit" aria-pressed="true" >PIDE CITA</button>
          </form >

        </div>
      </section>

      {/*============= book apppointment section 2 end*/}





      {/*------------------------------------------------------- faq */}
      <section className="faqquestion" >
        <div className="container">

          <div className="global-label">
            <div className="global-label-text">
              <p className="faqtitle">Preguntas Frecuentes</p>
            </div>

          </div>
          <div className="row faqrow">
            <div className="col-md-6">
              <button className="accordion1">¿Qué tipo de empresas pueden pedir financiación?<span className="faqright"><i className="fa fa-caret-down" aria-hidden="true"></i></span></button>
              <div className="panel1">
                <p className="acco_pad">Credit store está dirigido a sociedades anónimas (S.A.), sociedades limitadas (S.L.), sociedades colectivas (S.C.), entre otras.</p>
              </div>

              <button className="accordion1">¿Encuentran también financiación para autónomos?<span className="faqright"><i className="fa fa-caret-down" aria-hidden="true"></i></span></button>
              <div className="panel1">
                <p className="acco_pad">Nos enfocamos en encontrar financiación para las siguientes estructuras de empresas: sociedades anónimas (S.A.), sociedades limitadas (S.L.), sociedades colectivas (S.C.). No brindamos servicios para autónomos.</p>
              </div>

              <button className="accordion1">¿Cuánto cobran para encontrar la financiación?<span className="faqright"><i className="fa fa-caret-down" aria-hidden="true"></i></span></button>
              <div className="panel1">
                <p className="acco_pad">No cobramos nada. Credit Store es totalmente gratuito para las empresas que solicitan financiación a través de nuestra plataforma..</p>
              </div>
              <div className="panel1">
                <p>Answer 1</p>
              </div>

              <button className="accordion1">¿Una vez solicitada la financiación, ¿en cuánto tiempo recibiré una respuesta?<span className="faqright"><i className="fa fa-caret-down" aria-hidden="true"></i></span></button>
              <div className="panel1">
                <p className="acco_pad">Si bien no hay un tiempo establecido, en menos de 72 horas deberías tener una respuesta. Siempre y cuando hayas completado todos los formularios de solicitud.</p>
              </div>

              <button className="accordion1">¿Cuál es el importe máximo de financiación al que puedo acceder?<span className="faqright"><i className="fa fa-caret-down" aria-hidden="true"></i></span></button>
              <div className="panel1">
                <p className="acco_pad">Trabajamos con tickets que pueden alcanzar los 10.000.000€</p>
              </div>
              <button className="accordion1">¿Puedo solicitar un préstamo si tengo un historial crediticio negativo?<span className="faqright"><i className="fa fa-caret-down" aria-hidden="true"></i></span></button>
              <div className="panel1">
                <p className="acco_pad">No podremos darte esta respuesta hasta no analizar tu información de manera individual. Te animamos a que cargues la información solicitada y esperes a la respuesta de uno de nuestros analistas.</p>
              </div>
              <button className="accordion1">¿Puedo usar el dinero prestado para cualquier propósito?<span className="faqright"><i className="fa fa-caret-down" aria-hidden="true"></i></span></button>
              <div className="panel1">
                <div className="acco_pad">
                  <p>Entre otras opciones, podrás destinar tus fondos para:</p>


                  <p>1. &nbsp;       Inversiones en investigación y desarrollo</p>
                  <p>2. &nbsp;       Adquisición de equipos y tecnología</p>
                  <p>3. &nbsp;        Expansión del negocio, incluyendo la apertura de nuevas sucursales o el desarrollo de nuevos mercados</p>
                  <p>4. &nbsp;        Mejoras en la infraestructura y en la eficiencia operativas existentes</p>
                  <p>5. &nbsp;        Financiación de proyectos de construcción o de desarrollo inmobiliario</p>
                  <p>6. &nbsp;        Adquisiciones</p>
                  <p>7. &nbsp;        Mejora de la liquidez o el manejo del flujo de caja.</p>

                </div>
              </div>
              <button className="accordion1">¿Cómo puedo contactarlos en caso de tener dudas o problemas?<span className="faqright"><i className="fa fa-caret-down" aria-hidden="true"></i></span></button>
              <div className="panel1">
                <p className="acco_pad">Podes contactarnos a través de email, chat en línea o telefónicamente.</p>
              </div>

            </div>




            <div className="col-md-6">
              <button className="accordion1">¿Qué tasas de interés puedo esperar?<span className="faqright"><i className="fa fa-caret-down" aria-hidden="true"></i></span></button>
              <div className="panel1">
                <p className="acco_pad">El tipo de interés dependerá de varios factores: plazos, montos, tipo de financiación, etc. Ofreceremos una o más formas de financiación entre las que podrás elegir la que mejor se adapte a tus necesidades.</p>
              </div>

              <button className="accordion1">¿Con qué entidades financieras trabajáis?<span className="faqright"><i className="fa fa-caret-down" aria-hidden="true"></i></span></button>
              <div className="panel1">
                <p className="acco_pad">Trabajamos tanto con banca tradicional, como con entidades de financiación alternativas, asegurándonos de acercarte la oferta más conveniente para las necesidades y el momento de tu negocio.</p>
              </div>

              <button className="accordion1">¿Qué tipo de créditos ofrecen?<span className="faqright"><i className="fa fa-caret-down" aria-hidden="true"></i></span></button>
              <div className="panel1">
                <p className="acco_pad">Ofrecemos préstamos a corto, mediano y largo plazo, hipotecarios, prendarios, líneas de crédito, entre otras opciones, dependiendo de tus necesidades.</p>
              </div>


              <button className="accordion1">¿Qué pasa si no puedo pagar a tiempo?<span className="faqright"><i className="fa fa-caret-down" aria-hidden="true"></i></span></button>
              <div className="panel1">
                <p className="acco_pad">Pueden haber penalizaciones e intereses por pago tardío, así como un impacto en tu historial crediticio.</p>
              </div>

              <button className="accordion1">¿Cuál es el requisito mínimo de ingresos mensuales para poder pedir financiación?<span className="faqright"><i className="fa fa-caret-down" aria-hidden="true"></i></span></button>
              <div className="panel1">
                <p className="acco_pad">Encontramos financiación para pequeñas y medianas empresas, las cuales tienen que tener una facturación mensual superior a 15.000€.
                </p>
              </div>
              <button className="accordion1">¿Qué pasa si mi empresa no tiene buena salud financiera? ¿Me pueden encontrar financiación de todas maneras?<span className="faqright"><i className="fa fa-caret-down" aria-hidden="true"></i></span></button>
              <div className="panel1">
                <p className="acco_pad">Observaremos varios aspectos para darte la mejor solución. En el caso de que tu salud financiera no sea la más óptima, te brindamos sugerencias personalizadas para que puedas mejorar con el tiempo tus opciones de financiación.</p>
              </div>

              <button className="accordion1">¿Es seguro proporcionar mis información personal y financiera a la plataforma?<span className="faqright"><i className="fa fa-caret-down" aria-hidden="true"></i></span></button>
              <div className="panel1">
                <p className="acco_pad">Si. En Credit Store contamos con las medidas de seguridad y protección adecuadas para conservar la privacidad de tus datos.</p>
              </div>
            </div>


          </div>

        </div>


      </section>



      {/*---------------------------------------- blog section */}
      {/*---------------------------------------- blog section */}
      <section className="b_bg">
        <div className="container">
          <div className="row" >
            <div className="col-md-8  blogsec"  >
              <h1 className="bloghead">Credit Store Blog</h1>
              <h1 className="bloghead2">Suscríbete a nuestro blog</h1>
              <p className="mt-4 mb-3 bloghead3">No te pierdas las últimas noticias y consejos sobre financiación para empresas. </p>


              {/* <form > */}
              <div className="mt-3 blogform">

                <div className="form-group">
                  <label htmlFor="usr">Correo electrónico</label><br />
                  <input type="text" className=" em_in" placeholder="Dirección de correo electrónico" required id="usr"
                    onChange={(e) => setEmail(e.target.value)} value={email} />

                  <button className="btn1 btn-cta mb-5 subsribebtn " type="button" name="submit" aria-pressed="true" onClick={blogsub}>Suscribir</button>
                </div>
              </div>
              {/* </form> */}

              <p className="blogtext" >Tu privacidad es importante para nosotros. Credit Store utiliza la información que proporcionas para ponerse en contacto contigo en relación con contenido, productos y servicios relevantes para ti. Puedes darte de baja para dejar de recibir este tipo de comunicaciones en cualquier momento. Si deseas obtener más información sobre la protección de tus datos en Credit Store, consulta nuestra <Link to="/dataprotect"> Política de Privacidad.</Link></p>



            </div>
            <div className="col-md-4" >

              {/* <img alt='' src={require('../assets/img/multi_blog_subscription.png')} className="b_sec_img" /> */}
              <img className="b_sec_img" src={images.Blog_sub} alt='Blog_sub' />


            </div>

          </div>
        </div>
      </section>






      {/* <!--------------------footer --> */}
      {/* <!--------------------footer --> */}

      <footer className="bacgco">
        <div className="container">
          <div className="row footerr" >
            <div className="col-md-3 ">
              <p className="fotcol">2023 Credit Store</p>

            </div>
            <div className="col-md-3 fot_mob">
              <div className="displaygrid">
                <label className="text-white">Menú</label>

                <Link to="/auth/Register" className="fotcol">Crear cuenta </Link>
                <Link to="/auth/Login" className="fotcol">Iniciar Sesión </Link>
                <Link to="/about" className="fotcol">Sobre Credit Store </Link>
                <Link to="/contact" className="fotcol">Contacto </Link>
              </div>

            </div>
            <div className="col-md-3 fot_mob">
              <div className="displaygrid">
                <label className="text-white">Seguridad</label>
                <Link to="/warning" className="fotcol">Aviso Legal </Link>
                <Link to="/dataprotect" className="fotcol">Política de Privacidad </Link>
                <Link to="/condition" className="fotcol">Condiciones Generales </Link>
                <Link to="/cookies" className="fotcol">Política de Uso de Cookies </Link>
              </div>

            </div>
            <div className="col-md-3">
              <div className="displaygrid">
                <Link to="https://twitter.com/CreditStoreLine" className="fotcol"><span className="footericon"><i className="fa fa-twitter-square footericon " aria-hidden="true" ></i></span>Twitter </Link>
                <Link to="https://www.linkedin.com/company/thecreditstore/" className="fotcol"><span className="footericon"><i className="fa fa-linkedin-square footericon" aria-hidden="true" ></i></span>LinkedIn </Link>
              </div>


            </div>
          </div>
        </div>
      </footer>




    </React.Fragment>
  )
}





var $animation_elements = $('.animation-element');
var $window = $(window);

function check_if_in_view() {
  var window_height = $window.height();
  var window_top_position = $window.scrollTop();
  var window_bottom_position = (window_top_position + window_height);

  $.each($animation_elements, function () {
    var $element = $(this);
    var element_height = $element.outerHeight();
    var element_top_position = $element.offset().top;
    var element_bottom_position = (element_top_position + element_height);

    //check to see if this current container is within viewport
    if ((element_bottom_position >= window_top_position) &&
      (element_top_position <= window_bottom_position)) {
      $element.addClass('in-view');
    } else {
      $element.removeClass('in-view');
    }
  });
}

$window.on('scroll resize', check_if_in_view);
$window.trigger('scroll');


// ---------------------- nav bar ------------------


// define all UI variable
// const navToggler = document.querySelector('.nav-toggler');
// const navMenu = document.querySelector('.site-navbar ul');
// const navLinks = document.querySelectorAll('.site-navbar a');

// // load all event listners
// allEventListners();

// // functions of all event listners
// function allEventListners() {
//   // toggler icon click event
//   navToggler.addEventListener('click', togglerClick);
//   // nav links click event
//   navLinks.forEach( elem => elem.addEventListener('click', navLinkClick));


// }

// togglerClick function
// function togglerClick() {
//   navToggler.classList.toggle('toggler-open');
//   navMenu.classList.toggle('open');
// }

// navLinkClick function
// function navLinkClick() {
//   if (navMenu.classList.contains('open')) {
//     navToggler.click();
//   }
// }



//===============-------------- faq



$(document).ready(function () {

  var acc = document.getElementsByClassName("accordion1");
  var i;
  var j;

  function naven() {
    for (j = 0; j < acc.length; j++) {
      acc[j].nextElementSibling.style.maxHeight = null;
    }
    this.classList.toggle("accordion1:active");
    var panel = this.nextElementSibling;

    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
    }
  }

  for (i = 0; i < acc.length; i++) {

    acc[i].addEventListener("click", naven);
  }
});
import React from "react";
import { Link } from "react-router-dom"
// import * as con from '../repository/urls.js';
import * as apis from '../repository/api_calls.js';
import * as images from '../pages/images.jsx';
import { useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';


export default function Reset() {

    const navigate = useNavigate();
    //=================================   Forger password API integration  
    const emailRef = React.useRef();
    const forgetpwd = (event) => {
        event.preventDefault();
        const email = emailRef.current.value



        
        ForgetPassword();
            
        async function ForgetPassword() {
          const response = await apis.ForgetPasswordAPI(JSON.stringify({
            email: email
          }),);
          console.log(response)
          if (response['status'] === true) {
            toast.success('Ahora crea tu contraseña', {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo_bar_log_Success'
            });
            setTimeout(() => {
                navigate('/auth/conformpassword');
            }, 700);
        }
        else {
            toast.error('Ocurre algo', {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo_bar_log_Success'
            });
        }
          
        }
      

        // fetch(`${process.env.REACT_APP_BASE_URL}${con.forget}`, {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify({
        //         email: email
        //     })
        // }).then(async response => {
        //     var res = await response.json();
        //     if (res['status'] === true) {
        //         toast.success('Ahora crea tu contraseña', {
        //             position: toast.POSITION.TOP_RIGHT,
        //             className: 'foo_bar_log_Success'
        //         });
        //         setTimeout(() => {
        //             navigate('/auth/conformpassword');
        //         }, 700);
        //     }
        //     else {
        //         toast.error('Ocurre algo', {
        //             position: toast.POSITION.TOP_RIGHT,
        //             className: 'foo_bar_log_Success'
        //         });
        //     }
        // })
        //     .catch(error => {
        //         console.error('There was an error!', error);
        //     });
    }

    // const handleChange = (e) => {
    //     if (emailRef.current.value === 'email') {
    //         validateEmail(e.target.value);
    //     }
    // }

    // const validateEmail = (email) => {
    //     const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
    //     const result = pattern.test(email);
    //     if (result === true) {
    //         this.setState({
    //             emailError: false,
    //             email: email
    //         })
    //     } else {
    //         this.setState({
    //             emailError: true
    //         })
    //     }
    // }


    return (
        <React.Fragment>

            <section className="lo_x">
                <div className="d-md-flex vh-100">
                    <ToastContainer autoClose={3000} />
                    {/* <!-- Second Half --> */}
                    <div className="col-md-7 order-2 my-auto">
                        <div className="d-md-flex align-items-center p-3 justify-content-center">
                            <div data-aos="fade-up" style={{ width: 420 }}>
                                <div className="row mb-3">
                                    <div className="col-md-8 mb-2">
                                        <h4 className="">Restablecer la contraseña</h4>
                                    </div>
                                    <div className="col-md-4 align-self-end mb-3 text-end">
                                        <Link to="/auth/Register">
                                            <span>
                                                <i className="fa fa-long-arrow-right me-3" aria-hidden="true"></i>
                                            </span>
                                            Registrarme
                                        </Link>
                                    </div>
                                </div>

                                <form onSubmit={forgetpwd} className="form-signin">
                                    <div className="form-label-group">
                                        <input
                                            className="form-control"
                                            id="email"
                                            name="email"
                                            placeholder="Dirección de correo"
                                            required
                                            type="email"
                                            ref={emailRef}
                                            style={{ height: 45 }}
                                        />
                                        <label>Dirección de correo</label>
                                    </div>

                                    <div className="form-group d-grid mt-5"  >
                                        <input
                                            className="btn  btn-primary-orange btn-block mb-3"
                                            id="submit"
                                            name="submit"
                                            type="submit"
                                            value="enviar"
                                            style={{ width: "200px", fontSize: "18px", display: "block", margin: "0px auto" }}
                                        />
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>

                    {/* <!-- First Half --> */}

                    <div className="col-md-4  leftsplit order-1 vh-100 bg-dark text-light reg-img-bg">
                        <div className="d-flex flex-column align-items-center p-5 loginmargin-top">
                            <img className="" src={images.Logo} alt='Logo' height="40px" />
                        </div>
                    </div>
                </div>
            </section>


        </React.Fragment>
    )
}
import React from "react"
import { Link, useNavigate } from "react-router-dom";
import { Nav, NavDropdown } from "react-bootstrap";
import * as con from '../../repository/urls.js';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import * as images from '../../pages/images.jsx'

const UserHeader = () => {

    const role_id = localStorage.getItem("role_id");
    const navigate = useNavigate();
    const token = localStorage.getItem("token");

      function logout() {
    localStorage.clear();
    navigate('/dashboard');
    window.location.reload();
  }

    // function logout() {
    //     fetch(`${process.env.REACT_APP_BASE_URL}${con.logout}`, {
    //       method: 'POST',
    //       headers: {
    //         "Authorization": `Bearer ${token}`,
    //       }
    //     })
    //       .then(async response => {
    //         const result = await response.json();
    //         if (result['status'] === true) {
    //           toast.success(result['message'], {
    //             position: toast.POSITION.TOP_RIGHT,
    //             className: 'foo_bar_log_Success'
    //           });
    //           setTimeout(() => {
    //             localStorage.clear();
    //             window.location.reload();
    //             navigate('/dashboard');
    //           }, 800);
    
    //         } else {
    //           toast.error(result['message'], {
    //             position: toast.POSITION.TOP_RIGHT,
    //             className: 'foo_bar_log_Success'
    //           });
    //         }
    //       })
    //       .catch(error => {
    //       });
    //   }


    return (
        <React.Fragment>
            <ToastContainer autoClose={3000} />
            <header className="header-area">
          <div className="navbar-area" >
            <div className="container">
              <nav className="site-navbar">
                <Link to="/">

                  <img className="logo" src={images.Logo} alt='Logo' />
                </Link>

                {token !== null ?
               
                <Nav>

                  <NavDropdown title={localStorage.getItem("firstname") + " " + localStorage.getItem("lastname")} style={{ marginLeft: "848px", fontSize: "18px" }} >
                    <NavDropdown.Item href="/user/user_profile">Perfil</NavDropdown.Item>
                    <NavDropdown.Item onClick={logout}>Cerrar sesión</NavDropdown.Item>
                  </NavDropdown>

                </Nav>
                : null}

                <button className="nav-toggler">
                  <span></span>
                </button>
              </nav>




            </div>
          </div>


        </header>
        </React.Fragment>
    )
}

export default UserHeader
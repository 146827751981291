import '../../src/assets/css/circle_css/dash_circle.css';
import React, { useEffect, useState } from 'react';

const CircleProgressBar1 = ({ percentage, scolor }) => {

  const [offset, setOffset] = useState(0);
  // const [percentage1, setPercentage] = useState(0);
  const [count, setCount] = useState("0")

  useEffect(() => {
    // if (percentage == null) {
    //   setPercentage(0);
    // } else {
    //   setPercentage(percentage);
    // }
    const progressOffset = (125 - percentage) / 125 * 314.16; // Calculate the offset based on the percentage
    setOffset(progressOffset);
  }, [percentage, offset]);

  // const label = 'ct';
  const number = '30';
  const duration = 1;

  useEffect(() => {
    let start = 0;
    const end = parseInt(percentage)
    if (start === end) return;
    let totalMilSecDur = parseInt(duration);
    let incrementTime = (totalMilSecDur / end) * 1000;
    let timer = setInterval(() => {
      start += 1;
      setCount(String(start))
      if (start === end) clearInterval(timer)
    }, incrementTime);
  }, [number, duration,]);


  return (
    <div className="circle-progress-bar">
      <svg>
        <circle className='circle1' cx="50%" cy="50%" r="40%" strokeWidth="10%" transform="rotate(-90 50 50)" />
        <circle className="progress1"
          style={scolor === "Bajo" ? { stroke: " #EB5757" } : scolor === "Regular" ? { stroke: "#F2994A" } : scolor === "Bueno" ? { stroke: "#F2C94C" }
            : scolor === "Muy Bueno" ? { stroke: "#27AE60" } : { stroke: "#24804B" }}
          cx="50%" cy="50%" r="40%" strokeWidth="10%" strokeDasharray="314.16" strokeDashoffset={offset} transform="rotate(-90 50 50)" />
      </svg>
      <div className="percentage2">{count}</div>
    </div>
  );
};
export default CircleProgressBar1;
import React from "react";
import * as con from '../repository/urls.js';
import { useNavigate, Navigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

export default function GuestCompany() {


  const role_id = localStorage.getItem("role_id");
  const [name, setName] = React.useState("");
  const [surname, setSurname] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [company_name, setCompany_name] = React.useState("");
  const nameRef = React.useRef();
  const surnameRef = React.useRef();
  const emailRef = React.useRef();
  const company_nameRef = React.useRef();

  const navigate = useNavigate();

  const addGuest = async (event) => {
    event.preventDefault();
    fetch(`${process.env.REACT_APP_BASE_URL}${con.guest1}`, {
      method: 'post',
      body: JSON.stringify({
        "name": name,
        "surname": surname,
        "company_name": company_name,
        "email": email,
        "user_id": localStorage.getItem("id")
      }),
      headers: {
        'Content-Type': 'application/json',
      }
    }
    ).then(async response => {
      var res = await response.json();


      if (res['status'] === true) {
        toast.success("Detalles añadidos", {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo_bar_log_Success'
        });
        setTimeout(() => {
          navigate('/guest_conformation');
        }, 800);
      }
      else {
        toast.error("Algo Error", {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo_bar_log_error'
        });
      }
    }).catch(error => {
      console.error('There was an error!', error);
    });
  }

  return (
    ((role_id !== null && role_id === "2")) ?
      <React.Fragment>
        <section className="container">
          <div className="row" style={{ marginTop: "40px" }}>
            <div className="col-md-3"></div>
            <div className="col-md-6 card " style={{ padding: 34 }}>
              <div className="">
                <h3 className="ta_1_h2"> Te envíamos tu Credit Store <br /> por email </h3>

                <ToastContainer autoClose={3000} />

                <form className="formguest" method="post" onSubmit={addGuest}>
                  <label className="ta_lab">Nombre </label>
                  <input
                    type="text"
                    className="form-control "
                    placeholder=""
                    id=""
                    pattern="^[A-Za-z0-9]{3,16}$"
                    required
                    ref={nameRef}
                    onChange={(e) => setName(e.target.value)} value={name} />
                  <p id='error_name' className="err "  ></p>

                  <label className="ta_lab">Apellido</label>
                  <input
                    type="text"
                    className="form-control "
                    placeholder=""
                    id=""
                    ref={surnameRef}
                    required
                    onChange={(e) => setSurname(e.target.value)} value={surname} />
                  <p id='error_surname' className="err "  ></p>

                  <label className="ta_lab">Nombre de la empresa</label>
                  <input
                    type="text"
                    className="form-control "
                    placeholder=""
                    id=""
                    required
                    ref={company_nameRef}
                    onChange={(e) => setCompany_name(e.target.value)} value={company_name} />

                  <p id='error_company' className="err "  ></p>

                  <div>
                    <label className="ta_lab"> Email</label>
                    <input
                      type="email"
                      className="form-control "
                      placeholder=""
                      id=""
                      required
                      ref={emailRef}
                      onChange={(e) => setEmail(e.target.value)} value={email} />
                    <p id='error_mail' className="err "  ></p>
                  </div>

                  <div style={{ display: "flex", marginTop: 30, marginLeft: 75 }}>
                    <button type="submit" className="btn cresc_bt1" >
                    FINALIZAR
                    </button>
                  </div>
                </form>

              </div>
            </div>
            <div className="col-md-3"></div>
          </div>
        </section>



      </React.Fragment>
      : <Navigate to="/" replace />
  )
}
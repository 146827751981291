import React from "react";
import { Link } from "react-router-dom";
import { useNavigate, Navigate } from "react-router-dom";
import { useState, useEffect } from "react";
// import * as con from '../repository/urls.js';
import * as apis from "../repository/api_calls.js";
import LenderHeader from "./component/header.jsx";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';


export default function LenderProfile() {



  const currentDate = new Date().toISOString().split('T')[0];



  // const [value, setValue] = useState(0);
  // const handleTabs = (e, val) => {
  //   console.warn(val)
  //   setValue(val)
  // }



  const token = localStorage.getItem("token");
  const user_type = localStorage.getItem("user_type")
  const role_id = localStorage.getItem("role_id");
  const creditScoreStatus = localStorage.getItem("creditScore_status");
  const navigate = useNavigate();

  const firstnaRef = React.useRef();
  const lastnaRef = React.useRef();
  const nombreDelRef = React.useRef();
  const fechadeRef = React.useRef();
  const numerodeRef = React.useRef();
  const correoeleRef = React.useRef();
  const telePhoRef = React.useRef();

  const [firstName, setfirstName] = useState('');
  const [lastName, setlastName] = useState('');
  const [businessName, setbusinessName] = useState('');
  const [dateOfReg, setdateOfReg] = useState('');
  const [taxNumber, setTaxNumber] = useState('');
  const [emailV, setemailV] = useState('');
  const [number, setnumber] = useState('');




  useEffect(() => {

    // if (user_type === "guest_user" || user_type === null) {

      // fetch(`${process.env.REACT_APP_BASE_URL}${con.guest_profile}`, {

      //   method: 'post',
      //   body: JSON.stringify({
      //     "user_id": localStorage.getItem("id")
      //   }),
      //   headers: {
      //     'Content-Type': 'application/json',

      //   }

      // })

      //   .then(res => res.json())
      //   .then(res => {
      //   });

    // }
    // else {
      
      // fetch(`${process.env.REACT_APP_BASE_URL}${con.user_profile}`, {
      //   method: 'post',
      //   headers: {
      //     'Content-Type': 'application/json',
      //     "Authorization": `Bearer ${token}`,
      //   }

      // })
      //   .then(res => res.json())
      //   .then(res => {
      //     console.log(res)
      //   });
    // }
    
    setfirstName(localStorage.getItem("firstname"))
    setlastName(localStorage.getItem("lastname"))
    setbusinessName(localStorage.getItem("bname"))
    setdateOfReg(localStorage.getItem("registerDate"))
    setTaxNumber(localStorage.getItem("tax_number"))
    setemailV(localStorage.getItem("lender_email"))
    setnumber(localStorage.getItem("lender_cmy_reg_number"))


  })


  //=========================================  Profile Update API
  const profileupdate = (event) => {
    event.preventDefault();
    // const nombreDel = nombreDelRef.current.value
    // const fechade = fechadeRef.current.value
    // const numerode = numerodeRef.current.value
    // const correoele = correoeleRef.current.value
    // const telePho = telePhoRef.current.value

    // const telePho = telePhoRef.current.value
    // const telePho = telePhoRef.current.value




    const firstna = firstnaRef.current.value;
    const lastna = lastnaRef.current.value;
    const nombreDel = nombreDelRef.current.value;
    const fechade = fechadeRef.current.value;
    const numerode = numerodeRef.current.value;
    const correoele = correoeleRef.current.value;
    const telePho = telePhoRef.current.value;
// console.log({
//   "first_name": firstna,
//   "sur_name": lastna,
//   "business_name": nombreDel,
//   "date_of_registration": fechade,
//   "tax_number": numerode,
//   "email": correoele,
//   "company_registration_number": telePho
// })


  LenderProfileUpdate();

async function LenderProfileUpdate() {
    const response = await apis.LenderProfile(JSON.stringify({
      "name": firstna,
      "surname": lastna,
      "business_name": nombreDel,
      "date_of_registration": fechade,
      "tax_number": numerode,
      "email": correoele,
      "company_registration_number": telePho
    }),);
    console.log(response)
    if (response['status'] === true) {

      // role 3
      localStorage.setItem("firstname", firstna);
      localStorage.setItem("lastname", lastna);
      localStorage.setItem("bname", nombreDel);
      localStorage.setItem("registerDate", fechade);
      localStorage.setItem("tax_number", numerode);
      localStorage.setItem("lender_email", correoele);
      localStorage.setItem("lender_cmy_reg_number", telePho);

      //

      toast.success(response['message'], {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo_bar_log_Success'
      });

      swal.fire({
        position: 'Center',
        icon: 'success',
        title: 'Perfil actualizado con éxito',
        showConfirmButton: false,
        timer: 1500
      })

      setTimeout(() => {
        navigate('/lender/lender_profile');
      }, 700);

    }
    else {
      toast.error(response['message'], {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo_bar_log_error'
      });

    }
}



    // fetch(`${process.env.REACT_APP_BASE_URL}${con.updateLenderProfile}`, {
    //   method: 'post',
    //   body: JSON.stringify({
    //     "name": firstna,
    //     "surname": lastna,
    //     "business_name": nombreDel,
    //     "date_of_registration": fechade,
    //     "tax_number": numerode,
    //     "email": correoele,
    //     "company_registration_number": telePho
    //   }),
    //   headers:
    //   {
    //     "Authorization": `Bearer ${token}`,
    //     'Content-Type': 'application/json',
    //   }
    // }
    // ).then(async response => {
    //   var re = await response.json();

    //   if (re['status'] === true) {

    //     // role 3
    //     localStorage.setItem("firstname", firstna);
    //     localStorage.setItem("lastname", lastna);
    //     localStorage.setItem("bname", nombreDel);
    //     localStorage.setItem("registerDate", fechade);
    //     localStorage.setItem("tax_number", numerode);
    //     localStorage.setItem("lender_email", correoele);
    //     localStorage.setItem("lender_cmy_reg_number", telePho);

    //     //

    //     toast.success(re['message'], {
    //       position: toast.POSITION.TOP_RIGHT,
    //       className: 'foo_bar_log_Success'
    //     });

    //     swal.fire({
    //       position: 'Center',
    //       icon: 'success',
    //       title: 'Perfil actualizado con éxito',
    //       showConfirmButton: false,
    //       timer: 1500
    //     })

    //     setTimeout(() => {
    //       navigate('/lender/lender_profile');
    //     }, 700);

    //   }
    //   else {
    //     toast.error(re['message'], {
    //       position: toast.POSITION.TOP_RIGHT,
    //       className: 'foo_bar_log_error'
    //     });

    //   }

    // }).catch(error => {
    //   console.error('There was an error!', error);
    // });

  }









  return (
    ((role_id !== null && role_id === "3") && (creditScoreStatus !== null)) ?
      <React.Fragment>
        <div>
          <LenderHeader />
        </div>

        <section className="" style={{ backgroundColor: "#fafafa", }}>
          <div className="container py-5" style={{ display: "flex" }}>
            <Link to="/lender/lender_profile" className="tip_1" style={{ textDecoration: "none" }}>
              <h4>Información del perfil</h4>
            </Link>
            {/* 
          <Link to="/lender/lender_profile" className="ms-5 tip_2" style={{ textDecoration: "none" }} >
            <h4> Profile Settings</h4>
          </Link> */}
          </div>
        </section>



        <section style={{ backgroundColor: "#fafafa", }}>
          <div className="container">

            <ToastContainer autoClose={3000} />
            <form onSubmit={profileupdate} className="formguest" >
              <div className="row" >
                <h3 style={{ color: "#183f4b" }}>información del prestamista</h3>
                <div className="col-md-4">

                  <div >
                    <label style={{ fontSize: "16px", fontWeight: "semibold", marginTop: "30px" }} >Nombre</label>
                  </div>
                  <div >
                    <label style={{ fontSize: "16px", fontWeight: "semibold", marginTop: "30px" }} >Apellido </label>
                  </div>
                  <div >
                    <label style={{ fontSize: "16px", fontWeight: "semibold", marginTop: "30px" }} >Nombre del Negocio </label>
                  </div>
                  <div >
                    <label style={{ fontSize: "16px", fontWeight: "semibold", marginTop: "30px" }}>fecha de registro </label>
                  </div>
                  <div >
                    <label style={{ fontSize: "16px", fontWeight: "semibold", marginTop: "30px" }}>Número de impuesto </label>
                  </div>
                  <div >
                    <label style={{ fontSize: "16px", fontWeight: "semibold", marginTop: "30px" }}>Correo electrónico </label>
                  </div>
                  <div >
                    <label style={{ fontSize: "16px", fontWeight: "semibold", marginTop: "30px" }}>número de registro de la compañía </label>
                  </div>

                </div>

                <div className="col-md-6">
                  <input
                    style={{ width: "300px", marginTop: "24px" }}
                    type="text"
                    className="form-control "
                    placeholder=""
                    id=""
                    defaultValue={firstName}
                    minLength={3} maxLength={25}
                    required
                    ref={firstnaRef}
                    pattern="[a-z A-Z]+"
                  />
                  <input
                    style={{ width: "300px", marginTop: "24px" }}
                    type="text"
                    className="form-control "
                    placeholder=""
                    id=""
                    defaultValue={lastName}
                    minLength={3} maxLength={5}
                    required
                    ref={lastnaRef}
                    pattern="[a-z A-Z]+"
                  />
                  <input
                    style={{ width: "300px", marginTop: "24px" }}
                    type="text"
                    className="form-control "
                    placeholder=""
                    id=""
                    defaultValue={businessName}
                    minLength={3} maxLength={25}
                    required
                    ref={nombreDelRef}

                  />

                  <input
                    style={{ width: "300px", marginTop: "24px" }}
                    type="date"
                    className="form-control "
                    placeholder=""
                    id=""
                    defaultValue={dateOfReg}
                    required
                    ref={fechadeRef}
                    max={currentDate}
                  />

                  <input
                    style={{ width: "300px", marginTop: "24px" }}
                    type="text"
                    className="form-control "
                    placeholder=""
                    id=""
                    defaultValue={taxNumber}
                    minLength={3} maxLength={20}
                    required
                    ref={numerodeRef}
                  />

                  <input
                    style={{ width: "300px", marginTop: "24px" }}
                    type="email"
                    className="form-control "
                    placeholder=""
                    id=""
                    defaultValue={emailV}
                    minLength={3} maxLength={30}
                    required
                    ref={correoeleRef}
                  />

                  <input
                    style={{ width: "300px", marginTop: "24px" }}

                    type="text"
                    className="form-control "
                    placeholder=""
                    id=""
                    defaultValue={number}
                    minLength={3} maxLength={20}
                    required
                    ref={telePhoRef}

                  />

                </div>
              </div>
              <button type="submit" className="btn cresc_bt1 my-5" style={{width:"auto"}} >
                Actualización del perfil
              </button>


            </form>


          </div>


        </section>







      </React.Fragment>
      : <Navigate to="/" replace />
  )
}
import React from "react";
import { Link } from "react-router-dom";
import * as images from './images.jsx';

export default function Contact() {




  // const [data, setData] = useState(0)


  return (
    <React.Fragment>



      <header className="header-area">
        <div className="navbar-area" >
          <div className="container">
            <nav className="site-navbar">
              <Link to="/">

                {/* <img alt='' src= "./img/logo.svg" className="logo" /> */}
                <img className="logo" src={images.Logo} alt='Logo' />
              </Link>

              {/* <ul>
              <Link to="/auth/Login" className="hed_bu1">  <button type="button" className="btn1 btn-link1 head_nv" >Iniciar sesión <span> <i className="fa fa-sign-in" aria-hidden="true"></i></span></button> </Link>
                  <Link to="/auth/Register" className="hed_bu2">  <button type="button" className="btn1 btn-cta hed_but_2">Crear cuenta</button> </Link>
              </ul> */}

              <button className="nav-toggler">
                <span></span>
              </button>
            </nav>
          </div>
        </div>


      </header>




      <section >
        <div className="bg-dark">
          <div className="container-fluid text-white vh-100">
            <iframe
              style={{ filter: "grayscale(100%) invert(92%) contrast(83%)" }}
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2992.6941170241344!2d2.1921288154427803!3d41.4024487792627!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a4a33d2b397615%3A0x86e0e33964fd7e13!2sCarrer%20de%20Roc%20Boronat%2C%20117%2C%202do%20Piso%2C%2008018%20Barcelona!5e0!3m2!1sen!2ses!4v1637254559124!5m2!1sen!2ses"
              width="100%"
              height={324}
              allowFullScreen=""
              loading="lazy"
              title="myFrame"
            />
            <div className="row mx-auto text-center">
              <div className="col pt-5">
                <h4 className="fw-bold">Dirección:</h4>
                <p>
                  <b>Credit Store</b>
                  <br />
                  71-75 Shelton St, Covent Garden,
                  <br />
                  London, WC2H 9JQ, <br />
                  United Kingdom
                  <br />
                </p>
                <p>info@creditstore.net</p>
              </div>
            </div>
          </div>
        </div>

      </section>


      {/* <!--footer --> */}

      <footer className="bacgco">
        <div className="container">
          <div className="row footerr" >
            <div className="col-md-3 ">
              <p className="fotcol">2023 Credit Store</p>

            </div>
            <div className="col-md-3 fot_mob">
              <div className="displaygrid">
                <label className="text-white">Menu</label>

                <Link to="/auth/Register" className="fotcol">Crear cuenta </Link>
                <Link to="/auth/Login" className="fotcol">Iniciar Sesión </Link>
                <Link to="/about" className="fotcol">Sobre Credit Store </Link>
                <Link to="/contact" className="fotcol">Contacto </Link>
              </div>

            </div>
            <div className="col-md-3 fot_mob">
              <div className="displaygrid">
                <label className="text-white">Seguridad</label>
                <Link to="/warning" className="fotcol">Aviso Legal </Link>
                <Link to="/dataprotect" className="fotcol">Política de Privacidad </Link>
                <Link to="/condition" className="fotcol">Condiciones Generales </Link>
                <Link to="/cookies" className="fotcol">Política de Uso de Cookies </Link>
              </div>

            </div>
            <div className="col-md-3">
              <div className="displaygrid">
                <Link to="https://twitter.com/CreditStoreLine" className="fotcol"><span className="footericon"><i className="fa fa-twitter-square footericon " aria-hidden="true" ></i></span>Twitter </Link>
                <Link to="https://www.linkedin.com/company/thecreditstore/" className="fotcol"><span span className="footericon"><i className="fa fa-linkedin-square footericon" aria-hidden="true" ></i></span>LinkedIn </Link>
              </div>


            </div>
          </div>
        </div>
      </footer>





    </React.Fragment>
  )
}
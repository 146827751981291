import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import * as apis from "../../repository/api_calls"
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';


export default function LoanOffer() {

    const location = useLocation();
    const userData = location.state;

    const handlekeydown = (e) => {
        const restrictedkeys = new Set(['-', '+', '*', '/', 'e'])
        if (restrictedkeys.has(e.key)) {
            e.preventDefault();
        }
    }

    // ------------ number validation -----


    const [values, setValues] = useState({
        field1: '', field2: '', field3: '', field4: '', field5: '', field6: '', field7: '',
        field8: '', field9: '', field10: '', field11: '', field12: '',
        // Add more fields as needed
    });

    const handleChange1 = (event) => {
        const { name, value } = event.target;
        if (value.length <= 8) {
            setValues((prevValues) => ({
                ...prevValues,
                [name]: value
            }));
        }
    };

    const handleChange2 = (event) => {
        const { name, value } = event.target;
        if (value.length <= 4) {
            setValues((prevValues) => ({
                ...prevValues,
                [name]: value
            }));
        }
    };
    const navigate = useNavigate();

    const [inputValue, setInputValue] = useState('');
    const [inputValue1, setInputValue1] = useState('');
    const [inputValue2, setInputValue2] = useState('');
    const [inputValue3, setInputValue3] = useState('');
    const [inputValue4, setInputValue4] = useState('');
    const [inputValue5, setInputValue5] = useState('');
    const [inputValue6, setInputValue6] = useState('');
    const [inputValue7, setInputValue7] = useState('');
    console.log(inputValue, inputValue2, inputValue3, inputValue4, inputValue5, inputValue6)

    const handleInputChange = (e) => {
        const value = e.target.value;
        const numericValue = value.replace(/\D/g, '').slice(0, 8);
        setInputValue(numericValue);


    };
    const handleInputChange1 = (e) => {
        const value = e.target.value;
        const numericValue = value.replace(/\D/g, '').slice(0, 8);
        setInputValue1(numericValue);
    };
    const handleInputChange2 = (e) => {
        const value = e.target.value;
        const numericValue = value.replace(/\D/g, '').slice(0, 8);
        setInputValue2(numericValue);
    };
    console.log(handleInputChange2)
    const handleInputChange3 = (e) => {
        const value = e.target.value;
        const numericValue = value.replace(/\D/g, '').slice(0, 8);
        setInputValue3(numericValue);
    };
    console.log(handleInputChange3)
    const handleInputChange4 = (e) => {
        const value = e.target.value;
        const numericValue = value.replace(/\D/g, '').slice(0, 8);
        setInputValue4(numericValue);
    };
    console.log(handleInputChange4)
    const handleInputChange5 = (e) => {
        const value = e.target.value;
        const numericValue = value.replace(/\D/g, '').slice(0, 8);
        setInputValue5(numericValue);
    };
    console.log(handleInputChange5)
    const handleInputChange6 = (e) => {
        const value = e.target.value;
        const numericValue = value.replace(/\D/g, '').slice(0, 8);
        setInputValue6(numericValue);
    };
    console.log(handleInputChange6)
    const handleInputChange7 = (e) => {
        const value = e.target.value;
        const numericValue = value.replace(/\D/g, '').slice(0, 8);
        setInputValue7(numericValue);
    };


    // tab one
    const importeRef = React.useRef();
    const duracionRef = React.useRef();
    const tasadeRef = React.useRef();
    const frequenceaRef = React.useRef();
    const fijiRef = React.useRef();
    const lacknumberRef = React.useRef();
    const lacktypeRef = React.useRef();
    const imported_subRef = React.useRef();

    const [fijoSt, setfijoSt] = React.useState('Yes');
    const [carensiaSt, setcarensiaSt] = React.useState('No');
    const [garentiasSt, setgarentiasSt] = React.useState('No');




    //----------------------------------------- module add more
    // 1st tab add more for module variables
    const [modules, setModules] = useState([{ number: '', month1: '1st month', month2: '2nd month' }]);

    function handleChangeModule(i, event) {
        const val = [...modules];


        if (event.target.name === 'number') {
            const value = event.target.value;
            const numericValue = value.replace(/\D/g, '').slice(0, 3);

            val[i][event.target.name] = numericValue;

        } else {
            val[i][event.target.name] = event.target.value;
        }
        setModules(val);
    }

    const handleAddModule = () => {
        const newModule = [...modules];
        newModule.push({ number: '', month1: '', month2: '' })
        setModules(newModule);
    };

    const handleRemoveModule = (i) => {
        const updatedModule = [...modules];
        updatedModule.splice(i, 1);
        setModules(updatedModule);
    };


    //----------------------------------------- cost
    // 1st tab add more for costs
    const [costs, setCosts] = useState([{ Apertura: '', Retraso: '', Otros: '', ddown: 'EUR', Mantenimiento: '', Cancelacion: '' }]);

    function handleChangeCost(i, event) {
        const values = [...costs];

        // values[i][event.target.name] = event.target.value;
        if (event.target.name === 'Apertura' || event.target.name === 'Mantenimiento' ||
            event.target.name === 'Retraso' || event.target.name === 'Cancelacion' || event.target.name === 'Otros'
            //  || event.target.name === 'Otros'
        ) {
            const value = event.target.value;
            const numericValue = value.replace(/\D/g, '').slice(0, 8);

            values[i][event.target.name] = numericValue;

        } else {
            values[i][event.target.name] = event.target.value;
        }

        setCosts(values);
    }

    const addCostField = () => {
        const addNewFields = [...costs];
        addNewFields.push({ Apertura: '', Retraso: '', Otros: '', ddown: 'EUR', Mantenimiento: '', Cancelacion: '' });
        setCosts(addNewFields);
    };

    const removeCostField = (i) => {
        const newFields = [...costs];
        newFields.splice(i, 1);
        setCosts(newFields);
    };

    const token = localStorage.getItem("token");
    const firstFormHandleSubmit = async (event) => {
        event.preventDefault();

        var percetNum = [];
        for (let item of modules) {
            percetNum.push({ "percentage": item['number'], "from_month": item['month1'], "to_month": item['month2'] });
        }


        let costfiled_pre = [];
        let costfiled = costfiled_pre.toString();
        console.log(costfiled);
        for (let item of costs) {
            costfiled_pre.push({
                opening: item['Apertura'], maintenance: item['Mantenimiento'],
                delay: item['Retraso'], early_cancellation: item['Cancelacion'],
                others: item['Otros'], others_type: item['ddown']

            })
        }
        console.log("costfiled")
        console.log(costfiled_pre)


        const percetNumber = fijiRef.current.value === "Yes" ? "No" : percetNum;
        const addmoreValue = costfiled_pre;
        console.log(percetNumber);
        console.log("percetNumber");
        // console.log("dddown = ", ddown);
        const lacknumber = lacknumberRef === "Yes" ? "No" : "No";
        console.log(lacknumber)
        const fijo = fijiRef.current.value === "Yes" ? "Yes" : "No";
        // const carena = carensiaSt=="Yes"?"Yes":"No";

        const carena = carensiaSt === "Yes" ? "Yes" : "No";
        const garante = garentiasSt === "Yes" ? "Yes" : "No";
        const lackNumber = carena === "No" ? "" : lacknumberRef.current.value;
        const lackSub = carena === "No" ? "" : lacktypeRef.current.value;
        const importedSub = garante === "No" ? "" : imported_subRef.current.value;

        // first form api
        var formData = JSON.stringify({
            "user_id": userData['user_id'],
            "loan_id": userData['loan_id'],
            "imported": importeRef.current.value,
            "interest_rate": tasadeRef.current.value,
            "permanent": fijo,
            "duration": duracionRef.current.value,
            "payment_frequency": frequenceaRef.current.value,
            "modelo_variable": percetNumber,
            "lack": carena,
            "lack_number": lackNumber,
            "lack_sub": lackSub,
            "guarantees": garante,
            "imported_sub": importedSub,
            "opening": "jdj",
            "maintenance": "ee",
            "delay": "ff",
            "early_cancellation": "k",
            "others": "ll",
            "add_more_field": addmoreValue,
            "type": "Loan",
        });

        console.log(carensiaSt);
        console.log(formData);
        console.log("formData");
        console.log("you = ", percetNumber)


        var res = await apis.postLenderFormData(token, formData);
        console.log(res['status']);
        console.log(res);
        console.log("respose data");

        if (res['status'] === true) {
            toast.success(res['message'], {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo_bar_log_Success'
            });
            setTimeout(() => {
                navigate('/lender/offer_confirmed');
            }, 800);

        }
        else {
            toast.error(res['message'], {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo_bar_log_error'
            });

        }
    };

    return (
        <React.Fragment>
            <section>
                <p style={{ fontWeight: 600, fontSize: 18, color: "#9b51e0", textAlign: "left" }}>
                    Oferta
                </p>
                <p style={{ border: "solid 1px #0069CB", width: "6%" }}></p>
                <form onSubmit={firstFormHandleSubmit} method="post">
                    <div className="row ms-5">
                        <div className="col-md-4">
                            <div className="mt-5" style={{ display: "flex" }}>

                                <input
                                    type="number"

                                    minLength={1} maxLength={7}
                                    onChange={handleInputChange}
                                    className="form-control "
                                    style={{ display: "none" }}
                                    defaultValue={"Loan"}

                                    onKeyDown={handlekeydown}
                                    min="0"

                                />
                                <label style={{ fontWeight: 600, fontSize: 16 }}>
                                    Importe
                                </label>
                                <input
                                    type="text"
                                    value={inputValue}
                                    onChange={handleInputChange}
                                    className="form-control "
                                    style={{ width: "50%", marginLeft: 65 }}
                                    placeholder="EUR"
                                    required
                                    ref={importeRef}
                                />
                            </div>

                            <div className="mt-4" style={{ display: "flex" }}>
                                <label style={{ fontWeight: 600, fontSize: 16 }}>
                                    Tasa de Interés
                                </label>
                                <input
                                    type="text"
                                    value={inputValue1}
                                    onChange={handleInputChange1}
                                    className="form-control "
                                    style={{ width: "50%", marginLeft: 16 }}
                                    placeholder="%"
                                    required
                                    ref={tasadeRef}
                                />
                            </div>

                            <div className="mt-4" style={{ display: "flex" }}>
                                <p style={{ fontWeight: 600, fontSize: 16 }}>Fijo</p>
                                <select
                                    className="form-select"
                                    onChange={e => { setfijoSt(e.target.value) }}
                                    value={fijoSt}
                                    style={{
                                        fontSize: 14,
                                        height: 34,
                                        width: "50%",
                                        marginLeft: 97,
                                    }}
                                    ref={fijiRef}
                                >
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </div>
                            {(fijoSt === "No") ?
                                <div>
                                    <p
                                        style={{
                                            marginTop: 30,
                                            fontWeight: 600,
                                            fontSize: 16,
                                        }}
                                    >
                                        Modelo Variable
                                    </p>
                                    <div>
                                        {modules.map((module, idx) => (
                                            <div className="mt-4" style={{ display: "flex" }} key={`${module}-${idx}`}>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    style={{ width: "50%" }}
                                                    placeholder="%"
                                                    required
                                                    name="number"
                                                    value={module.number || ""}
                                                    onChange={e => handleChangeModule(idx, e)}
                                                    onKeyDown={handlekeydown}
                                                    min="0"
                                                />
                                                <select
                                                    className="form-select"
                                                    style={{
                                                        fontSize: 16,
                                                        height: 34,
                                                        width: "60%",
                                                        marginLeft: 10,
                                                    }}
                                                    name="month1"
                                                    value={module.month1 || ""}
                                                    onChange={e => handleChangeModule(idx, e)}
                                                >
                                                    <option value="1st month">1st month</option>
                                                    <option value="2nd month">2nd month</option>
                                                    <option value="3rd month">3rd month</option>
                                                </select>

                                                <select
                                                    className="form-select"
                                                    style={{
                                                        fontSize: 16,
                                                        height: 34,
                                                        width: "60%",
                                                        marginLeft: 10,
                                                    }}
                                                    name="month2"
                                                    value={module.month2 || ""}
                                                    onChange={e => handleChangeModule(idx, e)}
                                                >
                                                    <option value="2nd month">2nd month</option>
                                                    <option value="120th month">120th month</option>
                                                </select>

                                                {((modules.length > 1) && (idx !== 0)) ?
                                                    <button
                                                        type="button"
                                                        className="btn"
                                                        onClick={() => handleRemoveModule(idx)}
                                                        style={{
                                                            backgroundColor: "transprent",//#0069CB
                                                            color: "red",
                                                            borderRadius: 4,
                                                        }}
                                                    >
                                                        X
                                                    </button> :
                                                    <button
                                                        type="button"
                                                        className="btn"
                                                        style={{
                                                            backgroundColor: "transprent",
                                                            color: "white",
                                                            borderRadius: 4,
                                                        }}
                                                    >
                                                        X
                                                    </button>}
                                            </div>
                                        ))}

                                        <button
                                            type="button"
                                            className="btn mt-2"
                                            onClick={handleAddModule}
                                            style={{
                                                backgroundColor: "#0069CB",
                                                color: "white",
                                                borderRadius: 4,
                                                marginTop: 15
                                            }}
                                        >
                                            Añadir más
                                        </button>
                                    </div>
                                </div> : null}

                            {/* <div>
                      <div id="req_input11" className="datainputs"></div>
                      <Link to="#">
                      <button
                        type="button"
                        // id="addmore11"
                        className="btn"
                        style={{
                          backgroundColor: "#0069CB",
                          color: "white",
                          borderRadius: 4,
                          marginTop: 15,
                        }}
                      >
                        Añadir más
                        <span>
                          <i
                            className="fa fa-plus"
                            style={{ marginLeft: 10 }}
                            aria-hidden="true"
                          />
                        </span>
                      </button>
                    </div> */}
                        </div>
                        <div className="col-md-4">
                            <div className="mt-5" style={{ display: "flex" }}>
                                <label style={{ fontSize: 16, fontWeight: 600 }}>
                                    Duración (meses)
                                </label>
                                <input
                                    type="text"
                                    name="field1" value={inputValue7} onChange={handleInputChange7} maxLength={8}
                                    // value={inputValue7}
                                    // onChange={handleInputChange7}
                                    className="form-control "
                                    style={{ width: "50%", marginLeft: 40 }}
                                    placeholder="EUR"
                                    required
                                    ref={duracionRef}
                                />
                            </div>

                            <div className="mt-4" style={{ display: "flex" }}>
                                <p style={{ fontWeight: 600, fontSize: 16 }}>
                                    Frecuencia de pago
                                </p>
                                <select
                                    className="form-select"
                                    style={{
                                        fontSize: 16,
                                        height: 34,
                                        width: "50%",
                                        marginLeft: 25,
                                    }}
                                    ref={frequenceaRef}
                                >
                                    <option>Semanal</option>
                                    <option>Bimensual</option>
                                    <option>Mensual</option>
                                    <option>Bimestral</option>
                                    <option>Trimestral</option>
                                    <option>Anual</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <p
                        className="mt-5"
                        style={{ border: "solid 1px #E0E0E0", width: "50%" }}
                    ></p>

                    <div className="row ms-5">
                        <div className="col-md-4">
                            <div className="mt-4" style={{ display: "flex" }}>
                                <p style={{ fontWeight: 600, fontSize: 16 }}>
                                    Carencia
                                </p>
                                <select
                                    className="form-select"
                                    style={{
                                        fontSize: 16,
                                        height: 34,
                                        width: "50%",
                                        marginLeft: 25,
                                    }}
                                    onChange={e => { setcarensiaSt(e.target.value) }}
                                    value={carensiaSt}

                                >
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </div>
                            {(carensiaSt === "Yes") ?
                                <div className="mt-4" style={{ display: "flex" }}>
                                    <input
                                        type="number"
                                        className="form-control "
                                        style={{ width: "20%" }}
                                        placeholder="1"
                                        required
                                        ref={lacknumberRef}
                                        name="field12" value={values.field12} onChange={handleChange2} maxLength={4}
                                        onKeyDown={handlekeydown}
                                        min="0"
                                    />
                                    <select
                                        className="form-select"
                                        style={{
                                            fontSize: 16,
                                            height: 34,
                                            width: "50%",
                                            marginLeft: 26,

                                        }}
                                        name=""
                                        // onChange={e => {setlack(e.target.value)}}
                                        ref={lacktypeRef}
                                    >
                                        <option>Semanas</option>
                                        <option>Meses</option>
                                    </select>
                                </div> : null}
                        </div>
                        <div className="col-md-4">
                            <div className="mt-4" style={{ display: "flex" }}>
                                <p style={{ fontWeight: 600, fontSize: 16 }}>
                                    Garantias
                                </p>
                                <select
                                    className="form-select"
                                    style={{
                                        fontSize: 16,
                                        height: 34,
                                        width: "50%",
                                        marginLeft: 56,
                                    }}
                                    onChange={e => { setgarentiasSt(e.target.value) }}
                                    value={garentiasSt}
                                >
                                    <option>Yes</option>
                                    <option>No</option>
                                </select>
                            </div>
                            {(garentiasSt === "Yes") ?
                                <div className="mt-5" style={{ display: "flex" }}>
                                    <label style={{ fontWeight: 600, fontSize: 16 }}>
                                        Importe
                                    </label>
                                    <input
                                        type="number"
                                        className="form-control "
                                        style={{ width: "50%", marginLeft: 65 }}
                                        name="field2" value={values.field2} onChange={handleChange1} maxLength={8}
                                        placeholder="EUR"
                                        required
                                        ref={imported_subRef}
                                        onKeyDown={handlekeydown}
                                        min="0"
                                    />
                                </div> : null}
                        </div>
                    </div>

                    <p
                        className="mt-5"
                        style={{ border: "solid 1px #E0E0E0", width: "50%" }}
                    ></p>

                    <div className="row ms-5">
                        <ul
                            style={{
                                fontWeight: 600,
                                fontSize: 20,
                                textDecorationLine: "underline",
                                textDecorationColor: "#E0E0E0",
                                textAlign: "left"
                            }}
                        >
                            COSTES
                        </ul>
                        {/* 1 */}
                        {costs.map((cost, idx2) => (
                            <div className="row" key={`${cost}-${idx2}`}>
                                <div className="col-md-4">
                                    <div className="mt-5" style={{ display: "flex" }}>
                                        <label style={{ fontWeight: 600, fontSize: 16 }}>Apertura:</label>
                                        <input type="number"
                                            value={cost.Apertura || ""}
                                            //  onChange={handleInputChange2}
                                            name="Apertura"
                                            onChange={e => handleChangeCost(idx2, e)}
                                            className="form-control"
                                            style={{ width: "50%", marginLeft: 55 }} placeholder="EUR" required
                                            onKeyDown={handlekeydown}
                                            min="0" />
                                    </div>
                                    <div className="mt-5" style={{ display: "flex" }}>
                                        <label style={{ fontWeight: 600, fontSize: 16 }}>Retraso:</label>
                                        <input
                                            type="number"
                                            name="Retraso"
                                            value={cost.Retraso || ""}
                                            onChange={e => handleChangeCost(idx2, e)}
                                            className="form-control" style={{ width: "50%", marginLeft: 65 }} placeholder="EUR" required
                                            onKeyDown={handlekeydown}
                                            min="0" />
                                    </div>
                                    <div className="mt-5" style={{ display: "flex" }}>


                                        <label style={{ fontWeight: 600, fontSize: 16 }}>Otros:</label>
                                        <input
                                            type="number"
                                            // minLength={1} maxLength={8}
                                            name="Otros"
                                            value={cost.Otros || ""}
                                            onChange={e => handleChangeCost(idx2, e)}
                                            className="form-control"
                                            style={{ width: "40%", marginLeft: 79 }} placeholder="Nombre" required
                                            onKeyDown={handlekeydown}
                                            min="0" />

                                        <select
                                            className="form-select"
                                            style={{ fontSize: 16, height: 34, width: "40%", marginLeft: 7 }}
                                            name="ddown"
                                            value={cost.ddown || "EUR"}
                                            onChange={(e) => handleChangeCost(idx2, e)}
                                            required
                                        > 
                                            <option value="EUR">EUR</option>
                                            <option value="%">%</option>
                                           
                                        </select>




                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mt-5" style={{ display: "flex" }}>
                                        <label style={{ fontWeight: 600, fontSize: 16 }}>Mantenimiento:</label>
                                        <input
                                            type="text"
                                            //  value={inputValue5}
                                            //  onChange={handleInputChange5}
                                            name="Mantenimiento"
                                            value={cost.Mantenimiento || ""}
                                            onChange={e => handleChangeCost(idx2, e)}
                                            className="form-control"
                                            style={{ width: "50%", marginLeft: 35 }} placeholder="EUR" required />
                                    </div>
                                    <div className="mt-5" style={{ display: "flex" }}>
                                        <label style={{ fontWeight: 600, fontSize: 16 }}>Cancelacion <br /> Anticipada:</label>
                                        <input
                                            type="text"
                                            //  value={inputValue6}
                                            //  onChange={handleInputChange6}
                                            name="Cancelacion"
                                            value={cost.Cancelacion || ""}
                                            onChange={e => handleChangeCost(idx2, e)}
                                            className="form-control"
                                            style={{ width: "50%", marginLeft: 65 }} placeholder="EUR" required />
                                    </div>
                                    {((costs.length > 1) && (idx2 !== 0)) ?
                                        <button
                                            type="button"
                                            className="btn mt-5"
                                            onClick={() => removeCostField(idx2)}
                                            style={{
                                                backgroundColor: "transprent",//#0069CB
                                                color: "red",
                                                borderRadius: 4,
                                                width: "100px",
                                                borderColor: "red",
                                                marginLeft: 25
                                            }}
                                        >
                                            remove
                                        </button> :
                                        <button
                                            type="button mt-5"
                                            className="btn"
                                            style={{
                                                backgroundColor: "transprent",
                                                color: "white",
                                                borderRadius: 4,
                                                width: "100px",
                                                marginLeft: 25
                                            }}
                                        >
                                            remove
                                        </button>}
                                </div>

                            </div>
                        ))}




                        <button
                            type="button"
                            className="btn mt-2"
                            onClick={addCostField}
                            style={{
                                backgroundColor: "#0069CB",
                                color: "white",
                                borderRadius: 4,
                                marginTop: 15,
                                width: "100px",
                            }}
                        >
                            Añadir más
                        </button>
                    </div>
                    <button
                        id=""
                        className="mt-5 mb-4"
                        type="submit"
                        style={{
                            marginLeft: "45px",
                            float: "left",
                            height: "42px",
                            width: "120px",
                            marginTop: 0,
                            fontSize: "14px",
                            fontWeight: "600",
                            backgroundColor: "#f2994a",
                            color: "white",
                            borderRadius: 6,
                            border: "solid 2px #f2994a",
                        }}
                    >
                        ENVIAR OFERTA
                    </button>
                </form>
            </section>
        </React.Fragment>
    );
}

import React from "react";
import { Link } from "react-router-dom";
import UserHeader from "../user/component/header";

export default function Home() {
  return (
    <React.Fragment>

      <UserHeader />

      <section className="legalconform">

      </section>

      {/* <!--footer --> */}
      <footer className="bacgco">
        <div className="container">
          <div className="row footerr" >
            <div className="col-md-3 ">
              <p className="fotcol">2023 Credit Store</p>

            </div>
            <div className="col-md-3 fot_mob">
              <div className="displaygrid">
                <label className="text-white">Menu</label>

                <Link to="register.html" className="fotcol">Crear cuenta</Link>
                <Link to="login.html" className="fotcol">Iniciar Sesión</Link>
                <Link to="about_creditscore.html" className="fotcol">Sobre Credit Store</Link>
                <Link to="contact.html" className="fotcol">Contacto</Link>
              </div>

            </div>
            <div className="col-md-3 fot_mob">
              <div className="displaygrid">
                <label className="text-white">Seguridad</label>
                <Link to="legal_warning.html" className="fotcol">Aviso Legal</Link>
                <Link to="data_protection.html" className="fotcol">Política de Privacidad</Link>
                <Link to="legal_conditions.html" className="fotcol">Condiciones Generales</Link>
                <Link to="cookie_policy.html" className="fotcol">Política de Uso de Cookies</Link>
              </div>

            </div>
            <div className="col-md-3">
              <div className="displaygrid">
                <Link to="https://twitter.com/CreditStoreLine" className="fotcol"><span className="footericon"><i className="fa fa-twitter-square footericon " aria-hidden="true" ></i></span>Twitter</Link>
                <Link to="https://www.linkedin.com/company/thecreditstore/" className="fotcol"><span span className="footericon"><i className="fa fa-linkedin-square footericon" aria-hidden="true" ></i></span>LinkedIn</Link>
              </div>


            </div>
          </div>
        </div>
      </footer>

    </React.Fragment>
  )
}



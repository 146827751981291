import React from "react";
import { Link } from "react-router-dom";
import * as images from './images.jsx';
import UserHeader from "../user/component/header.jsx";

export default function Warning() {
  return (
    <React.Fragment>
      <UserHeader />

      <section className="warningbg">
        <div className="container">
          <div className="row" >
            <div className="col-md-12  "  > <h1><strong>Aviso Legal</strong></h1>

              <h2><strong>Identidad del titular del Sitio Web</strong></h2>
              <p>El presente aviso e informaci&oacute;n legal regula el uso del servicio del Web de Internet bajo el siguiente nombre de dominio: <Link to="https://www.creditstore.net/">www.creditstore.net</Link></p>
              <p>Credit Store es un nombre comercial propiedad de Credit Store Ltd con Registry No. 14413783 y domicilio en 71-75 Shelton St, Covent Garden, London, WC2H 9JQ, United Kingdom.&nbsp;</p>
              <p>El Web es propiedad de Credit Store Ltd, quien se reserva la facultad de efectuar, en cualquier momento y sin previo aviso, modificaciones de la informaci&oacute;n contenida en su Web o en la configuraci&oacute;n y presentaci&oacute;n del mismo. El titular posee todos los derechos de autor sobre todos los contenidos del Web, ya sean por derecho propio o por cesiones o acuerdos con terceros.</p>
              <p>Por el acceso a este Web, incluidas todas sus p&aacute;ginas, se acepta que Credit Store no ser&aacute; responsable de consecuencia alguna, da&ntilde;o o perjuicio que se derive de dicho acceso o uso de la informaci&oacute;n de dicho Web o por el acceso a otras materias en Internet a trav&eacute;s de las conexiones con este Web. Tampoco ser&aacute; responsable y el usuario lo acepta de los errores o problemas inform&aacute;ticos causados por virus, gusanos, software malicioso u otros ataques de ingenier&iacute;a social (<em>phising</em>, <em>pharming</em>, <em>troyanos</em>, etc.) que terceros puedan haber ubicado maliciosamente en nuestros servidores, durante el margen de tiempo hasta que se logren poner en marcha las medidas de protecci&oacute;n reactiva de que dispone esta entidad. Si el usuario se percata de errores en el funcionamiento o de comportamientos extra&ntilde;os del sitio, puede dirigir un correo a info@creditstore.net. Se proh&iacute;be el acceso a los contenidos de este Sitio Web por medio de sistemas mecanizados que sean distintos a personas f&iacute;sicas, ya que pueden ocasionar al titular del Web da&ntilde;os al no poder medir con objetividad las audiencias.</p>
              <p>Ir a las <Link to className="link" href="{{ url_for('main.legal_conditions') }}">Condiciones Generales</Link> condiciones generales de contrataci&oacute;n</p>

              <h3><strong>Condiciones de uso del Web</strong></h3>
              <p>Los contenidos e informaciones que se suministran a trav&eacute;s de <Link to="https://www.creditstore.net/">www.creditstore.net</Link> cumplen con la legislaci&oacute;n vigente y son propiedad de Credit Store o adquiridos o explotados con t&iacute;tulos leg&iacute;timos.</p>
              <p>El titular del Sitio Web puede ofrecer a trav&eacute;s del Web, servicios o productos que podr&aacute;n encontrarse sometidos a unas condiciones particulares propias que, seg&uacute;n los casos, sustituyen, completan y/o modifican las presentes Condiciones, y sobre las cuales se informar&aacute; al Usuario en cada caso concreto.</p>
              <p>La utilizaci&oacute;n del Web atribuye la condici&oacute;n de usuario del Web e implica la aceptaci&oacute;n plena y sin reservas de todas y cada una de las disposiciones incluidas en este Aviso Legal en la versi&oacute;n publicada por Credit Store Europe en el momento mismo en que el Usuario acceda al Web. En consecuencia, el Usuario debe leer atentamente el presente Aviso Legal en cada una de las ocasiones en que se proponga utilizar el Web, ya que aqu&eacute;l puede sufrir modificaciones.</p>
              <p>El Usuario se compromete a utilizar el Web, los contenidos y servicios de conformidad con la Ley, el presente Aviso Legal, la pol&iacute;tica de privacidad, las buenas costumbres y el orden p&uacute;blico. Del mismo modo, el Usuario se obliga a no utilizar el Web o los servicios que se presten a trav&eacute;s de &eacute;l con fines o efectos il&iacute;citos o contrarios al contenido del presente Aviso Legal, lesivos de los intereses o derechos de terceros, o que de cualquier forma pueda da&ntilde;ar, inutilizar o deteriorar el Web o sus servicios, o impedir un normal disfrute del Web por otros Usuarios.</p>
              <p>Asimismo, el Usuario se compromete expresamente a no destruir, alterar, inutilizar o, de cualquier otra forma, da&ntilde;ar los datos, programas o documentos electr&oacute;nicos y dem&aacute;s que se encuentren en la presente Web.</p>
              <p>El Usuario se compromete a no introducir programas, virus, macros, controles o cualquier otro dispositivo l&oacute;gico o secuencia de caracteres que causen o sean susceptibles de causar cualquier tipo de alteraci&oacute;n en los sistemas inform&aacute;ticos del titular del Web o de terceros.</p>
              <p>El Usuario acepta y declara conocer el presente Aviso legal y Pol&iacute;tica de Privacidad. En caso contrario, el usuario debe abandonar el Sitio Web en este momento.</p>
              <p>La actividad no est&aacute; sujeta a ning&uacute;n r&eacute;gimen de autorizaci&oacute;n administrativa previa, ni adherida a c&oacute;digos de conducta, salvo a la buena fe, la costumbre y al C&oacute;digo civil. El titular no presta servicios de intermediaci&oacute;n o alojamiento.</p>

              <h3><strong>Publicidad</strong></h3>
              <p>El Sitio web puede albergar contenidos publicitarios o estar patrocinado. Los anunciantes y patrocinadores son los &uacute;nicos responsables de asegurarse que el material remitido para su inclusi&oacute;n en el Sitio web cumple con las leyes y consentimientos que en cada caso puedan ser de aplicaci&oacute;n. El titular del Web no ser&aacute; responsable de cualquier error, inexactitud o irregularidad que puedan contener los contenidos publicitarios o de los patrocinadores. Tampoco ser&aacute; responsable de da&ntilde;os a la sensibilidad que el material publicitario u otro material pueda causar a los usuarios o visitantes del Web. Para informaci&oacute;n de menores, consulte la Pol&iacute;tica de Privacidad.</p>

              <h3><strong>Propiedad Intelectual e Industrial</strong></h3>
              <p>Todos los contenidos del Web, salvo que se indique lo contrario, son titularidad exclusiva del titular de la web o de terceros y, con car&aacute;cter enunciativo, que no limitativo, el dise&ntilde;o gr&aacute;fico, c&oacute;digo fuente, logos, textos, gr&aacute;ficos, ilustraciones, fotograf&iacute;as, y dem&aacute;s elementos que aparecen en la Web. Las fotograf&iacute;as de la Web pueden contener datos de car&aacute;cter personal, por lo que no se permite su uso para ninguna finalidad.</p>
              <p>Igualmente, todos los nombres comerciales, marcas o signos distintivos de cualquier clase contenidos en el Web est&aacute;n protegidos por la Ley.</p>
              <p>El titular del Web no concede ning&uacute;n tipo de licencia o autorizaci&oacute;n de uso personal al Usuario o visitante sobre sus derechos de propiedad intelectual e industrial o sobre cualquier otro derecho relacionado con su Web, sus fotograf&iacute;as y los servicios ofrecidos en la misma.</p>
              <p>Todos los derechos reservados. El Usuario o visitante reconoce que la reproducci&oacute;n, distribuci&oacute;n, comercializaci&oacute;n, transformaci&oacute;n, y en general, cualquier otra forma de explotaci&oacute;n, por cualquier procedimiento, de todo o parte de los contenidos de este Web constituye una infracci&oacute;n de los derechos de propiedad intelectual y/o industrial del titular del Web o del titular de los mismos, y un delito del C&oacute;digo penal, y queda prohibido.</p>

              <h3><strong>Responsabilidad por el Uso del Sitio Web</strong></h3>
              <p>El Usuario es el &uacute;nico responsable de las infracciones en las que pueda incurrir o de los perjuicios que pueda causar por la utilizaci&oacute;n del Web o de sus contenidos, quedando el titular del Web, sus socios, proveedores, colaboradores, empleados y representantes, exonerados de cualquier clase de responsabilidad que se pudiera derivar por las acciones del Usuario.</p>
              <p>El titular del Web emplear&aacute; todos los esfuerzos y medios razonables para facilitar informaci&oacute;n actualizada y fehaciente en el Web, no obstante, el titular del Web no asume ninguna garant&iacute;a en relaci&oacute;n con la ausencia de errores, o de posibles inexactitudes y/u omisiones en ninguno de los contenidos accesibles a trav&eacute;s de este Web.</p>
              <p>El Usuario es el &uacute;nico responsable frente a cualquier reclamaci&oacute;n o acci&oacute;n legal, judicial o extrajudicial, iniciada por terceras personas contra el titular del Web basada en la utilizaci&oacute;n por el Usuario del Web o de sus contenidos. En su caso, el Usuario asumir&aacute; cuantos gastos, costes e indemnizaciones sean irrogados al titular de la Web con motivo de tales reclamaciones o acciones legales.</p>

              <h3><strong>Responsabilidad por el funcionamiento del Sitio Web</strong></h3>
              <p>El titular del Sitio Web excluye toda responsabilidad que se pudiera derivar de interferencias, omisiones, interrupciones, virus inform&aacute;ticos, aver&iacute;as telef&oacute;nicas o desconexiones en el funcionamiento operativo del sistema electr&oacute;nico, motivado por causas ajenas al titular de la Web.</p>
              <p>Asimismo, el titular del Sitio Web tambi&eacute;n excluye cualquier responsabilidad que pudiera derivarse por retrasos o bloqueos en el funcionamiento operativo de este sistema electr&oacute;nico causado por deficiencias o sobre carga en las l&iacute;neas telef&oacute;nicas o en Internet, as&iacute; como de da&ntilde;os causados por terceras personas mediante intromisiones ilegitimas fuera del control del titular del Web.</p>
              <p>El titular del Web esta facultado para suspender temporalmente, y sin previo aviso, la accesibilidad al Web con motivo de operaciones de mantenimiento, reparaci&oacute;n, actualizaci&oacute;n o mejora.</p>

              <h3><strong>Responsabilidad por Enlaces o Links</strong></h3>
              <p>El titular del Web est&aacute; exento de cualquier responsabilidad respecto a la informaci&oacute;n que se halle fuera del Web, ya que la funci&oacute;n de los links que aparecen es &uacute;nicamente la de informar al Usuario sobre la existencia de otras fuentes de informaci&oacute;n sobre un tema en concreto o de publicidad.</p>

              <h3><strong>Modificaciones de las condiciones de uso</strong></h3>
              <p>El titular del Web se reserva el derecho a modificar, desarrollar o actualizar en cualquier momento y sin previa notificaci&oacute;n, las condiciones de uso del presente Web. El Usuario quedar&aacute; obligado autom&aacute;ticamente por las condiciones de uso que se hallen vigentes en el momento en que acceda al Web, por lo que deber&aacute; leer peri&oacute;dicamente dichas condiciones de uso.</p>

              <h3><strong>Resoluci&oacute;n de conflictos y jurisdicci&oacute;n competente</strong></h3>
              <p>Todas las controversias que pudieran surgir en cuanto a la aplicaci&oacute;n del presente aviso legal, ser&aacute;n dirimidas ante los Juzgados y Tribunales del domicilio del titular del Web.</p>



            </div>

          </div>
        </div>
      </section>


      {/* <!--footer --> */}

      <footer className="bacgco">
        <div className="container">
          <div className="row footerr" >
            <div className="col-md-3 ">
              <p className="fotcol">2023 Credit Store</p>

            </div>
            <div className="col-md-3 fot_mob">
              <div className="displaygrid">
                <label className="text-white">Menu</label>

                <Link to="/auth/Register" className="fotcol">Crear cuenta </Link>
                <Link to="/auth/Login" className="fotcol">Iniciar Sesión </Link>
                <Link to="/about" className="fotcol">Sobre Credit Store </Link>
                <Link to="/contact" className="fotcol">Contacto </Link>
              </div>

            </div>
            <div className="col-md-3 fot_mob">
              <div className="displaygrid">
                <label className="text-white">Seguridad</label>
                <Link to="/warning" className="fotcol">Aviso Legal </Link>
                <Link to="/dataprotect" className="fotcol">Política de Privacidad </Link>
                <Link to="/condition" className="fotcol">Condiciones Generales </Link>
                <Link to="/cookies" className="fotcol">Política de Uso de Cookies </Link>
              </div>

            </div>
            <div className="col-md-3">
              <div className="displaygrid">
                <Link to="https://twitter.com/CreditStoreLine" className="fotcol"><span className="footericon"><i className="fa fa-twitter-square footericon " aria-hidden="true" ></i></span>Twitter </Link>
                <Link to="https://www.linkedin.com/company/thecreditstore/" className="fotcol"><span span className="footericon"><i className="fa fa-linkedin-square footericon" aria-hidden="true" ></i></span>LinkedIn </Link>
              </div>


            </div>
          </div>
        </div>
      </footer>


    </React.Fragment>
  )
}



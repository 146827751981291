import React from "react";
import { Link } from "react-router-dom";
import { useNavigate,Navigate } from "react-router-dom";
import * as con from '../repository/urls.js';
import { useState, useEffect } from "react";
import * as images from './images.jsx';
import * as apis from '../repository/api_calls.js';
import CircleProgressBar1 from "../circle/dashboard_circle.jsx";
import 'react-toastify/dist/ReactToastify.css';
import UserHeader from "../user/component/header.jsx";

export default function Home() {

  const [userscore, setuserscore] = React.useState()
  const token = localStorage.getItem("token");
  const [userLoanData, setUserLoanData] = useState([]);
  const role_id = localStorage.getItem("role_id");


  const navigate = useNavigate();

  function moveToScoreTips(data){
    localStorage.setItem("score_tips",JSON.stringify(data));
    navigate('/score_tips');
  }

  
  function getLoanPercent(score){
    var percentage = '';
    if(score < 0){
      percentage = 'Error - Data Incompleta';
     }
     else if(score > 0 && score <= 50){
      percentage = 'Bajo';
     }
   else if(score > 50 && score <= 66){
       percentage = 'Regular';
   }    
   else if (score > 66 && score <= 79){
       percentage  = 'Bueno';
   }
   else if (score >= 80 && score < 90){
       percentage = 'Muy Bueno';
   }  
  else if (score >= 90 && score <= 100){
      percentage = 'Excelente';
  } else{
    percentage = 'Indefinida';
  }
  return percentage;
  }
  

  useEffect(() => {
    getUserScore();
    // getLoan();
    
  }, [])

//   async function getLoan() {
//     const response = await apis.getLenderLoanOffers();
//     console.log(response)
//     if (response['status'] === true) {
//       localStorage.setItem("lender_id", response.data.lender_id);
//     }
// }


  // useEffect(() => {
  //   fetch(`${process.env.REACT_APP_BASE_URL}${con.loanOfferList}`, {
  //     method: 'post',
  //     headers: {
  //       "Authorization": `Bearer ${token}`,
  //     }
  //   }).then(res => res.json())
  //     .then(res => {
  //       if (res['status'] === true) {
  //         localStorage.setItem("lender_id", res.data.lender_id);
  //       }
  //     });
  //   getUserScore();
  // }, [])




  async function getUserScore() {
    const response = await apis.getUserScore(token);
    console.log(response)
    if (response['status'] === true) {
      setUserLoanData(response['data']);
      for(let i=0;i<response['data'].length;i++){
        if(response['data'][i]['status']==="Active"){
          setuserscore(response['data'][i]);
        }
      }
    }
  }

  return (
    (role_id !== null && role_id === "2") ?
    <React.Fragment>
        
        <UserHeader />

      <section style={{ backgroundColor: "#FFFFFF", top: "50px", height: "65px", position: "sticky", zIndex: 2 }}>
        <div className="container">
          <div className="row" style={{ padding: 10 }}>
            <div className="col-md-3" style={{ display: "flex" }}>
              <a href="/dashboard" style={{ textDecoration: "none" }}>
                <p style={{ color: "#183F4B", fontSize: "18px", marginTop: "2px" }}>
                  <span>
                    <img  className="" src={images.dash_dark} alt='dash_dark' />
                  </span>
                  <span style={{ textTransform: "capitalize" }}>  Panel</span>                  
                </p>
              </a>
              <div
                style={{
                  borderLeft: "2px solid #E0E0E0",
                  height: 45,
                  marginLeft: 80
                }}
              ></div>
            </div>

            <div className="col-md-3" style={{ display: "flex" }}>
              <a href="/myscore" style={{ textDecoration: "none",marginLeft:"-38px" }}>
                <p style={{ color: "#0069CB", marginLeft: 10, fontSize: "18px", marginTop: "2px" }}>
                  <span>
                 
                    <img  className="" src={images.z_score_blue} alt='z_score_blue' />
                  </span>{" "}
                  <span style={{ textTransform: "capitalize" }}>  Mi puntaje</span>                   
                </p>
              </a>
              <div
                style={{
                  borderLeft: "2px solid #E0E0E0",
                  height: 45,
                  marginLeft: 80
                }}
              ></div>
            </div>
            <div className="col-md-3" style={{ display: "flex" }}>
              <a href="/dashboardapp" style={{ textDecoration: "none" }}>
                <p style={{ color: "#183F4B", fontSize: "18px", marginTop: "2px", marginLeft:"-20px" }}>
                  <span>
                    <img  className="" src={images.applica_dark} alt='applica_dark' style={{ marginRight: 7 }} />
                  </span>{" "}
                  <span style={{ textTransform: "capitalize" }}> Solicitudes enviadas</span>                  
                </p>
              </a>
              <div
                style={{
                  borderLeft: "2px solid #E0E0E0",
                  height: 45,
                  marginLeft: 80
                }}
              ></div>
            </div>
            <div className="col-md-3">
              <a href="/dashboardloan" style={{ textDecoration: "none" }}>
                <p style={{ color: "#183F4B", fontSize: "18px", }}>
                  <span>
                    <img  className="" src={images.loan_offer_dark} alt='loan_offer_dark' />
                  </span>
                  <span style={{ textTransform: "capitalize" }}>Ofertas recibidas</span>                  
                </p>
              </a>
            </div>

          </div>
        </div>
      </section>


      {/* {(userLoanData.length===0)?
      <h4 className="text-center" style={{ fontWeight: 600 }}>No tiene detalles de ZScore. </h4>
      : null} */}


         
      {/* {(userscore === undefined) ? */}

      {((userscore === undefined) || (userscore['user_score'] === null)) ?
      (
        <section className="container" style={{ marginTop: 20 }}>
          <div className="card myscorecrd" >

            <div style={{ display: "flex" }}>
              <div style={{ display: "grid", marginLeft: 5 }}>
                <h4 style={{ color: "white", fontWeight: 800, fontSize: "19px" }}>¿Puede tu negocio ahora recibir financiamiento?</h4>
                <p style={{ color: "white", fontSize: "11px" }}>
                Completa todos los formularios y descubre tu puntaje ahora mismo
                </p>
                <Link to="/creditscore">
                  <button
                    type="button"
                    className="btn1 btn_das"
                    style={{color: "white",  height: "38px", width: "auto", marginTop: 0, fontSize: "14px", fontWeight: "600" }}>

                    CALCULA TU PUNTAJE
                  </button>
                </Link>
              </div>
              <div style={{marginLeft:"500px"}}>
              {((userscore===undefined)||(userscore['user_score']===null))? 
              <div style={{display:"flex"}}>
                <CircleProgressBar1 percentage={0}  /> 
            
              </div>: null} 
              </div>
            </div>
          </div>
        </section>  ):



        <section className="container" style={{ marginTop: "40px" }}>
          <div className="scorecard">
            <div style={{ display: "flex" }}>
              <div
                style={{
                  backgroundColor: "#293042",
                  width: 300,
                  display: "flex",
                  padding: 20,
                  borderBottomLeftRadius: 6,
                  borderTopLeftRadius: 6
                }}
              >

{/* 
                {(userscore===undefined)? <CircleProgressBar1 percentage={0}  /> :
              <div style={{display:"flex"}}>
               
                <CircleProgressBar1 percentage={userscore['user_score']} scolor={getLoanPercent(userscore['user_score'])} />

                <p style={{ marginTop: 30, marginLeft: 25 }}>
                <i style={{ color: "#F2994A", fontSize: 18 }}>{getLoanPercent(userscore['user_score'])}</i>
                <br />
                <span style={{ color: "#FAFAFD" }}>Puntaje de crédito</span>{" "}
                </p>
              </div>
              
                } */}

              {((userscore === undefined) || (userscore['user_score'] === null)) ?
                    <div style={{ display: "flex" }}>
                      <CircleProgressBar1 percentage={0} />
                      <p style={{ marginTop: 30, marginLeft: 25 }}>
                        <br />
                        <span style={{ color: "#FAFAFD" }}>Puntaje de crédito</span>{" "}

                      </p>
                    </div>

                    :
                    <div style={{ display: "flex" }}>
                      <CircleProgressBar1 percentage={userscore['user_score']} scolor={getLoanPercent(userscore['user_score'])} />


                      <p style={{ marginTop: 30, marginLeft: 25 }}>
                        <i style={{ color: "#F2994A", fontSize: 18 }}>{getLoanPercent(userscore['user_score'])}</i>
                        <br />
                        <span style={{ color: "#FAFAFD" }}>Puntaje de crédito</span>{" "}

                      </p>
                    </div>

                  }
              </div>

              <div style={{ marginLeft: 60, padding: 20 }}>
              <div><p><b>ID DE PUNTUACIÓN DE CRÉDITO : </b> 
                {(userscore===undefined)? ' - ' : <span className="badge bg-primary">{userscore['score_ran_id']}</span>}
                </p></div>

   
                <p>Obtenga sugerencias para mejorar su puntaje de crédito. </p>
                <button onClick={()=>{moveToScoreTips(userscore);}} className="das_sco">
                Ver detalles
                  <span>
                    <i className="fa fa-long-arrow-right" aria-hidden="true" />
                  </span>
                </button>
                <br />
               
                {(userscore === undefined) ? (
                  <a href="/creditscore" >  <button type="button" className="btn1 btn-cta" style={{ height: 32, marginTop: 10, fontSize: 17 }} >Verifique el puntaje de crédito</button></a>
                 ) : null} 
              </div>
             
              <img  className="" src={images.rockt} alt='rockt' style={{ marginLeft: 290, padding: 10, height: 100, marginTop: 20 }} />
            </div>
          </div>
        </section>
        } 





      {(userLoanData.length !== 0) ?
        <section className="container">
          <div className="col-md-12 mt-5">
            <div className="card" style={{ padding: 20 }}>
              <h4 className="text-center" style={{ fontWeight: 600 }}>Detalles de tu puntuación </h4>


              {userLoanData.map((list) => {
                return (

                  <div className="row mt-5">
                    <div className="col-md-12">
                      <div className="card" style={{ padding: 30 }}>

                        <table className="table">

                          <tbody>

                            <tr>
                              <th>Identificación de puntuación</th>
                              <td><span className="badge bg-primary">{list.score_ran_id}</span></td>
                              
                              <th>Estado</th>

                          

                              {/* {(list.status === 'Active')?
                              <span className="badge bg-success">{list.status}</span>:
                              <span className="badge bg-danger">{list.status}</span>} */}

                                   {(list.status === 'Active') ?
                                    <span className="badge bg-success" style={{marginTop:"8px"}}>Activa</span> :
                                    <span className="badge bg-danger" style={{marginTop:"8px"}}>Inactiva</span>}
                            </tr>

                            <tr>
                            <th>Puntuación del usuario</th>
                              <td>  <span className="badge bg-info">{list.user_score}%</span></td>
                           
                              <th>Costos financieros</th>
                              <td>{list.financial_costs}</td>
                            </tr>

                            <tr>
                              <th>Ingreso</th>
                              <td>{list.income}</td>
                              <th>Actualizado en</th>
                              <td>{list.updated_at}</td>
                            </tr>
                            <tr>
                              <th>Reservaciones</th>
                              <td>{list.bookings}</td>
                              <th>Creado en</th>
                              <td>{list.created_at}</td>
                            </tr>

                          </tbody>


                        </table>
                        <div className="my-4" style={{ display: "flex" }}>
                        
                        </div>
                      </div>
                    </div>

                  </div>
                )
              }
              )}

            </div>
          </div>

        </section> : null}





    </React.Fragment>
    : <Navigate to="/" replace />
  )
}



// $(document).ready(function () {
//   $(".circle_percent").each(function () {
//     var $this = $(this),
//       $dataV = $this.data("percent"),
//       $dataDeg = $dataV * 3.6,
//       $round = $this.find(".round_per");
//     $round.css("transform", "rotate(" + parseInt($dataDeg + 180) + "deg)");
//     $this.append('<div class="circle_inbox"><span class="percent_text"></span></div>');
//     $this.prop('Counter', 0).animate({ Counter: $dataV },
//       {
//         duration: 2000,
//         easing: 'swing',
//         step: function (now) {
         
//           $this.find(".percent_text").text(Math.ceil(now) + "");
//         }
//       });
//     if ($dataV >= 51) {
//       $round.css("transform", "rotate(" + 360 + "deg)");
//       setTimeout(function () {
//         $this.addClass("percent_more");
//       }, 1000);
//       setTimeout(function () {
//         $round.css("transform", "rotate(" + parseInt($dataDeg + 180) + "deg)");
//       }, 1000);
//     }
//   });
// });
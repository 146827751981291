// ---------- home page image --------

import Logo1 from '../assets/img/logo.svg';
import Segura1 from '../assets/img/icon1.svg';
import Protected1 from '../assets/img/img2.png';
import Check1 from '../assets/img/check_se2.svg';
import Datos1 from '../assets/img/datos.svg';
import Ofertas1 from '../assets/img/ofertas.svg';
import Financiera1 from '../assets/img/salud-financiera.svg';
import Comp_icon1 from '../assets/img/comp_icon.svg';
import Compar_1_1 from '../assets/img/compar_1.svg';
import Compar_2_1 from '../assets/img/compar_2.svg';
import Macbook1 from '../assets/img/macbook.svg';
import Our_das1 from '../assets/img/our_das.svg';
import Blog_sub1 from '../assets/img/multi_blog_subscription.png';



// ---------- Blog page image --------

import Bfac_e from '../assets/img/blog_caro1.jpg';
import Cov_e from '../assets/img/blog_caro2.jpg';
import Glosario_e from '../assets/img/blog_caro3.png';
import Credit_e from '../assets/img/blog_caro4.jpg';
import blog_caro5_e from '../assets/img/blog_caro5.png';
import blog_caro6_e from '../assets/img/blog_caro6.jpg';
import blog_caro7_e from '../assets/img/blog_caro7.jpg';
import blog_caro8_e from '../assets/img/blog_caro8.png';
import blog_caro9_e from '../assets/img/blog_caro9.png';
import blog_caro10_e from '../assets/img/blog_caro10.png';
import blog_caro11_e from '../assets/img/blog_caro11.jpeg';
import blog1_e from '../assets/img/blog1.jpeg';
import blog5_e from '../assets/img/blog5.png';
import blog2_e from '../assets/img/blog2.png';
import credit_store_logo_light_e from '../assets/img/credit_store_logo_light.svg';


// ---------- Dashboard page image --------

import dash_blue_e from '../assets/img/dash_blue.svg';
import z_score_dark_e from '../assets/img/z_score_dark.svg';
import applica_dark_e from '../assets/img/applica_dark.svg';
import loan_offer_dark_e from '../assets/img/loan_offer_dark.svg';
import rockt_e from '../assets/img/rockt.svg';
import form_filled_e from '../assets/img/form_filled.svg';
import loan_appli_w_e from '../assets/img/loan_appli_w.svg';
import company_info_w_e from '../assets/img/company_info_w.svg';
import partners_w_e from '../assets/img/partners_w.svg';
import legal_w_e from '../assets/img/legal_w.svg';
import documents_w_e from '../assets/img/documents_w.svg';
import loan_appli_d_e from '../assets/img/loan_appli_d.svg';
import company_info_d_e from '../assets/img/company_info_d.svg';
import partners_d_e from '../assets/img/partners_d.svg';
import legal_d_e from '../assets/img/legal_d.svg';
import documents_d_e from '../assets/img/documents_d.svg';


import offer from '../assets/img/offer.png';

// ---------- Myscore page image --------

import dash_dark_e from '../assets/img/dash_dark.svg';
import z_score_blue_e from '../assets/img/z_score_blue.svg';


import applica_blue_e from '../assets/img/applica_blue.svg';
import inverst_e from '../assets/img/inverst.svg';


import loan_offer_blue_e from '../assets/img/loan_offer_blue.svg';


// ----------lender /lender/lenderdashbo page image --------

import logo2_e from '../assets/img/logo2.svg';
import profile_e from '../assets/img/profile.jpg';
import pro_icon_e from '../assets/img/pro_icon.svg';


import Mys_mail_e from '../assets/img/mail.svg';





// ---------- home page image --------

export const Logo = Logo1;
export const Segura = Segura1;
export const Protected = Protected1;
export const Check = Check1;
export const Datos = Datos1;
export const Ofertas = Ofertas1;
export const Financiera = Financiera1;
export const Comp_icon = Comp_icon1;
export const Compar_1 = Compar_1_1;
export const Compar_2 = Compar_2_1;
export const Macbook = Macbook1;
export const Our_das = Our_das1;
export const Blog_sub = Blog_sub1;


// ---------- Blog page image --------


export const Bfac = Bfac_e;
export const Cov = Cov_e;
export const Glosario = Glosario_e;
export const Credit = Credit_e;
export const blog_caro5 = blog_caro5_e;
export const blog_caro6 = blog_caro6_e;
export const blog_caro7 = blog_caro7_e;
export const blog_caro8 = blog_caro8_e;
export const blog_caro9 = blog_caro9_e;
export const blog_caro10 = blog_caro10_e;
export const blog_caro11 = blog_caro11_e;
export const blog1 = blog1_e;
export const blog5 = blog5_e;
export const blog2 = blog2_e;
export const credit_store_logo_light = credit_store_logo_light_e;

// -------- Dashboard page image -------


export const dash_blue = dash_blue_e;
export const z_score_dark = z_score_dark_e;
export const applica_dark = applica_dark_e;
export const loan_offer_dark = loan_offer_dark_e;
export const rockt = rockt_e;
export const form_filled = form_filled_e;
export const loan_appli_w = loan_appli_w_e;
export const company_info_w = company_info_w_e;
export const partners_w = partners_w_e;
export const legal_w = legal_w_e;
export const documents_w = documents_w_e;
export const loan_appli_d = loan_appli_d_e;
export const company_info_d = company_info_d_e;
export const partners_d = partners_d_e;
export const legal_d = legal_d_e;
export const documents_d = documents_d_e;

export const offer_e = offer;
// -------- myscore page image -------


export const dash_dark = dash_dark_e;
export const z_score_blue = z_score_blue_e;

export const applica_blue = applica_blue_e;
export const inverst = inverst_e;


export const loan_offer_blue = loan_offer_blue_e;


// -------- lender /lender/lenderdashbo page image -------


export const logo2 = logo2_e;
export const profile = profile_e;
export const pro_icon = pro_icon_e;


export const Mys_mail = Mys_mail_e;
import React from "react";
import { Link } from "react-router-dom";
import * as images from './images.jsx';
import UserHeader from "../user/component/header.jsx";

export default function Home() {

  return (
    <React.Fragment>

      <UserHeader />

      <section className="warningbg">
        <div className="container">
          <div className="row" >
            <div className="col-md-12  "  >

              <h1><strong>Condiciones Generales</strong></h1>
              <h3>1. Objeto.</h3>
              <p>Las presentes Condiciones Generales (en adelante, las &ldquo;Condiciones&rdquo;) regulan la prestaci&oacute;n por Credit Store Ltd, inscrita en el Registro Mercantil, con Registry No. 14413783 y domicilio en 71-75 Shelton St, Covent Garden, London, WC2H 9JQ, United Kingdom con email info@creditstore.net (en adelante, &ldquo;Credit Store&rdquo;) del servicio Tool Box (en adelante el &ldquo;Servicio&rdquo;), consistente en la asistencia y recomendaci&oacute;n al usuario (en adelante, el &ldquo;Usuario&rdquo;) para la gesti&oacute;n inteligente de sus cuentas conforme a su perfil de ingresos, inversiones y gastos, a fin de mejorar en el control del gasto y la eficiencia de sus decisiones desde el punto de vista econ&oacute;mico con entidades financieras y con otros proveedores financieros y no financieros que el Usuario pueda integrar en cada momento en el Servicio.</p>
              <p>El Usuario deber&aacute; leer con especial atenci&oacute;n estas Condiciones y determinar, libre y voluntariamente, si desea encargar a Credit Store el Servicio.</p>
              <h3>2. El Servicio Tool Box de Credit Store.</h3>
              <p>El Servicio Tool Box facilita al Usuario la optimizaci&oacute;n de la gesti&oacute;n de sus cuentas de ingresos y gastos, y de los planes de financiaci&oacute;n, ofreci&eacute;ndole la posibilidad de comprender f&aacute;cilmente su situaci&oacute;n financiera, de adelantarse a las necesidades de liquidez conforme a su perfil de gastos, as&iacute; como de conocer la relevancia de sus gastos y decisiones y optar entre los diferentes productos y servicios de financiaci&oacute;n que mejor se adec&uacute;an a su perfil.</p>
              <p>El Servicio Tool Box recopila, conserva y ordena la informaci&oacute;n contable, as&iacute; como otros productos financieros que el Usuario haya integrado en Servicio Tool Box de Credit Store.</p>
              <p>Por &uacute;ltimo, el Servicio Tool Box facilita tambi&eacute;n al Usuario informaci&oacute;n que otras empresas est&aacute;n interesadas en ofrecerle, en atenci&oacute;n a su situaci&oacute;n financiera y su perfil de comportamiento.</p>
              <p>En este &uacute;ltimo caso, el Usuario podr&aacute; solicitar una oferta singular para el servicio e incluso contratarlo en el entorno del Servicio Tool Box. El Servicio facilitar&aacute; al Usuario, en consecuencia, la posibilidad de comunicarse con las empresas, solicitar ofertas singulares firmes de las condiciones del servicio o solicitar servicios conforme a las condiciones informadas. El Servicio Tool Box intermediar&aacute; en la contrataci&oacute;n. Esta mediaci&oacute;n, se llevar&aacute; a efecto conforme a unas condiciones espec&iacute;ficas diferentes del Servicio Tool Box.</p>
              <p>El Servicio Tool Box no facilita ning&uacute;n tipo de asesoramiento financiero o de inversi&oacute;n, de seguros, ni legal ni fiscal; ni interviene a ning&uacute;n efecto en las relaciones contractuales entre el Usuario y las entidades con las que mantiene las cuentas que se agreguen. Por tanto, no podr&aacute; entenderse prestado un servicio de esta naturaleza.</p>
              <p>No obstante, en ocasiones, en la misma pantalla del Servicio Tool Box podr&iacute;an ofrecerse utilidades ajenas al Servicio que, por sus caracter&iacute;sticas singulares, podr&iacute;an constituir servicios de asesoramiento. En estos casos, el Usuario deber&aacute; contratar espec&iacute;ficamente estas utilidades cuando tenga inter&eacute;s en ellas conforme a las condiciones espec&iacute;ficas que las regulen. La naturaleza de estas utilidades accesorias no modificar&aacute; la naturaleza del Servicio Tool Box y deber&aacute;n considerarse siempre diferentes e independientes del Servicio.</p>
              <p>Todas las utilidades del Servicio Tool Box se prestan mediante decisiones automatizadas basadas en el Perfil Personal del Usuario.</p>
              <p>El Servicio Tool Box constituye una unidad y, por su estructura y funcionamiento autom&aacute;tico, Credit Store no puede prestarlo de forma diferencial, evitando o seleccionando solo alguna de las utilidades que lo forman. El Usuario contrata el Servicio Tool Box en su conjunto, sin que quepa la opci&oacute;n de contratar de manera independiente o de oponerse a las distintas utilidades que lo conforman.</p>
              <h3>3. Utilidades de Servicio Tool Box:</h3>
              <h4>i. Descripci&oacute;n de la situaci&oacute;n financiera</h4>
              <p>En el momento de suscribir el Servicio o de integrar por primera vez una nueva cuenta, el usuario facilitar&aacute; la informaci&oacute;n en formato electr&oacute;nico a su cuenta Credit Store.</p>
              <p>Credit Store conservar&aacute; un indicador extra&iacute;do de la informaci&oacute;n sobre integrada por el titular, a fin de elaborar y analizar adecuadamente la Informaci&oacute;n Contable que corresponde realmente al Usuario y prestar las utilidades del Servicio.</p>
              <p>Diariamente o con una periodicidad m&aacute;s breve y, adem&aacute;s, cada vez que el Usuario se conecte al Servicio Tool Box, Credit Store descargar&aacute; la informaci&oacute;n correspondiente a las cuentas integradas. El Servicio permite, adem&aacute;s, actualizar la informaci&oacute;n durante una sesi&oacute;n mediante un nuevo acceso a las cuentas integradas en el Servicio Tool Box.</p>
              <p>En caso de que exista alguna incidencia que impida obtener la informaci&oacute;n en la p&aacute;gina web de alguna entidad se informar&aacute; al Usuario.</p>
              <p>Credit Store se limita a la descarga, conservaci&oacute;n, lectura y an&aacute;lisis de la informaci&oacute;n de las cuentas integradas, y no responde de su exactitud, falta de actualizaci&oacute;n o de las dificultades que pueda encontrar en la obtenci&oacute;n de la informaci&oacute;n por parte de las entidades gestoras de las cuentas integradas.</p>
              <p>Toda la informaci&oacute;n integrada, extra&iacute;da y actualizada la procesar&aacute; Credit Store para conservarla, ordenarla, agregarla y categorizarla, generando el Credit Store.</p>
              <p>El Servicio Tool Box conservar&aacute; la informaci&oacute;n contable completa y permanentemente accesible a disposici&oacute;n del Usuario.</p>
              <p>El Credit Store se conforma con la informaci&oacute;n de la persona Jur&iacute;dica, as&iacute; como la informaci&oacute;n contable y cualesquiera otras que en el futuro se puedan incorporar al Servicio.</p>
              <h4>ii. Relevancia financiera de la informaci&oacute;n contable, diagn&oacute;stico y Coaching Tool</h4>
              <p>Para ayudar al Usuario a comprender la relevancia que tienen los gastos e inversiones que realiza, su financiaci&oacute;n, el ahorro y los pr&eacute;stamos que contrata, el Servicio Tool Box conserva completa la informaci&oacute;n contable y facilita al Usuario herramientas y sugerencias para ordenar y mejorar su Credit Store.</p>
              <p>Adem&aacute;s, el Usuario podr&aacute; establecer criterios propios de selecci&oacute;n mediante herramientas del Servicio Tool Box, y hacer simulacros y proyecciones.</p>
              <p>El Servicio Tool Box ofrece utilidades de valor a&ntilde;adido como el Credit Store y el Coaching Tool, que auxiliar&aacute;n al Usuario en el seguimiento y comprensi&oacute;n de la relevancia de su situaci&oacute;n contable.</p>
              <p>La funcionalidad de Diagn&oacute;stico opera mediante la anonimizaci&oacute;n de la informaci&oacute;n contable y se fundamenta en un motor de an&aacute;lisis financiero que permite estudiar la situaci&oacute;n de ingreso, gasto, inversi&oacute;n y deuda del Usuario y compararlos con los promedios anonimizados de otras empresas de perfil similar. Esta comparaci&oacute;n facilita al Usuario referencias sobre aquellos aspectos de su gesti&oacute;n econ&oacute;mica en los que le podr&iacute;a convenir concentrar sus esfuerzos para mejorarla.</p>
              <p>Esta herramienta se complementa con las recomendaciones del Coaching Tool, que aconseja y avisa respecto de las decisiones del Usuario en los patrones en que se diferencie de los promedios de comportamiento de empresas similares del sector.</p>
              <h4>iii. Credit Store</h4>
              <p>La utilidad Credit Store facilita un &iacute;ndice (de 0 a 100 puntos) que obtiene el Servicio Tool Box mediante el an&aacute;lisis de la informaci&oacute;n Contable y el Perfil Personal que corresponde efectivamente al Usuario, valorando de forma imparcial e independiente c&oacute;mo usa y gestiona el Usuario el dinero.</p>
              <p>El Credit Store permite al Usuario evaluar su situaci&oacute;n financiera y conocer el nivel de riesgo que valorar&aacute; la entidad financiera en el momento de solicitar un cr&eacute;dito. El Usuario conoce su perfil financiero, su capacidad de endeudamiento y cu&aacute;les son las condiciones crediticias (importe m&aacute;ximo, plazo e inter&eacute;s) que deber&iacute;an ofrecerle normalmente las entidades financieras.</p>
              <p>De esta forma, el Usuario domina su poder de negociaci&oacute;n frente a las entidades, puede exigir mejores condiciones de financiaci&oacute;n y sabe de antemano qu&eacute; dificultades va a encontrar para llevar a cabo sus planes.</p>
              <p>Esta utilidad se complementa con la de Coaching Tool, que permite al Usuario mejorar su valoraci&oacute;n y as&iacute; las posibilidades de obtener mejores condiciones y ofertas con los consejos del Credit Store.</p>
              <h4>iv. Optimizaci&oacute;n del Credit Store</h4>
              <p>Por &uacute;ltimo, el Servicio Coaching Tool informa al Usuario sobre la posibilidad de mejorar su situaci&oacute;n financiera, incrementando la eficacia del esfuerzo econ&oacute;mico siguiendo recomendaciones pr&aacute;cticas personalizadas a las necesidades del usuario.</p>
              <h3>4. Datos de Car&aacute;cter Personal para el Servicio Tool Box.</h3>
              <p>Para prestar el Servicio de Servicio Tool Box, Credit Store tiene que obtener la informaci&oacute;n contable y el Perfil de la persona jur&iacute;dica as&iacute; como el Perfil Personal de sus directores, sin los cuales resulta imposible la prestaci&oacute;n del servicio en su totalidad. Es m&aacute;s, dado que el servicio se fundamenta en el an&aacute;lisis de la informaci&oacute;n personal, cuanta m&aacute;s y mejor informaci&oacute;n personal aporte el usuario, mejor ser&aacute; la calidad de las funcionalidades que ofrece Servicio Tool Box.</p>
              <h4>i. La Informaci&oacute;n Contable</h4>
              <p>La Informaci&oacute;n Contable est&aacute; formada por el indicador integrado manualmente por el Usuario en el Servicio Tool Box.</p>
              <p>Para elaborar la Informaci&oacute;n contable, Credit Store precisa que el usuario le facilite la informaci&oacute;n en formato electr&oacute;nico, lo que puede llevarse a cabo mediante la aportaci&oacute;n de las claves o permisos precisos para acceder a la misma en los sitios en donde se encuentre alojada, o mediante su volcado por medio de las herramientas que se habiliten para ello, directamente por el usuario.</p>
              <p>Al facilitar los permisos o claves de acceso, el Usuario atribuye a Credit Store la capacidad para acceder a los sitios en los que las entidades que gestionan las cuentas integradas alojan dicha informaci&oacute;n y a obtenerla en nombre y por cuenta del Usuario.</p>
              <p>Esta habilitaci&oacute;n tiene su fundamento en el derecho a la portabilidad de los datos personales que garantiza al Usuario el Data Protection Act 2018, de Protección de Datos de carácter personal, sobre servicios de pago.</p>
              <p>En el caso de que alguna entidad infrinja las normas europeas antes citadas e impida o dificulte a Credit Store la obtenci&oacute;n o gesti&oacute;n de la informaci&oacute;n, Credit Store no podr&aacute; prestar el Servicio Tool Box con la misma eficiencia y calidad que respecto del resto de las cuentas integradas.</p>
              <h4>ii. El Perfil Personal del Usuario</h4>
              <p>El Perfil Personal del Usuario est&aacute; formado por los datos personales que el Usuario aporta a Credit Store al Servicio Tool Box y mediante el uso de sus utilidades. Entre estos se encuentran: el indicador de titularidad, la direcci&oacute;n de correo electr&oacute;nico y n&uacute;mero de m&oacute;vil si se hubiera facilitado, la IP de conexi&oacute;n a Internet, el identificador del navegador m&oacute;vil, caracter&iacute;sticas del dispositivo, idioma de preferencia y pa&iacute;s de ubicaci&oacute;n, datos de la sesi&oacute;n (dominio de referencia, p&aacute;ginas visitadas, fecha y hora de acceso a la web, geolocalizaci&oacute;n), las claves de acceso a la informaci&oacute;n de las cuentas que se integren, el Historial de Movimientos en estas cuentas, la informaci&oacute;n que se le invite a facilitar desde el Servicio Gestor Inteligente para comprender mejor el Historial de Movimientos, la informaci&oacute;n que nos faciliten leg&iacute;timamente las redes sociales, as&iacute; como los derivados de las acciones que el Usuario realice a trav&eacute;s de las utilidades del Servicio Tool Box.</p>
              <p>El Usuario podr&aacute; acceder permanentemente a su Perfil Personal y actualizar, enriquecer o borrar aquella informaci&oacute;n que convenga a sus intereses, si bien es importante que comprenda que, si elimina informaci&oacute;n de su Perfil Personal, o no lo mantiene actualizado, se ver&aacute; afectada la calidad de las utilidades del Servicio Tool Box.</p>
              <p>Por tanto, al suscribir el Servicio Tool Box el Usuario comprende que Credit Store tiene que obtener toda la informaci&oacute;n mencionada, conservarla, actualizarla constantemente y analizarla para poder prestarle las utilidades que lo componen.</p>
              <h3>5. Suscripci&oacute;n al Servicio Tool Box.</h3>
              <p>Para contratar y acceder al Servicio Tool Box es necesario que el Usuario sea mayor de edad, acepte sin reserva alguna las presentes Condiciones previa lectura y se comprometa a hacer un uso diligente del sitio web y del Servicio Tool Box conforme a las estipulaciones de estas Condiciones y la normativa de aplicaci&oacute;n.</p>
              <p>Para darse de alta en el Servicio Tool Box, el Usuario deber&aacute; indicar una direcci&oacute;n de correo electr&oacute;nico v&aacute;lida, un n&uacute;mero de tel&eacute;fono m&oacute;vil y una contrase&ntilde;a de como m&iacute;nimo seis caracteres de longitud. Estos datos se utilizar&aacute;n como canal de comunicaci&oacute;n con el Usuario para la resoluci&oacute;n de incidencias en la cuenta.</p>
              <p>Credit Store enviar&aacute; un mensaje conteniendo un link que le permitir&aacute; asignar una nueva clave de acceso al Servicio.</p>
              <p>Una vez dado de alta en el Servicio Tool Box, se solicitar&aacute; al Usuario que facilite su c&oacute;digo postal de residencia y la fecha de nacimiento, que son necesarios para prestarle las utilidades del Servicio Tool Box y, adem&aacute;s, cierta informaci&oacute;n personal adicional (p. ej. situaci&oacute;n familiar, aficiones, sexo, etc.) que permitir&aacute;n mejorar su Perfil Personal y prestar con mayor eficacia las utilidades del Servicio Tool Box. La aportaci&oacute;n de la informaci&oacute;n adicional es voluntaria, pero si no la facilita se reducir&aacute; la calidad de las utilidades del Servicio Tool Box.</p>
              <h3>6. Acceso al Servicio Tool Box.</h3>
              <p>El Servicio Tool Box se presta solo a trav&eacute;s de Internet, pudiendo acceder al mismo a trav&eacute;s de equipos inform&aacute;ticos (PC, Mac, Linux, etc.), dispositivos m&oacute;viles (tel&eacute;fonos m&oacute;viles, tablets, smartwatches, etc.), y dem&aacute;s canales telem&aacute;ticos que la tecnolog&iacute;a permita en cada momento y que Credit Store ponga a su disposici&oacute;n.</p>
              <p>Las funcionalidades del Servicio ser&aacute;n las mismas cuando se acceda trav&eacute;s de dispositivos m&oacute;viles u otros medios distintos de los equipos inform&aacute;ticos, teniendo siempre en cuenta que algunas funcionalidades pueden no estar totalmente operativas por razones t&eacute;cnicas.</p>
              <p>Credit Store podr&aacute; bloquear el acceso y utilizaci&oacute;n del Servicio cuando lo estime necesario por motivos de seguridad. Para desbloquear el servicio, el Usuario deber&aacute; utilizar las herramientas habilitadas en la web del Servicio o, en casos especiales, ponerse en contacto con Credit Store.</p>
              <p>El Usuario del Servicio es el responsable de la custodia de las claves de acceso, no estando autorizado a compartirlas con nadie. En caso de que exista alg&uacute;n riesgo de seguridad respecto de dichas claves, el Usuario deber&aacute; cambiarlas inmediatamente. Igualmente, el Usuario podr&aacute; en cualquier momento modificar o sustituir sus claves de acceso, mediante las herramientas habilitadas a tal efecto y rehabilitar las claves en caso de olvido.</p>
              <p>Para desbloquear o recuperar las claves, el Usuario encontrar&aacute; las herramientas oportunas en el Servicio Tool Box.</p>
              <h3>7. Deberes, obligaciones y responsabilidades del Usuario.</h3>
              <p>El Usuario est&aacute; obligado a utilizar el Servicio de forma adecuada, no utilizar bajo ning&uacute;n concepto claves de acceso de terceros, mantener la confidencialidad de las claves de acceso al Servicio y actualizar o cancelar las claves personales del Servicio Tool Box.</p>
              <p>El Servicio Tool Box no constituye ning&uacute;n tipo de asesoramiento financiero o de inversi&oacute;n, de seguros, legal ni fiscal. La informaci&oacute;n y comentarios que se insertan mediante sus utilidades son meramente informativos y Tool Box no responde de las acciones que realice el Usuario a la vista de dicha informaci&oacute;n. De igual modo, el uso de la informaci&oacute;n publicada por otros Usuarios mediante las herramientas de red social que forman parte del Servicio es de la exclusiva responsabilidad del Usuario. Credit Store no controla dichos contenidos.</p>
              <p>El Usuario cede a Credit Store el derecho a servirse de las acciones de categorizaci&oacute;n que realice utilizando el Servicio para la mejora del Servicio y el desarrollo de su actividad, sin derecho a compensaci&oacute;n alguna por esta reutilizaci&oacute;n.</p>
              <h3>8. Concursos y promociones.</h3>
              <p>Credit Store lleva a cabo campa&ntilde;as promocionales de sus propios servicios en las que se premian las nuevas altas, la agregaci&oacute;n de nuevas cuentas, la suscripci&oacute;n o uso de utilidades, etc.</p>
              <p>Para la adecuada gesti&oacute;n de estas campa&ntilde;as y prevenir el fraude, Credit Store conservar&aacute; la informaci&oacute;n m&iacute;nima necesaria para identificar las cuentas que se vayan agregando y cualquier otro dato necesario para dicha comprobaci&oacute;n, incluso cuando el usuario se d&eacute; de baja del servicio y durante el tiempo que se estime preciso en t&eacute;rminos de prevenci&oacute;n del fraude.</p>
              <h3>9.Protecci&oacute;n de Datos.</h3>
              <p>Respecto al tratamiento de datos personales, las presentes Condiciones ser&aacute;n de aplicaci&oacute;n a todos los datos personales que forman el Perfil de Cuentas y el Perfil Personal del Usuario.</p>
              <p>Para cualquier cuesti&oacute;n relacionada con estipulaciones recogidas en esta cl&aacute;usula, el Usuario podr&aacute; ponerse en contacto con el delegado de protecci&oacute;n de datos de Credit Store, cuyos datos de contacto son, email: dpo@zscore.eu.</p>
              <p>Para prestar el Tool Box, Credit Store tratar&aacute; los datos personales del Usuario que forman el Perfil de Cuentas y el Perfil Personal del Usuario, entre los que se encuentran la direcci&oacute;n de correo electr&oacute;nico y n&uacute;mero de m&oacute;vil si se hubiera facilitado, la IP de conexi&oacute;n a Internet, el identificador del navegador m&oacute;vil, caracter&iacute;sticas del dispositivo, idioma de preferencia y pa&iacute;s de ubicaci&oacute;n, datos de la sesi&oacute;n (dominio de referencia, p&aacute;ginas visitadas, fecha y hora de acceso a la web), las claves de acceso a la informaci&oacute;n de las cuentas que se agreguen, el Perfil de Cuentas en dichas cuentas, as&iacute; como los derivados de las acciones que el Usuario realice a trav&eacute;s de las utilidades del Tool Box.</p>
              <h3>10. Seguridad.</h3>
              <p>Credit Store utiliza las mejores pr&aacute;cticas promovidas por la ISO 27002, siguiendo las recomendaciones de seguridad de las mejores compa&ntilde;&iacute;as y especialistas de seguridad, as&iacute; como las exigencias derivadas del Reglamento General de Protecci&oacute;n de Datos, la Directivas PSD2 y dem&aacute;s normas aplicables que regulan esta actividad.</p>
              <p>Conforme a dichas recomendaciones, Credit Store:</p>
              <p>- Trabaja en un entorno seguro (https).</p>
              <p>- Cifra las comunicaciones mediante el protocolo de cifrado SSL.</p>
              <p>- Cifra igualmente las credenciales del Usuario en las entidades financieras, limitando la informaci&oacute;n de credenciales a la de acceso a la informaci&oacute;n (salvo el caso de los bancos que piden coordenadas para acceder).</p>
              <p>- Seudonimiza la informaci&oacute;n mediante su almacenamiento en entornos diferentes seg&uacute;n los diferentes tipos de informaci&oacute;n, lo que imposibilita relacionar los ficheros que contienen cada tipo de informaci&oacute;n sin conocer los identificadores del Usuario. Sustituye los n&uacute;meros de cuenta por un alias y aloja su infraestructura siempre dentro del Espacio Econ&oacute;mico Europeo, utilizando para esta finalidad a la compa&ntilde;&iacute;a Amazon, que exige certificaciones de cumplimiento de unos altos est&aacute;ndares en seguridad, seguridad f&iacute;sica de las instalaciones, copias de seguridad, seguridad de las instancias donde se alojan los servidores, seguridad de la red y seguridad de los servicios de almacenamiento.</p>
              <h3>11. Terceros prestadores de servicios de Credit Store que pueden acceder a Datos Personales.</h3>
              <p>Los encargados del tratamiento que colaboran con Credit Store en la prestaci&oacute;n del Servicio Tool Box pueden consultarse en el siguiente  <Link to className="link" href="{{ url_for('main.data_protection') }}">enlace</Link>.</p>
              <h3>12. Plazo de conservaci&oacute;n de los datos personales de los Usuarios.</h3>
              <p>Los datos integrados en el Historial de Movimientos se conservar&aacute;n mientras el Usuario se mantenga suscrito al Servicio Gestor Inteligente. En el caso de que el Usuario se d&eacute; de baja del Servicio, se eliminar&aacute; completo el Historial de Movimientos, siendo imposible recuperarlo con posterioridad.</p>
              <h3>13. Derechos de los Usuarios.</h3>
              <p>Los derechos de acceso, rectificaci&oacute;n, supresi&oacute;n, oposici&oacute;n, limitaci&oacute;n y portabilidad de sus datos podr&aacute;n ser ejercidos a trav&eacute;s del propio Servicio, en el &aacute;rea privada del Usuario (creditstore.net<Link to="https://www.fintonic.com/">)</Link>, mediante correo electr&oacute;nico a info@creditstore.net, o mediante comunicaci&oacute;n por escrito a Credit Store Ltd, 71-75 Shelton St, Covent Garden, London, WC2H 9JQ, United Kingdom.</p>
              <p>Si el Usuario considera que se han vulnerado, o podido vulnerar, los derechos que se le reconocen por la normativa aplicable de protecci&oacute;n de datos, podr&aacute; presentar una reclamaci&oacute;n ante el Delegado de Protecci&oacute;n de Datos en la siguiente direcci&oacute;n: lopd@fintonic.com. En todo caso, podr&aacute; tambi&eacute;n presentar una reclamaci&oacute;n ante la Agencia Espa&ntilde;ola de Protecci&oacute;n de Datos en la calle Jorge Juan n&uacute;mero 6, 28001, Madrid.</p>
              <h3>14. Revocaci&oacute;n del consentimiento.</h3>
              <p>Los tratamientos de datos descritos son necesarios para la prestaci&oacute;n del Servicio Tool Box en su totalidad, de modo que si el Usuario desea que Credit Store cese en ellos, deber&aacute; cancelar su cuenta en Credit Store.</p>
              <h3>15. Cookies.</h3>
              <p>Para la operatividad de nuestro sitio web necesitamos utilizar cookies funcionales. Estas cookies se utilizan exclusivamente con la finalidad de permitir la operatividad de la web y app y prestar el Servicio. Sin ellas, no funcionar&iacute;an ni la web ni la app. Por ello, si usted no desea la instalaci&oacute;n de estas cookies, no debe suscribirse al Servicio.</p>
              <p>Para m&aacute;s informaci&oacute;n se puede acceder a la pol&iacute;tica de cookies actualizada en el siguiente <Link to className="link" href="{{ url_for('main.cookie_policy') }}">enlace</Link>.</p>
              <h3>16. Propiedad Intelectual e Industrial.</h3>
              <p>Todos los procedimientos, funcionalidades, software, marcas o signos distintivos, im&aacute;genes, fotograf&iacute;as, gr&aacute;ficos, archivos de texto, audio, v&iacute;deo y dem&aacute;s contenidos del Servicio Tool Box se encuentran protegidos por las leyes de propiedad intelectual e industrial; no pudiendo ser objeto de reutilizaci&oacute;n ni aprovechamiento por parte del Usuario o de terceros, ni se permite tampoco su modificaci&oacute;n, copia, alteraci&oacute;n, reproducci&oacute;n, adaptaci&oacute;n o traducci&oacute;n, sin la expresa autorizaci&oacute;n de los respectivos y leg&iacute;timos propietarios de dichos contenidos.</p>
              <p>En ning&uacute;n caso, la puesta a disposici&oacute;n de estos contenidos a los Usuarios implicar&aacute; la cesi&oacute;n de su titularidad o de cualquier derecho de utilizaci&oacute;n o de explotaci&oacute;n en favor del Usuario distinto del uso que comporta la utilizaci&oacute;n leg&iacute;tima y acorde con la naturaleza de los Servicios.</p>
              <p>El Usuario se compromete a respetar los derechos de propiedad intelectual e industrial titularidad de Credit Store y/o de terceros.</p>
              <h3>17. Exoneraci&oacute;n de responsabilidad a Credit Store.</h3>
              <p>Credit Store no responder&aacute; del incumplimiento de estas condiciones generales en el caso de que se deba a causas ajenas a su control, ya sean t&eacute;cnicas, de seguridad o de otra &iacute;ndole. El Usuario exonera a Credit Store de cualquier p&eacute;rdida, da&ntilde;o o sanci&oacute;n que pudiera sufrir a consecuencia de este tipo de incumplimiento.</p>
              <p>Adem&aacute;s, Credit Store no ser&aacute; responsable de los contenidos proporcionados por los Usuarios a trav&eacute;s de las herramientas de red social, ni por las entidades agregadas ni por las sugerencias u orientaciones que Credit Store proponga mediante las funcionalidades de Scoring o Tool Box, o de cualesquiera otras funcionalidades disponibles ahora o en el futuro, ni de los da&ntilde;os o perjuicios que se deriven de la utilizaci&oacute;n del Servicio por parte del Usuario.</p>
              <h3>18. Modificaci&oacute;n de las condiciones.</h3>
              <p>Credit Store podr&aacute; modificar total o parcialmente, las presentes Condiciones previa comunicaci&oacute;n al Usuario de la existencia de unas nuevas Condiciones.</p>
              <p>Las nuevas Condiciones se considerar&aacute;n aceptadas por el Usuario si transcurrido un mes desde la comunicaci&oacute;n del cambio, el Usuario no se hubiera dado de baja del Servicio. En cualquier caso, si hubiera cambios ulteriores adicionales en el tratamiento de datos de car&aacute;cter personal del Usuario, Credit Store remitir&aacute; una comunicaci&oacute;n espec&iacute;fica al Usuario relatando dicho cambio.</p>
              <h3>19. Legislaci&oacute;n y Foro.</h3>
              <p>La interpretaci&oacute;n y la ejecuci&oacute;n del presente contrato se rigen por la legislaci&oacute;n espa&ntilde;ola.</p>
              <p>Las partes se someten a su elecci&oacute;n, para la resoluci&oacute;n de los conflictos y con renuncia a cualquier otro fuero, a los juzgados y tribunales del domicilio del Usuario.</p>
              <h3><br /><br /><br /><br /></h3>
            </div>
          </div>
        </div>
      </section>

      {/* <!--footer --> */}

      <footer className="bacgco">
        <div className="container">
          <div className="row footerr" >
            <div className="col-md-3 ">
              <p className="fotcol">2023 Credit Store</p>

            </div>
            <div className="col-md-3 fot_mob">
              <div className="displaygrid">
                <label className="text-white">Menu</label>
                <Link to="/auth/Register" className="fotcol">Crear cuenta </Link>
                <Link to="/auth/Login" className="fotcol">Iniciar Sesión </Link>
                <Link to="/about" className="fotcol">Sobre Credit Store </Link>
                <Link to="/contact" className="fotcol">Contacto </Link>
              </div>
            </div>
            <div className="col-md-3 fot_mob">
              <div className="displaygrid">
                <label className="text-white">Seguridad</label>
                <Link to="/warning" className="fotcol">Aviso Legal </Link>
                <Link to="/dataprotect" className="fotcol">Política de Privacidad </Link>
                <Link to="/condition" className="fotcol">Condiciones Generales </Link>
                <Link to="/cookies" className="fotcol">Política de Uso de Cookies </Link>
              </div>
            </div>
            <div className="col-md-3">
              <div className="displaygrid">
                <Link to="https://twitter.com/CreditStoreLine" className="fotcol"><span className="footericon"><i className="fa fa-twitter-square footericon " aria-hidden="true" ></i></span>Twitter </Link>
                <Link to="https://www.linkedin.com/company/thecreditstore/" className="fotcol"><span span className="footericon"><i className="fa fa-linkedin-square footericon" aria-hidden="true" ></i></span>LinkedIn </Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  )
}



import '../../src/assets/css/circle_css/circle.css';
import React, { useEffect, useState } from 'react';

const CircleProgressBar = ({ percentage }) => {

  const [offset, setOffset] = useState(0);
  const [percentage1, setPercentage] = useState(0);
  useEffect(() => {
    if (percentage == null) {
      setPercentage(0);
    } else {
      setPercentage(percentage);
    }
    const progressOffset = (125 - percentage) / 125 * 314.16; // Calculate the offset based on the percentage
    setOffset(progressOffset);
  }, [percentage, offset]);
  return (
    <div className="circle-progress-bar">
      <svg>
        <circle cx="50%" cy="50%" r="40%" strokeWidth="10%" transform="rotate(-90 50 50)" />
        <circle className="progress" cx="50%" cy="50%" r="40%" strokeWidth="10%" strokeDasharray="314.16" strokeDashoffset={offset} transform="rotate(-90 50 50)" />
      </svg>
      <div className="percentage">{percentage1}</div>
    </div>
  );
};
export default CircleProgressBar;
import React from "react";
import $ from 'jquery';
import * as con from '../repository/urls.js';
import * as apis from '../repository/api_calls.js';
import { Navigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { Margin } from "@mui/icons-material";

export default function Creditscore() {


  const currentYear = new Date().getFullYear();

  const [total, setTotal] = useState(0);
  const [total2, setTotal2] = useState(0);
  const [totalSum, setTotalSum] = useState(0);
  const [isTotalEqual, setIsTotalEqual] = useState(false);

  const checkEqualityAndEnableSubmit = () => {
    const result_of_the_exerciseValue = result_of_the_exerciseRef.current.value;
    const results_previous_yearsValue = results_previous_yearsRef.current.value;

    setIsTotalEqual(result_of_the_exerciseValue === results_previous_yearsValue);
  };


  const handlekeydown = (e) =>{
    const restrictedkeys = new Set(['-', '+', '*', '/', 'e'])
    if(restrictedkeys.has(e.key)){
      e.preventDefault();
    }
  }

  const role_id = localStorage.getItem("role_id");

  // ------------ number validation -----

  const [values, setValues] = useState({
    field1: '', field2: '', field3: '', field4: '', field5: '', field6: '', field7: '',
    field8: '', field9: '', field10: '', field11: '', field12: '', field13: '', field14: '',
    field15: '', field16: '', field17: '', field18: '', field19: '', field20: '', field21: '',
    field22: '', field23: '', field24: '', field25: '', field26: '', field27: '',
    // Add more fields as needed
  });

  const handleChange1 = (event) => {

    //   const sum =
    //   parseFloat(values.field21 ||0 ) +
    //   parseFloat(values.field23  ||0) +
    //   parseFloat(values.field24  ||0) +
    //   parseFloat(values.field25  ||0) +
    //   parseFloat(values.field26  ||0) 



    // setTotalSum(sum);
    // const isTotalEqual = total === totalSum;
    // setIsTotalEqual(isTotalEqual);


    const { name, value } = event.target;
    if (value.length <= 8) {
      setValues((prevValues) => ({
        ...prevValues,
        [name]: value
      }));
    }
  };


  const navigate = useNavigate();
  // const showAlert = () => {
  //   Swal.fire({
  //     title: 'Score Created!',
  //     text: 'All form completed',
  //     icon: 'success',
  //     confirmButtonText: 'OK',
  //   }).then((result)=>{
  //       if(result.isConfirmed){
  //           navigate('/user/company_sector');
  //       }
  //   });
  // };


  // for income statement
  const cifranitaRef = React.useRef();
  const incomeRef = React.useRef();
  const salesCostsRef = React.useRef();
  const expensesRef = React.useRef();
  const depreciationRef = React.useRef();
  const financialCostsRef = React.useRef();
  const financialIncomeRef = React.useRef();
  const profitTaxRef = React.useRef();
  const netbusinessRef = React.useRef();

  // Non current asstes
  const tangibleAssetsRef = React.useRef();
  const intangibleAssetsRef = React.useRef();
  // Current asstes
  const inventoriesRef = React.useRef();
  const tradeReceivablesRef = React.useRef();
  const cash_and_cash_equivalentsRef = React.useRef();
  const other_current_assetsRef = React.useRef();



  // Long-term Liabilities
  const long_term_borrowingsRef = React.useRef();
  const long_term_liabilitesRef = React.useRef();
  // Short-term Liabilities
  const accrued_expensesRef = React.useRef();
  const trade_and_other_payablesRef = React.useRef();
  const short_term_borrowingsRef = React.useRef();
  const current_tax_payableRef = React.useRef();
  const short_term_liabilitiesRef = React.useRef();


  // net worth
  const dividend_paymentRef = React.useRef();
  const social_capitalRef = React.useRef();
  const share_premiumRef = React.useRef();
  const bookingsRef = React.useRef();
  const results_previous_yearsRef = React.useRef();
  const result_of_the_exerciseRef = React.useRef();


  // const navigate = useNavigate();

  // const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("id");
  const user_type = localStorage.getItem("user_type")
  const [localId, setLocalId] = useState('');




  //---------------------- update api
  useEffect(() => {
    getId();
  }, [])


  async function getId() {
    const response = await apis.getUserScore();
    console.log(response)
    if(response['status']===true){
      for(let i=0;i<response.data.length;i++){
        if(response.data[i]['status']==='Active'){
          netbusinessRef.current.value = response.data[i].netbusiness;
          incomeRef.current.value = response.data[i].income;
          salesCostsRef.current.value = response.data[i].sales_costs;
          expensesRef.current.value = response.data[i].expenses;
          depreciationRef.current.value = response.data[i].depreciation;
          financialCostsRef.current.value = response.data[i].financial_costs;
          financialIncomeRef.current.value = response.data[i].financial_income;
          profitTaxRef.current.value = response.data[i].profit_tax;
          tangibleAssetsRef.current.value = response.data[i].tangible_assets;
          intangibleAssetsRef.current.value = response.data[i].intangible_assets;
          inventoriesRef.current.value =  response.data[i].inventories;
          tradeReceivablesRef.current.value = response.data[i].trade_receivables;
          cash_and_cash_equivalentsRef.current.value = response.data[i].cash_and_cash_equivalents;
          other_current_assetsRef.current.value = response.data[i].other_current_assets;
          long_term_borrowingsRef.current.value = response.data[i].long_term_borrowings;
          long_term_liabilitesRef.current.value =   response.data[i].long_term_liabilites;
          accrued_expensesRef.current.value = response.data[i].accrued_expenses;
          trade_and_other_payablesRef.current.value = response.data[i].trade_and_other_payables;
          short_term_borrowingsRef.current.value = response.data[i].short_term_borrowings;
          current_tax_payableRef.current.value = response.data[i].current_tax_payable;
          short_term_liabilitiesRef.current.value = response.data[i].short_term_liabilities;

        }
      }
      
    }
}



  // function getId() {

  //   fetch(`${process.env.REACT_APP_BASE_URL}${con.Score_user}`, {
  //     method: 'post',
  //     headers:
  //     { 'Content-Type': 'application/json',
  //     "Authorization": `Bearer ${token}`, }
  //   }).then(async response => {
  //     const result = await response.json();
  //     console.log(result)
  //     if(result['status']===true){
  //       for(let i=0;i<result.data.length;i++){
  //         if(result.data[i]['status']==='Active'){
  //           netbusinessRef.current.value = result.data[i].netbusiness;
  //           incomeRef.current.value = result.data[i].income;
  //           salesCostsRef.current.value = result.data[i].sales_costs;
  //           expensesRef.current.value = result.data[i].expenses;
  //           depreciationRef.current.value = result.data[i].depreciation;
  //           financialCostsRef.current.value = result.data[i].financial_costs;
  //           financialIncomeRef.current.value = result.data[i].financial_income;
  //           profitTaxRef.current.value = result.data[i].profit_tax;
  //           tangibleAssetsRef.current.value = result.data[i].tangible_assets;
  //           intangibleAssetsRef.current.value = result.data[i].intangible_assets;
  //           inventoriesRef.current.value =  result.data[i].inventories;
  //           tradeReceivablesRef.current.value = result.data[i].trade_receivables;
  //           cash_and_cash_equivalentsRef.current.value = result.data[i].cash_and_cash_equivalents;
  //           other_current_assetsRef.current.value = result.data[i].other_current_assets;
  //           long_term_borrowingsRef.current.value = result.data[i].long_term_borrowings;
  //           long_term_liabilitesRef.current.value =   result.data[i].long_term_liabilites;
  //           accrued_expensesRef.current.value = result.data[i].accrued_expenses;
  //           trade_and_other_payablesRef.current.value = result.data[i].trade_and_other_payables;
  //           short_term_borrowingsRef.current.value = result.data[i].short_term_borrowings;
  //           current_tax_payableRef.current.value = result.data[i].current_tax_payable;
  //           short_term_liabilitiesRef.current.value = result.data[i].short_term_liabilities;

  //         }
  //       }
        
  //     }
  //   });
  // }

  //-------------- stepper 1 api integration finished
  const handlerSubmitOne = (event) => {
    event.preventDefault();

    
    const income = incomeRef.current.value
    const sales_costs = salesCostsRef.current.value
    const expenses = expensesRef.current.value
    const depreciation = depreciationRef.current.value
    const financial_costs = financialCostsRef.current.value
    const financial_income = financialIncomeRef.current.value
    const profit_tax = profitTaxRef.current.value
    const netbusiness = netbusinessRef.current.value


    const answer = Number(income) - Number(sales_costs) - Number(expenses) - Number(depreciation) -
      Number(financial_costs) + Number(financial_income) - Number(profit_tax) 
    setTotal(answer);
  
  

  

    if (user_type === "guest_user") {
      
        GetGuestScore1();   

      async function GetGuestScore1() {
        const response = await apis.getscoreGuest(
          JSON.stringify({
                  "user_id": user_id,
                  "income": income,
                  "sales_costs": sales_costs,
                  "expenses": expenses,
                  "depreciation": depreciation,
                  "financial_costs": financial_costs,
                  "financial_income": financial_income,
                  "profit_tax": profit_tax,
                  "net_business":netbusiness
                }),

        );
        console.log(response)
        if (response['status'] === true) {
          localStorage.setItem("guestupdateid", response["data"]["id"]);

          setLocalId(response['data']['score_ran_id']);

          $('#stepper11').addClass('step-done');
          $('#stepper11').removeClass('editable-step');
          $('#stepper21').addClass('active-step');
          $('#stepper21').addClass('editable-step');
          $('#stepper_line11').removeClass('tab1_border_step');
          $('#stepper_line11').addClass('tab1_border_step_green');
          $('#stepper_cir_bod11').removeClass('boder_whi');
          $('#stepper_cir_bod11').addClass('boder_green');
          activeStep($('#step21'));

          toast.success(response["message"], {
            position: toast.POSITION.TOP_RIGHT,
            className: 'foo_bar_log_Success'
          });

        }
        else {
          toast.error(response["message"], {
            position: toast.POSITION.TOP_RIGHT,
            className: 'foo_bar_log_error'
          });
        }
    }


    //   fetch(`${process.env.REACT_APP_BASE_URL}${con.creditscoreguest}`, {
    //     method: 'post',
    //     body: JSON.stringify({
    //       "user_id": user_id,
    //       "income": income,
    //       "sales_costs": sales_costs,
    //       "expenses": expenses,
    //       "depreciation": depreciation,
    //       "financial_costs": financial_costs,
    //       "financial_income": financial_income,
    //       "profit_tax": profit_tax,
    //       "net_business":netbusiness
    //     }),
    //     headers:
    //       { 'Content-Type': 'application/json' }
    //   }
    //   ).then(async response => {
    //     const result = await response.json();
    //     if (result['status'] === true) {
    //       localStorage.setItem("guestupdateid", result["data"]["id"]);

    //       setLocalId(result['data']['score_ran_id']);
    //       console.log("----------------------00000000----")
    //       console.log(result["data"])


    //       $('#stepper11').addClass('step-done');
    //       $('#stepper11').removeClass('editable-step');
    //       $('#stepper21').addClass('active-step');
    //       $('#stepper21').addClass('editable-step');
    //       $('#stepper_line11').removeClass('tab1_border_step');
    //       $('#stepper_line11').addClass('tab1_border_step_green');
    //       $('#stepper_cir_bod11').removeClass('boder_whi');
    //       $('#stepper_cir_bod11').addClass('boder_green');
    //       activeStep($('#step21'));

    //       toast.success(result["message"], {
    //         position: toast.POSITION.TOP_RIGHT,
    //         className: 'foo_bar_log_Success'
    //       });

    //     }
    //     else {
    //       toast.error(result["message"], {
    //         position: toast.POSITION.TOP_RIGHT,
    //         className: 'foo_bar_log_error'
    //       });
    //     }

    //   }).catch(error => {
    //     console.error('There was an error!', error);
    //   });
   
  }
    else if (user_type === "register_user") {

      GetRegisterForm1Score();   

      async function GetRegisterForm1Score() {
        const response = await apis.getScoreRegister(
          JSON.stringify({
            "income": income,
            "sales_costs": sales_costs,
            "expenses": expenses,
            "depreciation": depreciation,
            "financial_costs": financial_costs,
            "financial_income": financial_income,
            "profit_tax": profit_tax,
            "net_business":netbusiness
                }),

        );
        console.log(response)
        if (response['status'] === true) {

          localStorage.setItem("regupdateid", response["data"]["id"]);
          localStorage.setItem("income", response["data"]["income"])

          $('#stepper11').addClass('step-done');
          $('#stepper11').removeClass('editable-step');
          $('#stepper21').addClass('active-step');
          $('#stepper21').addClass('editable-step');
          $('#stepper_line11').removeClass('tab1_border_step');
          $('#stepper_line11').addClass('tab1_border_step_green');
          $('#stepper_cir_bod11').removeClass('boder_whi');
          $('#stepper_cir_bod11').addClass('boder_green');
          activeStep($('#step21'));

          toast.success(response["message"], {
            position: toast.POSITION.TOP_RIGHT,
            className: 'foo_bar_log_Success'
          });
        }

        else {
          toast.error(response["message"], {
            position: toast.POSITION.TOP_RIGHT,
            className: 'foo_bar_log_error'
          });
        }
    }



      // fetch(`${process.env.REACT_APP_BASE_URL}${con.creditscoreregister}`, {
      //   method: 'post',
      //   body: JSON.stringify({
      //     "income": income,
      //     "sales_costs": sales_costs,
      //     "expenses": expenses,
      //     "depreciation": depreciation,
      //     "financial_costs": financial_costs,
      //     "financial_income": financial_income,
      //     "profit_tax": profit_tax,
      //     "net_business":netbusiness
      //   }),
      //   headers:
      //   {
      //     "Authorization": `Bearer ${token}`,
      //     'Content-Type': 'application/json',
      //   }
      // }
      // ).then(async response => {

      //   const result = await response.json();
      //   if (result['status'] === true) {

      //     localStorage.setItem("regupdateid", result["data"]["id"]);
      //     console.log("iam register user")
      //     console.log("999999999999999999999999999999999")
      //     console.log(result["data"])
      //     console.log(result["data"]["income"])
      //     localStorage.setItem("income", result["data"]["income"])
      //     console.log("income value = " + localStorage.getItem("income"))
      //     const income = localStorage.getItem("income")

      //     $('#stepper11').addClass('step-done');
      //     $('#stepper11').removeClass('editable-step');
      //     $('#stepper21').addClass('active-step');
      //     $('#stepper21').addClass('editable-step');
      //     $('#stepper_line11').removeClass('tab1_border_step');
      //     $('#stepper_line11').addClass('tab1_border_step_green');
      //     $('#stepper_cir_bod11').removeClass('boder_whi');
      //     $('#stepper_cir_bod11').addClass('boder_green');
      //     activeStep($('#step21'));

      //     toast.success(result["message"], {
      //       position: toast.POSITION.TOP_RIGHT,
      //       className: 'foo_bar_log_Success'
      //     });
      //   }

      //   else {
      //     toast.error(result["message"], {
      //       position: toast.POSITION.TOP_RIGHT,
      //       className: 'foo_bar_log_error'
      //     });
      //   }

      // }).catch(error => {
      //   console.error('There was an error!', error);
      // });
   
    }
  }

  //-------------- Stepper 2 api integration START --------------------------------------- 
  const handlerSubmitTwo = (event) => {

    event.preventDefault();
    const income = incomeRef.current.value
    const sales_costs = salesCostsRef.current.value
    const expenses = expensesRef.current.value
    const depreciation = depreciationRef.current.value
    const financial_costs = financialCostsRef.current.value
    const financial_income = financialIncomeRef.current.value
    const profit_tax = profitTaxRef.current.value
    const net_business = netbusinessRef.current.value
    const tangible_assets = tangibleAssetsRef.current.value
    const intangible_assets = intangibleAssetsRef.current.value
    const inventories = inventoriesRef.current.value
    const trade_receivables = tradeReceivablesRef.current.value
    const cash_and_cash_equivalents = cash_and_cash_equivalentsRef.current.value
    const other_current_assets = other_current_assetsRef.current.value

    if (user_type === "guest_user") {


      GetGuestCreditScore1();   

      async function GetGuestCreditScore1() {
        const response = await apis.getCreditscoreGuest(
          JSON.stringify({
            "user_id": user_id,
            "score_id": localStorage.getItem("guestupdateid"),
            "income": income,
            "sales_costs": sales_costs,
            "expenses": expenses,
            "depreciation": depreciation,
            "financial_costs": financial_costs,
            "financial_income": financial_income,
            "profit_tax": profit_tax,
            "net_business":net_business,
            "tangible_assets": tangible_assets,
            "intangible_assets": intangible_assets,
            "inventories": inventories,
            "trade_receivables": trade_receivables,
            "cash_and_cash_equivalents": cash_and_cash_equivalents,
            "other_current_assets": other_current_assets,
                }),

        );
        console.log(response)
        if (response['status'] === true) {
          localStorage.setItem("regupdateid", response["data"]["id"]);
          $('#stepper21').addClass('step-done');
          $('#stepper21').removeClass('editable-step');
          $('#stepper31').addClass('active-step');
          $('#stepper31').addClass('editable-step');
          $('#stepper_line21').removeClass('tab1_border_step');
          $('#stepper_line21').addClass('tab1_border_step_green');
          $('#stepper_cir_bod21').removeClass('boder_whi');
          $('#stepper_cir_bod21').addClass('boder_green');
          activeStep($('#step31'));

          toast.success(response["message"], {
            position: toast.POSITION.TOP_RIGHT,
            className: 'foo_bar_log_Success'
          });

        } else {
          toast.error(response["message"], {
            position: toast.POSITION.TOP_RIGHT,
            className: 'foo_bar_log_error'
          });

        }
    }


      // fetch(`${process.env.REACT_APP_BASE_URL}${con.creditscoreguestupdate}`, {
      //   method: 'post',
      //   body: JSON.stringify({
      //     "user_id": user_id,
      //     "score_id": localStorage.getItem("guestupdateid"),
      //     "income": income,
      //     "sales_costs": sales_costs,
      //     "expenses": expenses,
      //     "depreciation": depreciation,
      //     "financial_costs": financial_costs,
      //     "financial_income": financial_income,
      //     "profit_tax": profit_tax,
      //     "net_business":net_business,
      //     "tangible_assets": tangible_assets,
      //     "intangible_assets": intangible_assets,
      //     "inventories": inventories,
      //     "trade_receivables": trade_receivables,
      //     "cash_and_cash_equivalents": cash_and_cash_equivalents,
      //     "other_current_assets": other_current_assets,
      //   }),
      //   headers:
      //     { 'Content-Type': 'application/json', }
      // }


      // ).then(async response => {
      //   const result = await response.json();
      //   if (result['status'] === true) {
      //     localStorage.setItem("regupdateid", result["data"]["id"]);
      //     $('#stepper21').addClass('step-done');
      //     $('#stepper21').removeClass('editable-step');
      //     $('#stepper31').addClass('active-step');
      //     $('#stepper31').addClass('editable-step');
      //     $('#stepper_line21').removeClass('tab1_border_step');
      //     $('#stepper_line21').addClass('tab1_border_step_green');
      //     $('#stepper_cir_bod21').removeClass('boder_whi');
      //     $('#stepper_cir_bod21').addClass('boder_green');
      //     activeStep($('#step31'));

      //     toast.success(result["message"], {
      //       position: toast.POSITION.TOP_RIGHT,
      //       className: 'foo_bar_log_Success'
      //     });

      //   } else {
      //     toast.error(result["message"], {
      //       position: toast.POSITION.TOP_RIGHT,
      //       className: 'foo_bar_log_error'
      //     });

      //   }
      // }).catch(error => {
      //   console.error('There was an error!', error);
      // });
  
    }
    else if (user_type === "register_user") {


      GetRegisterCreditScore1();   

      async function GetRegisterCreditScore1() {
        const response = await apis.getCreditscoreRegister(
          JSON.stringify({
            "score_id": localStorage.getItem("regupdateid"),
            "income": income,
            "sales_costs": sales_costs,
            "expenses": expenses,
            "depreciation": depreciation,
            "financial_costs": financial_costs,
            "financial_income": financial_income,
            "profit_tax": profit_tax,
            "net_business":net_business,
            "tangible_assets": tangible_assets,
            "intangible_assets": intangible_assets,
            "inventories": inventories,
            "trade_receivables": trade_receivables,
            "cash_and_cash_equivalents": cash_and_cash_equivalents,
            "other_current_assets": other_current_assets,
                }),

        );
        console.log(response)
        if (response['status'] === true) {
          $('#stepper21').addClass('step-done');
          $('#stepper21').removeClass('editable-step');
          $('#stepper31').addClass('active-step');
          $('#stepper31').addClass('editable-step');
          $('#stepper_line21').removeClass('tab1_border_step');
          $('#stepper_line21').addClass('tab1_border_step_green');
          $('#stepper_cir_bod21').removeClass('boder_whi');
          $('#stepper_cir_bod21').addClass('boder_green');
          activeStep($('#step31'));

          toast.success(response["message"], {
            position: toast.POSITION.TOP_RIGHT,
            className: 'foo_bar_log_Success'
          });

        } else {
          toast.error(response["message"], {
            position: toast.POSITION.TOP_RIGHT,
            className: 'foo_bar_log_error'
          });

        }
    }


      // fetch(`${process.env.REACT_APP_BASE_URL}${con.creditscoreregisterupdate}`, {
      //   method: 'post',
      //   body: JSON.stringify({
      //     "score_id": localStorage.getItem("regupdateid"),
      //     "income": income,
      //     "sales_costs": sales_costs,
      //     "expenses": expenses,
      //     "depreciation": depreciation,
      //     "financial_costs": financial_costs,
      //     "financial_income": financial_income,
      //     "profit_tax": profit_tax,
      //     "net_business":net_business,
      //     "tangible_assets": tangible_assets,
      //     "intangible_assets": intangible_assets,
      //     "inventories": inventories,
      //     "trade_receivables": trade_receivables,
      //     "cash_and_cash_equivalents": cash_and_cash_equivalents,
      //     "other_current_assets": other_current_assets,
      //   }),
      //   headers:
      //   {
      //     "Authorization": `Bearer ${token}`,
      //     'Content-Type': 'application/json',
      //   }
      // }
      // ).then(async response => {
      //   const result = await response.json();

      //   if (result['status'] === true) {
      //     // localStorage.setItem("regupdateid", result["data"]["id"]);
      //     $('#stepper21').addClass('step-done');
      //     $('#stepper21').removeClass('editable-step');
      //     $('#stepper31').addClass('active-step');
      //     $('#stepper31').addClass('editable-step');
      //     $('#stepper_line21').removeClass('tab1_border_step');
      //     $('#stepper_line21').addClass('tab1_border_step_green');
      //     $('#stepper_cir_bod21').removeClass('boder_whi');
      //     $('#stepper_cir_bod21').addClass('boder_green');
      //     activeStep($('#step31'));

      //     toast.success(result["message"], {
      //       position: toast.POSITION.TOP_RIGHT,
      //       className: 'foo_bar_log_Success'
      //     });

      //   } else {
      //     toast.error(result["message"], {
      //       position: toast.POSITION.TOP_RIGHT,
      //       className: 'foo_bar_log_error'
      //     });

      //   }
      // }).catch(error => {
      //   console.error('There was an error!', error);
      // });
   
    }
  }

  //---------------- stepper3 api integration start  ------------------------------

  const handlerSubmitThree = (event) => {
    event.preventDefault();

    const income = incomeRef.current.value
    const sales_costs = salesCostsRef.current.value
    const expenses = expensesRef.current.value
    const depreciation = depreciationRef.current.value
    const financial_costs = financialCostsRef.current.value
    const financial_income = financialIncomeRef.current.value
    const profit_tax = profitTaxRef.current.value
    const net_business = netbusinessRef.current.value
    const tangible_assets = tangibleAssetsRef.current.value
    const intangible_assets = intangibleAssetsRef.current.value
    const inventories = inventoriesRef.current.value
    const trade_receivables = tradeReceivablesRef.current.value
    const cash_and_cash_equivalents = cash_and_cash_equivalentsRef.current.value
    const other_current_assets = other_current_assetsRef.current.value
    const long_term_borrowings = long_term_borrowingsRef.current.value
    const long_term_liabilites = long_term_liabilitesRef.current.value
    const accrued_expenses = accrued_expensesRef.current.value
    const trade_and_other_payables = trade_and_other_payablesRef.current.value
    const short_term_borrowings = short_term_borrowingsRef.current.value
    const current_tax_payable = current_tax_payableRef.current.value
    const short_term_liabilities = short_term_liabilitiesRef.current.value
    if (user_type === "guest_user") {
      

      GetGuestCreditScore2();   

      async function GetGuestCreditScore2() {
        const response = await apis.getCreditscoreGuest(
          JSON.stringify({
            "user_id": user_id,
          "score_id": localStorage.getItem("guestupdateid"),
          "income": income,
          "sales_costs": sales_costs,
          "expenses": expenses,
          "depreciation": depreciation,
          "financial_costs": financial_costs,
          "financial_income": financial_income,
          "profit_tax": profit_tax,
          "net_business":net_business,
          "tangible_assets": tangible_assets,
          "intangible_assets": intangible_assets,
          "inventories": inventories,
          "trade_receivables": trade_receivables,
          "cash_and_cash_equivalents": cash_and_cash_equivalents,
          "other_current_assets": other_current_assets,
          "long_term_borrowings": long_term_borrowings,
          "long_term_liabilites": long_term_liabilites,
          "accrued_expenses": accrued_expenses,
          "trade_and_other_payables": trade_and_other_payables,
          "short_term_borrowings": short_term_borrowings,
          "current_tax_payable": current_tax_payable,
          "short_term_liabilities": short_term_liabilities,
                }),

        );
        console.log(response)
        if (response['status'] === true) {
          $('#stepper31').addClass('step-done');
          $('#stepper31').removeClass('editable-step');
          $('#stepper41').addClass('active-step');
          $('#stepper41').addClass('editable-step');
          $('#stepper_line31').removeClass('tab1_border_step');
          $('#stepper_line31').addClass('tab1_border_step_green');
          $('#stepper_cir_bod31').removeClass('boder_whi');
          $('#stepper_cir_bod31').addClass('boder_green');
          activeStep($('#step41'));

          toast.success(response["message"], {
            position: toast.POSITION.TOP_RIGHT,
            className: 'foo_bar_log_Success'
          });

        } else {
          toast.error(response["message"], {
            position: toast.POSITION.TOP_RIGHT,
            className: 'foo_bar_log_error'
          });

        }
    }

      // fetch(`${process.env.REACT_APP_BASE_URL}${con.creditscoreguestupdate}`, {
      //   method: 'post',
      //   body: JSON.stringify({
      //     "user_id": user_id,
      //     "score_id": localStorage.getItem("guestupdateid"),
      //     "income": income,
      //     "sales_costs": sales_costs,
      //     "expenses": expenses,
      //     "depreciation": depreciation,
      //     "financial_costs": financial_costs,
      //     "financial_income": financial_income,
      //     "profit_tax": profit_tax,
      //     "net_business":net_business,
      //     "tangible_assets": tangible_assets,
      //     "intangible_assets": intangible_assets,
      //     "inventories": inventories,
      //     "trade_receivables": trade_receivables,
      //     "cash_and_cash_equivalents": cash_and_cash_equivalents,
      //     "other_current_assets": other_current_assets,
      //     "long_term_borrowings": long_term_borrowings,
      //     "long_term_liabilites": long_term_liabilites,
      //     "accrued_expenses": accrued_expenses,
      //     "trade_and_other_payables": trade_and_other_payables,
      //     "short_term_borrowings": short_term_borrowings,
      //     "current_tax_payable": current_tax_payable,
      //     "short_term_liabilities": short_term_liabilities,
      //   }),
      //   headers:
      //     { 'Content-Type': 'application/json', }
      // }
      // ).then(async response => {
      //   const result = await response.json();

      //   if (result['status'] === true) {
      //     // localStorage.setItem("regupdateid", result["data"]["id"]);
      //     $('#stepper31').addClass('step-done');
      //     $('#stepper31').removeClass('editable-step');
      //     $('#stepper41').addClass('active-step');
      //     $('#stepper41').addClass('editable-step');
      //     $('#stepper_line31').removeClass('tab1_border_step');
      //     $('#stepper_line31').addClass('tab1_border_step_green');
      //     $('#stepper_cir_bod31').removeClass('boder_whi');
      //     $('#stepper_cir_bod31').addClass('boder_green');
      //     activeStep($('#step41'));

      //     toast.success(result["message"], {
      //       position: toast.POSITION.TOP_RIGHT,
      //       className: 'foo_bar_log_Success'
      //     });

      //   } else {
      //     toast.error(result["message"], {
      //       position: toast.POSITION.TOP_RIGHT,
      //       className: 'foo_bar_log_error'
      //     });

      //   }
      // }).catch(error => {
      //   console.error('There was an error!', error);
      // });
    
    }
    else if (user_type === "register_user") {
      
      GetRegisterCreditScore2();   

      async function GetRegisterCreditScore2() {
        const response = await apis.getCreditscoreRegister(
          JSON.stringify({
            "score_id": localStorage.getItem("regupdateid"),
          "income": income,
          "sales_costs": sales_costs,
          "expenses": expenses,
          "depreciation": depreciation,
          "financial_costs": financial_costs,
          "financial_income": financial_income,
          "profit_tax": profit_tax,
          "net_business":net_business,
          "tangible_assets": tangible_assets,
          "intangible_assets": intangible_assets,
          "inventories": inventories,
          "trade_receivables": trade_receivables,
          "cash_and_cash_equivalents": cash_and_cash_equivalents,
          "other_current_assets": other_current_assets,
          "long_term_borrowings": long_term_borrowings,
          "long_term_liabilites": long_term_liabilites,
          "accrued_expenses": accrued_expenses,
          "trade_and_other_payables": trade_and_other_payables,
          "short_term_borrowings": short_term_borrowings,
          "current_tax_payable": current_tax_payable,
          "short_term_liabilities": short_term_liabilities,
                }),

        );
        console.log(response)
        if (response['status'] === true) {
          $('#stepper31').addClass('step-done');
          $('#stepper31').removeClass('editable-step');
          $('#stepper41').addClass('active-step');
          $('#stepper41').addClass('editable-step');
          $('#stepper_line31').removeClass('tab1_border_step');
          $('#stepper_line31').addClass('tab1_border_step_green');
          $('#stepper_cir_bod31').removeClass('boder_whi');
          $('#stepper_cir_bod31').addClass('boder_green');
          activeStep($('#step41'));

          toast.success(response["message"], {
            position: toast.POSITION.TOP_RIGHT,
            className: 'foo_bar_log_Success'
          });

        } else {
          toast.error(response["message"], {
            position: toast.POSITION.TOP_RIGHT,
            className: 'foo_bar_log_error'
          });

        }
    }


    
      // fetch(`${process.env.REACT_APP_BASE_URL}${con.creditscoreregisterupdate}`, {
      //   method: 'post',
      //   body: JSON.stringify({
      //     "score_id": localStorage.getItem("regupdateid"),
      //     "income": income,
      //     "sales_costs": sales_costs,
      //     "expenses": expenses,
      //     "depreciation": depreciation,
      //     "financial_costs": financial_costs,
      //     "financial_income": financial_income,
      //     "profit_tax": profit_tax,
      //     "net_business":net_business,
      //     "tangible_assets": tangible_assets,
      //     "intangible_assets": intangible_assets,
      //     "inventories": inventories,
      //     "trade_receivables": trade_receivables,
      //     "cash_and_cash_equivalents": cash_and_cash_equivalents,
      //     "other_current_assets": other_current_assets,
      //     "long_term_borrowings": long_term_borrowings,
      //     "long_term_liabilites": long_term_liabilites,
      //     "accrued_expenses": accrued_expenses,
      //     "trade_and_other_payables": trade_and_other_payables,
      //     "short_term_borrowings": short_term_borrowings,
      //     "current_tax_payable": current_tax_payable,
      //     "short_term_liabilities": short_term_liabilities,
      //   }),
      //   headers:
      //   {
      //     "Authorization": `Bearer ${token}`,
      //     'Content-Type': 'application/json',
      //   }
      // }
      // ).then(async response => {
      //   const result = await response.json();

      //   if (result['status'] === true) {
      //     // localStorage.setItem("regupdateid", result["data"]["id"]);
      //     $('#stepper31').addClass('step-done');
      //     $('#stepper31').removeClass('editable-step');
      //     $('#stepper41').addClass('active-step');
      //     $('#stepper41').addClass('editable-step');
      //     $('#stepper_line31').removeClass('tab1_border_step');
      //     $('#stepper_line31').addClass('tab1_border_step_green');
      //     $('#stepper_cir_bod31').removeClass('boder_whi');
      //     $('#stepper_cir_bod31').addClass('boder_green');
      //     activeStep($('#step41'));

      //     toast.success(result["message"], {
      //       position: toast.POSITION.TOP_RIGHT,
      //       className: 'foo_bar_log_Success'
      //     });

      //   } else {
      //     toast.error(result["message"], {
      //       position: toast.POSITION.TOP_RIGHT,
      //       className: 'foo_bar_log_error'
      //     });

      //   }
      //   // window.location.href = "/dashboard";
      // }).catch(error => {
      //   console.error('There was an error!', error);
      // });
    
    }
  }

  //  -------------------  stepper4 api integration finish -----------------

  const handlerSubmitFour = (event) => {
    event.preventDefault();

    const income = incomeRef.current.value
    const sales_costs = salesCostsRef.current.value
    const expenses = expensesRef.current.value
    const depreciation = depreciationRef.current.value
    const financial_costs = financialCostsRef.current.value
    const financial_income = financialIncomeRef.current.value
    const profit_tax = profitTaxRef.current.value
    const net_business = netbusinessRef.current.value
    const tangible_assets = tangibleAssetsRef.current.value
    const intangible_assets = intangibleAssetsRef.current.value
    const inventories = inventoriesRef.current.value
    const trade_receivables = tradeReceivablesRef.current.value
    const cash_and_cash_equivalents = cash_and_cash_equivalentsRef.current.value
    const other_current_assets = other_current_assetsRef.current.value
    const long_term_borrowings = long_term_borrowingsRef.current.value
    const long_term_liabilites = long_term_liabilitesRef.current.value
    const accrued_expenses = accrued_expensesRef.current.value
    const trade_and_other_payables = trade_and_other_payablesRef.current.value
    const short_term_borrowings = short_term_borrowingsRef.current.value
    const current_tax_payable = current_tax_payableRef.current.value
    const short_term_liabilities = short_term_liabilitiesRef.current.value
    const dividend_payment = dividend_paymentRef.current.value
    const social_capital = social_capitalRef.current.value
    const share_premium = share_premiumRef.current.value
    const bookings = bookingsRef.current.value
    const results_previous_years = results_previous_yearsRef.current.value
    const result_of_the_excersice = result_of_the_exerciseRef.current.value

    const answer = Number(dividend_payment) + Number(social_capital) + Number(expenses) - Number(share_premium) -
      Number(bookings) + Number(result_of_the_excersice);
    setTotal2(answer);



    if (user_type === "guest_user") {


      GetGuestCreditScore3();   

      async function GetGuestCreditScore3() {
        const response = await apis.getCreditscoreGuest(
          JSON.stringify({
            "user_id": user_id,
            "score_id": localStorage.getItem("guestupdateid"),
            "income": income,
            "sales_costs": sales_costs,
            "expenses": expenses,
            "depreciation": depreciation,
            "financial_costs": financial_costs,
            "financial_income": financial_income,
            "profit_tax": profit_tax,
            "net_business":net_business,
            "tangible_assets": tangible_assets,
            "intangible_assets": intangible_assets,
            "inventories": inventories,
            "trade_receivables": trade_receivables,
            "cash_and_cash_equivalents": cash_and_cash_equivalents,
            "other_current_assets": other_current_assets,
            "long_term_borrowings": long_term_borrowings,
            "long_term_liabilites": long_term_liabilites,
            "accrued_expenses": accrued_expenses,
            "trade_and_other_payables": trade_and_other_payables,
            "short_term_borrowings": short_term_borrowings,
            "current_tax_payable": current_tax_payable,
            "short_term_liabilities": short_term_liabilities,
            "dividend_payment": dividend_payment,
            "social_capital": social_capital,
            "share_premium": share_premium,
            "bookings": bookings,
            "results_previous_years": results_previous_years,
            "result_of_the_excersice": result_of_the_excersice,
                }),

        );
        console.log(response)
        if (response['status'] === true) {
          $('#stepper41').addClass('step-done');
          $('#stepper41').removeClass('editable-step');
          $('#stepper_cir_bod41').removeClass('boder_whi');
          $('#stepper_cir_bod41').addClass('boder_green');

          localStorage.setItem("scoreran", response["data"]["score_ran_id"]);
          localStorage.setItem("user_score", response["data"]["user_score"]);
          localStorage.setItem("guest_conformation", 'credit_store');
      
          toast.success(response["message"], {
            position: toast.POSITION.TOP_RIGHT,
            className: 'foo_bar_log_Success'
          });


          swal.fire({
            title: 'Credit Form Updated Successfully',
            // text: 'All form completed',
            icon: 'success',
            confirmButtonText: 'OK',
            // timer: 1500
          }).then((result) => {
            if (result.isConfirmed) {
              navigate('/user/company_register');
            }
          });

        } else {
          toast.error(response["message"], {
            position: toast.POSITION.TOP_RIGHT,
            className: 'foo_bar_log_error'
          });

        }
    }


      // fetch(`${process.env.REACT_APP_BASE_URL}${con.creditscoreguestupdate}`, {
      //   method: 'post',
      //   body: JSON.stringify({
      //     "user_id": user_id,
      //     "score_id": localStorage.getItem("guestupdateid"),
      //     "income": income,
      //     "sales_costs": sales_costs,
      //     "expenses": expenses,
      //     "depreciation": depreciation,
      //     "financial_costs": financial_costs,
      //     "financial_income": financial_income,
      //     "profit_tax": profit_tax,
      //     "net_business":net_business,
      //     "tangible_assets": tangible_assets,
      //     "intangible_assets": intangible_assets,
      //     "inventories": inventories,
      //     "trade_receivables": trade_receivables,
      //     "cash_and_cash_equivalents": cash_and_cash_equivalents,
      //     "other_current_assets": other_current_assets,
      //     "long_term_borrowings": long_term_borrowings,
      //     "long_term_liabilites": long_term_liabilites,
      //     "accrued_expenses": accrued_expenses,
      //     "trade_and_other_payables": trade_and_other_payables,
      //     "short_term_borrowings": short_term_borrowings,
      //     "current_tax_payable": current_tax_payable,
      //     "short_term_liabilities": short_term_liabilities,
      //     "dividend_payment": dividend_payment,
      //     "social_capital": social_capital,
      //     "share_premium": share_premium,
      //     "bookings": bookings,
      //     "results_previous_years": results_previous_years,
      //     "result_of_the_excersice": result_of_the_excersice,
      //   }),
      //   headers:
      //     { 'Content-Type': 'application/json', }
      // }
      // ).then(async response => {
      //   const result4 = await response.json();

      //   if (result4['status'] === true) {
      //     // localStorage.setItem("regupdateid", result["data"]["id"]);
      //     $('#stepper41').addClass('step-done');
      //     $('#stepper41').removeClass('editable-step');
      //     $('#stepper_cir_bod41').removeClass('boder_whi');
      //     $('#stepper_cir_bod41').addClass('boder_green');

      //     localStorage.setItem("scoreran", result4["data"]["score_ran_id"]);
      //     localStorage.setItem("user_score", result4["data"]["user_score"]);
      //     localStorage.setItem("guest_conformation", 'credit_store');

      //     // window.location.href = "/user/company_sector";
      //     // showAlert();

      //     //   toast.success("Score Created", {
      //     //     position: toast.POSITION.TOP_RIGHT,
      //     //     className: 'foo_bar_log_Success'
      //     //   });

      //     // } else{
      //     //   toast.error("Something Error", {
      //     //     position: toast.POSITION.TOP_RIGHT,
      //     //     className: 'foo_bar_log_error'
      //     //   });

      //     // }        
      //     toast.success(result4["message"], {
      //       position: toast.POSITION.TOP_RIGHT,
      //       className: 'foo_bar_log_Success'
      //     });


      //     swal.fire({
      //       title: 'Credit Form Updated Successfully',
      //       // text: 'All form completed',
      //       icon: 'success',
      //       confirmButtonText: 'OK',
      //       // timer: 1500
      //     }).then((result) => {
      //       if (result.isConfirmed) {
      //         // navigate('/user/company_sector');
      //         navigate('/user/company_register');
      //       }
      //     });

      //   } else {
      //     toast.error(result4["message"], {
      //       position: toast.POSITION.TOP_RIGHT,
      //       className: 'foo_bar_log_error'
      //     });

      //   }
      // }).catch(error => {
      //   console.error('There was an error!', error);
      // });
    }
    else if (user_type === "register_user") {
      

      GetRegisterCreditScore2();   

      async function GetRegisterCreditScore2() {
        const response = await apis.getCreditscoreRegister(
          JSON.stringify({
            "score_id": localStorage.getItem("regupdateid"),
            "income": income,
            "sales_costs": sales_costs,
            "expenses": expenses,
            "depreciation": depreciation,
            "financial_costs": financial_costs,
            "financial_income": financial_income,
            "profit_tax": profit_tax,
            "net_business":net_business,
            "tangible_assets": tangible_assets,
            "intangible_assets": intangible_assets,
            "inventories": inventories,
            "trade_receivables": trade_receivables,
            "cash_and_cash_equivalents": cash_and_cash_equivalents,
            "other_current_assets": other_current_assets,
            "long_term_borrowings": long_term_borrowings,
            "long_term_liabilites": long_term_liabilites,
            "accrued_expenses": accrued_expenses,
            "trade_and_other_payables": trade_and_other_payables,
            "short_term_borrowings": short_term_borrowings,
            "current_tax_payable": current_tax_payable,
            "short_term_liabilities": short_term_liabilities,
            "dividend_payment": dividend_payment,
            "social_capital": social_capital,
            "share_premium": share_premium,
            "bookings": bookings,
            "results_previous_years": results_previous_years,
            "result_of_the_excersice": result_of_the_excersice,
                }),

        );
        console.log(response)
        if (response['status'] === true) {
          $('#stepper41').addClass('step-done');
          $('#stepper41').removeClass('editable-step');
          $('#stepper_cir_bod41').removeClass('boder_whi');
          $('#stepper_cir_bod41').addClass('boder_green');

          localStorage.setItem("scoreran", response["data"]["score_ran_id"]);
          localStorage.setItem("user_score", response["data"]["user_score"]);
          window.location.href = "/user/company_sector";
          toast.success(response["message"], {
            position: toast.POSITION.TOP_RIGHT,
            className: 'foo_bar_log_Success'
          });


          swal.fire({
            title: 'Credit Form Updated Successfully',
            // text: 'All form completed',
            icon: 'success',
            confirmButtonText: 'OK',
            // timer: 1500
          }).then((result) => {
            if (result.isConfirmed) {
              navigate('/user/company_sector');
            }
          });

        } else {
          toast.error(response["message"], {
            position: toast.POSITION.TOP_RIGHT,
            className: 'foo_bar_log_error'
          });

        }
    }


      // fetch(`${process.env.REACT_APP_BASE_URL}${con.creditscoreregisterupdate}`, {
      //   method: 'post',
      //   body: JSON.stringify({
      //     "score_id": localStorage.getItem("regupdateid"),
      //     "income": income,
      //     "sales_costs": sales_costs,
      //     "expenses": expenses,
      //     "depreciation": depreciation,
      //     "financial_costs": financial_costs,
      //     "financial_income": financial_income,
      //     "profit_tax": profit_tax,
      //     "net_business":net_business,
      //     "tangible_assets": tangible_assets,
      //     "intangible_assets": intangible_assets,
      //     "inventories": inventories,
      //     "trade_receivables": trade_receivables,
      //     "cash_and_cash_equivalents": cash_and_cash_equivalents,
      //     "other_current_assets": other_current_assets,
      //     "long_term_borrowings": long_term_borrowings,
      //     "long_term_liabilites": long_term_liabilites,
      //     "accrued_expenses": accrued_expenses,
      //     "trade_and_other_payables": trade_and_other_payables,
      //     "short_term_borrowings": short_term_borrowings,
      //     "current_tax_payable": current_tax_payable,
      //     "short_term_liabilities": short_term_liabilities,
      //     "dividend_payment": dividend_payment,
      //     "social_capital": social_capital,
      //     "share_premium": share_premium,
      //     "bookings": bookings,
      //     "results_previous_years": results_previous_years,
      //     "result_of_the_excersice": result_of_the_excersice,
      //   }),
      //   headers:
      //   {
      //     "Authorization": `Bearer ${token}`,
      //     'Content-Type': 'application/json',
      //   }
      // }
      // ).then(async response => {
      //   const result4 = await response.json();

      //   if (result4['status'] === true) {
      //     // localStorage.setItem("regupdateid", result["data"]["id"]);
      //     $('#stepper41').addClass('step-done');
      //     $('#stepper41').removeClass('editable-step');
      //     $('#stepper_cir_bod41').removeClass('boder_whi');
      //     $('#stepper_cir_bod41').addClass('boder_green');

      //     localStorage.setItem("scoreran", result4["data"]["score_ran_id"]);
      //     localStorage.setItem("user_score", result4["data"]["user_score"]);
      //     // window.location.href = "/dashboard";

      //     // if (result4['status'] === true) {
      //     window.location.href = "/user/company_sector";

      //     toast.success(result4["message"], {
      //       position: toast.POSITION.TOP_RIGHT,
      //       className: 'foo_bar_log_Success'
      //     });


      //     swal.fire({
      //       title: 'Credit Form Updated Successfully',
      //       // text: 'All form completed',
      //       icon: 'success',
      //       confirmButtonText: 'OK',
      //       // timer: 1500
      //     }).then((result) => {
      //       if (result.isConfirmed) {
      //         navigate('/user/company_sector');
      //         // navigate('/user/company_register');
      //       }
      //     });

      //   } else {
      //     toast.error(result4["message"], {
      //       position: toast.POSITION.TOP_RIGHT,
      //       className: 'foo_bar_log_error'
      //     });

      //   }

      // }).catch(error => {
      //   console.error('There was an error!', error);
      // });
    
    }

  }





  return (
    ((role_id !== null && role_id === "2")) ?
      <React.Fragment>
        <section className="overflowx">
          <div className="">
            <div className="row">
              <div className="col-md-3 tb_cred_bg container" id="sideform" >
                <h3 className="my-5 cre_left_st" >
                  <span className="formspan"> puntuación de crédito</span>
                </h3>


                <div className="mdl-card mdl-shadow--2dp ">
                  <div className="mdl-card__supporting-text " id="stepform1" >
                    <div className="mdl-stepper-vertical-alternative">
                      <div
                        id="stepper11"
                        className="mdl-stepper-step active-step editable-step "
                      >
                        <div className="flex">

                          <div id="stepper_cir_bod11" className="mdl-stepper-circle boder_whi">
                            <span>1</span>
                          </div>
                          <p className="paratag">
                            Cuenta de pérdidas y <br />
                            <span className="formspan">ganancias</span>
                          </p>
                        </div>
                        <div id="stepper_line11" className="tab1_border_step">
                        </div>
                      </div>
                      <div id="stepper21" className="mdl-stepper-step">
                        <div className="flex" >
                          <div id="stepper_cir_bod21" className="mdl-stepper-circle boder_whi">
                            <span>2</span>
                          </div>
                          <p className="paratag1">Activos</p>
                        </div>
                        <div id="stepper_line21" className="tab1_border_step"></div>
                      </div>
                      <div id="stepper31" className="mdl-stepper-step">
                        <div className="flex" >
                          <div id="stepper_cir_bod31" className="mdl-stepper-circle boder_whi">
                            <span>3</span>
                          </div>
                          <p className="paratag1" >Pasivos</p>
                        </div>
                        <div id="stepper_line31" className="tab1_border_step"></div>
                      </div>
                      <div id="stepper41" className="mdl-stepper-step">
                        <div className="flex">
                          <div id="stepper_cir_bod31" className="mdl-stepper-circle boder_whi">
                            <span>4</span>
                          </div>
                          <p className="paratag1">Patrimonio
                            <span className="formspan">  Neto</span>
                          </p>
                        </div>
                        <div className=""></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8  steps" >
                <div className="container">

                  <ToastContainer autoClose={3000} />

                  <div className="stepper">

                    <div id="step11" className="step">



                      {/* <div>{localId}</div> */}
                      <div className="ta_1_dpa">


                        {/*------------------------------- form 1 */}
                        <h3 className="ta_1_h1">Cuenta de Resultados</h3>

                        <form onSubmit={handlerSubmitOne}>

                          <label className="ta_lab">Cifra Neta <span className="formspan" >de Negocio {currentYear}</span></label>
                          <input
                            type="number"
                            className="form-control ta_inpu"
                            placeholder="Cifra Neta de Negocio"
                            id=""
                            name="field22" value={values.field22} onChange={handleChange1} maxLength={8}
                            ref={incomeRef}
                             onKeyDown={handlekeydown}
                            min="0"
                            required />

                          <label className="ta_lab">Costes de <span className="formspan" >ventas</span></label>
                          <input
                            type="number"
                            className="form-control ta_inpu"
                            placeholder="Costes de ventas"
                            id=""
                            name="field2" value={values.field2} onChange={handleChange1} maxLength={8}
                            ref={salesCostsRef}
                             onKeyDown={handlekeydown}
                            min="0"
                            required />


                          <label className="ta_lab">
                            Gastos de Administración y otros gastos de explotación
                          </label>
                          <input
                            type="number"
                            className="form-control ta_inpu"
                            placeholder="  Gastos de Administración"
                            id=""
                            name="field3" value={values.field3} onChange={handleChange1} maxLength={8}
                            ref={expensesRef}
                             onKeyDown={handlekeydown}
                            min="0"
                            required />
                          <label className="ta_lab">
                            Depreciación y coste de Amortización
                          </label>
                          <input
                            type="number"
                            className="form-control ta_inpu"
                            placeholder=" Depreciación y coste de Amortización"
                            id=""
                            name="field4" value={values.field4} onChange={handleChange1} maxLength={8}
                            ref={depreciationRef}
                             onKeyDown={handlekeydown}
                            min="0"
                            required />
                          <label className="ta_lab">Costes Financieros</label>
                          <input
                            type="number"
                            className="form-control ta_inpu"
                            placeholder="Costes Financieros"
                            id=""
                            ref={financialCostsRef}
                            name="field5" value={values.field5} onChange={handleChange1} maxLength={8}
                             onKeyDown={handlekeydown}
                            min="0"
                            required />
                          <label className="ta_lab">Ingresos <span >F</span>inancieros</label>
                          <input
                            type="number"
                            className="form-control ta_inpu"
                            placeholder="Ingresos financieros"
                            id=""
                            ref={financialIncomeRef}
                            name="field6" value={values.field6} onChange={handleChange1} maxLength={8}
                             onKeyDown={handlekeydown}
                            min="0"
                            required />
                          <label className="ta_lab"> Impuesto<span className="formspan" >por beneficios </span>    </label>
                          <input
                            type="number"
                            className="form-control ta_inpu mb-30"
                            placeholder="Impuesto por beneficios"
                            id=""
                            name="field7" value={values.field7} onChange={handleChange1} maxLength={8}
                            ref={profitTaxRef}
                             onKeyDown={handlekeydown}
                            min="0"
                            required 
                            style={{marginBottom:"30px"}}/>

                          <b>Resultado del ejercicio {currentYear} <span className="result">{total}</span></b>   <br/>
                          <label className="ta_lab">Cifra nea de Negocio  {currentYear - 1}</label>
                          <input
                            type="number"
                            className="form-control ta_inpu"
                            placeholder="cifra nea de Negocio"
                            id=""
                            name="field1" value={values.field1} onChange={handleChange1} maxLength={8}
                            ref={netbusinessRef}
                             onKeyDown={handlekeydown}
                            min="0"
                            required />
                          <div className="col2_bor"></div>
                        

                          <div className="cred_but">
                            <button type="submit" id="next11" className="btn cresc_bt2" >
                              <span className="formspan">  Próxima</span>
                            </button>
                            {/* </a> */}
                            <div>

                            </div>
                          </div>
                        </form>
                      </div>
                      {/* <a id="next1" class="waves-effect waves-light btn"><i class="material-icons right">keyboard_arrow_right</i>NEXT</a> */}
                    </div>



                    {(localId !== '') ?
                      <div className="idclass" ><span className="badge bg-info">ID : {localId}</span></div>
                      : null}


                    <div id="step21" className="step hide">

                      <div className="ta_1_dpa">
                        <h3 className="ta_1_h1">Activos</h3>
                        {/*------------------------------- form 2 */}
                        <h4 className="font_we"> <span className="formspan">Activos no corrientes  </span>   </h4>

                        <form onSubmit={handlerSubmitTwo}>

                          <label className="ta_lab">Activos Tangibles</label>
                          <input
                            type="number"
                            className="form-control ta_inpu"
                            placeholder="Activos Tangibles"
                            id=""
                            name="field8" value={values.field8} onChange={handleChange1} maxLength={8}
                            ref={tangibleAssetsRef}
                             onKeyDown={handlekeydown}
                            min="0"
                            required />
                          <label className="ta_lab">Activos Intangibles</label>
                          <input
                            type="number"
                            className="form-control ta_inpu"
                            placeholder="Activos Intangibles"
                            id=""
                            name="field9" value={values.field9} onChange={handleChange1} maxLength={8}
                            ref={intangibleAssetsRef}
                             onKeyDown={handlekeydown}
                            min="0"
                            required />
                          <div className="col2_bor"></div>
                          <h4 className="font_we">Activos circulantes</h4>
                          <label className="ta_lab"> <span className="formspan">  Inventarios</span>   </label>
                          <input
                            type="number"
                            className="form-control ta_inpu"
                            placeholder="Inventarios"
                            id=""
                            name="field10" value={values.field10} onChange={handleChange1} maxLength={8}
                            ref={inventoriesRef}
                             onKeyDown={handlekeydown}
                            min="0"
                            required />
                          <label className="ta_lab">Cuentas comerciales por cobrar</label>
                          <input
                            type="number"
                            className="form-control ta_inpu"
                            placeholder="Cuentas comerciales por cobrar"
                            id=""
                            name="field11" value={values.field11} onChange={handleChange1} maxLength={8}
                            ref={tradeReceivablesRef}
                             onKeyDown={handlekeydown}
                            min="0"
                            required />
                          <label className="ta_lab">Efectivo y equivalentes de efectivo</label>
                          <input
                            type="number"
                            className="form-control ta_inpu"
                            placeholder="Efectivo y equivalentes de efectivo"
                            id=""
                            name="field12" value={values.field12} onChange={handleChange1} maxLength={8}
                            ref={cash_and_cash_equivalentsRef}
                             onKeyDown={handlekeydown}
                            min="0"
                            required />
                          <label className="ta_lab">Otros activos circulantes</label>
                          <input
                            type="number"
                            className="form-control ta_inpu"
                            placeholder="Otros activos circulantes"
                            id=""
                            name="field13" value={values.field13} onChange={handleChange1} maxLength={8}
                            ref={other_current_assetsRef}
                             onKeyDown={handlekeydown}
                            min="0"
                            required />
                          <div className="cred_but">
                            {/* <a href=""> */}
                            <button
                              type="button"
                              id="previous21"
                              className="btn cresc_bt"
                              onClick={back1}
                            >
                              Atrás
                            </button>
                            {/* </a> */}
                            {/* <a href=""> */}
                            <button type="submit" id="next21" className="btn cresc_bt1" >
                              <span className="formspan" >  Próxima</span>
                            </button>
                            {/* </a> */}
                          </div>
                        </form>
                      </div>
                      {/*  <a id="previous2" class="waves-effect waves-light btn"><i class="material-icons left">keyboard_arrow_left</i>PREVIOUS</a>
    <a id="next2" class="waves-effect waves-light btn"><i class="material-icons right">keyboard_arrow_right</i>NEXT</a> */}
                    </div>
                    <div id="step31" className="step hide">
                      <div className="ta_1_dpa">
                        <h3 className="ta_1_h1">Pasivos</h3>
                        <h4 className="font_we">Pasivos a largo plazo </h4>

                        {/*------------------------------- form 3 */}

                        <form onSubmit={handlerSubmitThree}>
                          <label className="ta_lab">Préstamos a Largo plazo </label>
                          <input
                            type="number"
                            className="form-control ta_inpu"
                            placeholder="Préstamos a Largo plazo"
                            id=""
                            name="field14" value={values.field14} onChange={handleChange1} maxLength={8}
                            ref={long_term_borrowingsRef}
                             onKeyDown={handlekeydown}
                            min="0"
                            required />
                          <label className="ta_lab">Otros pasivos a largo plazo</label>
                          <input
                            type="number"
                            className="form-control ta_inpu"
                            placeholder="Otros pasivos a largo plazo"
                            id=""
                            name="field15" value={values.field15} onChange={handleChange1} maxLength={8}
                            ref={long_term_liabilitesRef}
                             onKeyDown={handlekeydown}
                            min="0"
                            required />
                          <div className="col2_bor"></div>
                          <h4 className="font_we">Deudas a corto plazo </h4>
                          <label className="ta_lab">Gastos Acumulados</label>
                          <input
                            type="number"
                            className="form-control ta_inpu"
                            placeholder="Gastos Acumulados"
                            id=""
                            name="field16" value={values.field16} onChange={handleChange1} maxLength={8}
                            ref={accrued_expensesRef}
                             onKeyDown={handlekeydown}
                            min="0"
                            required />
                          <label className="ta_lab">Comerciales y otras cuentas a pagar</label>
                          <input
                            type="number"
                            className="form-control ta_inpu"
                            placeholder="Comerciales y otras cuentas a pagar"
                            id=""
                            name="field17" value={values.field17} onChange={handleChange1} maxLength={8}
                            ref={trade_and_other_payablesRef}
                             onKeyDown={handlekeydown}
                            min="0"
                            required />
                          <label className="ta_lab">Préstamos de corto Plazo </label>
                          <input
                            type="number"
                            className="form-control ta_inpu"
                            placeholder="Préstamos de corto Plazo"
                            id=""
                            name="field18" value={values.field18} onChange={handleChange1} maxLength={8}
                            ref={short_term_borrowingsRef}
                             onKeyDown={handlekeydown}
                            min="0"
                            required />
                          <label className="ta_lab">Impuesto corriente a pagar</label>
                          <input
                            type="number"
                            className="form-control ta_inpu"
                            placeholder="Impuesto corriente a pagar"
                            id=""
                            name="field19" value={values.field19} onChange={handleChange1} maxLength={8}
                            ref={current_tax_payableRef}
                             onKeyDown={handlekeydown}
                            min="0"
                            required />
                          <label className="ta_lab">Otros pasivos a corto plazo</label>
                          <input
                            type="number"
                            className="form-control ta_inpu"
                            placeholder="Otros pasivos a corto plazo"
                            id=""
                            name="field20" value={values.field20} onChange={handleChange1} maxLength={8}
                            ref={short_term_liabilitiesRef}
                             onKeyDown={handlekeydown}
                            min="0"
                            required />
                          <div className="cred_but">
                            {/* <a href="creditscore_2.html"> */}
                            <button
                              type="button"
                              id="previous31"
                              className="btn cresc_bt"
                              onClick={back2}
                            >
                              Atrás
                            </button>
                            {/* </a> */}
                            {/* <a href="creditscore_4.html"> */}
                            <button type="submit" id="next31" className="btn cresc_bt1" >
                              <span form="formspan">  Próxima</span>
                            </button>
                            {/* </a> */}
                          </div>
                        </form>
                      </div>
                      {/* 
                    <a id="previous3" clvalass="waves-effect waves-light btn"><i class="material-icons left">keyboard_arrow_left</i>PREVIOUS</a>
                    <a id="next3" class="waves-effect waves-light btn"><i class="material-icons right">done_all</i>FINISH</a> */}
                    </div>
                    <div id="step41" className="step hide">
                      {/* <div className="ta_1_dpa"> */}






                      {/*------------------------------- form 4 */}

                      <form onSubmit={handlerSubmitFour}>
                        <div className="ta_1_dpa">
                          <h3 className="ta_1_h1">Patrimonio Neto</h3>
                          <label className="ta_lab">Paga de Dividendos</label>
                          <input
                            type="number"
                            className="form-control ta_inpu"
                            placeholder="Paga de Dividendos"
                            name="field21"
                            value={values.field21}
                            onChange={handleChange1}
                            maxLength={8}
                            ref={dividend_paymentRef}
                             onKeyDown={handlekeydown}
                            min="0"
                            required
                          />
                          <label className="ta_lab">Capitales Sociales</label>
                          <input
                            type="number"
                            className="form-control ta_inpu"
                            placeholder="Capitales Sociales"
                            name="field23"
                            value={values.field23}
                            onChange={handleChange1}
                            maxLength={8}
                            ref={social_capitalRef}
                             onKeyDown={handlekeydown}
                            min="0"
                            required
                          />
                          <label className="ta_lab">Prima de Emisión</label>
                          <input
                            type="number"
                            className="form-control ta_inpu"
                            placeholder="Prima de Emisión"
                            name="field24"
                            value={values.field24}
                            onChange={handleChange1}
                            maxLength={8}
                            ref={share_premiumRef}
                             onKeyDown={handlekeydown}
                            min="0"
                            required
                          />
                          <label className="ta_lab">Reservas</label>
                          <input
                            type="number"
                            className="form-control ta_inpu"
                            placeholder="Reservas"
                            name="field25"
                            value={values.field25}
                            onChange={handleChange1}
                            maxLength={8}
                            ref={bookingsRef}
                             onKeyDown={handlekeydown}
                            min="0"
                            required
                          />


                          <label className="ta_lab">Resultado del ejercicio</label>
                          <input
                            type="number"
                            className="form-control ta_inpu"
                            placeholder="Resultado del ejercicio"
                            name="field26"
                            value={total}
                            onChange={handleChange1}
                            maxLength={8}
                            ref={result_of_the_exerciseRef}
                             onKeyDown={handlekeydown}
                            min="0"
                            required
                          />


                          <label className="ta_lab">Resultados ejercicios anteriores</label>
                          <input
                            type="number"
                            className="form-control ta_inpu"
                            placeholder="Resultados ejercicios anteriores"
                            name="field27"
                            value={values.field27}
                            onChange={handleChange1}
                            maxLength={8}
                            ref={results_previous_yearsRef}
                             onKeyDown={handlekeydown}
                            min="0"
                            required
                          />

                          {/* <p style={{ marginTop: "15px" }}>Resultado del ejercicio <br /> estos dos numeros tienen que ser iguales</p>
                          <h4 style={{ color: "#27AE60" }}>{total}<span style={{ color: "black" }}><i className="fa fa-arrows-h" aria-hidden="true"></i></span>{values.field27}</h4> */}
                          <div className="cred_but">
                            <button
                              type="button"
                              id="previous41"
                              className="btn cresc_bt"
                              onClick={back3}
                            >
                              Atrás
                            </button>
                            <button
                              type="submit"
                              id="finish"
                              className="btn cresc_bt1"
                              disabled={total != values.field27}
                            >
                              <span className="formspan">Obtener mi puntaje</span>
                            </button>
                          </div>
                        </div>
                      </form>
                      {/* </div> */}
                      {/*  <a id="previous2" class="waves-effect waves-light btn"><i class="material-icons left">keyboard_arrow_left</i>PREVIOUS</a>
                      <a id="next2" class="waves-effect waves-light btn"><i class="material-icons right">keyboard_arrow_right</i>NEXT</a> */}
                    </div>
                  </div>
                  {/* end STEPS */}
                </div>
              </div>
            </div>
          </div>
        </section>



      </React.Fragment>
      : <Navigate to="/" replace />
  )
}

// mdl-stepper-step active-step editable-step

$(document).ready(function () {
  $('#next11').click(function () {

  });
  $('#next21').click(function () {

  });
  $('#next31').click(function () {

  });
  $('#finish').click(function () {

    //DO SOMETHING
  });
  $('#previous21').click(function () {
    // $('#stepper1').removeClass('step-done');

    $('#stepper11').addClass('step-done');
    $('#stepper11').removeClass('editable-step');
    $('#stepper21').removeClass('active-step');
    $('#stepper21').removeClass('editable-step');

    $('#stepper_line11').addClass('tab1_border_step');
    $('#stepper_line11').removeClass('tab1_border_step_green');

    $('#stepper_cir_bod11').removeClass('boder_green');
    $('#stepper_cir_bod11').addClass('boder_whi');
    $('#stepper_cir_bod21').removeClass('boder_green');
    $('#stepper_cir_bod21').addClass('boder_whi');

    activeStep1($('#step11'));
  });


  $('#previous31').click(function () {
    // $('#stepper2').removeClass('step-done');

    $('#stepper21').addClass('step-done');
    $('#stepper21').removeClass('editable-step');
    $('#stepper31').removeClass('active-step');
    $('#stepper31').removeClass('editable-step');

    $('#stepper_line21').addClass('tab1_border_step');
    $('#stepper_line21').removeClass('tab1_border_step_green');

    $('#stepper_line31').addClass('tab1_border_step');
    $('#stepper_line31').removeClass('tab1_border_step_green');

    $('#stepper_cir_bod31').removeClass('boder_green');
    $('#stepper_cir_bod31').addClass('boder_whi');
    $('#stepper_cir_bod21').removeClass('boder_green');
    $('#stepper_cir_bod21').addClass('boder_whi');

    activeStep1($('#step21'));
  });
  $('#previous41').click(function () {
    // $('#stepper3').removeClass('step-done');
    $('#stepper31').addClass('step-done');
    $('#stepper31').removeClass('editable-step');
    $('#stepper41').removeClass('active-step');
    $('#stepper41').removeClass('editable-step');

    $('#stepper_line41').addClass('tab1_border_step');
    $('#stepper_line41').removeClass('tab1_border_step_green');

    $('#stepper_cir_bod41').removeClass('boder_green');
    $('#stepper_cir_bod41').addClass('boder_whi');
    $('#stepper_cir_bod31').removeClass('boder_green');
    $('#stepper_cir_bod31').addClass('boder_whi');

    activeStep1($('#step31'));
  });
  function activeStep1(stepDiv) {
    $('.step').addClass('hide');
    stepDiv.removeClass('hide');
  }

});



function activeStep(stepDiv) {
  $('.step').addClass('hide');
  stepDiv.removeClass('hide');
}

function back1() {

  $('#stepper11').addClass('step-done');
  $('#stepper11').removeClass('editable-step');
  $('#stepper21').removeClass('active-step');
  $('#stepper21').removeClass('editable-step');

  $('#stepper_line11').addClass('tab1_border_step');
  $('#stepper_line11').removeClass('tab1_border_step_green');

  $('#stepper_cir_bod11').removeClass('boder_green');
  $('#stepper_cir_bod11').addClass('boder_whi');
  $('#stepper_cir_bod21').removeClass('boder_green');
  $('#stepper_cir_bod21').addClass('boder_whi');

  activeStep($('#step11'));
}



function back2() {
  // $('#stepper2').removeClass('step-done');

  $('#stepper21').addClass('step-done');
  $('#stepper21').removeClass('editable-step');
  $('#stepper31').removeClass('active-step');
  $('#stepper31').removeClass('editable-step');

  $('#stepper_line21').addClass('tab1_border_step');
  $('#stepper_line21').removeClass('tab1_border_step_green');

  $('#stepper_line31').addClass('tab1_border_step');
  $('#stepper_line31').removeClass('tab1_border_step_green');

  $('#stepper_cir_bod31').removeClass('boder_green');
  $('#stepper_cir_bod31').addClass('boder_whi');
  $('#stepper_cir_bod21').removeClass('boder_green');
  $('#stepper_cir_bod21').addClass('boder_whi');

  activeStep($('#step21'));
}


function back3() {

  // $('#stepper3').removeClass('step-done');
  $('#stepper31').addClass('step-done');
  $('#stepper31').removeClass('editable-step');
  $('#stepper41').removeClass('active-step');
  $('#stepper41').removeClass('editable-step');

  $('#stepper_line41').addClass('tab1_border_step');
  $('#stepper_line41').removeClass('tab1_border_step_green');

  $('#stepper_cir_bod41').removeClass('boder_green');
  $('#stepper_cir_bod41').addClass('boder_whi');
  $('#stepper_cir_bod31').removeClass('boder_green');
  $('#stepper_cir_bod31').addClass('boder_whi');

  activeStep($('#step31'));
}
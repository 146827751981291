import React, { useEffect } from "react";
import * as con from '../repository/urls.js';
import { Navigate } from "react-router-dom";
import * as images from './images.jsx';
import * as apis from '../repository/api_calls.js';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import UserHeader from "../user/component/header.jsx";




export default function Home() {
  const token = localStorage.getItem("token");
  const role_id = localStorage.getItem("role_id");
  const [userLoanOfferData, setUserLoanData] = React.useState([]);
  const stop = React.useRef(true);

  
  useEffect(() => {
    GetUserofferd();
  }, [])
  

  async function GetUserofferd() {
    const response = await apis.GetUserofferdetail();
    console.log(response)
    if (response['status'] === true) {
      for (let i = 0; i < response['data'].length; i++) {
        response['data'][i]['modelo_variable1'] = JSON.parse(response['data'][i]['modelo_variable'])
      }
      setUserLoanData([...response['data']]);
      
    }
}


  // function convertStringToList(item) {
  //   var data = JSON.parse(item);
  //   console.log(item);
  //   console.log(data[0])
  //   return data[0];
  // }

  function convertStringToList(item) {
    // console.log('convert to list ********** ')
    // console.log(item)
    if (item !== 'null' && item != undefined) {
      var data = JSON.parse(item);
      // console.log(item);
      // console.log(data[0])
      return data;
    } else {
      return null;
    }
  }


  

  async function getOfferStatus(id, status, reason, index) {
    if (stop.current) {
      return null;
    } else {
      await apis.offerStatus(id.offer_id, status, reason, token);
      var list1 = userLoanOfferData;
      console.log(list1);
      if (status === 'Accept') {
        list1[index]['offer_status'] = "Accept";
        setUserLoanData([...list1])
      } else {
        list1[index]['offer_status'] = "Reject";
        setUserLoanData([...list1])
      }
      console.log(userLoanOfferData);
    }
  }

  return (
    (role_id !== null && role_id === "2") ?
      <React.Fragment>
  <UserHeader />

        <ToastContainer autoClose={3000} />
        <section style={{ backgroundColor: "#FFFFFF", height: "65px", top: "50px", position: "sticky", zIndex: 2 }}>
          <div className="container">
            <div className="row" style={{ padding: 10 }}>
              <div className="col-md-3" style={{ display: "flex" }}>
                <a href="/dashboard" style={{ textDecoration: "none" }}>
                  <p style={{ color: "#183F4B", fontSize: "18px", marginTop: "2px" }}>
                    <span>
                      <img className="" src={images.dash_dark} alt='dash_dark' />
                    </span>
                    <span style={{ textTransform: "capitalize" }}>Panel</span>
                  </p>
                </a>
                <div
                  style={{
                    borderLeft: "2px solid #E0E0E0",
                    height: 45,
                    marginLeft: 80
                  }}
                ></div>
              </div>


              <div className="col-md-3" style={{ display: "flex",marginLeft:"-38px" }}>
                <a href="/myscore" style={{ textDecoration: "none" }}>
                  <p style={{ color: "#183F4B", fontSize: "18px", marginTop: "2px" }}>
                    <span>
                      <img className="" src={images.z_score_dark} alt='z_score_dark' />
                    </span>{" "}
                    <span style={{ textTransform: "capitalize" }}>Mi puntaje</span>
                  </p>
                </a>
                <div
                  style={{
                    borderLeft: "2px solid #E0E0E0",
                    height: 45,
                    marginLeft: 80
                  }}
                ></div>
              </div>
              <div className="col-md-3" style={{ display: "flex" }}>
                <a href="/dashboardapp" style={{ textDecoration: "none" }}>
                  <p style={{ color: "#183F4B", fontSize: "18px", marginTop: "2px", marginLeft: "-20px" }}>
                    <span>
                      <img className="" src={images.applica_dark} alt='applica_dark' style={{ marginRight: 7 }} />
                    </span>{" "}
                    <span style={{ textTransform: "capitalize" }}> Solicitudes enviadas</span>
                  </p>
                </a>
                <div
                  style={{
                    borderLeft: "2px solid #E0E0E0",
                    height: 45,
                    marginLeft: 80
                  }}
                ></div>
              </div>
              <div className="col-md-3">
                <a href="/dashboardloan" style={{ textDecoration: "none" }}>
                  <p style={{ color: "#0069CB", fontSize: "18px", marginTop: "2px" }}>
                    <span>
                      <img className="" src={images.loan_offer_blue} alt='loan_offer_blue' />
                    </span>
                    <span style={{ textTransform: "capitalize" }}> Ofertas recibidas</span>
                  </p>
                </a>
              </div>
            </div>
          </div>
        </section>


        {(userLoanOfferData.length === 0) ?
          <h4 className="text-center" style={{ fontWeight: 600 }}>No tienes ningún préstamo activo en este momento. Las ofertas se mostrarán solo si envía un préstamo a nuestros prestamistas.</h4>
          : null}


        {(userLoanOfferData.length !== 0) ?




          <section className="container mt-5">
            {(userLoanOfferData.length >= 3) ?


              // <section className="container mb-5">
              <div className="row" >

                {userLoanOfferData.map((list, index) => {
                  // return (
                  //   <div className="col-md-4" >
                  //     <div class="dashcard">
                  //       <br></br>

                  //       <div class="smallcard">
                  //         <img className="offerimg" src={images.offer_e} alt='Logo' />
                  //         <p className="offertext">Offer : {list.offer_id}</p>
                  //       </div>
                  //       <p class="loanoffertxt"> {list.type}</p>
                  //       <p class="loanoffertxt2"> Titulo del prestamo: {list.loan_title}</p>

                  //       {(list['offer_status'] !== null) ? (list.offer_status === "Accept") ?
                  //         <p style={{ textAlign: "right", padding: "10px", marginTop: "-50px" }}> <span className="badge_use bg-success" style={{ marginTop: "-50px" }}>{list['offer_status']}</span> </p> :
                  //         <p style={{ textAlign: "right", padding: "10px", marginTop: "-50px" }}> <span className="badge_use bg-danger" style={{ marginTop: "-50px" }}>{list['offer_status']}</span> </p>
                  //         : null
                  //       }

                  //       <div className="innercard">

                  //         <div style={{ display: "flex", justifyContent: "space-around", paddingTop: "13px" }}>
                  //           <div>
                  //             <p className="cardtextb"> IMPORTE: {list.imported}</p>
                  //             <p className="cardtextn"> TIN: {list.interest_rate}</p>
                  //             <p className="cardtextn"> FIJO: <span className="cardtextb">{list.permanent}</span></p>

                  //             <div style={{ display: "flex" }}>
                  //               <p className="cardtextn MODULO "> MODULO</p>
                  //               <div>
                  //                 <p className="cardtextn meses1"> 3% 1-12 meses</p>
                  //                 <p className="cardtextn meses2"> 4% 12-36 meses</p>
                  //               </div>
                  //             </div>
                  //             <p className="cardtextn"> GARANTIAS: <span className="cardtextb">{list.guarantees}</span></p>
                  //             <div style={{ display: "flex" }}>
                  //               <input type="text" class="eur1"></input>
                  //               <p className="cardtextb"> EUR</p>

                  //             </div>
                  //             <p className="cardtextb"><u> OTROS CARGOS:</u></p>
                  //             <div style={{ display: "flex" }}>
                  //               <p className="MODULO">APERTURA: </p>
                  //               <div class="eur2" style={{ marginLeft: "23px" }} > {list.opening}</div>
                  //               <p className="MODULO"> EUR</p>

                  //             </div>
                  //             <div style={{ display: "flex", marginTop: "-11px" }}>
                  //               <p className="MODULO">CANCELACION: </p>
                  //               <div class="eur2" style={{ marginLeft: "23px" }} > {list.early_cancellation}</div>
                  //               <p className="MODULO"> EUR</p>

                  //             </div>

                  //             <button type="button" onClick={
                  //               () => {
                  //                 stop.current = false;
                  //                 getOfferStatus(list, "Accept", "", index);
                  //               }}
                  //               className="btnacepta">Aceptar Oferta</button>



                  //           </div>
                  //           <div>
                  //             <p className="cardtextb">FECHA  : {list.created_at} </p>
                  //             <p className="cardtextn"> DISPONINILIDAD: <span className="cardtextb">36 meses</span></p>
                  //             <p className="cardtextn"> FRECUENCIA DE<br /> REEMBOLSO: <span className="cardtextb" style={{ marginLeft: "25px" }}> {list.payment_frequency}</span></p>
                  //             <p className="cardtextn"> COBRO POR<br /> RENOVACION: <span className="cardtextb" style={{ marginLeft: "20px" }}>  Si</span></p>
                  //             <div style={{ display: "flex", marginTop: "20px" }}>
                  //               <input type="text" class="eur1"></input>
                  //               <p className="cardtextb"> EUR</p>

                  //             </div>
                  //             <div style={{ display: "flex", marginTop: "27px" }}>
                  //               <p className="MODULO">MANTENIMIENTO: </p>
                  //               <div class="eur2" style={{ marginLeft: "23px" }} > {list.maintenance}</div>
                  //               <p className="MODULO"> EUR</p>

                  //             </div>
                  //             <div style={{ display: "flex", marginLeft: "38px", marginTop: "-11px" }}>
                  //               <p className="MODULO">RETRASO: </p>
                  //               <div class="eur2" style={{ marginLeft: "23px" }} > {list.delay}</div>
                  //               <p className="MODULO"> EUR</p>

                  //             </div>


                  //             <button type="button" onClick={() => {
                  //               stop.current = false;

                  //               swal.fire({
                  //                 title: 'Reason for reject',
                  //                 icon: 'info',
                  //                 showCancelButton: true,
                  //                 confirmButtonText: 'OK',
                  //                 inputAttributes: {
                  //                   required: true
                  //                 },
                  //                 inputValidator: (value) => {
                  //                   if (value.length < 10 || value.length > 100) {
                  //                     return 'Please enter a value between 10 and 100 characters.';
                  //                   }
                  //                 }
                  //               }).then((result) => {
                  //                 if (result.isConfirmed) {
                  //                   const userInput = result.value;
                  //                   getOfferStatus(list, "Reject", userInput, index);
                  //                 }
                  //               });
                  //             }}
                  //               className="btnacepta2">Rechazar Oferta</button>
                  //           </div>
                  //         </div>
                  //       </div>


                  //     </div>

                  //   </div>

                  // )

                  return (
                    <div className="col-md-4" >
                      <div className="card dashcard" style={{height:"540px", overflow:"auto"}}>
                        <br></br>
    
                        <div className="smallcard" style={{marginBottom:"20px"}}>
                          <img className="offerimg" src={images.offer_e} alt='Logo' />
                          <p className="offertext">Oferta : {list.offer_id}</p>
                        </div>
                        <div>
                        {(list['offer_status'] !== null) ? (list.offer_status === "Accept") ?
                          <p style={{ textAlign: "right", padding: "10px", marginTop: "-50px" }}> <span className="badge_use bg-success" style={{ marginTop: "-15px",borderRadius:"6px",height:"25px" }}>Aceptar</span> </p> :
                          <p style={{ textAlign: "right", padding: "10px", marginTop: "-50px" }}> <span className="badge_use bg-danger" style={{ marginTop: "-15px",borderRadius:"6px",height:"25px" }}>Desestimar</span> </p>
                          : null
                        }
                          </div>
                        {/* <p class="loanoffertxt" style={{marginTop:"-49px"}}> {list.type}</p> */}
                        <p className="loanoffertxt" style={{marginTop:"0px"}}> {(list.type === "Loan") ?
                        <span>Prestamo</span>: (list.type === "Credit Line") ?
                      <span>Linea de Credito</span>:
                      <span>Factoring</span>}</p>
                        <p className="loanoffertxt2" style={{marginTop:"20px",marginBottom:"10px",textDecoration:"underline"}}> Titulo del prestamo: {list.loan_title}</p>
    
                        <div className="innercard" style={{paddingBottom:"1px",marginTop:"7px"}}>
    
                          <div style={{ display: "flex"}}>
                              <p className="cardtextn" style={{marginLeft:"5px"}} > IMPORTE: {list.imported}</p>
                              <p className="cardtextn" style={{marginLeft:"45px"}}>FECHA : {list.created_at} </p>
                          </div>
                          <div style={{ display: "flex"  }}>
                              <p className="cardtextn" style={{marginLeft:"5px"}}> TIN: {list.interest_rate}</p>
                              {(list.duration !== null && list.duration !== undefined) &&
                          <p className="cardtextn" style={{ marginLeft: "76px" }}> DISPONINILIDAD: <span className="cardtextb">{list.duration}</span></p>
                        }
                          </div>
                          <div style={{ display: "flex"}}>
                          <div>
                          {(list.permanent !== null && list.permanent !== undefined) &&
                            <p className="cardtextn" style={{ marginLeft: "5px" }}>  FIJO: <span className="cardtextb">{list.permanent}</span></p>
                          }
                          {(list.resource !== null && list.resource !== undefined) &&
                            <p className="cardtextn" style={{ marginLeft: "5px" }}>  Recurso: <span className="cardtextb">{list.resource}</span></p>
                          }
                          <p className="cardtextn" style={{ marginLeft: "5px" }}> GARANTIAS: <span className="cardtextb">{list.guarantees}</span></p>
                        </div>
                              <p className="cardtextn"style={{marginLeft:"37px",marginTop:"10px"}}> FRECUENCIA DE<br /> REEMBOLSO: <span className="cardtextb" style={{ marginLeft: "25px" }}> {list.payment_frequency}</span></p>
                          </div>
                        
                          <div style={{ display: "flex" }}>
                                   
    
    
                          {(list.lack === "Yes") ? (list.lack !== "No") ?
                          <div style={{ display: "flex" }}>
                            {(list.modelo_variable1 !== null && list.modelo_variable1 !== undefined) &&
                              <p className="cardtextn MODULO " style={{ marginLeft: "5px" }}> MODULO</p>
                            }

                            <p>{(list.modelo_variable1 !== null && list.modelo_variable1 !== undefined) && list.modelo_variable1.map((list12, index) => {

                              return (
                                <div>
                                  <p className="cardtextn meses1">
                                    {(list12['percentage']!=null && list12['percentage']!=undefined) ? list12['percentage'] : "0" }%
                                    {   (list12['from_month']!=null && list12['from_month']!=undefined) ? list12['from_month'].match(/\d+/g) : ''}  -   
                                     {(list12['to_month']!=null && list12['to_month']!=undefined) ? list12['to_month'].match(/\d+/g) : ''  }messes

                                  </p>

                                </div>

                              )
                            })}</p> </div> :
                          <p>No data</p>
                          : null
                        }
                             {/* <p className="cardtextn" style={{marginLeft:"20px",paddingTop:"9px"}}> COBRO POR<br /> RENOVACION: <span className="cardtextb" style={{ marginLeft: "20px" }}>  Si</span></p> */}
    
                          </div>
                       
            
                          <div style={{ display: "flex",marginTop:"-7px" }}>
                                <p className="cardtextb" style={{ marginLeft: "23px" }}> EUR</p>
                                <div className="eur1" style={{ marginLeft: "5px",textAlign:"center" }} >{list.imported_sub} </div>
                                
                                <p className="cardtextb" style={{ marginLeft: "81px"}}> EUR</p> 
                                <input type="text" className="eur1" value={list.lack_number} style={{marginLeft:"6px",textAlign:"center"}}></input>
                                
                         
                          </div>
                          <p className="cardtextb" style={{marginLeft:"5px",marginTop:"10px",marginBottom:"10px"}}><u> OTROS CARGOS:</u></p>

         {(convertStringToList(list.add_more_field) !== null) ?

                        convertStringToList(list.add_more_field).map((list1, index) => {
                          return (

                           
                            <div>
                              <div className="row container-fluid" style={{marginLeft:"-30px"}}>
                                <div className="col-md-6">
                                  <tr>
                                    <td>
                                    <p className="MODULO" >APERTURA: </p>
                                    </td>
                                    <td>
                                    <div className="eur2" > {(list1.opening) !== null ?
                                 list1.opening  : ' - '}</div>
                               
                                    </td>
                                    <td>
                                    <p className="MODULO"> EUR</p>
                                    </td>
                                  </tr>
                                  <tr>
                                   <td>
                                   <p className="MODULO" >RETRASO: </p>
                                   </td>
                                   <td>
                                   <div className="eur2" > {(list1.delay) !== null ?
                                  list1.delay : ' - '}</div>
                                   </td>
                                   <td>
                                   <p className="MODULO"> EUR</p>
                                   </td>
                                  </tr>
                                  
                                  </div>
                                  <div className="col-md-6" >
                                    <tr>
                                      <td>
                                      <p className="MODULO" >CANCELACION: </p>
                                      </td>
                                      <td>
                                      <div className="eur2"  > {(list1.early_cancellation) !== null ?
                                  list1.early_cancellation : ' - '}</div>
                                      </td>
                                      <td>
                                      <p className="MODULO"> EUR</p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                      <p className="MODULO" >MANTENIMIENTO: </p>
                                      </td>
                                      <td>
                                      <div className="eur2" > {(list1.maintenance) !== null ?
                                  list1.maintenance : ' - '}</div>
                                      </td>
                                      <td>
                                      <p className="MODULO"> EUR</p>
                                      </td>
                                    </tr>
                                  
                                  </div>
                                </div>
                              {/* <div style={{ display: "flex", marginTop: "-7px" }}>
                                <p className="MODULO" style={{ marginLeft: "5px" }}>APERTURA: </p>
                                <div class="eur2" style={{ marginLeft: "10px", textAlign: "center" }} > {(list1.opening) !== null ?
                                 list1.opening  : ' - '}</div>
                                <p className="MODULO"> EUR</p>

                                <p className="MODULO" style={{ marginLeft: "42px" }}>CANCELACION: </p>
                                <div class="eur2" style={{ marginLeft: "10px", textAlign: "center" }} > {(list1.early_cancellation) !== null ?
                                  list1.early_cancellation : ' - '}</div>
                                <p className="MODULO"> EUR</p>

                              </div>
                              <div style={{ display: "flex", marginTop: "-12px" }}>
                                <p className="MODULO" style={{ marginLeft: "5px" }}>RETRASO: </p>
                                <div class="eur2" style={{ marginLeft: "10px", textAlign: "center" }} > {(list1.delay) !== null ?
                                  list1.delay : ' - '}</div>
                                <p className="MODULO"> EUR</p>
                                <p className="MODULO" style={{ marginLeft: "22px" }}>MANTENIMIENTO: </p>
                                <div class="eur2" style={{ marginLeft: "10px", textAlign: "center" }} > {(list1.maintenance) !== null ?
                                  list1.maintenance : ' - '}</div>
                              <p className="MODULO"> EUR</p>

                              </div> */}
                            </div>
                          )
                        }) : null
                      }
                            <div style={{ display: "flex",marginBottom:"20px",justifyContent:"space-around" }}>
                               <button
                                type="button"
                                onClick={() => {
                                  stop.current = false;
                                  getOfferStatus(list, "Accept", "", index);
                                  toast.success("oferta aceptada con éxito", {
                                    onClose: () => {
                                      // window.location.reload();
                                    },
                                  });
                                }}
                                className="btnacepta"
                              >
                                Aceptar Oferta
                              </button>
                              <button  style={{margin_left:"22px"}}type="button" onClick={() => {
                                stop.current = false;
    
    
                                swal.fire({
                                  title: 'Motivo del rechazo',
                                  icon: 'info',
                                  input: 'textarea',
                                  // inputLabel: 'Reason for reject',
                                  showCancelButton: true,
                                  confirmButtonText: 'OK',
                                  inputAttributes: {
                                    required: true
                                  },
                                  inputValidator: (value) => {
                                    if (value.length < 10 || value.length > 100) {
                                      return 'Please enter a value between 10 and 100 characters.';
                                    }
                                  }
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    const userInput = result.value;
                                    getOfferStatus(list, "Reject", userInput, index);
                                    toast.error("Oferta rechazada con éxito", {
                                      onClose: () => {
                                        // window.location.reload();
                                      },
                                    });
                                  }
                                });
                              }}
                                className="btnacepta2"
                              
                              >Rechazar Oferta</button>
                              {/* : null  } */}
    
    
                            </div>
    
    
                        </div>
                      </div>
                    </div>
                  )
                })
                }


              </div>




              : null
            }

          </section> : null

        }



      </React.Fragment>
      : <Navigate to="/" replace />
  )
}
import React from "react";
import { Navigate } from "react-router-dom";
import { useState, useEffect } from "react";
import * as con from '../repository/urls.js';
import * as apis from '../repository/api_calls.js';
import * as images from './images.jsx';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import UserHeader from "../user/component/header.jsx";


export default function Home() {
  const role_id = localStorage.getItem("role_id");
  const token = localStorage.getItem("token");
  const [userLoanData, setUserLoanData] = useState([]);
  const [activeLoanData, setActiveLoanData] = useState([]);


  // useEffect(() => {
  //   fetch(`${process.env.REACT_APP_BASE_URL}${con.loanOfferList}`, {
  //     method: 'post',
  //     headers: {
  //       "Authorization": `Bearer ${token}`,
  //     }
  //   }).then(res => res.json())
  //     .then(res => {
  //       if (res['status'] === true) {
  //         localStorage.setItem("lender_id", res.data.lender_id);
  //       }
  //     });
  //   getUserLoan();

  // }, [])

  useEffect( () =>  {
    getUserLoan();
    // getLenderLoanDetails();
  },[]);


  // async function getLenderLoanDetails () {
  //   const response = await apis.getLenderLoanOffers(token);
  //   if (response['status'] === true) {
  //             localStorage.setItem("lender_id", response.data.lender_id);
  //           }
  // }

  async function getUserLoan() {
    // get user loan
    const response = await apis.getUserLoan(token);
    if (response['status'] === true) {
      setUserLoanData(response['data']);
      for (let i = 0; i < response['data'].length; i++) {
        if (response['data'][i]['main_status'] === 'Active') {
          setActiveLoanData(response['data'][i]);
        }
      }
    }
  }

  async function rejectLoan() {
    // get user loan
    const response = await apis.rejectLoanDetails(token);
    if (response['status'] === true) {
      setUserLoanData([]);
      window.location.reload();
    }
  }


  return (
    (role_id !== null && role_id === "2") ?
      <React.Fragment>
        <UserHeader />

        <ToastContainer autoClose={3000} />
        <section style={{ backgroundColor: "#FFFFFF", height: "65px", top: "50px", position: "sticky", zIndex: 2 }}>
          <div className="container">
            <div className="row" style={{ padding: 10 }}>
              <div className="col-md-3" style={{ display: "flex" }}>
                <a href="/dashboard" style={{ textDecoration: "none" }}>
                  <p style={{ color: "#183F4B", fontSize: "18px", marginTop: "2px" }}>
                    <span>
                      <img className="" src={images.dash_dark} alt='applica_dark' />
                    </span>
                    <span style={{ textTransform: "capitalize" }}>Panel</span>
                  </p>
                </a>
                <div
                  style={{
                    borderLeft: "2px solid #E0E0E0",
                    height: 45,
                    marginLeft: 80
                  }}
                ></div>
              </div>

              <div className="col-md-3" style={{ display: "flex",marginleft:"-38px" }}>
                <a href="/myscore" style={{ textDecoration: "none" }}>
                  <p style={{ color: "#183F4B", fontSize: "18px", marginTop: "2px" }}>
                    <span>
                      <img className="" src={images.z_score_dark} alt='z_score_dark' />
                    </span>{" "}
                    <span style={{ textTransform: "capitalize" }}>Mi puntaje</span>
                  </p>
                </a>
                <div
                  style={{
                    borderLeft: "2px solid #E0E0E0",
                    height: 45,
                    marginLeft: 80
                  }}
                ></div>
              </div>
              <div className="col-md-3" style={{ display: "flex" }}>
                <a href="/dashboardapp" style={{ textDecoration: "none" }}>
                  <p style={{ fontSize: "18px", marginTop: "2px", marginLeft: "-30px" }}>
                    <span>
                      <img className="" src={images.applica_blue} alt='applica_blue' style={{ marginRight: -1 }} />
                    </span>
                    <span style={{ textTransform: "capitalize" }}>Solicitudes enviadas</span>
                  </p>
                </a>
                <div
                  style={{
                    borderLeft: "2px solid #E0E0E0",
                    height: 45,
                    marginLeft: 80
                  }}
                ></div>
              </div>
              <div className="col-md-3">
                <a href="/dashboardloan" style={{ textDecoration: "none" }}>
                  <p style={{ color: "#183F4B", fontSize: "18px", marginTop: "2px" }} >
                    <span>
                      <img className="" src={images.loan_offer_dark} alt='loan_offer_dark' />
                    </span>
                    <span style={{ textTransform: "capitalize" }}>Ofertas recibidas</span>
                  </p>
                </a>
              </div>




            </div>
          </div>
        </section>

        {(userLoanData.length === 0) ?
          <h4 className="text-center" style={{ fontWeight: 600 }}>No tienes ninguna solicitud de préstamo activa. Por favor visítenos después de presentar su préstamo.</h4>
          : null}

        {(userLoanData.length !== 0) ?
          <section className="container">
            <div className="card" style={{ marginTop: 30, borderRadius: 6 }}>
              <div style={{ display: "flex" }}>
                <div style={{ backgroundColor: "#F2F2F2", width: 110, padding: 10 }}>
                  <img className="" src={images.inverst} alt='inverst' style={{ marginLeft: 30, marginTop: 19 }} />
                </div>

                <div style={{ marginLeft: 50, padding: 10 }}>
                  <div style={{ display: "flex" }}>
                    <p style={{ fontWeight: 670,fontSize:"16px" }}>
                      Los inversores ahora revisarán su solicitud
                    </p>

                    <p style={{ marginLeft: "335px" }}><span className="loapi">identificación del préstamo :</span>
                      {(activeLoanData === null || activeLoanData.loan_ran_id === null) ?
                        " - " :
                        <span className="loapid_api badge bg-primary"> {activeLoanData.loan_ran_id}</span>}</p>


                  </div>


                  <div style={{ display: "flex" }}>
                    <p>
                      Los inversores ahora pueden revisar su solicitud. Si alguien está interesado verá la oferta a continuación.
                    </p>

                    <p style={{ marginLeft: "87px" }}><span className="loapi">Estado principal :</span>
                      {(activeLoanData === null || activeLoanData.main_status === null) ?
                        " - " :
                        // <span className="loapid_api badge bg-success"> {activeLoanData.main_status}</span>}</p>
                        <span className="loapid_api badge bg-success"> {activeLoanData.main_status === "Active" ? 'Activa': "" }</span>}</p>
                  </div>
                </div>


              </div>
            </div>
          </section> : null
        }


        {(userLoanData.length !== 0) ?





          <section className="container" style={{ marginTop: 20 }}>

            <div className="col-md-12 mt-5">
              <div className="card" style={{ padding: 20 }}>
                <h4 className="text-center" style={{ fontWeight: 600 }}>Detalles de su préstamo </h4>


                {userLoanData.map((list) => {
                  return (

                    <div className="row mt-5">
                      <div className="col-md-12">
                        <div className="card">

                          <table className="table">

                            <tbody>

                              <tr>

                                <th>identificación del préstamo</th>
                                <td><span className="badge bg-primary">{list.loan_ran_id}</span></td>

                                <th>
Estado principal</th>
                                <td>

                                  {/* {(list.main_status === 'Active') ?
                                    <span className="badge bg-success">{list.main_status}</span> :
                                    <span className="badge bg-danger">{list.main_status}</span>} */}
                                        {(list.main_status === 'Active') ?
                                    <span className="badge bg-success">Activa</span> :
                                    <span className="badge bg-danger">Inactiva</span>}
                                </td>

                              </tr>
                              <tr>
                                <th>Estado</th>
                                {/* <td>{list.status}</td> */}
                                <td>
                                {(list.status === 'Open') ?
                                    <span >Abierta</span> : (list.status === 'Reject') ?
                                    <span >Desestimar</span>:
                                    (list.status === 'Close') ?
                                    <span >Cerrar</span>:
                                    (list.status === 'Pending') ?
                                    <span >Pendiente</span>:
                                    <span >Revisar</span>
                                    }
                                </td>
                                
                                <th>Plazo del préstamo</th>
                                <td>{list.loan_term}</td>


                              </tr>
                              <tr>
                                <th>Título del préstamo</th>
                                <td>{list.loan_title}</td>
                                <th>Actualizado en</th>
                                <td>{list.updated_at}</td>

                              </tr>
                              <tr>
                                <th>Descripción del préstamo</th>
                                <td>{list.loan_description}</td>
                                <th>Creado en</th>
                                <td>{list.created_at}</td>

                              </tr>

                            </tbody>


                          </table>
                          <div className="my-4" style={{ display: "flex" }}>
                            {/* <button id="" type="submit" className="btn_myscore" style={{ height: "38px", width: "120px", marginTop: 0, fontSize: "13px",marginLeft: "350px", fontWeight: "600" }}>
    Editar
                  </button> */}


                            {(list.main_status === 'Active') ?
                              <button
                              id=""
                              type="submit"
                              className="btn_myscore2"
                              onClick={() => {
                                rejectLoan();
                                toast.success("Autorechazo exitoso", {
                                  // onClose: () => {
                                  //   window.location.reload();
                                  // },
                                });
                              }}
                              style={{
                                height: "38px",
                                width: "120px",
                                display: "block",
                                margin: "0px auto",
                                fontSize: "13px",
                                fontWeight: "600",
                              }}
                            >
                              Desestimar
                            </button>: ""
                            }
                          </div>
                        </div>
                      </div>

                    </div>
                  )
                }
                )}

              </div>
            </div>
          </section>
          : null}





      </React.Fragment>
      : <Navigate to="/" replace />
  )

}
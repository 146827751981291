import * as con from '../repository/urls.js';



/**
 * The loginFunc function is an asynchronous function that sends a POST request to a specified URL with
 * the provided email and password, and returns the response data if successful.
 * @param email - The email parameter is the email address of the user trying to login.
 * @param password - The `password` parameter is the user's password that they entered during the login
 * process. It is used to authenticate the user and grant them access to the system.
 * @returns the data received from the API response.
 */
export const loginFunc = async (email, password) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}${con.login}`, {
            method: 'post',
            body: JSON.stringify({
                email,
                password
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

/**
 * The RegisterFunc function is an asynchronous function that sends a POST request to a specified URL
 * with the provided user details and returns the response data if successful, otherwise throws an
 * error.
 * @param registerUserDetails - The parameter `registerUserDetails` is an object that contains the
 * details of the user to be registered. It should have the following properties:
 * @returns the data received from the server after a successful registration.
 */

{/* -===============================User registration  api */}
export const RegisterFunc = async (registerUserDetails) => {
    try {
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}${con.register}`, {
            method: 'post',
            body: registerUserDetails,
            headers: {
                'Content-Type': 'application/json'
            }
        });
        if (!res.ok) {
            return { "status": false }
        } else {
            const data = await res.json();
            return data;
        }
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}


/**
 * The function `lenderRegisterFunc` is an asynchronous function that sends a POST request to a
 * specified URL with the provided user details and returns the response data if successful, otherwise
 * throws an error.
 * @param registerUserDetails - The `registerUserDetails` parameter is an object that contains the
 * details of the user being registered as a lender. This object should have the following properties:
 * @returns the data received from the API response.
 */

{/* -===============================lender registration */}
export const lenderRegisterFunc = async (registerUserDetails) => {
    try {
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}${con.lenderRegister}`, {
            method: 'post',
            body: registerUserDetails,
            headers: {
                'Content-Type': 'application/json'
            }
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}


/**
 * The function `getUserLoan` is an asynchronous function that fetches user loan data using a token for
 * authorization.
 * @param token - The `token` parameter is a string that represents the authentication token for the
 * user. It is used to authorize the request to fetch the user's loan information.
 * @returns the data fetched from the API endpoint.
 */
export const getUserLoan = async (token) => {
    try {
        const token = localStorage.getItem("token");
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}${con.getloan_user}`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            }
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}


/**
 * The function `postLenderFormData` sends a POST request to a specified URL with a form data and
 * authorization token, and returns the response data.
 * @param token - The `token` parameter is a string that represents the authentication token required
 * to access the API. It is used to authorize the request and ensure that only authenticated users can
 * submit the lender form.
 * @param form - The `form` parameter is the data that you want to send in the request body. It should
 * be in JSON format.
 * @returns the data received from the API response.
 */
export const postLenderFormData = async (token, form) => {
    try {
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}${con.lender_loan_offer}`, {
            method: 'post',
            body: form,
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            }
        });
        if (!res.ok) {
            return { "status": false }
        } else {
            const data = await res.json();
            console.log(data)
            return data;
        }

    } catch (error) {
        // Handle the error
        // console.error(error);
        return { "status": false }
    }
}


/**
 * The function `getUserScore` is an asynchronous function that fetches the user's score using a token
 * for authorization.
 * @param token - The `token` parameter is a string that represents the authentication token for the
 * user. It is used to authorize the request to fetch the user's score.
 * @returns the data fetched from the API, which is the user score.
 */
export const getUserScore = async (token) => {
    try {
        const token = localStorage.getItem("token");
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}${con.Score_user}`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            }
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}


/**
 * The function `offerStatus` sends a POST request to update the status of a lender's offer using the
 * offer ID, status, and authentication token.
 * @param offer_ID - The offer_ID parameter is the ID of the offer that you want to update the status
 * for.
 * @param aboutStatus - The `aboutStatus` parameter is a string that represents the status of the
 * offer. It could be values like "accepted", "rejected", "pending", etc. This parameter is used to
 * update the status of the offer in the backend.
 * @param token - The `token` parameter is a string that represents the authentication token required
 * to access the API. It is used in the `Authorization` header of the fetch request to authenticate the
 * user making the request.
 * @returns the data received from the API call.
 */
export const offerStatus = async (offer_ID, aboutStatus, reason, token) => {
    console.log(`${process.env.REACT_APP_BASE_URL}${con.lenderOfferStatus}`)
    try {
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}${con.lenderOfferStatus}`, {
            method: 'post',
            body: JSON.stringify({
                "offer_id": offer_ID,
                "offer_status": aboutStatus,
                "reason": reason
            }),
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            }
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}


/**
 * The function `getLoanFilledData` is an asynchronous function that fetches user loan data using a
 * token for authorization.
 * @param token - The `token` parameter is a string that represents the authentication token required
 * to access the API. It is used in the `Authorization` header of the HTTP request to authenticate the
 * user and authorize access to the loan filled data.
 * @returns the data fetched from the API call.
 */
export const getLoanFilledData = async (token) => {
    try {
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}${con.loanFilledStatus}`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            }
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}


/**
 * The function `rejectLoanDetails` sends a request to reject a loan using the provided token and
 * returns the response data.
 * @param token - The `token` parameter is a string that represents the authentication token required
 * to make the API request. It is used to authorize the user and ensure that only authenticated users
 * can reject a loan.
 * @returns the data received from the API call if the response is successful. If there is an error
 * during the API call or if the response is not successful, an error message is thrown.
 */
export const rejectLoanDetails = async (token) => {
    try {
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}${con.selfReject}`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            }
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}



/**
 * The function `usersLoanStatusUpdate` is an asynchronous function that updates the loan status of a
 * user by sending a POST request to a specified URL with the loan ID, status, and authorization token.
 * @param id - The id parameter represents the loan ID of the user whose loan status needs to be
 * updated.
 * @param status - The `status` parameter is the loan status that you want to update. It can be a
 * string value representing the new status of the loan.
 * @param token - The `token` parameter is a string that represents the authentication token required
 * to access the API. It is used to authorize the request and ensure that only authenticated users can
 * update the loan status.
 * @returns the data received from the API response.
 */
export const usersLoanStatusUpdate = async (id, status, token) => {
    try {
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}${con.usersLoanStatus}`, {
            method: 'post',
            body: JSON.stringify({
                "loan_id": id,
                "loan_status": status
            }),
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            }
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}


/**
 * The function `userLoanAcceptRejectLoan` sends a POST request to a specified URL with a token in the
 * header, and returns the response data.
 * @param token - The token parameter is a string that represents the authentication token for the
 * user. It is used to authorize the user's request to the server.
 * @returns the data received from the API call.
 */
export const userLoanAcceptRejectLoan = async (token) => {
    try {
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}${con.lenderOfferList}`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            }
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}


/**
 * The function `getComponySectorsList` is an asynchronous function that makes a POST request to a
 * specified URL and returns the response data.
 * @returns the data fetched from the API endpoint.
 */
export const getComponySectorsList = async () => {
    try {
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}${con.companySectorURL}`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}


/**
 * The function `getLenderLoanOffers` makes an API call to retrieve a list of loan offers for a lender
 * using the provided token for authentication.
 * @param token - The `token` parameter is a string that represents the authentication token for the
 * user. It is used to authorize the request to the server.
 * @returns the data received from the API call.
 */
export const getLenderLoanOffers = async (token) => {
    try {
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}${con.loanOfferList}`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            }
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}

/**
 * The function `getDocOperationTax` makes a POST request to a specified URL and returns the
 * response data.
 * @returns the data received from the API call.
 */
export const getDocOperationTax = async () => {
    try {
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}${con.doccorporation_tax}`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                // "Authorization": `Bearer ${token}`,
            }
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}


// to Document corporation_tax
export const getDoccorporation_tax = async () => {
    const res = await fetch(`${process.env.REACT_APP_BASE_URL}${con.doccorporation_tax}`, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            // "Authorization": Bearer ${token},
        }
    });
    return res.json();
}


// ===========blog page api integration 
// ===========blog page api integration 
export const sendSubscribeMail = async (email) => {
    try {
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}${con.subscription}`, {
            method: 'post',
            body: JSON.stringify({ email }),
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}


// ===========guest user credit score api integration 
// ===========guest user credit score api integration 
export const gusercredit = async (email) => {
    try {
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}${con.guestid}`, {
            method: 'post',
            headers: {
                    'Content-Type': 'application/json'
                    }
          });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}




export const getloanpage = async (usersData) => {
    try {
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}${con.getloan_get}`, {
            method: 'post',
            body: usersData,
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}

// -------------- offer detials ----------------------

export const GetUserofferdetail = async (token) => {
    try {
        const token = localStorage.getItem("token");
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}${con.userOfferDetails}`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            }
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}

// ------------------------------------- Score ID -------------------------

export const UserScoreIdAPI = async (user_id, token) => {
    try {
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}${con.dashboard_scoreid}`, {
            method: 'post',
            body: JSON.stringify({
               "user_id": user_id,
            }),
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            }
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}

// ------------------------ creditscore first form -- guest user --------------

export const getscoreGuest = async (jsonbody) => {
    try {
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}${con.creditscoreguest}`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body:jsonbody
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}

// ------------------------ creditscore first form -- register user --------------

export const getScoreRegister = async (jsonbody) => {
    try {
        const token = localStorage.getItem("token");
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}${con.creditscoreregister}`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`
            },
            body:jsonbody,
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}


// ------------------------ creditscore 2,3,4 form -- guest user --------------

export const getCreditscoreGuest = async (jsonbody) => {
    try {
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}${con.creditscoreguestupdate}`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body:jsonbody
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}

// ------------------------ creditscore 2,3,4 form -- register user --------------

export const getCreditscoreRegister = async (jsonbody) => {
    try {
        const token = localStorage.getItem("token");
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}${con.creditscoreregisterupdate}`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            },
            body:jsonbody,
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}



// ------------------------  guest user profile --------------

export const getGestuserProfile = async (jsonbody) => {
    try {
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}${con.creditscoreguestupdate}`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body:jsonbody
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}

// ------------------------  register user profile --------------

export const getRegisteruserProfile = async (jsonbody) => {
    try {
        const token = localStorage.getItem("token");
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}${con.user_profile}`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            },
            body:jsonbody,
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}



// ------------------------  register update user profile --------------

export const UpdateUserProfile = async (jsonbody) => {
    try {
        const token = localStorage.getItem("token");
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}${con.profileupdate}`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            },
            body:jsonbody,
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}


// ------------------------  Dashboard score id --------------

export const getDashboardScore = async (jsonbody) => {
    try {
        const token = localStorage.getItem("token");
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}${con.dashboard_scoreid}`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            },
            body:jsonbody,
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}

// ------------------------  Get loan for register user -----------
export const LoanForRegisterUser = async (jsonbody) => {
    try {
        const token = localStorage.getItem("token");
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}${con.getloan_register}`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
                'headers': { 'content-type': 'multipart/form-data' },
            },
            body:jsonbody,
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}


// ------------------------  guest user loan company form 1  --------------

export const GuestLoanCompany = async (jsonbody) => {
    try {
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}${con.getloan_get2}`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body:jsonbody
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}

// ------------------------  user loan company form 1 register user -----------

export const LoanCompanyRegister1 = async (jsonbody) => {
    try {
        const token = localStorage.getItem("token");
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}${con.company_register2}`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
                'headers': { 'content-type': 'multipart/form-data' },
            },
            body:jsonbody,
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}


// ------------------------  guest user loan company form 2  --------------

export const GuestLoanCompanyUpdate = async (formData) => {
    try {
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}${con.getloan_get2_update}`, {
            method: 'post',
            headers: {
                // 'Content-Type': 'application/json',
            },
            body:formData
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}

// ------------------------  user loan company form 2 register user -----------

export const LoanUpdateRegister1 = async (formData) => {
    try {
        const token = localStorage.getItem("token");
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}${con.getloan_register2}`, {
            method: 'post',
            headers: {
                // 'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
                'headers': { 'content-type': 'multipart/form-data' },
            },
            body:formData,
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}


// ------------------------  Score tip calculation -----------

export const getScoreTipCalculation = async (josinbody) => {
    try {
        const token = localStorage.getItem("token");
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}${con.ScoretipCalculation}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            },
            body:josinbody,
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}



// ------------------------  Loan Offer List -----------

export const getLoanOffer = async () => {
    try {
        const token = localStorage.getItem("token");
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}${con.loanOfferList}`, {
            method: 'post',
            headers: {
                "Authorization": `Bearer ${token}`,
            },
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}


// ------------------------  Lender Offer Accept, reject List -----------

export const getLenderOffer = async () => {
    try {
        const token = localStorage.getItem("token");
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}${con.lenderOfferList}`, {
            method: 'post',
            headers: {
                "Authorization": `Bearer ${token}`,
            },
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}


// ------------------------  Logout  -----------

export const getLogout = async () => {
    try {
        const token = localStorage.getItem("token");
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}${con.logout}`, {
            method: 'post',
            headers: {
                "Authorization": `Bearer ${token}`,
            },
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}


// ------------------------  Lender Profile -----------

export const LenderProfile = async (jsonbody) => {
    try {
        const token = localStorage.getItem("token");
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}${con.updateLenderProfile}`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            },
            body:jsonbody,
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}


// ------------------------   Visitor cta page --------------

export const GuestVisitor = async (jsonbodydata) => {
    try {
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}${con.visitor}`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body:jsonbodydata
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}


// ------------------------  Conform Password -----------

export const ConformPassword = async (jsonbodydata) => {
    try {
        const token = localStorage.getItem("token");
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}${con.confirm_pwd}`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            },
            body:jsonbodydata,
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}


// ------------------------  Reset Password -----------

export const ForgetPasswordAPI = async (jsonbodydata) => {
    try {
        const token = localStorage.getItem("token");
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}${con.forget}`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`,
            },
            body:jsonbodydata,
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}

// ------------------------   Guest user company register  --------------

export const GuestCompany = async (jsonbodydata) => {
    try {
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}${con.guest2}`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body:jsonbodydata
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}


// ------------------------   Guest user Detials  --------------

export const GuestUserDetials = async (jsonbodydata) => {
    try {
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}${con.guest1}`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body:jsonbodydata
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}

import $ from 'jquery';
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as con from '../repository/urls.js';
import * as apis from '../repository/api_calls.js';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import Select, { components } from 'react-select';


export default function Companyregister() {


  const navigate = useNavigate();
  const user_id = localStorage.getItem("id");
  const currentDate = new Date().toISOString().split('T')[0];


  const [company_start_date, setCompany_start_date] = React.useState("");
  const [sector_id, setSector_id] = React.useState("Seleccionar1");
  const [sectorsList, setSectorsList] = React.useState();
  // const [selectedSector, setSelectedSector] = React.useState();

  const fechaRef = React.useRef();
  const sectorRef = React.useRef();



  const handleSubmit = async (event) => {
    event.preventDefault();

    // useEffect(() => {
      getGuestCompany();
    // }, [])
  
    async function getGuestCompany() {
      const response = await apis.GuestCompany(JSON.stringify({
        "company_creation_date": company_start_date,
        "cnae_sector": sector_id,
        "user_id": user_id,
        "company_id": " "
      }),);
      console.log(response)
      if (response['status'] === true) {

        $('#stepper10').addClass('step-done');
        $('#stepper10').removeClass('editable-step');
        $('#stepper20').addClass('active-step');
        $('#stepper20').addClass('editable-step');
        $('#stepper_line10').removeClass('tab1_border_step');
        $('#stepper_line10').addClass('tab1_border_step_green');

        $('#stepper_cir_bod10').removeClass('boder_whi');
        $('#stepper_cir_bod10').addClass('boder_green');

        activeStep($('#step20'));
        toast.success(response['message'], {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo_bar_log_Success'
        });
        setTimeout(() => {
          // navigate('/user/company_detail');
        }, 800);
        // window.location.href = "/user/company_detail";
      }
      else {
        toast.error(response['message'], {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo_bar_log_error'
        });
      }
    }
  
    // fetch(`${process.env.REACT_APP_BASE_URL}${con.guest2}`, {
    //   method: 'post',
    //   body: JSON.stringify({
    //     "company_creation_date": company_start_date,
    //     "cnae_sector": sector_id,
    //     "user_id": user_id,
    //     "company_id": " "
    //   }),
    //   headers:
    //   {
    //     'Content-Type': 'application/json',
    //   }
    // }
    // ).then(async response => {
    //   const result = await response.json();
    //   console.log(result);

    //   if (result['status'] === true) {

    //     $('#stepper10').addClass('step-done');
    //     $('#stepper10').removeClass('editable-step');
    //     $('#stepper20').addClass('active-step');
    //     $('#stepper20').addClass('editable-step');
    //     $('#stepper_line10').removeClass('tab1_border_step');
    //     $('#stepper_line10').addClass('tab1_border_step_green');

    //     $('#stepper_cir_bod10').removeClass('boder_whi');
    //     $('#stepper_cir_bod10').addClass('boder_green');

    //     activeStep($('#step20'));
    //     toast.success(result['message'], {
    //       position: toast.POSITION.TOP_RIGHT,
    //       className: 'foo_bar_log_Success'
    //     });
    //     setTimeout(() => {
    //       // navigate('/user/company_detail');
    //     }, 800);
    //     // window.location.href = "/user/company_detail";
    //   }
    //   else {
    //     toast.error(result['message'], {
    //       position: toast.POSITION.TOP_RIGHT,
    //       className: 'foo_bar_log_error'
    //     });
    //   }
    // }).catch(error => {
    //   console.error('There was an error!', error);
    // });
  }

  useEffect(() => {
    getSectors()
  }, [])

  async function getSectors() {
    //get loan fill details
    const response = await apis.getComponySectorsList();
    console.log(response)
    if (response['status'] === true) {
      setSectorsList(response['data'])
      // setSelectedSector(response['data'][0])
    }
    console.log("comapny filled data");
    console.log(response)
  }




  // -------------- stepper - 2------------

  // const role_id = localStorage.getItem("role_id");
  const [name, setName] = React.useState("");
  const [surname, setSurname] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [company_name, setCompany_name] = React.useState("");
  const nameRef = React.useRef();
  const surnameRef = React.useRef();
  const emailRef = React.useRef();
  const company_nameRef = React.useRef();

  const addGuest = async (event) => {
    event.preventDefault();


    // useEffect(() => {
      getGuestCompany();
    // }, [])
  
    async function getGuestCompany() {
      const response = await apis.GuestUserDetials(JSON.stringify({
        "name": name,
        "surname": surname,
        "company_name": company_name,
        "email": email,
        "user_id": localStorage.getItem("id")
      }),);
      console.log(response)
      if (response['status'] === true) {
        toast.success(response['message'], {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo_bar_log_Success'
        });
        setTimeout(() => {
          navigate('/guest_conformation');
        }, 800);
      }
      else {
        toast.error(response['message'], {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo_bar_log_error'
        });
      }
    }
  


    // fetch(`${process.env.REACT_APP_BASE_URL}${con.guest1}`, {
    //   method: 'post',
    //   body: JSON.stringify({
    //     "name": name,
    //     "surname": surname,
    //     "company_name": company_name,
    //     "email": email,
    //     "user_id": localStorage.getItem("id")
    //   }),
    //   headers: {
    //     'Content-Type': 'application/json',
    //   }
    // }
    // ).then(async response => {
    //   var res = await response.json();
    //   console.log(res)


    //   if (res['status'] === true) {
    //     toast.success(res['message'], {
    //       position: toast.POSITION.TOP_RIGHT,
    //       className: 'foo_bar_log_Success'
    //     });
    //     setTimeout(() => {
    //       navigate('/guest_conformation');
    //     }, 800);
    //   }
    //   else {
    //     toast.error(res['message'], {
    //       position: toast.POSITION.TOP_RIGHT,
    //       className: 'foo_bar_log_error'
    //     });
    //   }
    // }).catch(error => {
    //   console.error('There was an error!', error);
    // });
  
  }


  // --------------------------- select box dropdown icon---------
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <span style={{ paddingRight: '5px' }}>&#9662;</span>
      </components.DropdownIndicator>
    );
  };



  return (
    <React.Fragment>
      <section style={{ overflowX: "hidden" }}>
        <div className="">
          <div className="row">
            {/* <div className="col-md-4 tb_col_bg1 container" style={{ height: "100vh" }}>
              <h3 className="my-5" style={{ color: "white", textAlign: "center" }}>
                <span style={{ textTransform: "capitalize" }}>  Registrarme</span>

              </h3> */}
                 <div className="col-md-3 tb_cred_bg container" id="sideform" >
                <h3 className="my-5 cre_left_st" >
                  <span className="formspan">Registrarme</span>
                </h3>
              <div className="mdl-card mdl-shadow--2dp ">
                <div className="mdl-card__supporting-text " id="stepform1" >
                  <div className="mdl-stepper-vertical-alternative">
                    <div
                      id="stepper10"
                      className="mdl-stepper-step active-step editable-step "
                    >
                      <div style={{ display: "flex" }}>

                        <div id="stepper_cir_bod10" className="mdl-stepper-circle boder_whi">
                          <span>1</span>
                        </div>
                        <p style={{ color: "white", marginLeft: 15, fontSize: 18 }}>
                          Información de la <br /> Empresa
                        </p>
                      </div>
                      <div id="stepper_line10" className="tab1_border_step">
                      </div>
                    </div>
                    <div id="stepper20" className="mdl-stepper-step">
                      <div style={{ display: "flex" }}>
                        <div id="stepper_cir_bod20" className="mdl-stepper-circle boder_whi">
                          <span>2</span>
                        </div>
                        <div style={{ display: "grid" }}>
                          <p className="tb_p2">Persona Responsable</p>
                          <p style={{ marginLeft: 12, marginTop: "-10px", color: "#828282" }}>
                            <span style={{ textTransform: "capitalize" }}> Company </span> owner
                          </p>
                        </div>

                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8 steps">
              <div className="container">
                {/* VERTICAL STEPPER */}
                {/* end VERTICAL STEPPER */}
                {/* STEPS */}

                <ToastContainer autoClose={3000} />
                <div className="stepper">
                  <div id="step10" className="step">
                    <div className="ta_1_dpa">
                      <h3 className="ta_1_h1">Información de la empresa</h3>

                      <form method="post" onSubmit={handleSubmit}>

                        <label className="ta_lab">Fecha de creación de la empresa</label>
                        <input
                          type="date"
                          className="form-control ta_inpu"
                          placeholder=""
                          required
                          id=""
                          ref={fechaRef}
                          onChange={(e) => setCompany_start_date(e.target.value)}
                          value={company_start_date}
                          max={currentDate}
                        />
                        <div>
                          <p style={{ marginTop: 30, fontWeight: 600, fontSize: 14 }}>
                            Sector CNAE
                          </p>
                          {(sectorsList != null) ?
                            // <select
                            //   className="form-select ta_inpu"
                            //   id="sel10"
                            //   style={{ fontSize: 14, height: 34, width: "100%" }}
                            //   required
                            //   ref={sectorRef}
                            //   onChange={(e) => setSector_id(e.target.value)}
                            // >

                            //   {sectorsList.map((list, index) =>
                            //     <option value={list['sector_id']}>{list['sector']}</option>
                            //   )}

                            // </select> 

                            <Select
                              id="sel10"
                              options={sectorsList.map((list) => ({
                                value: list['sector_id'],
                                label: list['sector'],
                              }))}
                              components={{ DropdownIndicator }}
                              styles={{
                                control: (provided) => ({
                                  ...provided,
                                  width: '60%',
                                  fontSize: 14,
                                  height: 34,
                                }),
                                menu: (provided) => ({
                                  ...provided,
                                  maxWidth: '580px',
                                }),
                              }}
                              required
                              ref={sectorRef}
                              onChange={(selectedOption) => setSector_id(selectedOption.value)}
                            />
                            : null}
                        </div>
                        <div style={{ display: "flex", marginTop: 20, marginBottom: 50 }}>
                          <button
                            type="button"
                            id=""
                            className="btn cresc_bt"
                          >
                            ATRÁS
                          </button>
                          <button type="submit" id="next10" className="btn cresc_bt1">
                            SIGUIENTE
                          </button>
                        </div>
                      </form>
                    </div>

                  </div>


                  {/* ------------------  stepper - 2 --------------------------- */}


                  <div id="step20" className="step hide">
                    <div className="ta_1_dpa">
                      <h3 className="ta_1_h1">Persona responsable</h3>
                      <form className="formguest" method="post" onSubmit={addGuest}>
                        <label className="ta_lab">Nombre </label>
                        <input
                          type="text"
                          className="form-control ta_inpu"
                          placeholder="Nombre"
                          id=""
                          pattern="^[A-Za-z0-9]{3,16}$"
                          required
                          ref={nameRef}
                          onChange={(e) => setName(e.target.value)} value={name} />


                        <label className="ta_lab">Apellido</label>
                        <input
                          type="text"
                          className="form-control ta_inpu"
                          placeholder="Apellido"
                          id=""
                          ref={surnameRef}
                          required
                          onChange={(e) => setSurname(e.target.value)} value={surname} />
                        <label className="ta_lab">Nombre de la empresa</label>
                        <input
                          type="text"
                          className="form-control ta_inpu"
                          placeholder="Nombre de la empresa"
                          id=""
                          required
                          ref={company_nameRef}
                          onChange={(e) => setCompany_name(e.target.value)} value={company_name} />
                        <label className="ta_lab"> Correo electrónico</label>
                        <input
                          type="email"
                          className="form-control ta_inpu"
                          placeholder=" Correo electrónico"
                          id=""
                          required
                          ref={emailRef}
                          onChange={(e) => setEmail(e.target.value)} value={email} />
                        <div style={{ display: "flex", marginTop: 20, marginBottom: 50 }}>

                          <button
                            type="button"
                            id="previous20"
                            className="btn cresc_bt"
                          >
                            ATRÁS
                          </button>

                          <button type="submit" id="" className="btn cresc_bt1" >
                            FINALIZAR
                          </button>

                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                {/* end STEPS */}
              </div>
            </div>
          </div>
        </div>
      </section>



    </React.Fragment>
  )
}

$(document).ready(function () {
  $('#next10').click(function () {

  });
  $('#next20').click(function () {
    $('#stepper20').addClass('step-done');
    $('#stepper20').removeClass('editable-step');
    $('#stepper30').addClass('active-step');
    $('#stepper30').addClass('editable-step');
    $('#stepper_line20').removeClass('tab1_border_step');
    $('#stepper_line20').addClass('tab1_border_step_green');

    $('#stepper_cir_bod20').removeClass('boder_whi');
    $('#stepper_cir_bod20').addClass('boder_green');
    activeStep1($('#step30'));
  });
  $('#next30').click(function () {
    $('#stepper30').addClass('step-done');
    $('#stepper30').removeClass('editable-step');
    $('#stepper40').addClass('active-step');
    $('#stepper40').addClass('editable-step');
    $('#stepper_line30').removeClass('tab1_border_step');
    $('#stepper_line30').addClass('tab1_border_step_green');

    $('#stepper_cir_bod30').removeClass('boder_whi');
    $('#stepper_cir_bod30').addClass('boder_green');
    activeStep1($('#step40'));
  });
  $('#finish').click(function () {
    $('#stepper40').addClass('step-done');
    $('#stepper40').removeClass('editable-step');

    $('#stepper_cir_bod40').removeClass('boder_whi');
    $('#stepper_cir_bod40').addClass('boder_green');
  });
  $('#previous20').click(function () {
    $('#stepper10').addClass('step-done');
    $('#stepper10').removeClass('editable-step');
    $('#stepper20').removeClass('active-step');
    $('#stepper20').removeClass('editable-step');

    $('#stepper_line10').addClass('tab1_border_step');
    $('#stepper_line10').removeClass('tab1_border_step_green');

    $('#stepper_cir_bod10').removeClass('boder_green');
    $('#stepper_cir_bod10').addClass('boder_whi');
    $('#stepper_cir_bod20').removeClass('boder_green');
    $('#stepper_cir_bod20').addClass('boder_whi');

    activeStep1($('#step10'));
  });
  $('#previous3').click(function () {
    $('#stepper20').addClass('step-done');
    $('#stepper20').removeClass('editable-step');
    $('#stepper30').removeClass('active-step');
    $('#stepper30').removeClass('editable-step');

    $('#stepper_line20').addClass('tab1_border_step');
    $('#stepper_line20').removeClass('tab1_border_step_green');

    $('#stepper_line30').addClass('tab1_border_step');
    $('#stepper_line30').removeClass('tab1_border_step_green');

    $('#stepper_cir_bod30').removeClass('boder_green');
    $('#stepper_cir_bod30').addClass('boder_whi');
    $('#stepper_cir_bod20').removeClass('boder_green');
    $('#stepper_cir_bod20').addClass('boder_whi');

    activeStep1($('#step20'));
  });
  $('#previous40').click(function () {
    $('#stepper30').addClass('step-done');
    $('#stepper30').removeClass('editable-step');
    $('#stepper40').removeClass('active-step');
    $('#stepper40').removeClass('editable-step');

    $('#stepper_line40').addClass('tab1_border_step');
    $('#stepper_line40').removeClass('tab1_border_step_green');

    $('#stepper_cir_bod40').removeClass('boder_green');
    $('#stepper_cir_bod40').addClass('boder_whi');
    $('#stepper_cir_bod30').removeClass('boder_green');
    $('#stepper_cir_bod30').addClass('boder_whi');

    activeStep1($('#step30'));
  });

  //   function activeStep(stepDiv) {
  //     $('.step').addClass('hide');
  //     stepDiv.removeClass('hide');
  //   }
  // });


  function activeStep1(stepDiv) {
    $('.step').addClass('hide');
    stepDiv.removeClass('hide');
  }

});
function activeStep(stepDiv) {
  $('.step').addClass('hide');
  stepDiv.removeClass('hide');
}
import React, { useState } from "react";
import {Navigate } from "react-router-dom";
import * as images from '../pages/images.jsx';
import { useNavigate, useLocation } from "react-router-dom";
import { Tabs, Tab } from "@material-ui/core";
import * as apis from "../repository/api_calls.js";
import CircleProgressBar from "../circle/circle.jsx";
// import * as con from '../repository/urls.js';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import LenderHeader from "./component/header.jsx";
import LoanOffer from "./component/loan_offer.jsx";
import CreditLineOffer from "./component/creditline_offer.jsx";
import FactoringOffer from "./component/factoring_offer.jsx";


export default function Lender() {

  const location = useLocation();
  const userData = location.state;
  const role_id = localStorage.getItem("role_id");
  const creditScoreStatus = localStorage.getItem("creditScore_status");

  const navigate = useNavigate();

  async function logout() {
    const response = await apis.getLogout();
    console.log(response)
    if (response['status'] === true) {
      toast.success(response['message'], {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo_bar_log_Success'
      });
      setTimeout(() => {
        localStorage.clear();
        window.location.reload();
        navigate('/dashboard');
      }, 800);

    } else {
      toast.error(response['message'], {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo_bar_log_error'
      });
    }
}

  // function logout() {
  //   fetch(`${process.env.REACT_APP_BASE_URL}${con.logout}`, {
  //     method: 'POST',
  //     headers: {
  //       "Authorization": `Bearer ${token}`,
  //     }
  //   })
  //     .then(async response => {
  //       const result = await response.json();
  //       if (result['status'] === true) {
  //         toast.success(result['message'], {
  //           position: toast.POSITION.TOP_RIGHT,
  //           className: 'foo_bar_log_Success'
  //         });
  //         setTimeout(() => {
  //           localStorage.clear();
  //           window.location.reload();
  //           navigate('/dashboard');
  //         }, 800);

  //       } else {
  //         toast.error(result['message'], {
  //           position: toast.POSITION.TOP_RIGHT,
  //           className: 'foo_bar_log_Success'
  //         });
  //       }
  //     })
  //     .catch(error => {
  //     });
  // }

  const [value, setValue] = React.useState(0);


  const handleTabs = (e, val) => {
    console.warn(val);
    setValue(val);
  };

  function formatDate(data) {
    // const datetime = new Date('2023-06-26 03:16:00');
    // const formattedDate = format(datetime, 'dd/MM/yyyy');
    const split = data.split(" ");
    return split[0];
  }

  const token = localStorage.getItem("token");




  return (
    ((role_id !== null && role_id === "3") && (creditScoreStatus !== null)) ?
      <React.Fragment>

        <div>
          <LenderHeader />
        </div>

        <section style={{ overflowX: "clip" }}>
          <div className="row">
            <div className="col-md-2 ">
              <div
                className="card"
                style={{
                  height: "90vh",
                  width: "100%",
                  zIndex: 1,
                  position: "sticky",
                  top: 55,
                }}
              >


                <img className="" src={images.profile} alt='profile' style={{ width: "90px", height: "90px", display: "block", margin: "0 auto" }} />
                <p style={{ textAlign: "center", fontWeight: "500" }}>
                Nombre del inversor
                </p>
                <p
                  style={{
                    textAlign: "center",
                    marginTop: "-10px",
                    fontSize: "13px",
                  }}
                >

                  {localStorage.getItem("firstname") + " " + localStorage.getItem("lastname")}
                </p>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "30px",
                  }}
                >
                  <img className="" src={images.pro_icon} alt='pro_icon' style={{ fontSize: "13px" }} />
                  <a href="/lender/lenderdashbo">
                    <p
                      style={{
                        color: "#828282",
                        fontSize: "13px",
                        marginTop: "4px",
                        marginLeft: "7px",
                        paddingTop: "9px",
                      }}
                    >
                      Panel
                    </p>
                  </a>
                </div>

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img className="" src={images.pro_icon} alt='pro_icon' style={{ fontSize: "13px" }} />
                  <a href="/lender/lender_profile">
                    <p
                      style={{
                        color: "#828282",
                        fontSize: "13px",
                        marginTop: "4px",
                        paddingLeft: "9px",
                        paddingTop: "9px",
                      }}
                    >
                      Mi perfil
                    </p>
                  </a>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginLeft: "10px",
                    marginTop: "90px",
                  }}
                >
                  <button type="submit" className="lender_logbut" onClick={logout}>Cerrar sesión</button>
                </div>
              </div>
            </div>

            <div className="col-md-10" style={{ backgroundColor: "white" }}>
              <div
                className="container"
                style={{
                  backgroundColor: "white",
                  width: "95%",
                  marginLeft: "-8px",
                  marginTop: "5px",
                }}
              >
                <div className="row" style={{ marginTop: "30px" }}>
                  <div className="col-md-2">
                    <img className="" src={images.profile} alt='profile' style={{
                      marginLeft: "28%",
                      width: "100px",
                      height: "100px",
                      borderRadius: "50%",
                      objectFit: "cover",
                      alignSelf: "center",
                      marginTop: "11px",
                    }} />
                  </div>
                  <div className="col-md-2">
                    <p
                      style={{
                        marginTop: "40px",
                        marginLeft: "-30px",
                        fontWeight: "600",
                      }}
                    >
                      Nombre de empresa
                    </p>
                    {(userData === null) ? null :
                      <p style={{ marginTop: "-9px", marginLeft: "-30px" }}>
                        {userData['company_name']}
                      </p>
                    }
                  </div>
                  <div className="col-md-4"></div>
                  <div className="col-md-4">
                    <div className="">
                      <div style={{ marginTop: "30px" }}>
                        {(userData === null) ? null :
                          <CircleProgressBar percentage={userData['user_score']} />}
                      </div>
                    </div>
                  </div>

                  {/*========== two button */}

                  <div style={{ display: "flex" }}>
                    <div id="myDIV">
                      <h4>Your alredy send an offer to this SME</h4>
                    </div>
                  </div>
                  {(userData === null) ? null :
                    <div
                      style={{
                        backgroundColor: "#f2f2f2",
                        height: "130px",
                        width: "80%",
                        marginTop: "20px",
                        display: "flex",
                        marginLeft: "-7px",
                        justifyContent: "space-around",
                      }}
                    >
                      <div style={{ marginTop: "30px" }}>
                        <p>Cantidad:</p>
                        <b style={{ fontSize: "20px" }}>{userData['loan_amount']} EUR</b>
                      </div>
                      <div style={{ marginTop: "30px" }}>
                        <p>Término:</p>
                        <b style={{ fontSize: "20px" }}>{userData['loan_term']}</b>
                      </div>
                      <div style={{ marginTop: "30px" }}>
                        <p>Fecha:</p>
                        <b style={{ fontSize: "20px" }}>{formatDate(userData['loan_created_at'])}</b>
                      </div>
                      <div style={{ marginTop: "40px" }}>
                        <p>ID de préstamo : {userData['loan_ran_id']}</p>
                      </div>
                    </div>}
                </div>
              </div>

              <Tabs
                className="mt-5"
                value={value}
                onChange={handleTabs}
                TabIndicatorProps={{
                  style: { height: 0 },
                }}
              >
                <Tab
                  label="Prestamo"
                  style={{
                    border: "solid 2px #183F4B",
                    borderRadius: 8,
                    fontSize: 12,
                  }}
                ></Tab>
                <Tab
                  className="ms-5"
                  label="Linea de Credito"
                  style={{
                    border: "solid 2px #183F4B",
                    borderRadius: 8,
                    fontSize: 12,
                  }}
                ></Tab>
                <Tab
                  className="ms-5"
                  label="Factoring"
                  style={{
                    border: "solid 2px #183F4B",
                    borderRadius: 8,
                    fontSize: 12,
                  }}
                ></Tab>
                {/* <Tab
                className="ms-5"
                label="Renting"
                style={{
                  border: "solid 2px #183F4B",
                  borderRadius: 8,
                  fontSize: 12,
                }}
              ></Tab>
              <Tab
                className="ms-5"
                label="Other"
                style={{
                  border: "solid 2px #183F4B",
                  borderRadius: 8,
                  fontSize: 12,
                }}
              ></Tab> */}
              </Tabs>
              <ToastContainer autoClose={3000} />
               {/*---------------------------- tab1 */}
              <TabPanel value={value} index={0}>
               <LoanOffer />
              </TabPanel>
              {/*---------------------------- tab2 */}
              <TabPanel value={value} index={1}>
              <CreditLineOffer />
              </TabPanel>
              {/*---------------------------- tab3 */}
              <TabPanel value={value} index={2} style={{}}>
            <FactoringOffer />
              </TabPanel>

            </div>
          </div>
        </section>
      </React.Fragment>
      : <Navigate to="/" replace />
  );
}

function TabPanel(props) {
  const { children, value, index } = props;
  return <div>{value === index && <h1>{children}</h1>}</div>;
}
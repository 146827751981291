import React from "react";
import { Link } from "react-router-dom";
import * as images from './images.jsx';
import UserHeader from "../user/component/header.jsx";

export default function Home() {
  return (
    <React.Fragment>

      <UserHeader />

      <section className="warningbg">
        <div className="container">
          <div className="row" >
            <div className="col-md-12  "  >
              <h1>Política de Privacidad</h1>
              <h4><strong>&iquest;Qui&eacute;n es el responsable del tratamiento de tus datos personales?</strong></h4>
              <p>Credit Store Ltd, con Registry No. 14413783, que dispone de un email de contacto a estos efectos que es info@creditstore.net</p>
              <h4><strong>&iquest;Qu&eacute; datos personales necesitamos?</strong></h4>
              <p>Desde que contactas con Credit Store, ya sea porque solicitas informaci&oacute;n sobre nuestros productos o servicios o porque deseas ser nuestro cliente, necesitamos tratar tus datos personales. Con car&aacute;cter general, necesitaremos tratar tus datos de identificaci&oacute;n y contacto, tus datos bancarios si eres cliente, y tus datos de navegaci&oacute;n en nuestra plataforma, as&iacute; como otros datos necesarios para llevar a cabo los fines que te describiremos en esta cl&aacute;usula.</p>
              <p>Tus datos identificativos, tus datos de contacto, los referentes a tu actividad profesional y cualesquiera otros que puedan ser exigidos por la normativa aplicable, son obligatorios por lo que la negativa a facilitarlos o cederlos, en el caso de que resulte preciso, har&iacute;a que nos resultara imposible prestarte los servicios que hubieras solicitado o contratado.</p>
              <p>Los datos personales facilitados deben ser veraces, completos, exactos y actualizados, por lo que cualquier modificaci&oacute;n en los mismos debes comunicarla sin dilaci&oacute;n. Esto es de especial relevancia en el caso de cambios de domicilio, direcci&oacute;n electr&oacute;nica o tel&eacute;fono de contacto, o cambios en la estructura de control o propiedad de la entidad a la que en tu caso representes, pues Credit Store puede enviarte comunicaciones de tu inter&eacute;s que tienen plena validez y eficacia si se efect&uacute;an a las direcciones y tel&eacute;fonos que nos hayas facilitado. Credit Store se reserva el derecho a actualizar tus datos con informaci&oacute;n obtenida de fuentes distintas a ti en los casos en que la normativa lo autorice. Te informamos de que Credit Store utilizar&aacute; tus n&uacute;meros de tel&eacute;fono m&oacute;vil y tu correo electr&oacute;nico para informarte, puntualmente, sobre cualquier aspecto relacionado con tu operativa con Credit Store as&iacute; como de que la informaci&oacute;n relativa a tus operaciones te ser&aacute; remitida por email, pudiendo, no obstante revocar, esta opci&oacute;n en cualquier momento.</p>
              <h4><strong>&iquest;C&oacute;mo obtenemos tus datos personales?</strong></h4>
              <p>Los habr&aacute;s proporcionado directamente o habremos accedido a los mismos a ra&iacute;z de tus consultas, solicitudes, o contrataciones de productos de Credit Store, operaciones o transacciones que hayas realizado con nosotros, por tu navegaci&oacute;n en sitios Web propios o de terceros, o que hayamos obtenido de otros canales igualmente leg&iacute;timos, como las fuentes publicas de datos mercantiles. En algunos casos, y siempre en el &aacute;mbito del tipo de servicios que te prestamos, podremos recopilar datos sobre ti que podr&aacute;n ser facilitados por terceros (por ejemplo, acceso a datos financieros en otras entidades) y obtenidos de otras fuentes, siempre que nos hayas prestado tu consentimiento para este supuesto y con esta finalidad. En caso de que facilites los datos de un segundo interviniente en la operativa que mantienes con nosotros trataremos los mismos de la forma indicada en esta informaci&oacute;n.</p>
              <h4><strong>&iquest;Cu&aacute;l es la finalidad del tratamiento y qu&eacute; legitimaci&oacute;n tenemos para hacerlo?</strong></h4>
              <p>Tratamos tus datos de identificaci&oacute;n y contacto con la finalidad de atender tu solicitud de informaci&oacute;n sobre nuestros productos o servicios, y si formalizas la contrataci&oacute;n de cualquiera de los mismos, necesitaremos tratar, adem&aacute;s, documentaci&oacute;n que acredite tu identidad y, en su caso, tu relaci&oacute;n con la empresa a la que representas. El tratamiento de estos datos es necesario para atender tu petici&oacute;n de informaci&oacute;n o, en tu caso, para la necesaria gesti&oacute;n de la contrataci&oacute;n solicitada y prestaci&oacute;n de los servicios correspondientes.</p>
              <p>Credit Store no necesita acceder a datos de menores de edad u otros de especial protecci&oacute;n.</p>
              <p>Credit Store podr&aacute; elaborar perfiles de cliente en relaci&oacute;n con nuestros productos para lo que podremos utilizar informaci&oacute;n derivada de los datos obtenidos en el seno de tu relaci&oacute;n comercial con Credit Store, o con terceros que hayamos recopilado, entre otros, del uso que hagas de nuestros sitios Web o de aplicaciones para dispositivos m&oacute;viles, u otros canales igualmente leg&iacute;timos. Estos datos que Credit Store recopila y trata incluir&aacute;n, entre otros, los relativos a las transacciones que realices a trav&eacute;s de, o se reflejen en los instrumentos y productos de Credit Store, los datos de tus contratos o de tus operaciones y se limitar&aacute;n al periodo de transacciones y datos estrictamente necesario. No obstante, puedes oponerte a este concreto tratamiento en cualquier momento comunic&aacute;ndolo a Credit Store a trav&eacute;s de la direcci&oacute;n electr&oacute;nica info@creditstore.net, previa acreditaci&oacute;n de tu identidad. En ning&uacute;n caso se vender&aacute;n tus datos personales a terceros.</p>
              <p>Para ofrecerte informaci&oacute;n, ofertas y contenidos personalizados seg&uacute;n tus preferencias y necesidades, en la forma de comunicaciones comerciales, ya sea a trav&eacute;s de nuestra Web, aplicaci&oacute;n m&oacute;vil, correo electr&oacute;nico, contacto telef&oacute;nico o postal u otros canales leg&iacute;timos, trataremos tus datos de identificaci&oacute;n y de contacto. En el caso del marketing telef&oacute;nico, podremos ponernos en contacto contigo con el fin de conocer tu nivel de satisfacci&oacute;n con nuestros productos. De conformidad con el art&iacute;culo 21 de la Ley 34/2002 de Servicios de la Sociedad de la Informaci&oacute;n, si eres nuestro cliente y mantienes vigente una relaci&oacute;n contractual con nosotros, estamos legalmente autorizados para enviarte comunicaciones comerciales, a trav&eacute;s de correo electr&oacute;nico u otro medio electr&oacute;nico equivalente (por ejemplo SMS, notificaciones push), relativas a productos o servicios similares a los que hayas contratado con nosotros; en todos los dem&aacute;s casos procederemos a recabar tu consentimiento expreso. Si no deseas que te remitamos m&aacute;s comunicaciones comerciales, ni/o que nos pongamos en contacto contigo telef&oacute;nicamente con los fines arriba detallados, puedes solicitarlo a trav&eacute;s de la direcci&oacute;n info@creditstore.net, o mediante el enlace habilitado al efecto en las propias comunicaciones que te enviemos.</p>
              <p>Para cumplir con obligaciones legales en materia fiscal, contable, de prevenci&oacute;n de fraude o blanqueo de capitales, seguridad social u otras impuestas por Organismos de Supervisi&oacute;n o Autoridades de Control, ser&aacute; necesario tambi&eacute;n tratar tus datos de identificaci&oacute;n, contacto, financieros, u otros relativos a tu perfil personal. La finalidad de estos tratamientos responde a necesidades de verificaci&oacute;n de identidad, actividad, edad , prevenci&oacute;n de fraude y blanqueo de capitales, presentaci&oacute;n de informes o reportes necesarios a organismos de supervisi&oacute;n y cumplimiento de obligaciones de informaci&oacute;n en materia fiscal.</p>
              <p>Para efectuar otros tratamientos distintos a los anteriores puede ser necesario obtener tu consentimiento expreso en cuyo caso ser&aacute;s informado de ello, con claridad y detalle, en el momento en que sea preciso obtenerlo, pudiendo denegarlo en ese momento, o revocarlo, con posterioridad y en cualquier momento, sin car&aacute;cter retroactivo</p>
              <h4><strong>&iquest;Cu&aacute;nto tiempo mantendremos tus datos personales?</strong></h4>
              <p>Conservaremos tus datos mientras se mantenga la finalidad para la que han sido recabados. Cuando ya no sea necesario para tal fin, mantendremos bloqueados aquellos datos que puedan resultar necesarios durante los plazos legalmente establecidos para atender cualquier cuesti&oacute;n relativa a su tratamiento, hasta un m&aacute;ximo de 4 a&ntilde;os por las obligaciones en materia fiscal y de 10 a&ntilde;os con car&aacute;cter general por las obligaciones en materia de blanqueo de capitales. Transcurridos los plazos legales, se suprimir&aacute;n los datos personales adoptando las medidas de seguridad adecuadas que garanticen la destrucci&oacute;n total de los mismos.</p>
              <h4><strong>&iquest;Con quien compartimos los datos?</strong></h4>
              <p>Compartiremos tus datos con proveedores de servicios cuya actividad es necesaria para prestarte el servicio y cumplir con las obligaciones contractuales que hayamos asumido contigo:</p>
              <p>Terceras empresas o proveedores de servicios en cuanto sea necesaria su intervenci&oacute;n para la prestaci&oacute;n del servicio y que act&uacute;an, en todo caso, de conformidad con las instrucciones emitidas por Credit Store para el adecuado tratamiento de tus datos. A t&iacute;tulo de ejemplo, nos referimos a entidades que prestan servicios de correo electr&oacute;nico y gesti&oacute;n de documentaci&oacute;n como Google, servicios de gesti&oacute;n de deudas en caso de incumplimiento de pago, servicios de Hosting y soporte inform&aacute;tico, como Amazon, etc. En todos los casos, te garantizamos que el acceso de los mismos a los datos ser&aacute; el imprescindible para los fines encargados por Credit Store, que, en todo caso, ser&aacute;n los descritos en la presente cl&aacute;usula.</p>
              <p>Finalmente, con organismos de la Administraci&oacute;n P&uacute;blica para el cumplimiento de obligaciones legalmente exigidas, como la Agencia Tributaria, o el Banco de Espa&ntilde;a.</p>
              <h4><strong>&iquest;Cu&aacute;les son tus derechos y c&oacute;mo puedes ejercerlos?</strong></h4>
              <p>Junto al derecho a ser informado de la forma en la que lo estamos haciendo en esta cl&aacute;usula, tienes los siguientes derechos:</p>
              <p>&ndash; Derecho de acceso para conocer qu&eacute; datos tuyos est&aacute;n siendo tratados, con qu&eacute; finalidades, el origen de los mismos y si los comunicamos o hemos comunicado a terceros;</p>
              <p>&ndash; Derecho de modificaci&oacute;n de tus datos cuando los mismos est&eacute;n incompletos o sean inexactos;</p>
              <p>&ndash; Derecho de supresi&oacute;n de tus datos si ha desaparecido el fin para el que los facilit&oacute;, el tratamiento no es l&iacute;cito, o revocas tu consentimiento y dem&aacute;s supuestos previstos en la ley;</p>
              <p>Para el ejercicio de cualquiera de estos derechos puedes dirigirte por escrito a info@creditstore.net, previa acreditaci&oacute;n de tu identidad. Asimismo te informamos de tu derecho a presentar una reclamaci&oacute;n a trav&eacute;s del e-mail info@creditstore.net (Delegado de Protecci&oacute;n de Datos) o ante la Agencia de Protecci&oacute;n de Datos (www.agpd.es) si entiendes que no has obtenido satisfacci&oacute;n en el ejercicio de tus derechos.</p>
              <h4><strong>Cambios en la Pol&iacute;tica de Privacidad</strong></h4>
              <p>Credit Store Ltd, se reserva el derecho de cambiar, cuando lo estime conveniente, su Pol&iacute;tica de Privacidad. Estos cambios se anunciar&aacute;n en esta p&aacute;gina para que el usuario siempre conozca en qu&eacute; terminos se produce el tratamiento de sus datos.</p>
              <p><br /><br /></p>


            </div>

          </div>
        </div>
      </section>


      {/* <!--footer --> */}

      <footer className="bacgco">
        <div className="container">
          <div className="row footerr" >
            <div className="col-md-3 ">
              <p className="fotcol">2023 Credit Store</p>

            </div>
            <div className="col-md-3 fot_mob">
              <div className="displaygrid">
                <label className="text-white">Menu</label>

                <Link to="/auth/Register" className="fotcol">Crear cuenta </Link>
                <Link to="/auth/Login" className="fotcol">Iniciar Sesión </Link>
                <Link to="/about" className="fotcol">Sobre Credit Store </Link>
                <Link to="/contact" className="fotcol">Contacto </Link>
              </div>

            </div>
            <div className="col-md-3 fot_mob">
              <div className="displaygrid">
                <label className="text-white">Seguridad</label>
                <Link to="/warning" className="fotcol">Aviso Legal </Link>
                <Link to="/dataprotect" className="fotcol">Política de Privacidad </Link>
                <Link to="/condition" className="fotcol">Condiciones Generales </Link>
                <Link to="/cookies" className="fotcol">Política de Uso de Cookies </Link>
              </div>

            </div>
            <div className="col-md-3">
              <div className="displaygrid">
                <Link to="https://twitter.com/CreditStoreLine" className="fotcol"><span className="footericon"><i className="fa fa-twitter-square footericon " aria-hidden="true" ></i></span>Twitter </Link>
                <Link to="https://www.linkedin.com/company/thecreditstore/" className="fotcol"><span span className="footericon"><i className="fa fa-linkedin-square footericon" aria-hidden="true" ></i></span>LinkedIn </Link>
              </div>


            </div>
          </div>
        </div>
      </footer>




    </React.Fragment>
  )
}



import React from "react";
import { Link } from "react-router-dom";
import * as images from './images.jsx';
import UserHeader from "../user/component/header.jsx";

export default function Home() {
  return (
    <React.Fragment>

      <UserHeader />
      <section className="warningbg">
        <div className="container">
          <div className="row" >
            <div className="col-md-12  "  >
              <h1>Política de Uso de Cookies</h1>
              <h4><strong>1.- Uso de cookies en  esta web</strong></h4>
              <p>Esta web utiliza cookies propias y de terceros para la correcta prestaci&oacute;n de nuestros servicios y para obtener informaci&oacute;n estad&iacute;stica an&oacute;nima para analizar y medir el uso y actividad de la web con el fin de mejorar la navegaci&oacute;n y nuestra oferta de servicios.</p>
              <h4><strong>2.- Qu&eacute; son las cookies</strong></h4>
              <p>Las cookies son&nbsp; ficheros que se descargan en el terminal (ordenador/smartphone/tablet) del usuario al acceder a determinadas p&aacute;ginas web y que se almacenan en la memoria del mismo. Las cookies permiten, entre otras cosas, almacenar y recuperar informaci&oacute;n sobre los h&aacute;bitos de navegaci&oacute;n de un usuario o de su equipo, ver qu&eacute; p&aacute;ginas se visitan, el tiempo de conexi&oacute;n, analizar el funcionamiento de una p&aacute;gina, si existen problemas t&eacute;cnicos, etc.&nbsp; Dependiendo de la informaci&oacute;n que contengan y la forma en que se utilice el terminal, pueden utilizarse para reconocer al usuario.</p>
              <h4><strong>3.- &iquest;Qu&eacute; tipos de cookies existen?</strong></h4>
              <p>Seg&uacute;n la entidad que gestione las cookies pueden ser:</p>

              <ul>
                <li>Cookies propias. Son de nuestra propiedad y gestionada por nosotros</li>
                <li>Cookies de terceros. Son propiedad de un tercero que es quien gestiona la informaci&oacute;n obtenida por la cookie.</li>
              </ul>


              <p>Seg&uacute;n el tiempo en que permanezcan activadas pueden ser:</p>
              <ul>
                <li>Cookies de sesi&oacute;n. Dise&ntilde;adas para recabar y almacenar datos mientras el usuario accede a una p&aacute;gina web.</li>
                <li>Cookies permanentes. Los datos quedan almacenados en el terminal y pueden ser accedidos y tratados durante un periodo definido por el responsable de la cookie, que puede ir desde minutos hasta a&ntilde;os.</li>
              </ul>


              <p>Seg&uacute;n su finalidad pueden ser:</p>
              <ul>
                <li>Cookies t&eacute;cnicas y/o de personalizaci&oacute;n. Se utilizan para mejorar la navegaci&oacute;n y el funcionamiento de la web y el uso de las opciones y servicios, as&iacute; como almacenar configuraciones de servicio para no tener que reconfigurar cada vez que se visita el sitio web.</li>
                <li>Cookies de an&aacute;lisis. Se utilizan para el seguimiento y an&aacute;lisis del comportamiento de los usuarios; se utiliza en la medici&oacute;n de la actividad de la web, elaboraci&oacute;n de perfiles de navegaci&oacute;n, etc&hellip;</li>
                <li>Cookies publicitarias y/o de publicidad comportamental. Permiten la gesti&oacute;n de los espacios publicitarios incluidos en la plataforma desde donde se presta el servicio y pueden almacenar informaci&oacute;n de comportamiento de los usuarios obtenida de los h&aacute;bitos de navegaci&oacute;n, para ofrecer publicidad personalizada de acuerdo a dichos h&aacute;bitos.</li>
              </ul>


              <h4><strong>4.- Configuraci&oacute;n y desactivaci&oacute;n del uso de cookies en su navegador</strong></h4>
              <p>La mayor&iacute;a de los navegadores indican c&oacute;mo configurar su navegador para que no acepte cookies, para que le notifique cada vez que reciba una nueva cookie, as&iacute; como para desactivarlas completamente. Para controlar qu&eacute; uso quiere que se haga de su informaci&oacute;n, puede configurar el navegador de su terminal en la forma que estime m&aacute;s conveniente para Vd.</p>
              <p>En todo caso, le hacemos notar que si se desactivan las cookies t&eacute;cnicas y/o de funcionamiento, la calidad de la p&aacute;gina web puede disminuir o puede que cueste m&aacute;s identificarle.</p>
              <p>Le indicamos los siguientes enlaces, donde podr&aacute; encontrar m&aacute;s informaci&oacute;n sobre los diferentes navegadores:</p>

              <ul>
                <li>Chrome <Link to="https://support.google.com/chrome/Linknswer/95647?hl=es">https://support.google.com/chrome/Linknswer/95647?hl=es</Link></li>
                <li>Explorer <Link to="http://windows.microsoft.com/es-xl/internet-explorer/delete-manage-cookies%23ie=ie-10">http://windows.microsoft.com/es-xl/internet-explorer/delete-manage-cookies#ie=ie-10</Link></li>
                <li>Firefox <Link to="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we">http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we</Link></li>
                <li>Safari&nbsp; <Link to="http://support.apple.com/kb/ph5042">http://support.apple.com/kb/ph5042</Link></li>
                <li>Opera&nbsp; http://help.opera.com/Windows/11.50/es-ES/cookies.html</li>
              </ul>
              <h4><strong>5.- Cookies de terceros que utiliza esta web</strong></h4>
              <table >
                <tbody >
                  <tr >
                    <td >
                      <p >Propietario</p>
                    </td>
                    <td>
                      <p className="text-center">Finalidad</p>
                    </td>
                    <td>
                      <p className="text-center">M&aacute;s Informaci&oacute;n</p>
                    </td>
                  </tr>
                  <tr >
                    <td>
                      <p>Google Analytics</p>
                    </td>
                    <td>
                      <p>Recopilan Informaci&oacute;n An&oacute;nima sobre la Navegaci&oacute;n de los Usuarios por el Sitio Web con el fin de conocer el origen de las visitas y otros datos estad&iacute;sticos similares</p>
                    </td>
                    <td>
                      <p style={{ marginLeft: 30 }}>Una descripci&oacute;n completa del tipo de cookies, su duraci&oacute;n e implementaci&oacute;n para el caso de Google Analytics puede encontrarse aqu&iacute;:<Link to="https://developers.google.com/Linknalytics/devguides/collection/Linknalyticsjs/cookie-usage">https://developers.google.com/Linknalytics/devguides/collection/Linknalyticsjs/cookie-usage</Link></p>
                      <p style={{ marginLeft: 30 }}>Informaci&oacute;n adicional: <Link to="https://support.google.com/Linkccounts/Linknswer/61416?hl=es">https://support.google.com/Linkccounts/Linknswer/61416?hl=es</Link></p>
                    </td>
                  </tr>
                  <tr >
                    <td>
                      <p>Google Anuncios</p>
                    </td>
                    <td>
                      <p>Permiten mostrar publicidad seleccionando anuncios basados en contenido que es relevante para un usuario, mejorar los informes de rendimiento de la campa&ntilde;a y evitar mostrar anuncios que el usuario ya haya visto.</p>
                    </td>
                    <td>
                      <p style={{ marginLeft: 30 }}>Una descripci&oacute;n completa del tipo de cookies, su duraci&oacute;n e implementaci&oacute;n, as&iacute; como los diferentes dominios bajo los que pueden aparecer(adwords.com, doubleclick.net, youtube.com,&hellip;) para el caso de Google Anuncios puede encontrarse aqu&iacute;:<Link to="https://policies.google.com/technologies/types">https://policies.google.com/technologies/types</Link></p>
                    </td>
                  </tr>
                </tbody>
              </table>



            </div>

          </div>
        </div>
      </section>


      {/* <!--footer --> */}

      <footer className="bacgco">
        <div className="container">
          <div className="row footerr" >
            <div className="col-md-3 ">
              <p className="fotcol">2023 Credit Store</p>

            </div>
            <div className="col-md-3 fot_mob">
              <div className="displaygrid">
                <label className="text-white">Menu</label>

                <Link to="/auth/Register" className="fotcol">Crear cuenta </Link>
                <Link to="/auth/Login" className="fotcol">Iniciar Sesión </Link>
                <Link to="/about" className="fotcol">Sobre Credit Store </Link>
                <Link to="/contact" className="fotcol">Contacto </Link>
              </div>

            </div>
            <div className="col-md-3 fot_mob">
              <div className="displaygrid">
                <label className="text-white">Seguridad</label>
                <Link to="/warning" className="fotcol">Aviso Legal </Link>
                <Link to="/dataprotect" className="fotcol">Política de Privacidad </Link>
                <Link to="/condition" className="fotcol">Condiciones Generales </Link>
                <Link to="/cookies" className="fotcol">Política de Uso de Cookies </Link>
              </div>

            </div>
            <div className="col-md-3">
              <div className="displaygrid">
                <Link to="https://twitter.com/CreditStoreLine" className="fotcol"><span className="footericon"><i className="fa fa-twitter-square footericon " aria-hidden="true" ></i></span>Twitter </Link>
                <Link to="https://www.linkedin.com/company/thecreditstore/" className="fotcol"><span span className="footericon"><i className="fa fa-linkedin-square footericon" aria-hidden="true" ></i></span>LinkedIn </Link>
              </div>


            </div>
          </div>
        </div>
      </footer>




    </React.Fragment>
  )
}



import React from "react";
import { Link } from "react-router-dom";
// import * as con from '../repository/urls.js';
import * as apis  from '../repository/api_calls.js';
import * as images from './images.jsx';
import { useNavigate } from "react-router-dom";

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import UserHeader from "../user/component/header.jsx";

export default function Cta() {





  const navigate = useNavigate();

  const leadhandle = (event) => {
    event.preventDefault();

      getVisitor();

  
    async function getVisitor() {
        const response = await apis.GuestVisitor(JSON.stringify({
          "company": localStorage.getItem("company_name"),
          "phone": localStorage.getItem("telephone_no"),
          "mailid": localStorage.getItem("mail_id")
  
        }),);
        console.log(response)
        if (response['status'] === true) {
          toast.success(response["message"], {
            position: toast.POSITION.TOP_RIGHT,
            className: 'foo_bar_log_Success'
          });
  
          setTimeout(() => {
            navigate('/visitor_conform');
          }, 800);
        }
        else {
          toast.error(response["message"], {
            position: toast.POSITION.TOP_RIGHT,
            className: 'foo_bar_log_error'
          });
        }
    }
  
    // fetch(`${process.env.REACT_APP_BASE_URL}${con.visitor}`, {
    //   method: 'post',
    //   body: JSON.stringify({
    //     "company": localStorage.getItem("company_name"),
    //     "phone": localStorage.getItem("telephone_no"),
    //     "mailid": localStorage.getItem("mail_id")

    //   }),

    //   headers: {
    //     'Content-Type': 'application/json',
    //   }

    // }).then(async response => {
    //   var res = await response.json();

    //   if (res['status'] === true) {
    //     toast.success(res["message"], {
    //       position: toast.POSITION.TOP_RIGHT,
    //       className: 'foo_bar_log_Success'
    //     });

    //     setTimeout(() => {
    //       navigate('/visitor_conform');
    //     }, 800);
    //   }
    //   else {
    //     toast.error(res["message"], {
    //       position: toast.POSITION.TOP_RIGHT,
    //       className: 'foo_bar_log_error'
    //     });
    //   }

    // });

  }




  return (
    <React.Fragment>

      <UserHeader />
      <ToastContainer autoClose={3000} />
      <section style={{ backgroundColor: "#212529" }}>

        <div className="container">

          <div
            className="row"
            style={{ marginTop: "4%", marginBottom: 100, marginLeft: "18%" }}
          >
            <div className="te_flex">
              <div>
                <p className="cal_sc_p">¡Quiero que me llamen!</p>

                <a href="/calander">
                  <button type="button" className="btn1 btn-cta mb-5 callbtn " aria-pressed="true">
                    AGENDAR LLAMADA
                  </button>
                </a>

              </div>
              <form onSubmit={leadhandle}>
                <div style={{ marginLeft: 70 }}>
                  <p className="emi_p">¡Quiero que me contacten por mail!</p>

                  <button className="btn1 btn-cta mb-5 callbtn" type="submit"
                  >
                    RECIBIR INFO POR EMAIL
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>


      {/* <!--footer --> */}

      <footer className="bacgco">
        <div className="container">
          <div className="row footerr" >
            <div className="col-md-3 ">
              <p className="fotcol">2023 Credit Store</p>

            </div>
            <div className="col-md-3 fot_mob">
              <div className="displaygrid">
                <label className="text-white">Menu</label>

                <Link to="/auth/Register" className="fotcol">Crear cuenta </Link>
                <Link to="/auth/Login" className="fotcol">Iniciar Sesión </Link>
                <Link to="/about" className="fotcol">Sobre Credit Store </Link>
                <Link to="/contact" className="fotcol">Contacto </Link>
              </div>

            </div>
            <div className="col-md-3 fot_mob">
              <div className="displaygrid">
                <label className="text-white">Seguridad</label>
                <Link to="/warning" className="fotcol">Aviso Legal </Link>
                <Link to="/dataprotect" className="fotcol">Política de Privacidad </Link>
                <Link to="/condition" className="fotcol">Condiciones Generales </Link>
                <Link to="/cookies" className="fotcol">Política de Uso de Cookies </Link>
              </div>

            </div>
            <div className="col-md-3">
              <div className="displaygrid">
                <Link to="https://twitter.com/CreditStoreLine" className="fotcol"><span className="footericon"><i className="fa fa-twitter-square footericon " aria-hidden="true" ></i></span>Twitter </Link>
                <Link to="https://www.linkedin.com/company/thecreditstore/" className="fotcol"><span span className="footericon"><i className="fa fa-linkedin-square footericon" aria-hidden="true" ></i></span>LinkedIn </Link>
              </div>


            </div>
          </div>
        </div>
      </footer>


    </React.Fragment>
  )
}
import React from "react";
import { Link } from "react-router-dom";
import * as images from './images.jsx';


export default function Calander() {



  return (
    <React.Fragment>
      <header className="header-area">
        <div className="navbar-area" >
          <div className="container">
            <nav className="site-navbar">
              <Link to="/">

                {/* <img alt='' src= "./img/logo.svg" className="logo" /> */}
                <img className="logo" src={images.Logo} alt='Logo' />
              </Link>

              {/* <ul>
              <Link to="/auth/Login" className="hed_bu1">  <button type="button" className="btn1 btn-link1 head_nv" >Iniciar sesión <span> <i className="fa fa-sign-in" aria-hidden="true"></i></span></button> </Link>
                  <Link to="/auth/Register" className="hed_bu2">  <button type="button" className="btn1 btn-cta hed_but_2">Crear cuenta</button> </Link>
              </ul> */}

              <button className="nav-toggler">
                <span></span>
              </button>
            </nav>
          </div>
        </div>


      </header>





      <section>
        <div id="tidycal-embed" data-path="creditstore/reunion-de-15-minutos" ></div>
      </section>




      {/* <script src="https://asset-tidycal.b-cdn.net//js/embed.js"></script> */}





    </React.Fragment>
  )
}
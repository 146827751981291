// import React from "react";
import * as apis from '../repository/api_calls.js';
import * as images from '../pages/images.jsx';
import { Tabs, Tab } from '@material-ui/core';
import { useNavigate } from "react-router-dom";
import React from 'react';
import '../../src/assets/css/datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from "react-router-dom";

export default function Register() {

  // const [selectedDate, setSelectedDate] = useState(null);
  const [value, setValue] = React.useState(0);

  const handleTabs = (e, val) => {
    console.warn(val);
    setValue(val);
  }

  const nameRef = React.useRef();
  const surnameRef = React.useRef();
  const emailRef = React.useRef();
  const passwordRef = React.useRef();
  const confirm_passwordRef = React.useRef();

  const le_nameRef = React.useRef();
  const le_surnameRef = React.useRef();
  const bs_nameRef = React.useRef();
  const bs_emailRef = React.useRef();
  const bs_passwordRef = React.useRef();
  const bs_clueRef = React.useRef();
  const bs_date_of_registrationRef = React.useRef();
  const bs_tax_numberRef = React.useRef();
  const bs_company_registration_numberRef = React.useRef();
  const pattern = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;
  const currentDate = new Date().toISOString().split('T')[0];

  
  const navigate = useNavigate();

  // const validateEmail = (email) => {
  //   const pattern = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;
  //   const result = pattern.test(email);
  //   if (result === true) {
  //     this.setState({
  //       emailError: false,
  //       email: email
  //     })
  //   } else {
  //     this.setState({
  //       emailError: true
  //     })
  //   }
  // }


  {/* -===============================User registration api */}
  const handleSubmit = async (event) => {
    event.preventDefault();
    const name = nameRef.current.value
    const surname = surnameRef.current.value
    const email = emailRef.current.value
    const password = passwordRef.current.value
    const confirmPassword = confirm_passwordRef.current.value

    const result = pattern.test(email);
    if (result === true) {
      const response = await apis.RegisterFunc(JSON.stringify({
        "name": name,
        "surname": surname,
        "email": email,
        "password": password,
        "clue": confirmPassword
      }));

      if (response['status'] === true) {
        localStorage.setItem("creditScore_status", "done");

        toast.success("Registro de usuario exitoso", {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo_bar_log_Success'
        });
        setTimeout(() => {
          navigate('/auth/Login');
        }, 800);

      } else {
        toast.error(response['message'], {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo_bar_log_error'
        });

      }
    } else {
      toast.warn(result['message'], {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo_bar_log_eil_wa'
      });
    }
  };



  {/* -===============================lender registration api */}
  const handleSubmitTwo = async (event) => {
    event.preventDefault();
    const le_name = le_nameRef.current.value
    const le_surname = le_surnameRef.current.value
    const bs_name = bs_nameRef.current.value
    const bs_email = bs_emailRef.current.value
    const bs_password = bs_passwordRef.current.value
    const bs_clue = bs_clueRef.current.value
    const bs_date_of_registration = bs_date_of_registrationRef.current.value
    const bs_tax_number = bs_tax_numberRef.current.value
    const bs_company_registration_number = bs_company_registration_numberRef.current.value;
    const result = pattern.test(bs_email);
    if (result === true) {
      const response = await apis.lenderRegisterFunc(JSON.stringify({
        "name": le_name,
        "surname": le_surname,
        "business_name": bs_name,
        "email": bs_email,
        "password": bs_password,
        "clue": bs_clue,
        "date_of_registration": bs_date_of_registration,
        "tax_number": bs_tax_number,
        "company_registration_number": bs_company_registration_number
      }));

      if (response['status'] === true) {
        toast.success("Éxito en el registro de prestamistas", {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo_bar_log_Success'
        });
        setTimeout(() => {
          navigate('/auth/Login');
        }, 800);
      } else {
        toast.error(response['message'], {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo_bar_log_error'
        });

      }
    } else {
      toast.warn("Email inválido", {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo_bar_log_eil_wa'
      });
    }
  };




  

  return (
    <React.Fragment>

      <section>

        <div className="d-md-flex">
          {/* Second Half */}
          <div className="col-md-7 order-2 my-auto">
            <div className="d-md-flex align-items-center p-3 justify-content-center">
              <div data-aos="fade-up">
                <Tabs value={value} onChange={handleTabs} TabIndicatorProps={{
                  style: { height: 0, }
                }}>
                  <Tab className="mt-5" label="Registro de usuario" style={{ border: "solid 2px #183F4B", borderRadius: 8, fontSize: 12 }}></Tab>
                  <Tab className="ms-3 mt-5" label="Registro de prestamista" style={{ border: "solid 2px #183F4B", borderRadius: 8, fontSize: 12 }}></Tab>
                </Tabs>

                <TabPanel value={value} index={0}>

                  <div className="row mb-3">
                    <div className="col-md-8 mb-2">
                      <h3 className="">¡Bienvenido!</h3>
                    </div>
                    <div className="col-md-4 align-self-end mb-3 text-end">
                      <Link to="/auth/login"
                        className="mb-2 text-primary text-decoration-none btn-link1"
                      >
                        <span>
                          <i className="fa fa-long-arrow-right me-3" aria-hidden="true"></i>
                        </span>
                        <span style={{ textTransform: "capitalize" }}>   Iniciar Sesión</span>
                      </Link>
                    </div>

                  </div>


                  {/* -===============================User registration */}
                  <ToastContainer autoClose={3000} />
                  <form onSubmit={handleSubmit} method="post" className="form-signin">

                    <div className="col">
                      <div className="form-label-group">
                      <input
                        className="form-control"
                        id="name"
                        name="name"
                        placeholder="Nombre"
                        required
                        type="text"
                        defaultValue=""
                        ref={nameRef}
                        style={{ height: 45 }}
                        pattern="[a-z A-Z]+"
                      />
                        <label>Nombre</label>
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-label-group">
                        <input
                          className="form-control my-5"
                          id="surname"
                          name="surname"
                          placeholder="Apellido"
                          required
                          type="text"
                          defaultValue=""
                          ref={surnameRef}
                          style={{ height: 45 }}
                          pattern="[a-z A-Z]+"
                        />
                        <label>Apellido</label>
                      </div>
                    </div>
                    <div className="form-label-group">
                      <input
                        className="form-control my-5"
                        id="email"
                        name="email"
                        placeholder="Correo Electrónico"
                        required
                        type="email"
                        defaultValue=""
                        ref={emailRef}
                        style={{ height: 45 }}
                      />
                      <label>Correo Electrónico</label>
                    </div>
                    <div className="form-label-group">


                      <input
                        className="form-control password-field"
                        id="password"
                        name="clue"
                        placeholder="Clave"
                        required
                        type="password"
                        minLength={6} maxLength={12}
                        ref={passwordRef}
                        defaultValue=""
                        style={{ height: 45 }}
                      />




                      <label>Clave</label>
                    </div>

                    <div className="form-label-group">
                      <input
                        className="form-control password-field my-5"
                        id="confirm_password"
                        name="confirm_password"
                        placeholder="Confirma tu clave"
                        required
                        type="password"
                        minLength={6} maxLength={12}
                        ref={confirm_passwordRef}
                        defaultValue=""
                        style={{ height: 45 }}
                      />
                      <label>Confirma tu clave</label>
                    </div>

                    <div className="checkbox mb-1" style={{ marginLeft: 20 }}>
                      <input
                        className="mr-2"
                        id="termsandconditions"
                        name="termsandconditions"
                        required
                        type="checkbox"
                        defaultValue="y"
                      />
                      Acepto los{" "}
                      <Link to="/condition" className="text-info text-primary" >
                        términos y condiciones.
                      </Link>
                    </div>
                    <div className="checkbox mb-3 my-3" style={{ marginLeft: 20 }}>
                      <input
                        className="mr-2"
                        id="gdpr"
                        name="gdpr"
                        required
                        type="checkbox"
                        defaultValue="y"
                      />
                      Acepto los{" "}
                      <Link to="/dataprotect" className="text-info text-primary ">
                        términos de privacidad.
                      </Link>
                    </div>
                    <div className="form-group d-grid"  >
                      <input
                        className="btn btn-lg btn-primary btn-block mb-3"
                        id="submit"
                        required
                        name="submit"
                        type="submit"
                        Value="Registrarme"
                      />
                    </div>
                  </form>

                </TabPanel>


                <TabPanel value={value} index={1}>

                  <div className="row mb-3">
                    <div className="col-md-8 mb-2">
                      <h3 className="">¡Bienvenido!</h3>
                    </div>
                    <div className="col-md-4 align-self-end mb-3 text-end">
                      <Link to="/auth/login"
                        className="mb-2 text-primary text-decoration-none btn-link1"
                      >
                        <span>
                          <i className="fa fa-long-arrow-right me-3" aria-hidden="true"></i>
                        </span>
                        <span style={{ textTransform: "capitalize" }}>   Iniciar Sesión</span>
                      </Link>
                    </div>
                  </div>



                {/* -===============================Lender registration */}
                  <ToastContainer autoClose={3000} />
                  <form onSubmit={handleSubmitTwo} method="post" className="form-signin">
                    <div className="row my-4">
                      <div className="col-md-6">
                        <div className="form-label-group">
                          <input
                            className="form-control"
                            id="name"
                            name="name"
                            placeholder="Nombre"
                            required
                            type="text"
                            defaultValue=""
                            ref={le_nameRef}
                            style={{ height: 45, width: "225px" }}
                            pattern="[a-z A-Z]+"
                          />
                          <label>Nombre</label>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-label-group ">
                          <input
                            className="form-control"
                            id="name"
                            name="name"
                            placeholder="Apellido"
                            required
                            type="text"
                            defaultValue=""
                            ref={le_surnameRef}
                            style={{ height: 45, width: "225px" }}
                            pattern="[a-z A-Z]+"
                          />
                          <label>Apellido</label>
                        </div>
                      </div>
                    </div>

                    <div className="col">
                      <div className="form-label-group">
                        <input
                          className="form-control"
                          id="name"
                          name="name"
                          placeholder="Nombre del Negocio"
                          required
                          type="text"
                          defaultValue=""
                          ref={bs_nameRef}
                          style={{ height: 45 }}
                        />
                        <label>Nombre del Negocio</label>
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-label-group">
                        <input
                        className="form-control my-5"
                        id="date_pi"
                        name="date_pi"
                        placeholder="fecha de registro"
                        required
                        type="date"
                        defaultValue=""
                        ref={bs_date_of_registrationRef}
                        style={{ height: 45 }}
                        max={currentDate}
                      />
                        <label>fecha de registro</label>
                      </div>
                    </div>

                    <div className="form-label-group">
                      <input
                        className="form-control my-5"
                        id="email"
                        name="email"
                        placeholder="Correo electrónico"
                        required=""
                        type="email"
                        defaultValue=""
                        ref={bs_emailRef}
                        style={{ height: 45 }}
                      />
                      <label>Correo electrónico
                      </label>
                    </div>

                    <div className="form-label-group">
                      <input
                        className="form-control my-5"
                        id="tax"
                        name="tax"
                        placeholder="Número de impuesto"
                        required=""
                        type="text"
                        defaultValue=""
                        minLength={1} maxLength={15}
                        ref={bs_tax_numberRef}
                        style={{ height: 45 }}
                      />
                      <label>Número de impuesto
                      </label>
                    </div>

                    <div className="form-label-group">
                      <input
                        className="form-control my-5"
                        id="tax"
                        name="tax"
                        placeholder="número de registro de la empresa"
                        required=""
                        type="text"
                        defaultValue=""
                        minLength={1} maxLength={15}
                        ref={bs_company_registration_numberRef}
                        style={{ height: 45 }}
                      />
                      <label>número de registro de la empresa
                      </label>
                    </div>


                    <div className="row my-4">
                      <div className="col-md-6">
                        <div className="form-label-group">
                          <input
                            className="form-control password-field"
                            id="password"
                            name="clue"
                            placeholder="contraseña"
                            required
                            type="password"
                            minLength={6} maxLength={12}
                            ref={bs_passwordRef}
                            defaultValue=""
                            style={{ height: 45, width: "225px" }}
                          />
                          <label>contraseña</label>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-label-group">
                          <input
                            className="form-control password-field"
                            id="confirm_password"
                            name="confirm_password"
                            placeholder="confirmar Contraseña"
                            required
                            type="password"
                            minLength={6} maxLength={12}
                            ref={bs_clueRef}
                            defaultValue=""
                            style={{ height: 45, width: "225px" }}
                          />
                          <label>confirmar Contraseña
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="form-group d-grid"  >
                      <input
                        className="btn btn-lg btn-primary btn-block mb-3"
                        id="submit"
                        required
                        name="submit"
                        type="submit"
                        Value="Registrarme"
                      />
                    </div>
                  </form>


                </TabPanel>

              </div>
            </div>
          </div>
          {/* First Half */}
          <div className="col-md-4  leftsplit order-1 vh-100 bg-dark text-light reg-img-bg ">
            <div className="d-flex flex-column align-items-center p-5 loginmargin-top">
              <img className="" src={images.Logo} alt='Logo' height="40px" />
              <h3 className="mt-4 fw-bold">3 pasos para conseguir financiación</h3>
              <ul className="list-unstyled">
                <li className="mt-3 fw-light">
                  <i className="fa fa-check tick" aria-hidden="true" /> Registrate y rellena el formulario  </li>
                <li className="mt-2 fw-light">
                  <i className="fa fa-check tick" aria-hidden="true" />Descubre tu salud financiera y mejórala con sugerencias prácticas y            personalizadas
                </li>
                <li className="mt-2 fw-light">
                  <i className="fa fa-check tick" aria-hidden="true" />Recibe financiaciÃ³n de prestamistas que mejor se adaptan a tus necesidades
                </li>
              </ul>

            </div>
          </div>
        </div>
      </section>



    </React.Fragment>
  )
}


function TabPanel(props) {
  const { children, value, index } = props;
  return (
    <div>
      {
        value === index &&
        (
          <h5>{children}</h5>
        )
      }

    </div>
  )
}


import React, { useEffect } from "react";
import { Link,Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
// import * as con from '../repository/urls.js';
import { Tabs, Tab } from '@material-ui/core';
import * as apis from '../repository/api_calls.js';
import CircleProgressBar from "../circle/circle.jsx";
import * as images from '../pages/images.jsx'; 
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import LenderHeader from "./component/header.jsx";

export default function Lenderdashbo() {

  const [value, setValue] = React.useState(0);
  const [userLoanData, setUserLoanData] = React.useState([]);

  const [userAccepted, setUserAccepted] = React.useState([]);
  const [userRejected, setUserRejected] = React.useState([]);

  const handleTabs = (e, val) => {
    console.warn(val);
    setValue(val);
  }

  const token = localStorage.getItem("token");
  const role_id = localStorage.getItem("role_id");
  const creditScoreStatus = localStorage.getItem("creditScore_status");


  

  
  useEffect(() => {
    getLoan();
    getLender();
    // loadLoanAcceptReject();
  }, [])

  async function getLoan() {
      const response = await apis.getLoanOffer();
      if (response['status'] === true) {
        setUserLoanData(response['data']);
      }
  }



  async function getLender() {
    const response = await apis.getLenderOffer();
   
    if(response['status'] === true){
      let accepted = [];
      let rejected = [];
      if (response && response['data'].length > 0) {
        for (let i = 0; i < response['data'].length; i++) {
          if (response['data'][i]['offer_status'] === "Accept") {
            accepted.push(response['data'][i]);
          } else if (response['data'][i]['offer_status'] === "Reject") {
            rejected.push(response['data'][i]);
          }
        }
      }
      console.log(accepted)
      console.log(rejected)
      setUserAccepted(accepted);
      setUserRejected(rejected);
    }
}


  // useEffect( () =>  {
    // fetch(`${process.env.REACT_APP_BASE_URL}${con.loanOfferList}`, {
    //   method: 'post',
    //   headers: {
    //     "Authorization": `Bearer ${token}`,
    //   }
    // }).then(res => res.json())
    //   .then(res => {
    //     if (res['status'] === true) {
    //       setUserLoanData(res['data']);
    //     }
    //   });
        //   fetch(`${process.env.REACT_APP_BASE_URL}${con.lenderOfferList}`, {
        //     method: 'post',
        //     headers: {
        //       "Authorization": `Bearer ${token}`,
        //     }
        //   }).then(res => res.json())
        //     .then(response => {
        //       var datalist = response['data'];
        //       // var accepted=[];
        //       // var rejected=[];
        //       // for(let i=0;i<datalist.length;i++){
        //       //     if(datalist[i]['offer_status']==="Accept"){
        //       //       accepted.push(datalist[i]);
        //       //     }else if(datalist[i]['offer_status']==="Reject"){
        //       //       rejected.push(datalist[i]);
        //       //     }
        //       // }
        //       let accepted = [];
        //       let rejected = [];
        //       if (datalist && datalist.length > 0) {
        //         for (let i = 0; i < datalist.length; i++) {
        //           if (datalist[i]['offer_status'] === "Accept") {
        //             accepted.push(datalist[i]);
        //           } else if (datalist[i]['offer_status'] === "Reject") {
        //             rejected.push(datalist[i]);
        //           }
        //         }
        //       } else {

        //         // toast.error("Usuario no disponible para ti ", {
        //         //   position: toast.POSITION.TOP_RIGHT,
        //         //   className: 'foo_bar_log_error'
        //         // });
        //         // console.log("No data in the datalist array or datalist is undefined.");
        //       }
        //       setUserAccepted(accepted);
        //       setUserRejected(rejected);
        // });
      // loadLoanAcceptReject();
// },[])

  async function loadLoanAcceptReject(){
  const response = await apis.userLoanAcceptRejectLoan(token);
    if (response['status'] === true) {
      var datalist = response['data'];
      var accepted=[];
      var rejected=[];
      for(let i=0;i<datalist.length;i++){
          if(datalist[i]['offer_status']==="Accept"){
            accepted.push(datalist[i]);
          }else if(datalist[i]['offer_status']==="Reject"){
            rejected.push(datalist[i]);
          }
      }
      setUserAccepted(accepted);
      setUserRejected(rejected);
    }
}

function changeToList(data){
  let listData=[];
  listData=JSON.parse(data);
  console.log(listData[0])
  return listData;
}

function getLoanPercent(score){
  var percentage = '';
  if(score < 0){
    percentage = 'Error - Data Incompleta';
   }
   else if(score > 0 && score <= 50){
    percentage = 'Bajo';
   }
 else if(score > 50 && score <= 66){
     percentage = 'Regular';
 }    
 else if (score > 66 && score <= 79){
     percentage  = 'Bueno';
 }
 else if (score >= 80 && score < 90){
     percentage = 'Muy Bueno';
 }  
else if (score >= 90 && score <= 100){
    percentage = 'Excelente';
} else{
  percentage = 'Cero';
}
return percentage;
}


  const navigate = useNavigate();
  

  async function logout() {
      const response = await apis.getLogout();
      console.log(response)
      if (response['status'] === true) {
        toast.success(response['message'], {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo_bar_log_Success'
        });
        setTimeout(() => {
          localStorage.clear();
          window.location.reload();
          navigate('/dashboard');
        }, 800);

      } else {
        toast.error(response['message'], {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo_bar_log_error'
        });
      }
  }


 function moveToMyscore3(data){
    navigate('/lender/user_detial',{ state: data });
  }

  function formatDate(data){
    const datetime = new Date('2023-06-26 03:16:00');
    // const formattedDate = format(datetime, 'dd/MM/yyyy');
    const split = data.split(" ");
    return split[0];
    }


  return (
    ((role_id !== null && role_id === "3") && (creditScoreStatus !== null)) ?
    <React.Fragment>

<div>
  <LenderHeader />
</div>

      <ToastContainer autoClose={3000} />

      <section>
        <div className="row">

          <div className="col-md-2" style={{ backgroundColor: "white" }}>
            <div  className="card"
              style={{
                height: "90vh",
                width: "100%",
                zIndex: 1,
                position: "sticky",
                top: 55,
              }}>
         
                <img  className="" src={images.profile} alt='profile'    style={{width: "90px", height: "90px", display: "block", margin: "0 auto" }} />
            <p style={{ textAlign: "center", fontWeight: "500" }}>Nombre del inversor</p>
            <p style={{ textAlign: "center", marginTop: "-10px", fontSize: "13px" }}>{localStorage.getItem("firstname") + " " + localStorage.getItem("lastname")}</p>


            <div style={{ display: "flex", justifyContent: "center", marginTop: "30px", marginLeft: "17px" }}>
              <img  className="" src={images.pro_icon} alt='pro_icon' style={{ fontSize: "13px" }}  />
              <Link to="/lender/lenderdashbo">
              <p style={{ color: "#828282", fontSize: "13px", marginTop: "4px", marginLeft: "7px", paddingTop: "9px" }}>
              Panel
              </p>
              </Link>
            </div>

            <div style={{ display: "flex", justifyContent: "center", marginLeft: "10px" }}>
            <img  className="" src={images.pro_icon} alt='pro_icon' style={{ fontSize: "13px" }}  />
              <a href='/lender/lender_profile'>
                <p style={{ color: "#828282", fontSize: "13px", marginTop: "4px", paddingLeft: "9px", paddingTop: "9px" }}>
                Mi perfil
                </p>
              </a>
            </div>
            <div style={{ display: "flex", justifyContent: "center", marginLeft: "10px", marginTop: "90px" }}>
            <button  type="submit" className="lender_logbut" onClick={logout}>Cerrar sesión</button>
            </div>

         



          </div>
          </div>

          <div className="col-md-10">

            <Tabs value={value} onChange={handleTabs} TabIndicatorProps={{
              style: { height: 0, }
            }}>
              <Tab className="mt-5" label="Nuevas ofertas" style={{ border: "solid 2px #183F4B", borderRadius: 8, fontSize: 12 }}></Tab> 
              <Tab className="ms-3 mt-5" label="Ofertas comprometidas" style={{ border: "solid 2px #183F4B", borderRadius: 8, fontSize: 12 }}></Tab> 
              <Tab className="ms-3 mt-5" label="Ofertas rechazadas" style={{ border: "solid 2px #183F4B", borderRadius: 8, fontSize: 12 }}></Tab> 
            </Tabs>

            <TabPanel value={value} index={0}>
              <div style={{textAlign:"left"}}>
              <div>
                <p style={{ fontSize: "11px", color: "#183f4b" }} >Aqui encontraras las empresas en busquede de<br />
                  financiaion. Haz clic scobre cada oferta para ver los<br />
                  details de la empresa<br />
                </p>
              </div>
              <div>
                <p style={{ fontSize: "11px", color: "#183f4b" }}>Aqui encontraras las empresas en busquede de<br />
                  financiaion. Haz clic scobre cada oferta para ver los<br />
                  details de la empresa<br />
                </p>
              </div>
              <div>
                <p style={{ fontSize: "15px", color: "black" }}>?Que es el score?</p>
                <div style={{ borderBottomColor: "#0069CB" }} />

                <p style={{ fontSize: "11px" }}>Credit Store te ofrece el score como referencia de<br />evalucation financiaion de la empresa</p>
              </div>

            
             
                
            <div className="row">
                {/* <div className="gridView"> */}
                 {userLoanData.map((list) => { 
                
                  
                  return (
                    
                    <div className="card col-md-4" style={{width:"330px", padding:"20px", marginLeft: "10px", cursor:"pointer"}}>
                  <div onClick={()=>{moveToMyscore3(list);}} >
                 
                    <div className="row "> 
                      <div className="col-md-6" style={{ fontSize: "12px", margin: "10px", color: "#183F4B" }}>
                       
                        <p style={{ color: "#183F4B" }}>{list.company_name}</p>
                        <p style={{ color: "#183F4B" }}><b>FECHA :  </b> {list.created_at}</p>
                        <p style={{ color: "#183F4B", }}><b>IMPORTE : </b> {list.loan_amount}</p>
                        <p style={{ color: "#183F4B", }}><b>SOLICITAR OFERTA : </b> {list.loan_reasons}</p>
                        <p style={{ fontWeight: "lighter" }}><b>PLAZO : </b> {list.loan_term}</p>
                        <p style={{ fontWeight: "lighter" }}><b>SECTOR CNAE : </b> {list.cnae_sector}</p>
                       

                      </div>
                      <div className="col-md-4">

       
                        <div style={{ marginLeft: "-10px", marginBottom: "30px" }}>
                             <CircleProgressBar percentage={list.user_score} />
                             </div>
                        
                        <p style={{ color: "#27ae60", fontStyle: "italic", fontSize: "15px", marginLeft: "16px", marginTop: "-21px" }}> {getLoanPercent(list.user_score)}</p>

                      </div>
                      <p style={{ color: "#0069CB", fontSize: "12px", marginLeft: "20px" }}>?PARA QUE QUEREMOS EL PRESTAMO?</p>

                    </div>
                  </div>
                  </div>

                  )
                })} 
                {/* </div> */}
                </div>
                
                {/* </Link> */}





             
            

                </div>

            </TabPanel>





            <TabPanel value={value} index={1}>
            {/* <section className="">
            <div className="row">
            {userAccepted.map((list, index) => {
                return (
          
             
            <div className="card col-md-4" style={{ padding: "20px", height:"360px", width:"330px", marginLeft:"10px" }}>
               
  <div >
    <h3 className="text-center mb-3"> {list['type']}</h3>
    <h5 className="text-center"> <span className="badge bg-info">Offer Id : {list['id']}</span> </h5>
    <h5 className="text-center">Titulo del prestamo : {list['title']}</h5>
    <div className="row" style={{ paddingLeft: "20px", paddingRight: "15px", textAlign:"left" }}>
      <div className="col-md-6" style={{ fontSize: "12px" }}>
      

      <div className="" >
                                <p>Importe : {list['imported']}</p>
                                <p>TIN : {list['interest_rate']}</p>
                                <p>Fijo : {list['permanent']}</p>
                                <p>Variable : -</p>
                              

                                <p>Carencia: {list['lack']}</p>
                             

                              </div>


        </div>

        <div className="col-md-6" style={{ fontSize: "12px" }}>


        <div className="" >
        <p>Fecha : {formatDate(list['created_at'])}</p>
                                <p>Duración : {list['duration']}</p>
                                <p>Frecuencia de pago : {list['payment_frequency']}</p>
                                <p>Variable: {list['modelo_variable']}</p>
                            
                                <p>Garantias:{list['guarantees']}</p>
                              
                             
                              </div>

        </div>
       
      </div>
      

      <div className="row"  style={{ paddingLeft: "20px", paddingRight: "15px", textAlign:"left"  }}>
      <h5>Otros CArgos:</h5>
        <div className="col-md-6" style={{ fontSize: "12px" }}>
        <p>Apertura:  {list['opening']}</p>
                                <p>Retraso: {list['delay']}</p>
                                <p>OTROS: {list['others']}</p>

          </div>

          <div className="col-md-6" style={{ fontSize: "12px" }}>
          <p>Mantenimiento: {list['maintenance']}</p>
                                <p>Cancelacion Anticipada:  {list['early_cancellation']}</p>
            

</div>
        </div>
 
      

    </div>
  </div>

 


  )
})}
</div>
  </section> */}



  <section className="mb-5">
        <div className="row" >

      {userAccepted.map((list, index) => {
                return (
                  <div className="col-md-4" style={{width:"360px", padding:"20px", marginLeft: "10px"}} >
                  <div class="dashcard" style={{height:"265px"}}>
                    <br></br>
      
                    <div class="smallcard">
                      <img className="offerimg" src={images.offer_e} alt='Logo' />
                      <p className="offertext_1">Offer : {list['id']}</p>
                    </div>
                    <p class="loanoffertxt"> {list['type']}</p>
                    <p class="loanoffertxt2 mt-5"> Titulo del prestamo: {list['loan_title']}</p>

                    {(list['offer_status'] !== null) ? (list.offer_status === "Accept") ?
                             <p style={{ textAlign: "right", padding:"10px", marginTop:"-70px" }}> <span className="badge_use bg-success" style={{marginTop: "-55px"}}> {list['offer_status']}</span> </p> :
                           <p style={{ textAlign: "right", padding:"10px", marginTop:"-70px" }}> <span className="badge_use bg-danger" style={{marginTop: "-55px"}}>{list['offer_status']}</span> </p>
                            : null
                          }
      
                    <div className="innercard_lend">
      
                      <div style={{ display: "flex", justifyContent: "space-around", paddingTop: "13px" }}>
                        {/* left */}
                        <div>
                          <p className="cardtextb ler_p"> IMPORTE:{list['imported']}</p>
                          <p className="cardtextn ler_p"> TIN:{list['interest_rate']} </p>
                          <p className="cardtextn ler_p"> FIJO: <span className="cardtextb">{list['permanent']}</span></p>
      
                          <div style={{ display: "flex" }}>
                            <p className="cardtextn MODULO "> MODULO : </p>
                          {/* {
                            (list['modelo_variable']!==undefined||
                            list['modelo_variable']!==null || list['modelo_variable'] !=='')?
                            changeToList(list['modelo_variable']).map((mod, index) => {
                              return (
                                         
                                                <p>{mod.percentage}</p>
                                              )
                                            })
                                          :null
                          } */}
                           

                          </div>
                          <p className="cardtextn ler_p"> GARANTIAS: <span className="cardtextb">{list['imported_sub']}</span></p>
                        
                          {/* <p className="cardtextb ler_p"><u> OTROS CARGOS:</u></p>
                          <div style={{ display: "flex" }}>
                            <p className="MODULO ler_p">APERTURA: </p>
                            <div class="eur3" style={{ marginLeft: "23px" }} >{list['opening']}</div>
                            <p className="MODULO"> EUR</p>
      
                          </div> */}
                          {/* <div style={{ display: "flex", marginTop: "-11px" }}>
                            <p className="MODULO ler_p">CANCELACION: </p>
                            <div class="eur3" style={{ marginLeft: "23px" }} >{list['early_cancellation']}</div>
                            <p className="MODULO"> EUR</p>
      
                          </div>       */}
      
                        </div>

      
                        {/* right */}
                        <div>
                          <p className="cardtextb ler_p">FECHA    23/06/2023 </p>
                          <p className="cardtextn ler_p"> DISPONINILIDAD: <span className="cardtextb">36 meses</span></p>
                          <p className="cardtextn ler_p"> FRECUENCIA DE<br /> REEMBOLSO: <span className="cardtextb" style={{ marginLeft: "25px" }}>{list['payment_frequency']}</span></p>
                          <p className="cardtextn ler_p"> COBRO POR<br /> RENOVACION: <span className="cardtextb" style={{ marginLeft: "20px" }}>  {list['interest_rate']}</span></p>
                        
                          {/* <div style={{ display: "flex", marginTop: "27px" }}>
                            <p className="MODULO ler_p">MANTENIMIENTO: </p>
                            <div class="eur3" style={{ marginLeft: "23px" }} >{list['maintenance']}</div>
                            <p className="MODULO"> EUR</p>
      
                          </div>
                          <div style={{ display: "flex", marginLeft: "38px", marginTop: "-11px" }}>
                            <p className="MODULO ler_p">RETRASO: </p>
                            <div class="eur3" style={{ marginLeft: "23px" }} > {list['delay']}</div>
                            <p className="MODULO"> EUR</p>
      
                          </div> */}

                        </div>
      
      
      
      
      
      
      
                      </div>
      
      
      
      
                    </div>
      
      
                  </div>
      
                </div>

                )
        })
      }
         
       
        </div>
        </section>

  

            </TabPanel>
   
            <TabPanel value={value} index={2} >
              {/* <div className="row">
            {userRejected.map((list, index) => {
                return (

            <div className="card col-md-4" style={{ padding: "20px", height:"350px", width:"330px", marginLeft:"10px" }}>
  <div >
    
    <h3 className="text-center mb-3">{list['type']} </h3>
    <h5 className="text-center"> <span className="badge bg-info">Offer Id : {list['id']}</span> </h5>
    <h5 className="text-center">Titulo del prestamo: Money for my start up</h5>
    <div className="row" style={{textAlign:"left"}}>
      <div className="col-md-6" style={{ fontSize: "12px" }}>
       
        <div className="" >
          <p>Importe: {list['imported']}</p>
          <p>TIN: 3%</p>
          <p>Fijo: NO</p>
          <p>Variable: NO</p>
          <p>Garantias: Si</p>


          <h5>Otros CArgos:</h5>
          <p>Apertura:  EUR</p>
          <p>Cancelacion
              Anticipada:  {list['early_cancellation']}</p>

          


          </div>


      </div>

      <div  className="col-md-6" style={{ fontSize: "12px" }}>

      <div className="" >


<p>Fecha : {formatDate(list['created_at'])}</p>
<p>Disponinilidad:  36 meses</p>
<p>Frecuencia:  {list['payment_frequency']}</p>
<p>Carencia:  Si</p>
<p>Meses</p>
<p>{list['maintenance']}</p>
<p>Retraso: {list['delay']}</p>
<p>OTROS</p>



</div>



      </div>
 
      

    </div>
  </div>
  </div>
 
    )
  })}
  </div> */}


  <section className="mb-5">
        <div className="row" >

        {userRejected.map((list, index) => {
                return (
                  <div className="col-md-4" style={{width:"360px", padding:"20px", marginLeft: "10px"}} >
                  <div class="dashcard" style={{height:"265px"}}>
                    <br></br>
      
                    <div class="smallcard">
                      <img className="offerimg" src={images.offer_e} alt='Logo' />
                      <p className="offertext_1">Offer : {list['id']}</p>
                    </div>
                    <p class="loanoffertxt"> {list['type']}</p>
                    <p class="loanoffertxt2 mt-5"> Titulo del prestamo: {list['loan_title']}</p>

                    {(list['offer_status'] !== null) ? (list.offer_status === "Accept") ?
                             <p style={{ textAlign: "right", padding:"10px", marginTop:"-70px" }}> <span className="badge_use bg-success" style={{marginTop: "-55px"}}> {list['offer_status']}</span> </p> :
                           <p style={{ textAlign: "right", padding:"10px", marginTop:"-70px" }}> <span className="badge_use bg-danger" style={{marginTop: "-55px"}}>{list['offer_status']}</span> </p>
                            : null
                          }
      
                    <div className="innercard_lend">
      
                      <div style={{ display: "flex", justifyContent: "space-around", paddingTop: "13px" }}>
                        {/* left */}
                        <div>
                          <p className="cardtextb ler_p"> IMPORTE:{list['imported']}</p>
                          <p className="cardtextn ler_p"> TIN:{list['interest_rate']} </p>
                          <p className="cardtextn ler_p"> FIJO: <span className="cardtextb">{list['permanent']}</span></p>
      
                          <div style={{ display: "flex" }}>
                            <p className="cardtextn MODULO "> MODULO : </p>
                          {/* {
                            (list['modelo_variable']!==undefined||
                            list['modelo_variable']!==null || list['modelo_variable'] !=='')?
                            changeToList(list['modelo_variable']).map((mod, index) => {
                              return (
                                         
                                                <p>{mod.percentage}</p>
                                              )
                                            })
                                          :null
                          } */}
                           

                          </div>
                          <p className="cardtextn ler_p"> GARANTIAS: <span className="cardtextb">{list['imported_sub']}</span></p>
                        
                          {/* <p className="cardtextb ler_p"><u> OTROS CARGOS:</u></p>
                          <div style={{ display: "flex" }}>
                            <p className="MODULO ler_p">APERTURA: </p>
                            <div class="eur3" style={{ marginLeft: "23px" }} >{list['opening']}</div>
                            <p className="MODULO"> EUR</p>
      
                          </div> */}
                          {/* <div style={{ display: "flex", marginTop: "-11px" }}>
                            <p className="MODULO ler_p">CANCELACION: </p>
                            <div class="eur3" style={{ marginLeft: "23px" }} >{list['early_cancellation']}</div>
                            <p className="MODULO"> EUR</p>
      
                          </div>       */}
      
                        </div>

      
                        {/* right */}
                        <div>
                          <p className="cardtextb ler_p">FECHA    23/06/2023 </p>
                          <p className="cardtextn ler_p"> DISPONINILIDAD: <span className="cardtextb">36 meses</span></p>
                          <p className="cardtextn ler_p"> FRECUENCIA DE<br /> REEMBOLSO: <span className="cardtextb" style={{ marginLeft: "25px" }}>{list['payment_frequency']}</span></p>
                          <p className="cardtextn ler_p"> COBRO POR<br /> RENOVACION: <span className="cardtextb" style={{ marginLeft: "20px" }}>  {list['interest_rate']}</span></p>
                        
                          {/* <div style={{ display: "flex", marginTop: "27px" }}>
                            <p className="MODULO ler_p">MANTENIMIENTO: </p>
                            <div class="eur3" style={{ marginLeft: "23px" }} >{list['maintenance']}</div>
                            <p className="MODULO"> EUR</p>
      
                          </div>
                          <div style={{ display: "flex", marginLeft: "38px", marginTop: "-11px" }}>
                            <p className="MODULO ler_p">RETRASO: </p>
                            <div class="eur3" style={{ marginLeft: "23px" }} > {list['delay']}</div>
                            <p className="MODULO"> EUR</p>
      
                          </div> */}

                        </div>
      
      
      
      
      
      
      
                      </div>
      
      
      
      
                    </div>
      
      
                  </div>
      
                </div>

                )
        })
      }
         
       
        </div>
        </section>

          </TabPanel>
            {/* </AppBar> */}


          </div>

        </div>
      </section>






    </React.Fragment>
     : <Navigate to="/" replace />
  )
}



function TabPanel(props) {
  const { children, value, index } = props;
  return (
    <div>
      {
        value === index &&
        (
          <h1>{children}</h1>
        )
      }

    </div>
  )
}





// $(document).ready(function () {
//   $(".circle_percent").each(function () {
//     var $this = $(this),
//       $dataV = $this.data("percent"),
//       $dataDeg = $dataV * 3.6,
//       $round = $this.find(".round_per");
//     $round.css("transform", "rotate(" + parseInt($dataDeg + 180) + "deg)");
//     $this.append('<div class="circle_inbox"><span class="percent_text"></span></div>');
//     $this.prop('Counter', 0).animate({ Counter: $dataV },
//       {
//         duration: 2000,
//         easing: 'swing',
//         step: function (now) {
         
//           $this.find(".percent_text").text(Math.ceil(now) + "");
//         }
//       });
//     if ($dataV >= 51) {
//       $round.css("transform", "rotate(" + 360 + "deg)");
//       setTimeout(function () {
//         $this.addClass("percent_more");
//       }, 1000);
//       setTimeout(function () {
//         $round.css("transform", "rotate(" + parseInt($dataDeg + 180) + "deg)");
//       }, 1000);
//     }
//   });
// });
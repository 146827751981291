import React from "react";
// import * as con from '../repository/urls.js';
import * as apis from '../repository/api_calls.js';
import * as images from '../pages/images.jsx';
import { useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';


export default function Mail_Reset() {

    const navigate = useNavigate();

    const emailRef = React.useRef();
    const pwdRef = React.useRef();
    const conformpwdRef = React.useRef();

    const conformpwd = (event) => {
        event.preventDefault();
        const email = emailRef.current.value
        const password = pwdRef.current.value
        const conformpassword = conformpwdRef.current.value
        if (password === conformpassword) {



                ConformPassword();
            
              async function ConformPassword() {
                const response = await apis.ConformPassword(JSON.stringify({
                    "email": email,
                    "password": password,
                    "confirm_password": conformpassword
                }),);
                console.log(response)
                if (response['status'] === true) {
                    toast.success(response['message'], {
                        position: toast.POSITION.TOP_RIGHT,
                        className: 'foo_bar_log_Success'
                    });
                    setTimeout(() => {
                        navigate('/auth/login');
                    }, 700);
                }
                else {
                    toast.error(response['message'], {
                        position: toast.POSITION.TOP_RIGHT,
                        className: 'foo_bar_log_error'
                    });
                }
                
              }
            
        //     fetch(`${process.env.REACT_APP_BASE_URL}${con.confirm_pwd}`, {
        //         method: 'post',
        //         body: JSON.stringify({
        //             "email": email,
        //             "password": password,
        //             "confirm_password": conformpassword
        //         }),
        //         headers: {
        //             'Content-Type': 'application/json'
        //         }
        //     }).then(async response => {
        //         var res = await response.json();
        //         if (res['status'] === true) {
        //             toast.success(res['message'], {
        //                 position: toast.POSITION.TOP_RIGHT,
        //                 className: 'foo_bar_log_Success'
        //             });
        //             setTimeout(() => {
        //                 navigate('/auth/login');
        //             }, 700);
        //         }
        //         else {
        //             toast.error(res['message'], {
        //                 position: toast.POSITION.TOP_RIGHT,
        //                 className: 'foo_bar_log_error'
        //             });
        //         }

        //     }).catch(error => {
        //         console.error('There was an error!', error);
        //     });
        // } else {
        //     toast.error("La contraseña y la contraseña de confirmación no coinciden.", {
        //         position: toast.POSITION.TOP_RIGHT,
        //         className: 'foo_bar_log_error'
        //     });
        }
    }



    return (
        <React.Fragment>
            <section className="lo_x">
                <div className="d-md-flex vh-100">
                    <div className="col-md-7 order-2 my-auto">
                        <div className="d-md-flex align-items-center p-3 justify-content-center">
                            <div data-aos="fade-up" style={{ width: 420 }}>
                                <div className="row mb-3">
                                    <div className="col-md-8 mb-2">
                                        <p id='log_eror' className="indima hidden_message"  ></p>
                                        <h4 className="">cambia tu contraseña</h4>
                                    </div>
                                </div>

                                <ToastContainer autoClose={3000} />
                                <form onSubmit={conformpwd} method="post" className="form-signin">
                                    <div className="form-label-group">
                                        <input
                                            className="form-control"
                                            id="email"
                                            name="email"
                                            placeholder="Dirección de correo"
                                            required
                                            type="email"
                                            ref={emailRef}
                                            style={{ height: 45 }}
                                        />
                                        <label>Dirección de correo</label>
                                    </div>

                                    <div className="form-label-group">
                                        <input
                                            className="form-control"
                                            id="password"
                                            name="password"
                                            placeholder="Nueva contraseña"
                                            required
                                            minLength={6} maxLength={12}
                                            type="password"
                                            ref={pwdRef}
                                            style={{ height: 45 }}
                                        />
                                        <label>Nueva contraseña</label>
                                    </div>

                                    <div className="form-label-group">
                                        <input
                                            className="form-control"
                                            id="password"
                                            name="password"
                                            placeholder="confirmar Contraseña"
                                            required
                                            minLength={6} maxLength={12}
                                            type="password"
                                            ref={conformpwdRef}
                                            style={{ height: 45 }}
                                        />
                                        <label>confirmar Contraseña
                                        </label>
                                    </div>

                                    <div className="form-group d-grid mt-5"  >
                                        <input
                                            className="btn  btn-primary-orange btn-block mb-3"
                                            id="submit"
                                            name="submit"
                                            type="submit"
                                            value="Restablecer"
                                            style={{ width: "200px", fontSize: "18px", display: "block", margin: "0px auto" }}
                                        />
                                    </div>
                                </form>


                            </div>
                        </div>
                    </div>

                    {/* <!-- First Half --> */}


                    <div className="col-md-4  leftsplit order-1 vh-100 bg-dark text-light reg-img-bg">
                        <div className="d-flex flex-column align-items-center p-5 loginmargin-top">
                            <img className="" src={images.Logo} alt='Logo' height="40px" />
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}
import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import * as images from './images.jsx';
import UserHeader from '../user/component/header.jsx';

export default function About() {

  useEffect(() => {
    const block = document.querySelectorAll('.block');
    block.forEach(item => {
      let numElement = item.querySelector('.num');
      let num = parseInt(numElement.innerText);
      let count = 0;
      let time = 2000 / num;
      let circle = item.querySelector('.circle_ab');
      setInterval(() => {
        if (count === num) {
          clearInterval();
        } else {
          count += 1;
          numElement.innerText = count;
        }
      }, time);
      circle.style.strokeDashoffset = 503 - (503 * (num / 100));
      let dots = item.querySelector('.dots');
      dots.style.transform = `rotate(${360 * (num / 100)}deg)`;
      if (num === 100) {
        dots.style.opacity = 0;
      }
    });
  }, []);

  return (
    <React.Fragment>
      {/* <header className="header-area">
        <div className="navbar-area" >
          <div className="container">
            <nav className="site-navbar">
              <Link to="/">
                <img className="logo" src={images.Logo} alt='Logo' />
              </Link>
              <button className="nav-toggler">
                <span></span>
              </button>
            </nav>
          </div>
        </div>
      </header> */}
      <UserHeader />


      <div className="vh-100 bg-dark">
        <div className="container mb-5 pt-5 text-white">
          <div className="row pt-5">
            <div className="col-md-6 pt-5 mx-auto">
              <h2>
                <b>Nuestra Misión</b>
              </h2>
              <p>
                Credit Store es la herramienta digital de asesoramiento financiero,
                ético y transparente para las PYMES. Somos la brújula e instrumento
                indispensable para darles fuerza de negociación en su búsqueda de
                financiación.
              </p>
              {/*<p>
              Fuimos testigos de como la última crisis impactó a la sociedad, especialmente a los más vulnerables
              del tejido empresarial, las pymes. Poca cosa se hizo para ayudarlas y las dejaron tiradas.
          </p>*/}
              <p>
                Creemos que TODAS las empresas, sean grandes o pequeñas, deben tener
                acceso a oportunidades y condiciones justas de financiación. Esto se
                logra mediante la democratización de las herramientas que ahora solo
                están al alcance de los bancos y las instituciones financieras.
              </p>
              <p>
                Con esto en mente, creamos una solución práctica, instantánea y fácil
                de usar para conectar a la empresa con la oferta de préstamo/crédito
                que mejor se adapte a sus necesidades.
              </p>
            </div>

            <div className="col-md-2">
            </div>
            <div className="col-md-4 text-center my-auto ">

              <div className="block mt-5">
                <div className="box">
                  <p className="number">
                    <span className="num">80</span>
                  </p>
                </div>
                <span className="dots" />
                <svg className="svg">
                  <defs>
                    <linearGradient id="gradientStyle">
                      <stop offset="0%" stopColor="#0069CB" />
                      <stop offset="100%" stopColor="#F2994A" />
                    </linearGradient>
                  </defs>
                  <circle className="circle_ab" cx={90} cy={90} r={80} />
                </svg>
              </div>


            </div>
          </div>
        </div>
      </div>


      {/* <!--footer --> */}

      <footer className="bacgco">
        <div className="container">
          <div className="row footerr" >
            <div className="col-md-3 ">
              <p className="fotcol">2023 Credit Store</p>

            </div>
            <div className="col-md-3 fot_mob">
              <div className="displaygrid">
                <label className="text-white">Menu</label>

                <Link to="/auth/Register" className="fotcol">Crear cuenta </Link>
                <Link to="/auth/Login" className="fotcol">Iniciar Sesión </Link>
                <Link to="/about" className="fotcol">Sobre Credit Store </Link>
                <Link to="/contact" className="fotcol">Contacto </Link>
              </div>

            </div>
            <div className="col-md-3 fot_mob">
              <div className="displaygrid">
                <label className="text-white">Seguridad</label>
                <Link to="/warning" className="fotcol">Aviso Legal </Link>
                <Link to="/dataprotect" className="fotcol">Política de Privacidad </Link>
                <Link to="/condition" className="fotcol">Condiciones Generales </Link>
                <Link to="/cookies" className="fotcol">Política de Uso de Cookies </Link>
              </div>

            </div>
            <div className="col-md-3">
              <div className="displaygrid">
                <Link to="https://twitter.com/CreditStoreLine" className="fotcol"><span className="footericon"><i className="fa fa-twitter-square footericon " aria-hidden="true" ></i></span>Twitter </Link>
                <Link to="https://www.linkedin.com/company/thecreditstore/" className="fotcol"><span span className="footericon"><i className="fa fa-linkedin-square footericon" aria-hidden="true" ></i></span>LinkedIn </Link>
              </div>


            </div>
          </div>
        </div>
      </footer>



    </React.Fragment>
  )
}
import React from "react";
import { Link } from "react-router-dom";
import * as images from './images.jsx';
import UserHeader from "../user/component/header.jsx";


export default function Blog() {



  return (
    <React.Fragment>

        <UserHeader />

        <section style={{ marginTop: 80, marginBottom: 50 }}>
            <div className="container">
                <div style={{ display: "flex" }}>
                    <label style={{ backgroundColor: "#202634", color: "#fff", padding: 10 }}>
                    Siendo tendencia ahora{" "}
                    </label>
                    <p style={{ marginLeft: 15, marginTop: 5 }}>
                        Lo que necesitas saber a la hora de solicitar un ENISA: Empresa Nacional
                        de Inovación
                    </p>
                </div>
            </div>
        </section>

        
            <section>
                <div className="container">
                    <div className="blog te_flex">
                        <div className="blog1">
                            <Link to= "https://blog.creditstore.net/enisa-empresa-nacional-de-innovacion/"
                          
                                style={{ textDecoration: "none" }}>
                                
                                <p className="blogtext1">Programas de financiación</p>
                                <p className="blogtext2">
                                    Lo que necesitas saber a la hora de solicitar un ENISA: Empresa
                                    Nacional de Inovación
                                </p>
                                <p className="blogtext3">01/12/2022</p>
                            </Link>
                        </div>
                        <div>
                            <Link to="https://blog.creditstore.net/prestamos-para-empresas-como-pedir-un-credito/"
                            
                                style={{ textDecoration: "none" }}>
                            </Link>
                            <div className="blog2">
                                <Link to="https://blog.creditstore.net/prestamos-para-empresas-como-pedir-un-credito/"
                                  
                                    style={{ textDecoration: "none" }}>
                                
                                    <div style={{ paddingTop: 80 }}>
                                        {" "}
                                        <p className="blogtext12">Educación sobre préstamos</p>
                                    </div>
                                    <p className="blogtext22">
                                         Préstamos para empresas, cómo pedir un crédito
                                    </p>
                                    <p className="blogtext32">14/11/2022</p>
                                </Link>
                            </div>
                                <Link to="https://blog.creditstore.net/financiacion-para-empresas/"
                              
                                    style={{ textDecoration: "none" }}   >
                                </Link>
                            <div className="blog3">
                                <Link to="https://blog.creditstore.net/financiacion-para-empresas/"
                                   
                                        style={{ textDecoration: "none" }}>
                                
                                    <div style={{ paddingTop: 80 }}>
                                        <p className="blogtext12">Educación sobre préstamos </p>
                                    </div>
                                        <p className="blogtext22">
                                            Financiación para empresas pequeñas y medianas
                                        </p>
                                        <p className="blogtext32">15/10/2022</p>
                                </Link>
                            </div>
                        </div>
                        <div>
                                <Link to="https://blog.creditstore.net/guia-de-caracteristicas-de-los-prestamos/"
                      
                                    style={{ textDecoration: "none" }}>
                                </Link>
                            <div className="blog4">
                                <Link to="https://blog.creditstore.net/guia-de-caracteristicas-de-los-prestamos/"
                          
                                    style={{ textDecoration: "none" }} >
                        
                                    <div style={{ paddingTop: 80 }}>
                                        <p className="blogtext12">Educación sobre préstamos</p>
                                    </div>
                                        <p className="blogtext22">
                                            Guía práctica para entender las características de los préstamos
                                        </p>
                                        <p className="blogtext32">26/10/2022</p>
                                </Link>
                            </div>
                                <Link to="https://blog.creditstore.net/agencias-de-credito-y-sus-usos/"
                       
                                     style={{ textDecoration: "none" }}>
                                </Link>
                            <div className="blog5">
                                <Link to="https://blog.creditstore.net/agencias-de-credito-y-sus-usos/"
                          
                                        style={{ textDecoration: "none" }} >
                       
                                    <div style={{ paddingTop: 80 }}>
                                        {" "}
                                        <p className="blogtext12">Credit Stores</p>
                                    </div>
                                        <p className="blogtext22">Agencias de créditos y sus usos</p>
                                        <p className="blogtext32">14/10/2022</p>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>





            <section style={{ marginTop: 50, marginBottom: 100 }}>
                <div className="container">
                        <div className="infinite-carousel">
                            <div id="carousel" className="carousel-wrapper">
                                <div className="carousel-items">
                                    <div className="carousel-block">
                                        <div style={{ display: "flex" }}>
                                            {/* <img alt='' src={require('../assets/img/blog_caro1.jpg')}  style={{ height: 75, marginRight: 10 }} /> */}
                                            <img  className="" src={images.Bfac} alt='FACTORES' style={{ height: 75, marginRight: 10 }} />
                                            <Link to="https://blog.creditstore.net/factores-importantes-al-pedir-un-prestamo/">
                                                <div style={{ display: "grid" }}>
                                                    <p>FACTORES IMPORTANTES AL PEDIR UN PRÉSTAMO</p>
                                                    <p>09/07/2021</p>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="carousel-block ">
                                        <div style={{ display: "flex" }}>
                                            {/* <img alt='' src={require('../assets/img/blog_caro2.jpg')}  style={{ height: 75, marginRight: 10 }} /> */}
                                            <img  className="" src={images.Cov} alt='SOBREVIVIENDO' style={{ height: 75, marginRight: 10 }} />
                                            <Link to="https://blog.creditstore.net/covid-19-y-sobreviviendo-a-traves-de-la-pandemia/">
                                                <div style={{ display: "grid" }}>
                                                    <p>COVID-19 Y SOBREVIVIENDO A TRAVÉS DE LA PANDEMIA</p>
                                                    <p>13/08/2021</p>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="carousel-block">
                                        <div style={{ display: "flex" }}>
                                            {/* <img alt='' src={require('../assets/img/blog_caro3.png')}  style={{ height: 75, marginRight: 10 }} /> */}
                                            <img  className="" src={images.Glosario} alt='Glosario' style={{ height: 75, marginRight: 10 }}  />
                                            <Link to="https://blog.creditstore.net/glosario-de-terminos-financieros-importantes/">
                                                <div style={{ display: "grid" }}>
                                                    <p>Glosario de Términos Financieros Importantes</p>
                                                    <p>23/09/2022</p>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="carousel-block ">
                                        <div style={{ display: "flex" }}>
                                            {/* <img alt='' src={require('../assets/img/blog_caro4.jpg')}  style={{ height: 75, marginRight: 10 }} /> */}
                                            <img  className="" src={images.Credit} alt='blog_caro'  style={{ height: 75, marginRight: 10 }} />
                                            <Link to="https://blog.creditstore.net/que-es-un-credit-score/">
                                                <div style={{ display: "grid" }}>
                                                    <p>¿Qué es un Credit Store?</p>
                                                    <p>06/10/2022</p>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="carousel-block">
                                        <div style={{ display: "flex" }}>
                                            {/* <img alt='' src={require('../assets/img/blog_caro5.png')}  style={{ height: 75, marginRight: 10 }} /> */}
                                            <img  className="" src={images.blog_caro5} alt='blog_caro5' style={{ height: 75, marginRight: 10 }} />
                                            <Link to="https://blog.creditstore.net/guia-de-financiacion-ico-para-pymes-y-emprendedores/">
                                                <div style={{ display: "grid" }}>
                                                    <p>Guía de Financiación ICO para PYMEs y Emprendedores</p>
                                                    <p>08/10/2022</p>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="carousel-block ">
                                        <div style={{ display: "flex" }}>
                                         {/* <img alt='' src={require('../assets/img/blog_caro6.jpg')}  style={{ height: 75, marginRight: 10 }} /> */}
                                         <img  className="" src={images.blog_caro6} alt='blog_caro6' style={{ height: 75, marginRight: 10 }} />
                                            <Link to="https://blog.creditstore.net/prestamos-y-entender-como-funcionan/">
                                                <div style={{ display: "grid" }}>
                                                    <p>Préstamos: entender cómo funcionan</p>
                                                    <p>11/10/2022</p>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="carousel-block">
                                        <div style={{ display: "flex" }}>
                                            {/* <img alt='' src={require('../assets/img/blog_caro7.jpg')}  style={{ height: 75, marginRight: 10 }} /> */}
                                            <img  className="" src={images.blog_caro7} alt='blog_caro7' style={{ height: 75, marginRight: 10 }}  />
                                            <Link to="https://blog.creditstore.net/agencias-de-credito-y-sus-usos/">
                                                <div style={{ display: "grid" }}>
                                                    <p>Agencias de créditos y sus usos</p>
                                                    <p>14/10/2022</p>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="carousel-block ">
                                        <div style={{ display: "flex" }}>
                                         {/* <img alt='' src={require('../assets/img/blog_caro8.png')}  style={{ height: 75, marginRight: 10 }} /> */}
                                         <img  className="" src={images.blog_caro8} alt='blog_caro8' style={{ height: 75, marginRight: 10 }} />
                                            <Link to="https://blog.creditstore.net/financiacion-para-empresas/">
                                                <div style={{ display: "grid" }}>
                                                    <p>Financiación para empresas pequeñas y medianas</p>
                                                    <p>15/10/2022</p>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                    {/* ------------------ */}
                                    <div className="carousel-block ">
                                        <div style={{ display: "flex" }}>
                                         {/* <img alt='' src={require('../assets/img/blog_caro9.png')}  style={{ height: 75, marginRight: 10 }} /> */}
                                         <img  className="" src={images.blog_caro9} alt='blog_caro9' style={{ height: 75, marginRight: 10 }} />
                                            <Link to="https://blog.creditstore.net/guia-de-caracteristicas-de-los-prestamos/">
                                                <div style={{ display: "grid" }}>
                                                    <p>
                                                        Guía práctica para entender las características de los
                                                        préstamos
                                                    </p>
                                                    <p>26/10/2022</p>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="carousel-block ">
                                        <div style={{ display: "flex" }}>
                                         {/* <img alt='' src={require('../assets/img/blog_caro10.png')}  style={{ height: 75, marginRight: 10 }} /> */}
                                         <img  className="" src={images.blog_caro10} alt='blog_caro10' style={{ height: 75, marginRight: 10 }} />
                                            <Link to="https://blog.creditstore.net/prestamos-para-empresas-como-pedir-un-credito/">
                                                <div style={{ display: "grid" }}>
                                                    <p>Préstamos para empresas, cómo pedir un crédito</p>
                                                    <p>14/11/2022</p>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="carousel-block ">
                                        <div style={{ display: "flex" }}>
                                         {/* <img alt='' src={require('../assets/img/blog_caro11.jpeg')}  style={{ height: 75, marginRight: 10 }} /> */}
                                         <img  className="" src={images.blog_caro11} alt='blog_caro11' style={{ height: 75, marginRight: 10 }} />
                                            <Link to="https://blog.creditstore.net/enisa-empresa-nacional-de-innovacion/ ">
                                                <div style={{ display: "grid" }}>
                                                    <p>
                                                        Lo que necesitas saber a la hora de solicitar un ENISA:
                                                        Empresa Nacional de Inovación
                                                    </p>
                                                    <p>01/12/2022</p>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="cor_but">
                            <button id="prev" className="carousel-button-left btn_caros">
                                <i className="fa fa-caret-left" aria-hidden="true" />
                            </button>
                            <button id="next" className="carousel-button-right btn_caros">
                                <i className="fa fa-caret-right" aria-hidden="true" />
                            </button>
                        </div>
                </div>
             
            </section>







            <section style={{ marginTop: 50, marginBottom: 50 }}>
                <div className="container">
                    <div style={{ display: "flex" }}>
                        <Link to="https://blog.creditstore.net/enisa-empresa-nacional-de-innovacion/"
                            style={{ textDecoration: "none" }}>
                                 {/* <img alt='' src={require('../assets/img/blog1.jpeg')} className="blo_im1" /> */}
                        
                                 <img  className="blo_im1" src={images.blog1} alt='blog1' />
                        </Link>
                        <div style={{ margin: "3px 28px" }}>
                            <p style={{ fontSize: 24 }}>
                                Lo que necesitas saber a la hora de solicitar un ENISA: Empresa
                                Nacional de Inovación
                            </p>
                            <p style={{ fontSize: 14 }}>01/12/2022 | Programas de financiación</p>
                            <p style={{ fontSize: 14 }}>
                                ENISA es una empresa pública que depende de la Dirección General de
                                Industria y de las PYMEs, integrada a su vez en el Ministerio de
                                Industria, Comercio y Turismo del gobierno español. ENISA concede
                                préstamos participativos a través de tres programas dirigidos a
                                jóvenes empresarios, emprendedores y empresas en fase de crecimiento.
                                Desde su creación…
                            </p>
                        </div>
                    </div>
                    <div style={{ display: "flex", marginTop: 20 }}>
                        <Link to="https://blog.creditstore.net/prestamos-para-empresas-como-pedir-un-credito/"
                            style={{ textDecoration: "none" }} >
                                 {/* <img alt='' src={require('../assets/img/blog5.png')} className="blo_im1" /> */}
                       
                                 <img  className="blo_im1" src={images.blog5} alt='blog5' />
                        </Link>
                        <div style={{ margin: "3px 28px" }}>
                            <p style={{ fontSize: 24 }}>
                                Préstamos para empresas, cómo pedir un crédito
                            </p>
                            <p style={{ fontSize: 14 }}>14/11/2022 | Educación sobre préstamos</p>
                            <p style={{ fontSize: 14 }}>
                                Para una empresa siempre es fundamental contar con un colchón
                                económico que permita arrancar el proyecto, soportar los gastos y
                                realizar las inversiones que se consideren oportunas, como la compra
                                de stock o la contratación de servicios como publicidad o mejora de la
                                infraestructura. Un préstamo es siempre una de las mejores
                                herramientas a nuestro …
                            </p>
                        </div>
                    </div>
                    <div style={{ display: "flex", marginTop: 20 }}>
                        <Link to="https://blog.creditstore.net/guia-de-caracteristicas-de-los-prestamos/"
                            style={{ textDecoration: "none" }} >

                            {/* <img alt='' src={require('../assets/img/blog2.png')} className="blo_im1" /> */}
                       
                            <img  className="blo_im1" src={images.blog2} alt='blog2' />
                        </Link>
                        <div style={{ margin: "3px 28px" }}>
                            <p style={{ fontSize: 24 }}>
                                 Guía práctica para entender las características de los préstamos
                            </p>
                            <p style={{ fontSize: 14 }}>26/10/2022 | Loan Education</p>
                            <p style={{ fontSize: 14 }}>
                                Sin conocimientos previos sobre los contratos de préstamo, puede ser
                                difícil comparar las ofertas. Por suerte, estamos aquí para ayudarte
                                con una guía sobre algunas de las características más comunes de los
                                préstamos que puedes encontrar. Tasas fijas y tasas variables de
                                préstamos¿Qué es un préstamo a tipo fijo?Los prést…
                            </p>
                        </div>
                    </div>
                </div>
            </section>



            <section style={{ marginTop: 100, marginBottom: 100 }}>
                <div className="container">
                <img alt='' src={ require('../assets/img/credit_store_logo_light.svg').default}  style={{ height: 50, marginBottom: 30 }} />
                <img  className="" src={images.credit_store_logo_light} alt='credit_store_logo_light' style={{ height: 50, marginBottom: 30 }}  />
                    <p style={{ marginLeft: 10 }}>
                        El asesor financiero ético y transparente para las pymes. Ahorra tiempo y
                        esfuerzo en tus decisiones financieras gracias a nuestro Score crediticio
                        empresarial y optimiza tus cuentas con sugerencias personalizadas.
                    </p>
                </div>
            </section>










  

                              {/* <!--footer --> */}
                              
      <footer className="bacgco">
        <div className="container">
          <div className="row footerr" >
            <div className="col-md-3 ">
                <p className="fotcol">2023 Credit Store</p>
                
            </div>
            <div className="col-md-3 fot_mob">
              <div className = "displaygrid">
                <label className="text-white">Menú</label>

                  <Link to="/auth/Register" className="fotcol">Crear cuenta </Link>
                  <Link to="/auth/Login" className="fotcol">Iniciar Sesión </Link>
                  <Link to="/about" className="fotcol">Sobre Credit Store </Link>
                  <Link to="/contact" className="fotcol">Contacto </Link>
              </div>
                
            </div>
            <div className="col-md-3 fot_mob">
              <div className = "displaygrid">
                <label className="text-white">Seguridad</label>
                  <Link to="/warning" className="fotcol">Aviso Legal </Link>
                  <Link to="/dataprotect" className="fotcol">Política de Privacidad </Link>
                  <Link to="/condition" className="fotcol">Condiciones Generales </Link>
                  <Link to="/cookies" className="fotcol">Política de Uso de Cookies </Link>
              </div>
                
            </div>
            <div className="col-md-3">
              <div className = "displaygrid">
                  <Link to="https://twitter.com/CreditStoreLine" className="fotcol"><span className="footericon"><i className="fa fa-twitter-square footericon " aria-hidden="true" ></i></span>Twitter </Link>
                  <Link to="https://www.linkedin.com/company/thecreditstore/" className="fotcol"><span span className="footericon"><i className="fa fa-linkedin-square footericon" aria-hidden="true" ></i></span>LinkedIn </Link>
              </div>
                            
                
            </div>
          </div>
        </div>
      </footer>






    </React.Fragment>
  )
}


// var slidesShow = 4;       // set number of displayed slides
// var buttons = true;       // show/hide buttons (true or false)
// var autoScroll = false;   // turn on/off slides auto-scroll (true or false) 
// var scrollSpeed = 2000;   // set slide scrolling speed

// // ---------------------------------------------

// var blockLen = document.querySelectorAll('.carousel-block').length;
// var show;


// $(document).ready(function() {
  
//   // conditions for variable 'show' 
//   if (slidesShow > blockLen) {
//     show = blockLen;
//   }
//   else if (slidesShow <= 0) {
//     show = 1;
//   }
//   else if (1 <= slidesShow <= blockLen) {
//     show = slidesShow;
//   }
 

//   let w = $('.infinite-carousel').width() / show;
//   let oneBlockWidthCreate = $('.carousel-block').width(w);
 

//   // set one block's width
//   $(document).ready(function() {
//     $('.carousel-block').each(function(index) {
//       $(this).css('width', oneBlockWidthCreate);
//     });
//   });


//   // clone block's array to the right sides
//   $('.carousel-items .carousel-block').clone().appendTo($('.carousel-items'));


//   // --------drag slides & mousemove--------  
//   let threshold = 100;
//   let carouselOneBlockWidth = $('#carousel').find('.carousel-block').eq(1).outerWidth();  

//   let carousel = document.querySelector('#carousel');
//   let carouselInner = carousel.querySelector('.carousel-items');
//   let items = carousel.querySelectorAll('.carousel-block');
  

//   // offset position of the block
//   carouselInner.style = ('left:' + '-' + carouselOneBlockWidth + 'px');  


//   // move the last element before the first
//   items[items.length-1].style = 'order: -1;';   
  
//   $('.carousel-block').width(w);


//   // drag functionality 
//   let slideActive = false;
//   let startX;
//   let currentX;
//   let offsetX = 0;
//   let order = 1;
  

//   carouselInner.addEventListener('touchstart', dragStart, false);
//   document.addEventListener('touchend', dragEnd, false);
//   document.addEventListener('touchmove', drag, false);

//   carouselInner.addEventListener('mousedown', dragStart, false);
//   document.addEventListener('mouseup', dragEnd, false);
//   document.addEventListener('mousemove', drag, false);

//   function dragStart(e) {
//     carouselInner.style.cursor = 'grabbing';
//     if (e.type === 'touchstart') {
//       startX = e.touches[0].clientX;
//     } 
//     else {
//       startX = e.clientX;
//     }
//     slideActive = true;
//   }

//   function dragEnd(e) {
//     if (slideActive) {
//       if (currentX) {
//         offsetX = offsetX + currentX - startX;
//       }
//       carouselInner.style.cursor = 'grab';
//       slideActive = false;
//       snapCarousel();
//     }
//   }

//   function drag(e) {
//     if (slideActive) {
//       e.preventDefault();
//       if (e.type === "touchmove") {
//         currentX = e.touches[0].clientX;
//       }
//       else {
//         currentX = e.clientX;
//       }
//       translateCarousel(currentX - startX);
//       offsetX += currentX - startX;
//       startX = currentX;
//       checkOffset();
//     }
//   }

//   function translateCarousel(deltaX) {
//     carouselInner.style.transform = 'translateX(' + (offsetX + deltaX) + 'px)';
//   }

//   function snapCarousel() {  
//     let rounded = Math.round((offsetX % carouselOneBlockWidth) / carouselOneBlockWidth);
//     let snapped;


//     if (rounded === 0) {
//       snapped = offsetX - (offsetX % carouselOneBlockWidth);
//     } 
//     else {
//       snapped = offsetX + ((carouselOneBlockWidth - Math.abs(offsetX % carouselOneBlockWidth)) * rounded);
//     }
    
//     carouselInner.style.transform = 'translateX(' + snapped + 'px)';
//     offsetX = snapped;
//     checkOffset();
//   }

//   function checkOffset() {
//     let tempOffset = offsetX;
//     let direction = 0;


//     if (slideActive) {
//       tempOffset += currentX - startX;
//     }

//     if (tempOffset >= threshold) {
//       direction = -1;
//     } else if (tempOffset <= -threshold) {
//       direction = 1;
//     }

//     if (direction !== 0) {
//       translateCarousel(carouselOneBlockWidth * direction);
//       updateOrder(direction * -1);
//       offsetX += carouselOneBlockWidth * direction;
//     }
//   }

//   function updateOrder(direction) {
//     order = (order + direction + items.length) % items.length;
//     if (order === 0) {
//       items.forEach(function(item) {
//         item.style.order = 0;
//       });
//     } 
//     else if (order === items.length - 1) {
//       for (let i = 1; i < items.length; i++) {
//         items[i].style.order = -1;
//       }
//     } 
//     else if (direction === 1) {
//       items[items.length - order].style.order = -1;
//     } 
//     else {
//       items[items.length - order - 1].style.order = 0;
//     }
//   }

//   function wrapAround(v, delta, minval, maxval) {
//     const mod = maxval + 1 - minval;

//     v += delta - minval;
//     v += (1 - v / mod) * mod;
//     return v % mod + minval;
//   }


//   // --------buttons--------
//   $(document).ready(function() {
//     if (buttons) {
//        $('.nav').show();
       
//       // click prev
//       $(document).on("click", ".carousel-button-left", function() {
//         offsetX += carouselOneBlockWidth;
//         $(carousel).find(".carousel-items").css({ left: "-" + (carouselOneBlockWidth*2) + "px" });
//         checkOffset();
//         $(carousel).find(".carousel-items").animate({ left: "-" + carouselOneBlockWidth + "px" }, 300);
//       });

//       // click next
//       $(document).on("click", ".carousel-button-right", function() {
//         $(carousel).find(".carousel-items").animate({ left: "-" + (carouselOneBlockWidth*2) + "px"}, 300, function() {
//           offsetX -= carouselOneBlockWidth;
//           checkOffset();
//           $(carousel).find(".carousel-items").css({ left: "-" + carouselOneBlockWidth + "px" });
//         });

//       });
//     }
//     else{
//       $('.nav').hide();
//     }
//   })
  

//   // --------auto-scroll slides--------
//   $(function() {
//     if (!autoScroll) return;
//       auto_scroll('#carousel:first');
//   });

//   function auto_scroll(carousel) {
//     setInterval(function () {
//       if (!$('#carousel').is(".hover")) {
//         $(carousel).find(".carousel-items").animate({ left: "-" + (carouselOneBlockWidth*2) + "px"}, 300, function() {
//           offsetX -= carouselOneBlockWidth;
//           checkOffset();
//           $(carousel).find(".carousel-items").css({ left: "-" + carouselOneBlockWidth + "px" });
//         });
//       }
//     }, scrollSpeed);
 

//   // cursor hover to the carousel
//   $(document).on("mouseenter", "#carousel", function() {
//     $(this).addClass("hover");
//   });


//   // cursor leave to the carousel
//   $(document).on("mouseleave", "#carousel", function() {
//     $(this).removeClass("hover");
//   });
//   }
  

// });

import React, { useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import $ from 'jquery';
import DoneIcon from "@material-ui/icons/Done";
import { useState } from "react";
// import * as con from '../repository/urls.js';
import * as images from './images.jsx';
import * as apis from '../repository/api_calls.js';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';


export default function Getloan() {

    const navigate = useNavigate();
    // const location = useLocation();

    const params = new URLSearchParams(window.location.search);

    const indexValue = params.get('id');

    // console.log(localStorage.getItem('load'))
    // if(localStorage.getItem('load')!=null){
    //     localStorage.setItem('load',null);
    //     // window.href(/)
    //     // window.location.href = "/getloan";
    // }


    const handlekeydown = (e) =>{
        const restrictedkeys = new Set(['-', '+', '*', '/', 'e'])
        if(restrictedkeys.has(e.key)){
          e.preventDefault();
        }
      }
    // ------------ number validation -----


    const [values, setValues] = useState({
        field1: '', field2: '', field3: '', field4: '', field5: '', field6: ''
        // Add more fields as needed
    });

    const handleChange5 = (event) => {
        const { name, value } = event.target;
        if (value.length <= 8) {
            setValues((prevValues) => ({
                ...prevValues,
                [name]: value
            }));
        }
    };


    const [value] = React.useState(0);
    useEffect(() => {
        const range = document.getElementById('range');
        const tooltip = document.getElementById('tooltip');
        const updateSlider = () => {
            const newValue = Number((range.value - range.min) * 100 / (range.max - range.min));
            const newPosition = 16 - (newValue * 0.32);
            tooltip.innerHTML = `<span>${range.value}</span>`;
            tooltip.style.left = `calc(${newValue}% + (${newPosition}px))`;
            document.documentElement.style.setProperty("--range-progress", `calc(${newValue}% + (${newPosition}px))`);
        };
        range.addEventListener('input', updateSlider);
        return () => {
            range.removeEventListener('input', updateSlider);
        };
    }, []);


    //    --------------------------- Range 2 ----------------
    const [value2] = React.useState(0);
    useEffect(() => {

        const range2 = document.getElementById('range2');
        const tooltip2 = document.getElementById('tooltip2');
        const label = document.getElementById('label');

        const setValue2 = () => {
            const newValue1 = Number((range2.value - range2.min) * 100 / (range2.max - range2.min));
            const newPosition1 = 16 - (newValue1 * 0.32);
            tooltip2.innerHTML = `<span>${getLabel(range2.value)}</span>`;
            tooltip2.style.left = `calc(${newValue1}% + (${newPosition1}px))`;
            document.documentElement.style.setProperty("--range-progress2", `calc(${newValue1}% + (${newPosition1}px))`);

            // Update the label value
            label.innerText = getLabel(range2.value);
        };

        const getLabel = (value) => {
            const lowerBound = Math.floor(value / 10) * 10;
            const upperBound = lowerBound + 10;
            return `${lowerBound}-${upperBound}`;
        };

        setValue2();

        if (range2) {
            range2.addEventListener('input', setValue2);
        }

        return () => {
            if (range2) {
                range2.removeEventListener('input', setValue2);
            }
        };
    }, []);

    //   ---------------------- range 2 end -------------------

    //   ---------------------- Web https only enter function start -------------------

    // const setwebsiteRef = useRef(null);

    const [errorMessage, setErrorMessage] = useState('');



    // const handleInputChangeUrl = (event) => {
    //     const inputValue = event.target.value;
    //     const urlRegex = /^https:\/\//;

    //     if (!urlRegex.test(inputValue)) {
    //         console.log('Please enter a URL starting with "https://"');
    //     } else {
    //         console.log('Valid URL:', inputValue);
    //     }
    // };

    const handleInputChangeUrl = (e) => {
        const inputValue = e.target.value;
        const urlPattern = /^(https?:\/\/|www\.)[^\s/$.?#].[^\s]*$/;

        if (!urlPattern.test(inputValue)) {
            setErrorMessage('Introduzca una URL válida que comience con http://, https:// o www.');
        } else {
            setErrorMessage('');
        }
    };
    //   ---------------------- Web https only enter function end -------------------
    //    ------------------- fill data api ---------------


    // const [loanFilled, setLoanFilled] = React.useState({
    //     "loan": "not_Filled",
    //     "company_one": "not_Filled",
    //     "company_two": "not_Filled",
    //     "company_three": "not_Filled",
    //     "company_four": "not_Filled"
    // });
    const token = localStorage.getItem("token");

    useEffect(() => {
        getLoanFilled();
    }, [])

    async function getLoanFilled() {
        //get loan fill details
        const response = await apis.getLoanFilledData(token);
        if (response['status'] === true) {
            loadCss(response['data'], indexValue);
        }
    }

    const showAlert = () => {
        Swal.fire({
            title: '¡Todo listo y enviado!',
            text: 'Muy pronto tendrás respuesta',
            icon: 'success',
            confirmButtonText: 'OK',
        }).then((result) => {
            if (result.isConfirmed) {
                navigate('/user/company_register');
            }
        });
    };
    //for 3rd form add more
    const [fields, setFields] = React.useState([{ name: '', nif: '', percentage: '' }]);
    function handleChange(i, event) {
        const values = [...fields];
        values[i][event.target.name] = event.target.value;
        setFields(values);

        // if (value.length <= 8) {
        //     setValues((prevValues) => ({
        //       ...prevValues,
        //       [fields]: value
        //     }));
        //   }

    }

    function handleAdd() {
        const values = [...fields];
        values.push({ name: '', nif: '', percentage: '' });
        setFields(values);
    }

    function handleRemove(i) {
        const values = [...fields];
        values.splice(i, 1);
        setFields(values);
    }

    //for 4th form add more
    const [fields1, setFields1] = useState([{ fullname: '', NIF: '' }]);
    function handleChange1(i, event) {
        const values1 = [...fields1];
        values1[i][event.target.name] = event.target.value;
        setFields1(values1);
    }
    function handleAdd1() {
        const values1 = [...fields1];
        values1.push({ fullname: '', NIF: '' });
        setFields1(values1);
    }

    function handleRemove1(i) {
        const values1 = [...fields1];
        values1.splice(i, 1);
        setFields1(values1);
    }

    const marks = [
        {
            value: 10,
            label: '1-10',
        },
        {
            value: 20,
            label: '10-20',
        },
        {
            value: 30,
            label: '20-30',
        },
        {
            value: 40,
            label: '30-40',
        },
        {
            value: 50,
            label: '40-50',
        },
        {
            value: 60,
            label: '50-60',
        },
        {
            value: 70,
            label: '60-70',
        },
        {
            value: 80,
            label: '70-80',
        },
        {
            value: 90,
            label: '80-90',
        },
        {
            value: 100,
            label: '90-100',
        },
        {
            value: 110,
            label: '100- 110',
        },
        {
            value: 120,
            label: '110- 120',
        },
        {
            value: 130,
            label: '120- 130',
        },
        {
            value: 140,
            label: '130- 140',
        }, {
            value: 150,
            label: '140- 150',
        },
    ];


    const user_id = localStorage.getItem("id");
    const user_type = localStorage.getItem("user_type")
    const role_id = localStorage.getItem("role_id");
    const [cortax, setcortax] = React.useState('');
    const [localId, setLocalId] = React.useState('');
    // const [company_ran_id, setCompany_ran_id] = React.useState('');


    useEffect(() => {
        getDocCorpTax();
    }, [])

    async function getDocCorpTax() {
        var response = await apis.getDoccorporation_tax();
        setcortax(response['data']['name'])
    }  

    //   -----------------loan form api(first form)
    const loanAmountRef = React.useRef();
    const loanTermRef = React.useRef();
    const [paymentAndObligationsValue, setpaymentAndObligationsValue] = React.useState('yes')
    const loanTitleRef = React.useRef();
    const loanPurposeRef = React.useRef();
    const whyDoYouWhantRef = React.useRef();

    const handleSubmit = (event) => {
        event.preventDefault();
        formOne();
    }

    async function formOne() {
        const loanAmount = loanAmountRef.current.value;
        const loanTerm = loanTermRef.current.value;
        // const paymentAndObligations = paymentAndObligationsRef.current.value;
        const paymentAndObligations = paymentAndObligationsValue;
        const loanTitle = loanTitleRef.current.value;
        const loanPurpose = loanPurposeRef.current.value;
        const whyDoYouWhant = whyDoYouWhantRef.current.value;

        if (user_type === "guest_user" || user_type === null) {

            const response = await apis.getloanpage(JSON.stringify({
                "user_id": user_id,
                "loan_title": loanTitle,
                "loan_description": whyDoYouWhant,
                "loan_term": loanTerm,
                "loan_amount": loanAmount,
                "loan_reasons": loanPurpose,
                "payment_and_obligations": paymentAndObligations
            }));
            console.log(response);
            if (response['status'] === true) {
                setLocalId(response['data']['loan_ran_id']);
                localStorage.setItem('Loan_id', response['data']['id'])
                localStorage.setItem('status', response['data']['status'])
                addClassNext1();
                toast.success(response["message"], {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo_bar_log_Success'
                });

            }
            else {
                toast.error(response["message"], {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo_bar_log_error'
                });
            }
            // });
        }
        else if (user_type === "register_user") {

            const response = await apis.LoanForRegisterUser(JSON.stringify({
                "user_id": user_id,
                "loan_title": loanTitle,
                "loan_description": whyDoYouWhant,
                "loan_term": loanTerm,
                "loan_amount": loanAmount,
                "loan_reasons": loanPurpose,
                "payment_and_obligations": paymentAndObligations
            }));
            console.log(response);
            if (response['status'] === true) {
                addClassNext1();
                toast.success(response["message"], {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo_bar_log_Success'
                });
            }
            else {
                toast.error(response["message"], {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo_bar_log_error'
                });
            }

            // fetch(`${process.env.REACT_APP_BASE_URL}${con.getloan_register}`, {
            //     method: 'post',
            //     body: JSON.stringify({
            //         "user_id": user_id,
            //         "loan_title": loanTitle,
            //         "loan_description": whyDoYouWhant,
            //         "loan_term": loanTerm,
            //         "loan_amount": loanAmount,
            //         "loan_reasons": loanPurpose,
            //         "payment_and_obligations": paymentAndObligations
            //     }),
            //     headers: {
            //         "Authorization": `Bearer ${token}`,
            //         'Content-Type': 'application/json',
            //         'headers': { 'content-type': 'multipart/form-data' }
            //     }
            // }).then(async response => {
            //     var res = await response.json();
            //     if (res['status'] === true) {
            //         addClassNext1();
            //         toast.success(res["message"], {
            //             position: toast.POSITION.TOP_RIGHT,
            //             className: 'foo_bar_log_Success'
            //         });
            //     }
            //     else {
            //         toast.error(res["message"], {
            //             position: toast.POSITION.TOP_RIGHT,
            //             className: 'foo_bar_log_error'
            //         });
            //     }

            // });
        }
    }

    //   -----------------company form form api(second form)
    const operation_termRef = React.useRef();
    const [setAudited, setsetAudited] = React.useState('yes')
    const [setBusiness_group, setSetBusiness_groupRef] = React.useState('yes')
    const setStreetRef = React.useRef();
    const setFloorRef = React.useRef();
    const setApportment_numberRef = React.useRef();
    const setCityRef = React.useRef();
    const setZip_codeRef = React.useRef();
    const setDeviceRef = React.useRef();
    const setWebsiteRef = React.useRef();
    const setDescriptionRef = React.useRef();

    const handleSubmit2 = (event) => {
        event.preventDefault();
        const operation_term = operation_termRef.current.value;
        const setBusiness_gp = setBusiness_group;
        const setaudited = setAudited;
        const setStreet = setStreetRef.current.value;
        const setFloor = setFloorRef.current.value;
        const setApportment_number = setApportment_numberRef.current.value;
        const setCity = setCityRef.current.value;
        const setZip_code = setZip_codeRef.current.value;
        const setDevice = setDeviceRef.current.value;
        const setWebsite = setWebsiteRef.current.value;
        const setDescription = setDescriptionRef.current.value;
        if (user_type === "guest_user" || user_type === null) {

            GuestLoanComp();
            async function GuestLoanComp() {
                const response = await apis.GuestLoanCompany(
                    JSON.stringify({
                        "user_id": user_id,
                        "company_id": "",
                        "operation_term": operation_term,
                        "business_group": setBusiness_gp,
                        "audited": setaudited,
                        "street": setStreet,
                        "floor": setFloor,
                        "apportment_number": setApportment_number,
                        "city": setCity,
                        "zip_code": setZip_code,
                        "device": setDevice,
                        "website": setWebsite,
                        "description": setDescription
                    }),
                );
                console.log(response)
                if (response['status'] === true) {
                    localStorage.setItem("company_id", response["data"]["id"]);
                    localStorage.setItem('company_ran_id', response['data']['company_ran_id'])
                    setLocalId(response['data']['company_ran_id']);
                    addClassNext2();

                    toast.success('La info se han cargado correctamente', {
                        position: toast.POSITION.TOP_RIGHT,
                        className: 'foo_bar_log_Success'
                    });
                }
                else {
                    toast.error(response["message"], {
                        position: toast.POSITION.TOP_RIGHT,
                        className: 'foo_bar_log_error'
                    });
                }
            }

            // fetch(`${process.env.REACT_APP_BASE_URL}${con.getloan_get2}`, {
            //     method: 'post',
            //     body: JSON.stringify({
            //         "user_id": user_id,
            //         "company_id": "",
            //         "operation_term": operation_term,
            //         "business_group": setBusiness_gp,
            //         "audited": setaudited,
            //         "street": setStreet,
            //         "floor": setFloor,
            //         "apportment_number": setApportment_number,
            //         "city": setCity,
            //         "zip_code": setZip_code,
            //         "device": setDevice,
            //         "website": setWebsite,
            //         "description": setDescription
            //     }),
            //     headers: {
            //         'Content-Type': 'application/json',
            //     }
            // }).then(async response => {

            //     const response = await response.json();
            //     localStorage.setItem("company_id", comresult["data"]["id"]);
            //     if (comresult['status'] === true) {
            //         localStorage.setItem("company_id", comresult["data"]["id"]);
            //         localStorage.setItem('company_ran_id', comresult['data']['company_ran_id'])
            //         setLocalId(comresult['data']['company_ran_id']);
            //         addClassNext2();

            //         toast.success('La info se han cargado correctamente', {
            //             position: toast.POSITION.TOP_RIGHT,
            //             className: 'foo_bar_log_Success'
            //         });
            //     }
            //     else {
            //         toast.error(comresult["message"], {
            //             position: toast.POSITION.TOP_RIGHT,
            //             className: 'foo_bar_log_error'
            //         });
            //     }
            // });
      
        }
        else if (user_type === "register_user") {

            RegisterLoanComp();
            async function RegisterLoanComp() {
                const response = await apis.LoanCompanyRegister1(
                    JSON.stringify({
                        "company_id": "",
                    "operation_term": operation_term,
                    "business_group": setBusiness_gp,
                    "audited": setaudited,
                    "street": setStreet,
                    "floor": setFloor,
                    "apportment_number": setApportment_number,
                    "city": setCity,
                    "zip_code": setZip_code,
                    "device": setDevice,
                    "website": setWebsite,
                    "description": setDescription
                    }),
                );
                console.log(response)
                if (response['status'] === true) {
                    addClassNext2();

                    toast.success('La info se han cargado correctamente', {
                        position: toast.POSITION.TOP_RIGHT,
                        className: 'foo_bar_log_Success'
                    });
                }
                else {
                    toast.error(response["message"], {
                        position: toast.POSITION.TOP_RIGHT,
                        className: 'foo_bar_log_error'
                    });
                }
            }

            // fetch(`${process.env.REACT_APP_BASE_URL}${con.company_register2}`, {
            //     method: 'post',
            //     body: JSON.stringify({
            //         "company_id": "",
            //         "operation_term": operation_term,
            //         "business_group": setBusiness_gp,
            //         "audited": setaudited,
            //         "street": setStreet,
            //         "floor": setFloor,
            //         "apportment_number": setApportment_number,
            //         "city": setCity,
            //         "zip_code": setZip_code,
            //         "device": setDevice,
            //         "website": setWebsite,
            //         "description": setDescription
            //     }),
            //     headers: {
            //         "Authorization": `Bearer ${token}`,
            //         'Content-Type': 'application/json',
            //         'headers': { 'content-type': 'multipart/form-data' }
            //     }
            // }).then(async response => {
            //     var res = await response.json();
            //     if (res['status'] === true) {
            //         addClassNext2();

            //         toast.success('La info se han cargado correctamente', {
            //             position: toast.POSITION.TOP_RIGHT,
            //             className: 'foo_bar_log_Success'
            //         });
            //     }
            //     else {
            //         toast.error(res["message"], {
            //             position: toast.POSITION.TOP_RIGHT,
            //             className: 'foo_bar_log_error'
            //         });
            //     }
            // });
      
        }

    }

    //   -----------------company form form api(third form)
    // const setPartner_nameRef = React.useRef();
    // const setNifRef = React.useRef();
    // const setPercentageRef = React.useRef();
    const setPartner_fileRef = React.useRef();

    //   -----------------Encript file function -----------------

    const [setImageFile, setSetImageFile] = React.useState([]);
    const [setImageFile2, setSetImageFile2] = React.useState([]);
    const [setImageFile3, setSetImageFile3] = React.useState([]);
    const [setImageFile4, setSetImageFile4] = React.useState([]);
    const [setImageFile5, setSetImageFile5] = React.useState([]);
    const [setImageFile6, setSetImageFile6] = React.useState([]);
    const [setImageFile7, setSetImageFile7] = React.useState([]);

    function handleFileSelect(event) {
        event.preventDefault();
        const file = event.target.files[0];
        setSetImageFile(file)
    };

    const handleSubmit3 = (event) => {
        event.preventDefault();

        var nameList = [];
        var nifList = [];
        var percList = [];
        for (let item of fields) {
            nameList.push(item['name']);
            nifList.push(item['nif']);
            percList.push(item['percentage']);
        }

        const operation_term = operation_termRef.current.value;
        const setBusiness_gp = setBusiness_group;
        const setaudited = setAudited;
        const setStreet = setStreetRef.current.value;
        const setFloor = setFloorRef.current.value;
        const setApportment_number = setApportment_numberRef.current.value;
        const setCity = setCityRef.current.value;
        const setZip_code = setZip_codeRef.current.value;
        const setDevice = setDeviceRef.current.value;
        const setWebsite = setWebsiteRef.current.value;
        const setDescription = setDescriptionRef.current.value;
        const partner_name = nameList;
        const nif = nifList;
        const percentage = percList;
        const partner_file = setImageFile;
        if (user_type === "guest_user" || user_type === null) {


            const formData = new FormData();
            formData.append("user_id", user_id);
            formData.append("company_id", localStorage.getItem('company_id'));
            formData.append("operation_term", operation_term);
            formData.append("business_group", setBusiness_gp);
            formData.append("audited", setaudited);
            formData.append("street", setStreet);
            formData.append("floor", setFloor);
            formData.append("apportment_number", setApportment_number);
            formData.append("city", setCity);
            formData.append("zip_code", setZip_code);
            formData.append("device", setDevice);
            formData.append("website", setWebsite);
            formData.append("description", setDescription);
            formData.append("partner_name", partner_name);
            formData.append("nif", nif);
            formData.append("percentage", percentage);
            formData.append("partner_file", partner_file);



          
            async function GuestLoanCompUpdate() {
                const response = await apis.GuestLoanCompanyUpdate(formData);
                console.log(response)
                if (response['status'] === true) {
                    setLocalId(response['data']['company_ran_id']);
                    addClassNext3();

                    toast.success('La info se han cargado correctamente', {
                        position: toast.POSITION.TOP_RIGHT,
                        className: 'foo_bar_log_Success'
                    });
                } else {
                    toast.error(response["message"], {
                        position: toast.POSITION.TOP_RIGHT,
                        className: 'foo_bar_log_error'
                    });
                }
            }
            GuestLoanCompUpdate();

            // fetch(`${process.env.REACT_APP_BASE_URL}${con.getloan_get2_update}`, {
            //     method: 'post',
            //     body: formData,
            // }).then(async response => {
            //     var res = await response.json();
            //     if (res['status'] === true) {
            //         setLocalId(res['data']['company_ran_id']);
            //         addClassNext3();

            //         toast.success('La info se han cargado correctamente', {
            //             position: toast.POSITION.TOP_RIGHT,
            //             className: 'foo_bar_log_Success'
            //         });
            //     } else {
            //         toast.error(res["message"], {
            //             position: toast.POSITION.TOP_RIGHT,
            //             className: 'foo_bar_log_error'
            //         });
            //     }
            // });


        }
        else if (user_type === "register_user") {


            const formData = new FormData();
            formData.append('company_id', localStorage.getItem('company_id'));
            formData.append('operation_term', operation_term);
            formData.append('business_group', setBusiness_gp);
            formData.append('audited', setaudited);
            formData.append('street', setStreet);
            formData.append('floor', setFloor);
            formData.append('apportment_number', setApportment_number);
            formData.append('city', setCity);
            formData.append('zip_code', setZip_code);
            formData.append('device', setDevice);
            formData.append('website', setWebsite);
            formData.append('description', setDescription);
            formData.append('partner_name', partner_name);
            formData.append('nif', nif);
            formData.append('percentage', percentage);
            formData.append('partner_file', partner_file);



            RegisterLoanCompUpdate();
            async function RegisterLoanCompUpdate() {
                const response = await apis.LoanUpdateRegister1(formData);
                console.log(response)
                if (response['status'] === true) {
                    addClassNext3();
                    toast.success('La info se han cargado correctamente', {
                        position: toast.POSITION.TOP_RIGHT,
                        className: 'foo_bar_log_Success'
                    });
                }
                else {
                    toast.error(response["message"], {
                        position: toast.POSITION.TOP_RIGHT,
                        className: 'foo_bar_log_error'
                    });
                }
            }



            // fetch(`${process.env.REACT_APP_BASE_URL}${con.getloan_register2}`, {
            //     method: 'post',
            //     body: formData,
            //     headers: {
            //         "Authorization": `Bearer ${token}`,
            //     }
            // }).then(async response => {
            //     var res = await response.json();
            //     if (res['status'] === true) {
            //         addClassNext3();
            //         toast.success('La info se han cargado correctamente', {
            //             position: toast.POSITION.TOP_RIGHT,
            //             className: 'foo_bar_log_Success'
            //         });
            //     }
            //     else {
            //         toast.error(res["message"], {
            //             position: toast.POSITION.TOP_RIGHT,
            //             className: 'foo_bar_log_error'
            //         });
            //     }
            // });
        }

    }


    // ------------------------------------- form 4 api --------------------------------


    // const setLegal_nameRef = React.useRef();
    // const setLegal_nifRef = React.useRef();

    const handleSubmit4 = (event) => {
        event.preventDefault();

        var nameList = [];
        var nifList = [];
        var percList = [];
        for (let item of fields) {
            nameList.push(item['name']);
            nifList.push(item['nif']);
            percList.push(item['percentage']);
        }

        var nameList1 = [];
        var nifList1 = [];
        for (let item of fields1) {
            nameList1.push(item['fullname']);
            nifList1.push(item['NIF']);
        }
        const operation_term = operation_termRef.current.value;
        const setBusiness_gp = setBusiness_group;
        const setaudited = setAudited;
        const setStreet = setStreetRef.current.value;
        const setFloor = setFloorRef.current.value;
        const setApportment_number = setApportment_numberRef.current.value;
        const setCity = setCityRef.current.value;
        const setZip_code = setZip_codeRef.current.value;
        const setDevice = setDeviceRef.current.value;
        const setWebsite = setWebsiteRef.current.value;
        const setDescription = setDescriptionRef.current.value;
        const partner_name = nameList;
        const nif = nifList;
        const percentage = percList;
        const partner_file = setImageFile;
        const legal_name = nameList1;
        const legal_nif = nifList1;
        if (user_type === "guest_user" || user_type === null) {

            const formData = new FormData();
            formData.append("user_id", user_id);
            formData.append("company_id", localStorage.getItem('company_id'));
            formData.append("operation_term", operation_term);
            formData.append("business_group", setBusiness_gp);
            formData.append("audited", setaudited);
            formData.append("street", setStreet);
            formData.append("floor", setFloor);
            formData.append("apportment_number", setApportment_number);
            formData.append("city", setCity);
            formData.append("zip_code", setZip_code);
            formData.append("device", setDevice);
            formData.append("website", setWebsite);
            formData.append("description", setDescription);
            formData.append("partner_name", partner_name);
            formData.append("nif", nif);
            formData.append("percentage", percentage);
            formData.append("partner_file", partner_file);
            formData.append("legal_name", legal_name);
            formData.append("legal_nif", legal_nif);


            async function GuestLoanCompUpdate2() {
                const response = await apis.GuestLoanCompanyUpdate(formData);
                console.log(response)
                if (response['status'] === true) {
                    setLocalId(response['data']['company_ran_id']);
                    addClassNext4();

                    toast.success('La info se han cargado correctamente', {
                        position: toast.POSITION.TOP_RIGHT,
                        className: 'foo_bar_log_Success'
                    });
                } else {
                    toast.error(response["message"], {
                        position: toast.POSITION.TOP_RIGHT,
                        className: 'foo_bar_log_error'
                    });
                }
            }
            GuestLoanCompUpdate2();


            // fetch(`${process.env.REACT_APP_BASE_URL}${con.getloan_get2_update}`, {
            //     method: 'post',
            //     body: formData,
            // }).then(async response => {
            //     var res = await response.json();
            //     if (res['status'] === true) {
            //         setLocalId(res['data']['company_ran_id']);
            //         addClassNext4();

            //         toast.success('La info se han cargado correctamente', {
            //             position: toast.POSITION.TOP_RIGHT,
            //             className: 'foo_bar_log_Success'
            //         });
            //     } else {
            //         toast.error(res["message"], {
            //             position: toast.POSITION.TOP_RIGHT,
            //             className: 'foo_bar_log_error'
            //         });
            //     }
            // });


        }
        else if (user_type === "register_user") {

            const formData = new FormData();
            formData.append("company_id", localStorage.getItem("company_id"));
            formData.append("operation_term", operation_term);
            formData.append("business_group", setBusiness_gp);
            formData.append("audited", setaudited);
            formData.append("street", setStreet);
            formData.append("floor", setFloor);
            formData.append("apportment_number", setApportment_number);
            formData.append("city", setCity);
            formData.append("zip_code", setZip_code);
            formData.append("device", setDevice);
            formData.append("website", setWebsite);
            formData.append("description", setDescription);
            formData.append("partner_name", partner_name);
            formData.append("nif", nif);
            formData.append("percentage", percentage);
            formData.append("partner_file", partner_file);
            formData.append("legal_name", legal_name);
            formData.append("legal_nif", legal_nif);


            RegisterLoanCompUpdate2();
            async function RegisterLoanCompUpdate2() {
                const response = await apis.LoanUpdateRegister1(formData);
                console.log(response)
                if (response["status"] === true) {
                    addClassNext4();

                    toast.success('La info se han cargado correctamente', {
                        position: toast.POSITION.TOP_RIGHT,
                        className: "foo_bar_log_Success",
                    });
                } else {
                    toast.error(response["message"], {
                        position: toast.POSITION.TOP_RIGHT,
                        className: "foo_bar_log_error",
                    });
                }
            }

            // fetch(`${process.env.REACT_APP_BASE_URL}${con.getloan_register2}`, {
            //     method: "post",
            //     body: formData,
            //     headers: {
            //         Authorization: `Bearer ${token}`,
            //     },
            // })
            //     .then(async (response) => {
            //         var res = await response.json();
            //         if (res["status"] === true) {
            //             addClassNext4();

            //             toast.success('La info se han cargado correctamente', {
            //                 position: toast.POSITION.TOP_RIGHT,
            //                 className: "foo_bar_log_Success",
            //             });
            //         } else {
            //             toast.error(res["message"], {
            //                 position: toast.POSITION.TOP_RIGHT,
            //                 className: "foo_bar_log_error",
            //             });
            //         }
            //     })
            //     .catch((error) => {
            //         console.error(error);
            //     });

        }

    }

    // ------------------------------------- form 5 api --------------------------------

    const setCorporation_taxRef = React.useRef();
    const setFinancial_statementsRef = React.useRef();
    const setPrevious_year_modelRef = React.useRef();
    const setVat_declarationsRef = React.useRef();
    const setBrief_summaryRef = React.useRef();
    const setMeetingRef = React.useRef();

    const handleSubmit5 = (event) => {
        event.preventDefault();

        var nameList = [];
        var nifList = [];
        var percList = [];
        for (let item of fields) {
            nameList.push(item['name']);
            nifList.push(item['nif']);
            percList.push(item['percentage']);
        }

        var nameList1 = [];
        var nifList1 = [];
        for (let item of fields1) {
            nameList1.push(item['fullname']);
            nifList1.push(item['NIF']);
        }

        const operation_term = operation_termRef.current.value;
        const setBusiness_gp = setBusiness_group;
        const setaudited = setAudited;
        const setStreet = setStreetRef.current.value;
        const setFloor = setFloorRef.current.value;
        const setApportment_number = setApportment_numberRef.current.value;
        const setCity = setCityRef.current.value;
        const setZip_code = setZip_codeRef.current.value;
        const setDevice = setDeviceRef.current.value;
        const setWebsite = setWebsiteRef.current.value;
        const setDescription = setDescriptionRef.current.value;
        const partner_name = nameList;
        const nif = nifList;
        const percentage = percList;
        const partner_file = setImageFile;
        const legal_name = nameList1;
        const legal_nif = nifList1;
        const corporation_tax = setImageFile2;
        const financial_statements = setImageFile3;
        const previous_year_model = setImageFile4;
        const vat_declarations = setImageFile5;
        const brief_summary = setImageFile6;
        const meeting = setImageFile7;

        if (user_type === "guest_user" || user_type === null) {

            const formData = new FormData();
            formData.append("user_id", user_id);
            formData.append("company_id", localStorage.getItem('company_id'));
            formData.append("operation_term", operation_term);
            formData.append("business_group", setBusiness_gp);
            formData.append("audited", setaudited);
            formData.append("street", setStreet);
            formData.append("floor", setFloor);
            formData.append("apportment_number", setApportment_number);
            formData.append("city", setCity);
            formData.append("zip_code", setZip_code);
            formData.append("device", setDevice);
            formData.append("website", setWebsite);
            formData.append("description", setDescription);
            formData.append("partner_name", partner_name);
            formData.append("nif", nif);
            formData.append("percentage", percentage);
            formData.append("partner_file", partner_file);
            formData.append("legal_name", legal_name);
            formData.append("legal_nif", legal_nif);
            formData.append("corporation_tax", corporation_tax);
            formData.append("financial_statements", financial_statements);
            formData.append("previous_year_model", previous_year_model);
            formData.append("vat_declarations", vat_declarations);
            formData.append("brief_summary", brief_summary);
            formData.append("meeting", meeting);


            async function GuestLoanCompUpdate3() {
                const response = await apis.GuestLoanCompanyUpdate(formData);
                console.log(response)
                if (response['status'] === true) {
                    setLocalId(response['data']['company_ran_id']);
                    addClassNext5();
                    localStorage.setItem("guest_conformation", 'getloan');
                    toast.success('La info se han cargado correctamente', {
                        position: toast.POSITION.TOP_RIGHT,
                        className: 'foo_bar_log_Success'
                    });
                    showAlert();
                } else {
                    toast.error(response["message"], {
                        position: toast.POSITION.TOP_RIGHT,
                        className: 'foo_bar_log_error'
                    });
                }
            }
            GuestLoanCompUpdate3();

            // fetch(`${process.env.REACT_APP_BASE_URL}${con.getloan_get2_update}`, {
            //     method: 'post',
            //     body: formData,
            // }).then(async response => {
            //     var res = await response.json();
            //     if (res['status'] === true) {
            //         setLocalId(res['data']['company_ran_id']);
            //         addClassNext5();
            //         localStorage.setItem("guest_conformation", 'getloan');
            //         toast.success('La info se han cargado correctamente', {
            //             position: toast.POSITION.TOP_RIGHT,
            //             className: 'foo_bar_log_Success'
            //         });
            //         showAlert();
            //     } else {
            //         toast.error(res["message"], {
            //             position: toast.POSITION.TOP_RIGHT,
            //             className: 'foo_bar_log_error'
            //         });
            //     }
            // });

        }
        else if (user_type === "register_user") {

            const formData = new FormData();
            formData.append("company_id", localStorage.getItem('company_id'));
            formData.append("operation_term", operation_term);
            formData.append("business_group", setBusiness_gp);
            formData.append("audited", setaudited);
            formData.append("street", setStreet);
            formData.append("floor", setFloor);
            formData.append("apportment_number", setApportment_number);
            formData.append("city", setCity);
            formData.append("zip_code", setZip_code);
            formData.append("device", setDevice);
            formData.append("website", setWebsite);
            formData.append("description", setDescription);
            formData.append("partner_name", partner_name);
            formData.append("nif", nif);
            formData.append("percentage", percentage);
            formData.append("partner_file", partner_file);
            formData.append("legal_name", legal_name);
            formData.append("legal_nif", legal_nif);
            formData.append("corporation_tax", corporation_tax);
            formData.append("financial_statements", financial_statements);
            formData.append("previous_year_model", previous_year_model);
            formData.append("vat_declarations", vat_declarations);
            formData.append("brief_summary", brief_summary);
            formData.append("meeting", meeting);


            RegisterLoanCompUpdate3();
            async function RegisterLoanCompUpdate3() {
                const response = await apis.LoanUpdateRegister1(formData);
                console.log(response)
                if (response['status'] === true) {
                    addClassNext5();

                    toast.success('La info se han cargado correctamente', {
                        position: toast.POSITION.TOP_RIGHT,
                        className: 'foo_bar_log_Success'
                    });
                    window.location.href = "/user/company_sector";

                }
                else {
                    toast.error(response["message"], {
                        position: toast.POSITION.TOP_RIGHT,
                        className: 'foo_bar_log_error'
                    });
                }
            }

            // fetch(`${process.env.REACT_APP_BASE_URL}${con.getloan_register2}`, {
            //     method: 'post',
            //     body: formData,
            //     headers: {
            //         "Authorization": `Bearer ${token}`,
            //         'headers': { 'content-type': 'multipart/form-data' }
            //     }
            // }).then(async response => {
            //     var res = await response.json();
            //     if (res['status'] === true) {
            //         addClassNext5();

            //         toast.success('La info se han cargado correctamente', {
            //             position: toast.POSITION.TOP_RIGHT,
            //             className: 'foo_bar_log_Success'
            //         });
            //         window.location.href = "/user/company_sector";

            //     }
            //     else {
            //         toast.error(res["message"], {
            //             position: toast.POSITION.TOP_RIGHT,
            //             className: 'foo_bar_log_error'
            //         });
            //     }

            // });


        }

    }

    function fun(val) {
        setpaymentAndObligationsValue(val);
    }

    function fun1(val) {
        setsetAudited(val);
    }

    function fun2(val) {
        console.log(val)
        setSetBusiness_groupRef(val);
    }






    return (
        ((role_id !== null && role_id === "2")) ?
            <React.Fragment>

                <section >
                    <div className="">
                        <div className="row " >

                            <div className="col-md-3 tb_col_bg1 " style={{ position: "sticky" }} >
                                <h3 className="my-5" style={{ color: "white", textAlign: "center", fontSize: "20px" }}>
                                    {/* <span style={{textTransform:"capitalize"}}>Conseguir</span> un prestamo */}
                                    Exprime al máximo Credit Store
                                </h3>

                                <div className="mdl-card mdl-shadow--2dp ">
                                    <div className="mdl-card__supporting-text " >
                                        <div className="mdl-stepper-vertical-alternative">
                                            <div
                                                id="stepper1"
                                                className="mdl-stepper-step card lo_app_card active-step editable-step "
                                                onClick={() => { changeSteps("0") }}
                                            >
                                                <div>
                                                    <div style={{ display: "flex" }}>
                                                        <img className="stepImage1_1" src={images.loan_appli_w} alt='loan_appli_w' />
                                                        <p className="get_p stpText" id="stepText1" >
                                                            Solicitud de financiamiento

                                                        </p>


                                                        <DoneIcon className="icon hide" id="stepIcon1" />
                                                    </div>
                                                </div>
                                                {/* <div id="stepper_line1" className="tab1_border_step">
                                            </div> */}
                                            </div>
                                            <div id="stepper2" className="mdl-stepper-step card lo_com_card" onClick={() => { changeSteps("1") }}>
                                                <div>
                                                    <div style={{ display: "flex" }}>
                                                        <img className="" src={images.company_info_d} id="stepImage2_1" alt='company_info_d' />
                                                        <img className="hide" src={images.company_info_w} id="stepImage2_2" alt='company_info_w' />
                                                        <p className="al_p" id="stepText2">Información de la empresa
                                                        </p>
                                                        <DoneIcon className="icon hide" id="stepIcon2" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="stepper3" className="mdl-stepper-step card lo_com_card" onClick={() => { changeSteps("2") }}>
                                                <div >
                                                    <div style={{ display: "flex" }}>
                                                        <img className="" src={images.partners_d} id="stepImage3_1" alt='partners_d' />
                                                        <img className="hide" src={images.partners_w} id="stepImage3_2" alt='partners_w' />
                                                        <p className="al_p" id="stepText3">Socios con más de 20% de participación</p>
                                                        <DoneIcon className="icon hide" id="stepIcon3" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="stepper4" className="mdl-stepper-step card lo_com_card" onClick={() => { changeSteps("3") }}>
                                                <div>
                                                    <div style={{ display: "flex" }}>
                                                        <img className="" src={images.legal_d} id="stepImage4_1" alt='legal_d' />
                                                        <img className="hide" src={images.legal_w} id="stepImage4_2" alt='legal_w' />
                                                        <p className="al_p" id="stepText4">Representante legal
                                                        </p>
                                                        <DoneIcon className="icon hide" id="stepIcon4" />
                                                    </div>
                                                </div>

                                            </div>
                                            <div id="stepper5" className="mdl-stepper-step card lo_com_card" onClick={() => { changeSteps("4") }}>
                                                <div>
                                                    <div style={{ display: "flex" }}>
                                                        <img className="" src={images.documents_d} id="stepImage5_1" alt='documents_d' />

                                                        <img className="hide" src={images.documents_w} id="stepImage5_2" alt='documents_w' />

                                                        <p className="al_p" id="stepText5">Documentos suplementarios</p>
                                                        <DoneIcon className="icon hide" id="stepIcon5" />
                                                    </div>
                                                </div>
                                                <div className=""></div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-md-1" style={{ backgroundColor: "#FAFAFD" }}></div>


                            <div className="col-md-7" style={{ position: "relative", backgroundColor: "#FAFAFD", overflowY: "visible", overflowX: "hidden" }} >
                                <div className="container"  >
                                    <ToastContainer autoClose={3000} />

                                    <div className="stepper" >

                                        {/* ------------------ step 1 start -------------- */}

                                        <div id="step1" className="step">

                                            <h4
                                                style={{
                                                    marginLeft: "10%",
                                                    marginTop: 40,
                                                    color: "#0069CB",
                                                    fontWeight: 600
                                                }}
                                            >
                                                Solicitud de financiamiento
                                            </h4>

                                            <form onSubmit={handleSubmit}>
                                                <div>
                                                    <label style={{ marginTop: 30 }}>Importe </label>
                                                    <input
                                                        type="number"
                                                        className="form-control lo_inpu"
                                                        placeholder={100.0}
                                                        id=""
                                                        required
                                                        name="field1" value={values.field1} onChange={handleChange5} maxLength={8}
                                                         onKeyDown={handlekeydown}
                            min="0"
                                                        ref={loanAmountRef}

                                                    // onChange={(e) => setLoan_amount(e.target.value)} value={loan_amount}
                                                    />
                                                    <div style={{ marginRight: 445 }}>
                                                        <p style={{ marginTop: 30, marginBottom: 50, fontWeight: 600 }}>
                                                            {" "}
                                                            Plazo de la operación (Meses)
                                                        </p>
                                                        <div className="range-slider">
                                                            <div id="tooltip"></div>
                                                            <input
                                                                id="range"
                                                                type="range"
                                                                step={1}
                                                                defaultValue={value}
                                                                min={0}
                                                                max={100}
                                                                required
                                                                ref={loanTermRef}
                                                            // onChange={(e) => setLoan_term(e.target.value)} value={loan_term} 

                                                            />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <p style={{ marginTop: 50, marginBottom: 20, fontWeight: 600 }}>
                                                            ¿Estás al día con todos los pagos y obligaciones?
                                                        </p>
                                                        <div style={{ display: "flex" }}>
                                                            {/* <button
                                                            type="button"
                                                            className="btn"
                                                            value="yes"
                                                            onClick={() => { setPayment_and_obligations('yes'); }}
                                                            style={{
                                                                backgroundColor: "#0069CB",
                                                                color: "white",
                                                                width: 65,
                                                                borderRadius: 16
                                                            }}
                                                        >
                                                            Si
                                                        </button> 
                                                        <button
                                                            type="button"
                                                            className="btn"
                                                            value="no"
                                                            onClick={() => { setPayment_and_obligations('no'); }}
                                                            style={{
                                                                borderColor: "#0069CB",
                                                                color: "#0069CB",
                                                                width: 65,
                                                                borderRadius: 16,
                                                                marginLeft: 10
                                                            }}
                                                        >
                                                            No
                                                        </button>  */}


                                                            <button type="button" className="button_get button_select"
                                                                // ref={paymentAndObligationsRef}
                                                                //  value="yes" 
                                                                onClick={() => fun('yes')}
                                                            >Sí</button>

                                                            <button type="button" className="button_get button_select ms-3"
                                                                // ref={paymentAndObligationsRef} 
                                                                // value="no" 
                                                                onClick={() => fun('no')}
                                                            >No</button>

                                                        </div>
                                                    </div>

                                                    <label style={{ marginTop: 25 }}>Titulo de <span style={{ textTransform: "capitalize" }}>la solicitud de financiamiento</span>  </label>
                                                    <input
                                                        type="text"
                                                        className="form-control lo_inpu"
                                                        placeholder="Titulo"

                                                        id=""
                                                        required

                                                        minLength={3} maxLength={70}
                                                        ref={loanTitleRef}
                                                        // valueLabelDisplay="auto"
                                                        marks={marks}
                                                    // onChange={(e) => setLoan_title(e.target.value)} value={loan_title}
                                                    />


                                                    <div>
                                                        <p style={{ marginTop: 30, fontWeight: 600, fontSize: 14 }}>
                                                            <span style={{ textTransform: "capitalize" }}> Finalidad</span>
                                                        </p>
                                                        <select
                                                            className="form-select"
                                                            id="sel1"
                                                            style={{ fontSize: 14, height: 34, width: "60%" }}
                                                            name="sellist1"
                                                            ref={loanPurposeRef}
                                                        // onChange={(e) => setLoan_reasons(e.target.value)} value={loan_reasons}
                                                        >
                                                            <option>Préstamo</option>
                                                            <option>Línea de crédito</option>
                                                            <option>Factorización</option>
                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="" style={{ marginTop: 30 }}>
                                                            Explicar en detalle la finalidad de la solicitud

                                                        </label>
                                                        <textarea
                                                            className="form-control"
                                                            rows={7}
                                                            style={{ width: "60%" }}
                                                            placeholder="...."
                                                            id="comment"
                                                            required
                                                            minLength={3} maxLength={250}
                                                            ref={whyDoYouWhantRef}
                                                        // defaultValue={""}
                                                        // onChange={(e) => setLoan_description(e.target.value)} value={loan_description}
                                                        />
                                                    </div>
                                                    <div style={{ display: "flex", marginTop: 30, marginBottom: 50 }}>


                                                        {/* <button
                                                        type="button"
                                                        id=""
                                                        className="btn "

                                                        style={{
                                                            backgroundColor: "#E0E0E0",
                                                            width: 150,
                                                            borderRadius: 8
                                                        }}
                                                    >
                                                        Back
                                                    </button> */}


                                                        <button type="submit" id="next1" className="btn "
                                                            style={{
                                                                marginLeft: 10,
                                                                width: 150,
                                                                backgroundColor: "#0069CB",
                                                                color: "white",
                                                                borderRadius: 8
                                                            }}
                                                        // onClick={showAlert}
                                                        // onClick={() => { saveloan(); addClassNext1(); }}
                                                        >
                                                            {/* <span style={{textTransform:"capitalize"}}>ahorrar</span>  */}
                                                            SIGUIENTE
                                                        </button>



                                                    </div>
                                                </div>
                                            </form>

                                        </div>

                                        {/* ------------------------------  step 2 start --------------- */}

                                        <div id="step2" className="step hide">
                                            {(localId !== '') ?
                                                <div style={{ textAlign: "center", marginTop: "10px" }}><span className="badge bg-info">ID de préstamo : {localId}</span></div>
                                                : null}
                                            <h4
                                                style={{
                                                    marginLeft: "10%",
                                                    marginTop: 40,
                                                    color: "#0069CB",
                                                    fontWeight: 600
                                                }}
                                            >
                                                Información de la empresa
                                            </h4>

                                            <form onSubmit={handleSubmit2} >
                                                <div>
                                                    <div style={{ marginRight: 445 }}>
                                                        <p style={{ marginTop: 30, marginBottom: 50, fontWeight: 600 }}>
                                                            {" "}
                                                            Número de empleados
                                                        </p>

                                                        <div className="range-slider">
                                                            <div id="tooltip2"></div>
                                                            <input
                                                                id="range2"
                                                                type="range"
                                                                step={10}
                                                                defaultValue={value2}
                                                                min={0}
                                                                max={100}
                                                                required
                                                                ref={operation_termRef}
                                                            />
                                                            <label id="label" style={{ display: "none" }}></label>
                                                        </div>
                                                        <div style={{ marginTop: "20px" }}>
                                                            <p> 0-10 <span className="ran_la_1" >10-20</span>
                                                                <span className="ran_la_2" >20-30</span>
                                                                <span className="ran_la_3" >30-40</span>
                                                                <span className="ran_la_4" >40-50</span>
                                                                <span className="ran_la_5" >50-60</span>
                                                                <span className="ran_la_6" >60-70</span>
                                                                <span className="ran_la_7" >70-80</span>
                                                                <span className="ran_la_8" >80-90</span>
                                                                <span className="ran_la_9" >90-100</span>
                                                                <span className="ran_la_10" >100+</span></p>

                                                        </div>
                                                    </div>
                                                    <div>
                                                        <p style={{ marginTop: 50, marginBottom: 20, fontWeight: 600 }}>
                                                            ¿Forma parte de un grupo empresarial?
                                                        </p>
                                                        <div style={{ display: "flex" }}>

                                                            <button type="button" className="button_get button_select1 "
                                                                // ref={setBusiness_groupRef} value="yes"
                                                                onClick={() => fun2('yes')}
                                                            >
                                                                Si
                                                            </button>

                                                            <button type="button" className="button_get button_select1 ms-3 "
                                                                // ref={setBusiness_groupRef} value="no"
                                                                onClick={() => fun2('no')}
                                                            >
                                                                No
                                                            </button>


                                                        </div>
                                                    </div>

                                                    <div>
                                                        <p style={{ marginTop: 50, marginBottom: 20, fontWeight: 600 }}>
                                                            <span style={{ textTransform: "capitalize" }}> ¿Su empresa ha sido auditada?</span>
                                                        </p>
                                                        <div style={{ display: "flex" }}>
                                                            <button type="button" className="button_get button_select "
                                                                // ref={setAuditedRef} value="yes"
                                                                onClick={() => fun1('yes')}
                                                            >Si</button>

                                                            <button type="button" className="button_get button_select ms-3 "
                                                                // ref={setAuditedRef} value="no"
                                                                onClick={() => fun1('no')}
                                                            >No</button>
                                                        </div>
                                                    </div>

                                                    <label style={{ marginTop: 30 }}>Dirección </label>
                                                    <div style={{ display: "flex" }}>
                                                        <input
                                                            type="text"
                                                            className="form-control "
                                                            style={{ height: 50, width: 247 }}
                                                            placeholder="Calle"
                                                            minLength={3} maxLength={25}
                                                            id=""
                                                            required
                                                            ref={setStreetRef}
                                                            //  onKeyDown={handlekeydown}
                            min="0"
                                                        />
                                                        <input
                                                            type="number"
                                                            className="form-control "
                                                            style={{ height: 50, width: 200 }}
                                                            placeholder="Piso"
                                                            id=""
                                                            name="field2" value={values.field2} onChange={handleChange5} maxLength={8}
                                                             onKeyDown={handlekeydown}
                            min="0"
                                                            ref={setFloorRef}
                                                        />

                                                        <input
                                                            type="number"
                                                            className="form-control "
                                                            style={{ marginLeft: 10, height: 50, width: 200 }}
                                                            placeholder="Número de apartamento"
                                                            name="field3" value={values.field3} onChange={handleChange5} maxLength={8}
                                                            id=""
                                                            required
                                                            ref={setApportment_numberRef}
                                                             onKeyDown={handlekeydown}
                            min="0"
                                                        />
                                                    </div>
                                                    <div style={{ display: "flex", marginTop: 20 }}>
                                                        <input
                                                            type="text"
                                                            className="form-control "
                                                            style={{ height: 50, width: 245 }}
                                                            placeholder="Ciudad"
                                                            minLength={3} maxLength={25}
                                                            id=""
                                                            required
                                                            ref={setCityRef}
                                                        />
                                                        <input
                                                            type="number"
                                                            className="form-control "
                                                            style={{ height: 50, width: 170 }}
                                                            placeholder="código de zip"
                                                            id=""
                                                            name="field4" value={values.field4} onChange={handleChange5} maxLength={8}
                                                            required
                                                            ref={setZip_codeRef}
                                                             onKeyDown={handlekeydown}
                            min="0"
                                                        />

                                                        <input
                                                            type="text"
                                                            className="form-control "
                                                            style={{ height: 50, width: 245 }}
                                                            placeholder="Provice"
                                                            id=""
                                                            minLength={3} maxLength={25}
                                                            required
                                                            ref={setDeviceRef}
                                                        />
                                                    </div>
                                                    <label style={{ marginTop: 30 }}>Sitio web </label>
                                                    {/* <input
                                                        type="url"
                                                        className="form-control lo_inpu"
                                                        placeholder="https://tuwebsite.net"
                                                        id=""
                                                        required
                                                        ref={setWebsiteRef}
                                                        onChange={handleInputChangeUrl}
                                                    /> */}


                                                    <div>
                                                        <input
                                                            type="text"
                                                            className="form-control lo_inpu"
                                                            placeholder="https://tuwebsite.net"
                                                            id=""
                                                            required
                                                            ref={setWebsiteRef}
                                                            onChange={handleInputChangeUrl}
                                                        />
                                                        {errorMessage && <p className="error-message" style={{ color: "red" }}>{errorMessage}</p>}
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="" style={{ marginTop: 30 }}>
                                                            ¿Cuál es la propuesta de valor de vuestro negocio, servicio y/o producto?
                                                        </label>
                                                        <textarea
                                                            className="form-control"
                                                            rows={7}
                                                            style={{ width: "60%" }}
                                                            placeholder="Sobre su empresa..."
                                                            minLength={3} maxLength={250}
                                                            id="comment"
                                                            // defaultValue={""}
                                                            required
                                                            ref={setDescriptionRef}
                                                        />
                                                    </div>
                                                    <div style={{ display: "flex", marginTop: 30, marginBottom: 50 }}>




                                                        <button
                                                            type="button"
                                                            id="previous2"
                                                            onClick={() => { previous2(); }}
                                                            className="btn " style={{
                                                                backgroundColor: "#E0E0E0",
                                                                width: 150,
                                                                borderRadius: 8
                                                            }}
                                                        >
                                                            ATRÁS
                                                            {/* <span style={{textTransform:"capitalize"}}>atrás</span>  */}
                                                        </button>

                                                        <button type="submit" id="next2" className="btn " style={{
                                                            marginLeft: 10,
                                                            width: 150,
                                                            backgroundColor: "#0069CB",
                                                            color: "white",
                                                            borderRadius: 8
                                                        }}
                                                        // onClick={() => { savecompanydetails(); addClassNext2(); }}
                                                        // onClick={savecompanydetails}
                                                        >
                                                            SIGUIENTE
                                                            {/* <span style={{textTransform:"capitalize"}}>ahorrar</span>  */}

                                                        </button>




                                                    </div>
                                                </div>
                                            </form>

                                        </div>
                                        {/* ------------------------------  step 3 start ------------------------------------------------- */}

                                        <div id="step3" className="step hide">
                                            {/* {(localId !== '') ?

                                                <div style={{ textAlign: "center", marginTop: "10px" }}><span className="badge bg-info">ID de la compañía : {localId}</span></div>
                                                : null} */}
                                            <h4
                                                style={{
                                                    marginLeft: 0,
                                                    marginTop: 40,
                                                    color: "#0069CB",
                                                    fontWeight: 600
                                                }}
                                            >
                                                Socios con más de 20% de participación
                                            </h4>

                                            <form onSubmit={handleSubmit3}>
                                                <div>
                                                    <label
                                                        style={{
                                                            marginTop: 30,
                                                            backgroundColor: "#E0E0E0",
                                                            padding: 10,
                                                            borderRadius: 5
                                                        }}
                                                    >
                                                        <span>
                                                            <i
                                                                className="fa fa-id-card"
                                                                style={{ color: "#828282", marginRight: 10 }}
                                                                aria-hidden="true"
                                                            />
                                                        </span>
                                                        Añadir DNI{" "}
                                                    </label>
                                                    {fields.map((field, idx) => (
                                                        <div key={`${field}-${idx}`}>
                                                            <input
                                                                type="text"
                                                                name="name"
                                                                className="form-control lo_inpu"
                                                                style={{ marginTop: 15 }}
                                                                placeholder="Nombre Completo"
                                                                id=""
                                                                required
                                                                minLength={3} maxLength={25}
                                                                // ref={setPartner_nameRef}
                                                                value={field.name || ""}
                                                                onChange={e => handleChange(idx, e)}
                                                            />
                                                            <input
                                                                type="text"
                                                                name="nif"
                                                                className="form-control lo_inpu"
                                                                style={{ marginTop: 15 }}
                                                                minLength={3} maxLength={25}
                                                                placeholder="NIF"
                                                                id=""
                                                                required
                                                                // name="field4" value={values.field4} onChange={handleChange5} maxLength={8}
                                                                // ref={setNifRef}
                                                                value={field.nif || ""}
                                                                onChange={e => handleChange(idx, e)}
                                                            />
                                                            <input
                                                                type="number"
                                                                name="percentage"
                                                                className="form-control lo_inpu"
                                                                style={{ marginTop: 15 }}
                                                                placeholder="Porcentaje"
                                                                id=""
                                                                required
                                                                //  name="field4" value={values.field4} onChange={handleChange5} maxLength={8}
                                                                // ref={setPercentageRef}
                                                                value={field.percentage || ""}
                                                                onChange={e => handleChange(idx, e)}
                                                                 onKeyDown={handlekeydown}
                            min="0"
                                                            />
                                                            {((fields.length > 1) && (idx !== 0)) ?
                                                                <button type="button" onClick={() => handleRemove(idx)}
                                                                    className="btn"
                                                                    style={{
                                                                        backgroundColor: "#0069CB",
                                                                        color: "white",
                                                                        borderRadius: 4,
                                                                        marginTop: 15
                                                                    }}>
                                                                    Remove
                                                                </button> : null}
                                                        </div>
                                                    ))}

                                                    <div id="req_input" className="datainputs"></div>
                                                    <div style={{ display: "flex" }}>
                                                        <Link to="#">
                                                            <button
                                                                type="button"
                                                                // id="addmore"
                                                                className="btn"
                                                                onClick={() => handleAdd()}
                                                                style={{
                                                                    backgroundColor: "#0069CB",
                                                                    color: "white",
                                                                    borderRadius: 4,
                                                                    marginTop: 15
                                                                }}
                                                            >
                                                                Añadir más
                                                                <span>
                                                                    <i
                                                                        className="fa fa-plus"
                                                                        style={{ marginLeft: 10 }}
                                                                        aria-hidden="true"
                                                                    />
                                                                </span>
                                                            </button>
                                                        </Link>

                                                        <p
                                                            style={{
                                                                marginLeft: 20,
                                                                fontWeight: 600,
                                                                marginTop: 20,
                                                                fontSize: 15
                                                            }}
                                                        >
                                                            <span style={{ textTransform: "capitalize" }}>  Añadir representante</span>
                                                        </p>
                                                    </div>
                                                    {/* <p style="color:#0069CB;margin-top: 30px;margin-bottom: 30px;">Get your Documento CIRBE here</p> */}
                                                    <div>
                                                        <p style={{ marginTop: 30, marginBottom: 20 }}>
                                                        Añadir copia de Documento de Identidad (PDF, JPG, PNG, DOC){" "}
                                                            <span>
                                                                <i
                                                                    className="fa fa-question-circle"
                                                                    style={{ color: "#0069CB" }}
                                                                    aria-hidden="true"
                                                                />
                                                            </span>{" "}
                                                        </p>
                                                        <div className="drop-zone">
                                                            <span className="drop-zone__prompt">
                                                                Soltar archivo para cargar o
                                                            </span>
                                                            <p style={{ color: "#0069CB", marginTop: 10 }}>
                                                                <span>
                                                                    <i
                                                                        className="fa fa-plus"
                                                                        style={{ marginLeft: 10, marginRight: 10 }}
                                                                        aria-hidden="true"
                                                                    />
                                                                </span>
                                                                Buscar Archivo
                                                            </p>
                                                            <input
                                                                type="file"
                                                             
                                                                className="drop-zone__input visually-hidden"
                                                                required
                                                                onChange={(e) => { handleFileSelect(e); }}
                                                                ref={setPartner_fileRef}
                                                                accept=".png, .doc, .docx, .pdf, .jpg"
                                                                />
                                                        </div>
                                                    </div>
                                                    <div style={{ display: "flex", marginTop: 40, marginBottom: 50 }}>




                                                        <button
                                                            type="button"
                                                            id="previous3"
                                                            className="btn "
                                                            onClick={() => { previous3(); }}
                                                            style={{
                                                                backgroundColor: "#E0E0E0",
                                                                width: 150,
                                                                borderRadius: 8
                                                            }}
                                                        >
                                                            ATRÁS
                                                            {/* <span style={{textTransform:"capitalize"}}>atrás</span>  */}

                                                        </button>

                                                        <button type="submit" id="next3" className="btn " style={{
                                                            marginLeft: 10,
                                                            width: 150,
                                                            backgroundColor: "#0069CB",
                                                            color: "white",
                                                            borderRadius: 8
                                                        }}
                                                        // onClick={() => { savecompanydetail2(); addClassNext3(); }}
                                                        // onClick={savecompanydetail2}
                                                        >
                                                            SIGUIENTE
                                                            {/* <span style={{textTransform:"capitalize"}}>ahorrar</span>  */}
                                                        </button>



                                                    </div>
                                                </div>
                                            </form>

                                        </div>

                                        {/* --------------------  step 4 start ------------------------------------------------------- */}

                                        <div id="step4" className="step hide">
                                            {/* {(localId !== '') ?
                                                <div style={{ textAlign: "center", marginTop: "10px" }}><span className="badge bg-info">Company Id : {localId}</span></div>
                                                : null} */}
                                            <h4
                                                style={{
                                                    marginLeft: 0,
                                                    marginTop: 40,
                                                    color: "#0069CB",
                                                    fontWeight: 600
                                                }}
                                            >
                                                Representante legal
                                            </h4>
                                            <div className="">
                                                <input
                                                className="radio"
                                                id="one"
                                                name="group"
                                                type="radio"
                                                defaultChecked="active" style={{ display: "none" }}
                                            />
                                                <input className="radio" id="two" name="group" type="radio" style={{ display: "none" }} />

                                                <label className="tab" >
                                                    Administrador único
                                                </label>
                                                {/* <label className="tab" >
                                                    Administrador único
                                                </label> */}


                                                <form onSubmit={handleSubmit4}>
                                                    <div>
                                                        <div style={{ display: "flex" }}>
                                                            <p style={{ fontWeight: 600, marginTop: 20, fontSize: 15 }}>
                                                                <span style={{ textTransform: "capitalize" }}>  Añadir representante</span>
                                                            </p>
                                                            
                                                        </div>


                                                        {fields1.map((field1, idxs) => (
                                                            <div key={`${field1}-${idxs}`}>
                                                                <input
                                                                    type="text"
                                                                    name="fullname"
                                                                    className="form-control lo_inpu"
                                                                    style={{ marginTop: 15 }}
                                                                    placeholder="Nombre Completo"
                                                                    id=""
                                                                    minLength={3} maxLength={25}
                                                                    required
                                                                    // ref={setLegal_nameRef}
                                                                    value={field1.fullname || ""}
                                                                    onChange={e => handleChange1(idxs, e)}
                                                                />
                                                                <input
                                                                    type="text"
                                                                    name="NIF"
                                                                    className="form-control lo_inpu"
                                                                    style={{ marginTop: 15 }}
                                                                    placeholder="NIF"
                                                                    id=""
                                                                    minLength={3} maxLength={25}
                                                                    required
                                                                    // ref={setLegal_nifRef}
                                                                    value={field1.NIF || ""}
                                                                    onChange={e => handleChange1(idxs, e)}
                                                                />
                                                                {((fields1.length > 1) && (idxs !== 0)) ?
                                                                    <button type="button" onClick={() => handleRemove1(idxs)}
                                                                        className="btn"
                                                                        style={{
                                                                            backgroundColor: "#0069CB",
                                                                            color: "white",
                                                                            borderRadius: 4,
                                                                            marginTop: 15
                                                                        }}>
                                                                        Remove
                                                                    </button> : null}
                                                            </div>
                                                        ))}

                                                        <div id="req_input2" className="datainputs"></div>
                                                        <Link to="#">
                                                            <button
                                                                type="button"
                                                                // id="addmore"
                                                                className="btn"
                                                                onClick={() => handleAdd1()}
                                                                style={{
                                                                    backgroundColor: "#0069CB",
                                                                    color: "white",
                                                                    borderRadius: 4,
                                                                    marginTop: 15
                                                                }}
                                                            >
                                                                Añadir más
                                                                <span>
                                                                    <i
                                                                        className="fa fa-plus"
                                                                        style={{ marginLeft: 10 }}
                                                                        aria-hidden="true"
                                                                    />
                                                                </span>
                                                            </button>
                                                        </Link>
                                                        <div>
                                                        <p style={{ marginTop: 30, marginBottom: 20 }}>
                                                        Añadir copia de Documento de Identidad (PDF, JPG, PNG, DOC){" "}
                                                            <span>
                                                                <i
                                                                    className="fa fa-question-circle"
                                                                    style={{ color: "#0069CB" }}
                                                                    aria-hidden="true"
                                                                />
                                                            </span>{" "}
                                                        </p>
                                                        <div className="drop-zone">
                                                            <span className="drop-zone__prompt">
                                                                Soltar archivo para cargar o
                                                            </span>
                                                            <p style={{ color: "#0069CB", marginTop: 10 }}>
                                                                <span>
                                                                    <i
                                                                        className="fa fa-plus"
                                                                        style={{ marginLeft: 10, marginRight: 10 }}
                                                                        aria-hidden="true"
                                                                    />
                                                                </span>
                                                                Buscar Archivo
                                                            </p>
                                                            <input
                                                                type="file"
                                                             
                                                                className="drop-zone__input visually-hidden"
                                                                required
                                                                onChange={(e) => { handleFileSelect(e); }}
                                                                ref={setPartner_fileRef}
                                                                accept=".png, .doc, .docx, .pdf, .jpg"
                                                                />
                                                        </div>
                                                    </div>
                                                        <div
                                                            style={{ display: "flex", marginTop: 40, marginBottom: 50 }}
                                                        >

                                                            <button
                                                                type="button"
                                                                id="previous4"
                                                                className="btn"
                                                                style={{
                                                                    backgroundColor: "#E0E0E0",
                                                                    width: 150,
                                                                    borderRadius: 8
                                                                }}
                                                                onClick={() => { previous4(); }}
                                                            >
                                                                ATRÁS
                                                                {/* <span style={{textTransform:"capitalize"}}>atrás</span>  */}

                                                            </button>

                                                            <button
                                                                type="submit"
                                                                id="next4"
                                                                className="btn"
                                                                style={{
                                                                    marginLeft: 10,
                                                                    width: 150,
                                                                    backgroundColor: "#0069CB",
                                                                    color: "white",
                                                                    borderRadius: 8
                                                                }}
                                                            // onClick={() => { savecompanydetail3(); addClassNext4(); }}
                                                            // onClick={savecompanydetail3}
                                                            >
                                                                SIGUIENTE
                                                                {/* <span style={{textTransform:"capitalize"}}>ahorrar</span>  */}
                                                            </button>

                                                        </div>
                                                    </div>
                                                </form>
                                                {/* </div> */}
                                                {/* </div> */}
                                            </div>



                                        </div>

                                        {/* ----------------------------  step 5 start  ----------------------------------------------------- */}


                                        <div id="step5" className="step hide">
                                            {/* {(localId !== '') ?

                                                <div style={{ textAlign: "center", marginTop: "10px" }}><span className="badge bg-info">Company Id : {localId}</span></div>
                                                : null} */}


                                            <h4
                                                style={{
                                                    marginLeft: 0,
                                                    marginTop: 40,
                                                    color: "#0069CB",
                                                    fontWeight: 600
                                                }}
                                            >
                                                Representante legal
                                            </h4>

                                            <form onSubmit={handleSubmit5}>
                                                <div>
                                                    <div className="card" style={{ padding: 10, maxWidth: "60%" }}>
                                                        <div>
                                                            <label
                                                                id="progress-label"
                                                                htmlFor="progress"
                                                                style={{ display: "none" }}
                                                            />
                                                            <progress
                                                                id="progress"
                                                                value={0}
                                                                max={100}
                                                                style={{
                                                                    backgroundColor: "#27AE60",
                                                                    height: 5,
                                                                    position: "relative",
                                                                    top: "-20px",
                                                                    width: "102%",
                                                                    left: "-9px"
                                                                }}
                                                            >
                                                                {" "}
                                                            </progress>

                                                            <p>{cortax} (PDF, JPG, PNG){" "}</p>

                                                            <div style={{ display: "grid" }}>
                                                                <div id="feedback">

                                                                </div>
                                                                <input
                                                                    type="file"
                                                                    id="file-uploader"
                                                                    
                                                                    onChange={(e) => {
                                                                        setSetImageFile2(e.target.files[0]);
                                                                    }}
                                                                    style={{ marginLeft: 430 }}
                                                                    ref={setCorporation_taxRef}
                                                                    accept=".png, .jpg, .pdf"

                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* drag and drop */}

                                                    <div>
                                                        <p style={{ marginTop: 30, marginBottom: 20 }}>
                                                            Estados financieros ultimos 3 ejercicios (PDF, JPG, PNG, DOC){" "}
                                                            
                                                            <span>
                                                                <i
                                                                    className="fa fa-question-circle"
                                                                    style={{ color: "#0069CB" }}
                                                                    aria-hidden="true"
                                                                />
                                                            </span>{" "}
                                                        </p>
                                                        <div className="drop-zone">
                                                            <span className="drop-zone__prompt">
                                                                Soltar archivo para cargar o
                                                            </span>
                                                            <p style={{ color: "#0069CB", marginTop: 10 }}>
                                                                <span>
                                                                    <i
                                                                        className="fa fa-plus"
                                                                        style={{ marginLeft: 10, marginRight: 10 }}
                                                                        aria-hidden="true"
                                                                    />
                                                                </span>
                                                                Buscar Archivo
                                                            </p>
                                                            <input
                                                                type="file"
                                                                style={{ display: "none" }}
                                                                className="drop-zone__input"
                                                                
                                                                ref={setFinancial_statementsRef}
                                                                onChange={(e) => {
                                                                    setSetImageFile3(e.target.files[0]);
                                                                }}
                                                                accept=".png, .doc, .docx, .pdf, .jpg"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <p style={{ marginTop: 30, marginBottom: 20 }}>
                                                            Modelo 347 del año previo (PDF, JPG, PNG, DOC){" "}
                                                            <span>
                                                                <i
                                                                    className="fa fa-question-circle"
                                                                    style={{ color: "#0069CB" }}
                                                                    aria-hidden="true"
                                                                />
                                                            </span>{" "}
                                                        </p>
                                                        <div className="drop-zone">
                                                            <span className="drop-zone__prompt">
                                                                Soltar archivo para cargar o 
                                                            </span>
                                                            <p style={{ color: "#0069CB", marginTop: 10 }}>
                                                                <span>
                                                                    <i
                                                                        className="fa fa-plus"
                                                                        style={{ marginLeft: 10, marginRight: 10 }}
                                                                        aria-hidden="true"
                                                                    />
                                                                </span>
                                                                Buscar Archivo
                                                            </p>
                                                            <input
                                                                type="file"

                                                                style={{ display: "none" }}
                                                                className="drop-zone__input"
                                                                
                                                                onChange={(e) => {
                                                                    setSetImageFile4(e.target.files[0]);
                                                                }}
                                                                ref={setPrevious_year_modelRef}
                                                                accept=".png, .doc, .docx, .pdf, .jpg"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <p style={{ marginTop: 30, marginBottom: 20 }}>
                                                            Declaraciones IVA últimos dos años (PDF, JPG, PNG, DOC){" "}
                                                            <span>
                                                                <i
                                                                    className="fa fa-question-circle"
                                                                    style={{ color: "#0069CB" }}
                                                                    aria-hidden="true"
                                                                />
                                                            </span>{" "}
                                                        </p>
                                                        <div className="drop-zone">
                                                            <span className="drop-zone__prompt">
                                                                Soltar archivo para cargar o
                                                            </span>
                                                            <p style={{ color: "#0069CB", marginTop: 10 }}>
                                                                <span>
                                                                    <i
                                                                        className="fa fa-plus"
                                                                        style={{ marginLeft: 10, marginRight: 10 }}
                                                                        aria-hidden="true"
                                                                    />
                                                                </span>
                                                                Buscar Archivo
                                                            </p>
                                                            <input
                                                                type="file"

                                                                style={{ display: "none" }}
                                                                className="drop-zone__input"
                                                                
                                                                onChange={(e) => {
                                                                    setSetImageFile5(e.target.files[0]);
                                                                }}
                                                                ref={setVat_declarationsRef}
                                                                accept=".png, .doc, .docx, .pdf, .jpg"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <p style={{ marginTop: 30, marginBottom: 20 }}>
                                                            Breve Resumen de como afecto su actividad la situación del COVID
                                                            y las medidas tomadas (PDF, JPG, PNG, DOC){" "}
                                                            <span>
                                                                <i
                                                                    className="fa fa-question-circle"
                                                                    style={{ color: "#0069CB" }}
                                                                    aria-hidden="true"
                                                                />
                                                            </span>{" "}
                                                        </p>
                                                        <div className="drop-zone">
                                                            <span className="drop-zone__prompt">
                                                                Soltar archivo para cargar o
                                                            </span>
                                                            <p style={{ color: "#0069CB", marginTop: 10 }}>
                                                                <span>
                                                                    <i
                                                                        className="fa fa-plus"
                                                                        style={{ marginLeft: 10, marginRight: 10 }}
                                                                        aria-hidden="true"
                                                                    />
                                                                </span>
                                                                Buscar Archivo
                                                            </p>
                                                            <input
                                                                type="file"

                                                                style={{ display: "none" }}
                                                                className="drop-zone__input"
                                                                
                                                                onChange={(e) => {
                                                                    setSetImageFile6(e.target.files[0]);
                                                                }}
                                                                ref={setBrief_summaryRef}
                                                                accept=".png, .doc, .docx, .pdf, .jpg"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <p style={{ marginTop: 30, marginBottom: 20 }}>
                                                            Minutas de Junta de accionistas o reunion de directorio (PDF, JPG, PNG, DOC){" "}
                                                            <span>
                                                                <i
                                                                    className="fa fa-question-circle"
                                                                    style={{ color: "#0069CB" }}
                                                                    aria-hidden="true"
                                                                />
                                                            </span>{" "}
                                                        </p>
                                                        <div className="drop-zone">
                                                            <span className="drop-zone__prompt">
                                                                Soltar archivo para cargar o
                                                            </span>
                                                            <p style={{ color: "#0069CB", marginTop: 10 }}>
                                                                <span>
                                                                    <i
                                                                        className="fa fa-plus"
                                                                        style={{ marginLeft: 10, marginRight: 10 }}
                                                                        aria-hidden="true"
                                                                    />
                                                                </span>
                                                                Buscar Archivo
                                                            </p>
                                                            <input
                                                                type="file"

                                                                style={{ display: "none" }}
                                                                className="drop-zone__input"
                                                                
                                                                onChange={(e) => {
                                                                    setSetImageFile7(e.target.files[0]);
                                                                }}
                                                                ref={setMeetingRef}
                                                                accept=".png, .doc, .docx, .pdf, .jpg"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div style={{ display: "flex", marginTop: 40, marginBottom: 50 }}>

                                                        <button
                                                            type="button"
                                                            id="previous5"
                                                            className="btn"
                                                            onClick={() => { previous5(); }}
                                                            style={{
                                                                backgroundColor: "#E0E0E0",
                                                                width: 150,
                                                                borderRadius: 8
                                                            }}
                                                        >
                                                            ATRÁS
                                                            {/* <span style={{textTransform:"capitalize"}}>atrás</span>  */}

                                                        </button>

                                                        <button
                                                            type="submit"
                                                            id="finish"
                                                            className="btn"
                                                            style={{
                                                                marginLeft: 10,
                                                                width: 150,
                                                                backgroundColor: "#0069CB",
                                                                color: "white",
                                                                borderRadius: 8
                                                            }}
                                                        // onClick={() => { savecompanydetail4(); addClassNext5(); }}
                                                        // onClick={savecompanydetail4}
                                                        >
                                                            FINALIZAR
                                                            {/* <span style={{textTransform:"capitalize"}}>ahorrar</span>  */}
                                                        </button>


                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    {/* end STEPS */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>



            </React.Fragment>
            : <Navigate to="/" replace />
    )

    function changeSteps(index) {
        //   loadCss(loanFilled,index)
    }

    // function changeCss(index) {
    //     if (index === '5') {
    //         if (loanFilled['company_four'] === 'Fill') {
    //             $('#stepper4').addClass('co_app_card');
    //             $('#stepper4').removeClass('lo_app_card');
    //             $('#stepImage4_1').addClass('hide');
    //             $('#stepImage4_2').removeClass('hide');

    //             $('#stepper3').addClass('co_app_card');
    //             $('#stepper3').removeClass('lo_app_card');
    //             $('#stepImage3_1').addClass('hide');
    //             $('#stepImage3_2').removeClass('hide');

    //             $('#stepper2').addClass('co_app_card');
    //             $('#stepper2').removeClass('lo_app_card');
    //             $('#stepImage2_1').addClass('hide');
    //             $('#stepImage2_2').removeClass('hide');

    //             $('#stepper1').addClass('co_app_card');
    //             $('#stepper1').removeClass('lo_app_card');
    //             $('#stepImage1_1').addClass('hide');
    //             $('#stepImage1_2').removeClass('hide');
    //         } else {
    //             $('#stepper4').addClass('lo_com_card');
    //             $('#stepper3').addClass('lo_com_card');
    //             $('#stepper2').addClass('lo_com_card');
    //             $('#stepper1').addClass('lo_com_card');
    //         }
    //     }
    //     if (index === '4') {
    //         if (loanFilled['company_three'] === 'Fill') {
    //             $('#stepper4').addClass('co_app_card');
    //             $('#stepper4').removeClass('lo_app_card');
    //             $('#stepImage4_1').addClass('hide');
    //             $('#stepImage4_2').removeClass('hide');
    //         } else {
    //             $('#stepper4').addClass('lo_com_card');
    //         }
    //     }
    //     if (index === '3') {
    //         if (loanFilled['company_two'] === 'Fill') {
    //             $('#stepper3').addClass('co_app_card');
    //             $('#stepper3').removeClass('lo_app_card');
    //             $('#stepImage3_1').addClass('hide');
    //             $('#stepImage3_2').removeClass('hide');
    //         } else {
    //             $('#stepper3').addClass('lo_com_card');
    //         }
    //     }
    //     if (index === '2') {
    //         if (loanFilled['company_one'] === 'Fill') {
    //             $('#stepper2').addClass('co_app_card');
    //             $('#stepper2').removeClass('lo_app_card');
    //             $('#stepImage2_1').addClass('hide');
    //             $('#stepImage2_2').removeClass('hide');
    //         } else {
    //             $('#stepper2').addClass('lo_com_card');
    //         }
    //     }
    //     if (index === '1') {
    //         if (loanFilled['loan'] === 'Fill') {
    //             $('#stepper1').addClass('co_app_card');
    //             $('#stepper1').removeClass('lo_app_card');
    //             $('#stepImage1_1').addClass('hide');
    //             $('#stepImage1_2').removeClass('hide');
    //         } else {
    //             $('#stepper1').addClass('lo_com_card');
    //         }
    //     }
    // }

    function loadCss(data, index) {
        if (data['company_four'] === 'Fill') {
            if (index !== undefined) {
                if (index != null) {
                    if (index === "4") {
                        // 1
                        $('#stepper1').addClass('co_app_card');
                        $('#stepper1').removeClass('lo_app_card');
                        // activeIcon($('#stepIcon1'));

                        // 2
                        $('#stepper2').addClass('co_app_card');
                        $('#stepper2').removeClass('lo_app_card');
                        $('#stepImage2_1').addClass('hide');
                        $('#stepImage2_2').removeClass('hide');
                        // activeIcon($('#stepIcon2'));

                        // 3
                        $('#stepper3').addClass('co_app_card');
                        $('#stepper3').removeClass('lo_app_card');
                        $('#stepImage3_1').addClass('hide');
                        $('#stepImage3_2').removeClass('hide');
                        // activeIcon($('#stepIcon3'));

                        // 4
                        $('#stepper4').addClass('co_app_card');
                        $('#stepper4').removeClass('lo_app_card');
                        $('#stepImage4_1').addClass('hide');
                        $('#stepImage4_2').removeClass('hide');
                        // activeIcon($('#stepIcon4'));
                        // 5
                        $('#stepper5').addClass('lo_app_card');
                        $('#stepper5').removeClass('lo_com_card');
                        $('#stepText5').addClass('stpText');
                        $('#stepImage5_1').addClass('hide');
                        $('#stepImage5_2').removeClass('hide');
                        activeStep($('#step5'));
                    } else if (index === "3") {
                        // 1
                        $('#stepper1').addClass('co_app_card');
                        $('#stepper1').removeClass('lo_app_card');
                        activeIcon($('#stepIcon1'));

                        // 2
                        $('#stepper2').addClass('co_app_card');
                        $('#stepper2').removeClass('lo_app_card');
                        // activeIcon($('#stepIcon2'));
                        $('#stepImage2_1').addClass('hide');
                        $('#stepImage2_2').removeClass('hide');

                        // 3
                        $('#stepper3').addClass('co_app_card');
                        $('#stepper3').removeClass('lo_app_card');
                        $('#stepImage3_1').addClass('hide');
                        $('#stepImage3_2').removeClass('hide');
                        // activeIcon($('#stepIcon3'));

                        // 4
                        $('#stepper4').addClass('lo_app_card');
                        $('#stepper4').removeClass('lo_com_card');
                        $('#stepText4').addClass('stpText');
                        $('#stepImage4_1').addClass('hide');
                        $('#stepImage4_2').removeClass('hide');
                        activeStep($('#step4'));

                        // 5
                        $('#stepper5').addClass('co_app_card');
                        $('#stepper5').removeClass('lo_app_card');
                        $('#stepImage5_1').addClass('hide');
                        $('#stepImage5_2').removeClass('hide');
                        // activeIcon($('#stepIcon5'));

                    } else if (index === "2") {
                        // 1
                        $('#stepper1').addClass('co_app_card');
                        $('#stepper1').removeClass('lo_app_card');
                        // activeIcon($('#stepIcon1'));

                        // 2
                        $('#stepper2').addClass('co_app_card');
                        $('#stepper2').removeClass('lo_app_card');
                        $('#stepImage2_1').addClass('hide');
                        $('#stepImage2_2').removeClass('hide');
                        // activeIcon($('#stepIcon2'));

                        // 3
                        $('#stepper3').addClass('lo_app_card');
                        $('#stepper3').removeClass('lo_com_card');
                        $('#stepImage3_1').addClass('hide');
                        $('#stepImage3_2').removeClass('hide');
                        $('#stepText3').addClass('stpText');
                        activeStep($('#step3'));


                        // 4
                        $('#stepper4').addClass('co_app_card');
                        $('#stepper4').removeClass('lo_app_card');
                        $('#stepImage4_1').addClass('hide');
                        $('#stepImage4_2').removeClass('hide');
                        // activeIcon($('#stepIcon4'));

                        // 5
                        $('#stepper5').addClass('co_app_card');
                        $('#stepper5').removeClass('lo_app_card');
                        $('#stepImage5_1').addClass('hide');
                        $('#stepImage5_2').removeClass('hide');
                        // activeIcon($('#stepIcon5'));

                    } else if (index === "1") {

                        // 1
                        $('#stepper1').addClass('co_app_card');
                        $('#stepper1').removeClass('lo_app_card');
                        // activeIcon($('#stepIcon1'));

                        // 2
                        $('#stepper2').addClass('lo_app_card');
                        $('#stepper2').removeClass('lo_com_card');
                        $('#stepImage2_1').addClass('hide');
                        $('#stepImage2_2').removeClass('hide');
                        $('#stepText2').addClass('stpText');
                        activeStep($('#step2'));

                        // 3
                        $('#stepper3').addClass('co_app_card');
                        $('#stepper3').removeClass('lo_app_card');
                        $('#stepImage3_1').addClass('hide');
                        $('#stepImage3_2').removeClass('hide');
                        // activeIcon($('#stepIcon3'));

                        // 4
                        $('#stepper4').addClass('co_app_card');
                        $('#stepper4').removeClass('lo_app_card');
                        $('#stepImage4_1').addClass('hide');
                        $('#stepImage4_2').removeClass('hide');
                        // activeIcon($('#stepIcon4'));

                        // 5
                        $('#stepper5').addClass('co_app_card');
                        $('#stepper5').removeClass('lo_app_card');
                        $('#stepImage5_1').addClass('hide');
                        $('#stepImage5_2').removeClass('hide');
                        // activeIcon($('#stepIcon5'));

                    } else if (index === "0") {

                        // 2
                        $('#stepper2').addClass('co_app_card');
                        $('#stepper2').removeClass('lo_app_card');
                        $('#stepImage2_1').addClass('hide');
                        $('#stepImage2_2').removeClass('hide');
                        // activeIcon($('#stepIcon2'));

                        // 3
                        $('#stepper3').addClass('co_app_card');
                        $('#stepper3').removeClass('lo_app_card');
                        $('#stepImage3_1').addClass('hide');
                        $('#stepImage3_2').removeClass('hide');
                        // activeIcon($('#stepIcon3'));

                        // 4
                        $('#stepper4').addClass('co_app_card');
                        $('#stepper4').removeClass('lo_app_card');
                        $('#stepImage4_1').addClass('hide');
                        $('#stepImage4_2').removeClass('hide');
                        // activeIcon($('#stepIcon4'));

                        // 5
                        $('#stepper5').addClass('co_app_card');
                        $('#stepper5').removeClass('lo_app_card');
                        // activeIcon($('#stepIcon5'));
                        $('#stepImage5_1').addClass('hide');
                        $('#stepImage5_2').removeClass('hide');
                    }
                }
            }

        } else if (data['company_three'] === 'Fill') {

            if (index !== undefined) {
                if (index != null) {
                    if (index === "4") {
                        // 1
                        $('#stepper1').addClass('co_app_card');
                        $('#stepper1').removeClass('lo_app_card');
                        // activeIcon($('#stepIcon1'));

                        // 2
                        $('#stepper2').addClass('co_app_card');
                        $('#stepper2').removeClass('lo_app_card');
                        $('#stepImage2_1').addClass('hide');
                        $('#stepImage2_2').removeClass('hide');
                        // activeIcon($('#stepIcon2'));

                        // 3
                        $('#stepper3').addClass('co_app_card');
                        $('#stepper3').removeClass('lo_app_card');
                        $('#stepImage3_1').addClass('hide');
                        $('#stepImage3_2').removeClass('hide');
                        // activeIcon($('#stepIcon3'));

                        // 4
                        $('#stepper4').addClass('co_app_card');
                        $('#stepper4').removeClass('lo_app_card');
                        $('#stepImage4_1').addClass('hide');
                        $('#stepImage4_2').removeClass('hide');
                        // activeIcon($('#stepIcon4'));
                        // 5
                        $('#stepper5').addClass('lo_app_card');
                        $('#stepper5').removeClass('lo_com_card');
                        $('#stepText5').addClass('stpText');
                        $('#stepImage5_1').addClass('hide');
                        $('#stepImage5_2').removeClass('hide');
                        activeStep($('#step5'));
                    } else if (index === "3") {
                        // 1
                        $('#stepper1').addClass('co_app_card');
                        $('#stepper1').removeClass('lo_app_card');
                        // activeIcon($('#stepIcon1'));

                        // 2
                        $('#stepper2').addClass('co_app_card');
                        $('#stepper2').removeClass('lo_app_card');
                        $('#stepImage2_1').addClass('hide');
                        $('#stepImage2_2').removeClass('hide');
                        // activeIcon($('#stepIcon2'));

                        // 3
                        $('#stepper3').addClass('co_app_card');
                        $('#stepper3').removeClass('lo_app_card');
                        $('#stepImage3_1').addClass('hide');
                        $('#stepImage3_2').removeClass('hide');
                        // activeIcon($('#stepIcon3'));

                        // 4
                        $('#stepper4').addClass('lo_app_card');
                        $('#stepper4').removeClass('lo_com_card');
                        $('#stepText4').addClass('stpText');
                        $('#stepImage4_1').addClass('hide');
                        $('#stepImage4_2').removeClass('hide');
                        activeStep($('#step4'));

                    } else if (index === "2") {
                        // 1
                        $('#stepper1').addClass('co_app_card');
                        $('#stepper1').removeClass('lo_app_card');
                        // activeIcon($('#stepIcon1'));

                        // 2
                        $('#stepper2').addClass('co_app_card');
                        $('#stepper2').removeClass('lo_app_card');
                        // activeIcon($('#stepIcon2'));
                        $('#stepImage2_1').addClass('hide');
                        $('#stepImage2_2').removeClass('hide');

                        // 3
                        $('#stepper3').addClass('lo_app_card');
                        $('#stepper3').removeClass('lo_com_card');
                        $('#stepImage3_1').addClass('hide');
                        $('#stepImage3_2').removeClass('hide');
                        $('#stepText3').addClass('stpText');
                        activeStep($('#step3'));


                        // 4
                        $('#stepper4').addClass('co_app_card');
                        $('#stepper4').removeClass('lo_app_card');
                        $('#stepImage4_1').addClass('hide');
                        $('#stepImage4_2').removeClass('hide');
                        // activeIcon($('#stepIcon4'));


                    } else if (index === "1") {

                        // 1
                        $('#stepper1').addClass('co_app_card');
                        $('#stepper1').removeClass('lo_app_card');
                        // activeIcon($('#stepIcon1'));

                        // 2
                        $('#stepper2').addClass('lo_app_card');
                        $('#stepper2').removeClass('lo_com_card');
                        $('#stepImage2_1').addClass('hide');
                        $('#stepImage2_2').removeClass('hide');
                        $('#stepText2').addClass('stpText');
                        activeStep($('#step2'));

                        // 3
                        $('#stepper3').addClass('co_app_card');
                        $('#stepper3').removeClass('lo_app_card');
                        $('#stepImage3_1').addClass('hide');
                        $('#stepImage3_2').removeClass('hide');
                        // activeIcon($('#stepIcon3'));

                        // 4
                        $('#stepper4').addClass('co_app_card');
                        $('#stepper4').removeClass('lo_app_card');
                        $('#stepImage4_1').addClass('hide');
                        $('#stepImage4_2').removeClass('hide');
                        // activeIcon($('#stepIcon4'));


                    } else if (index === "0") {

                        // 2
                        $('#stepper2').addClass('co_app_card');
                        $('#stepper2').removeClass('lo_app_card');
                        $('#stepImage2_1').addClass('hide');
                        $('#stepImage2_2').removeClass('hide');
                        // activeIcon($('#stepIcon2'));

                        // 3
                        $('#stepper3').addClass('co_app_card');
                        $('#stepper3').removeClass('lo_app_card');
                        $('#stepImage3_1').addClass('hide');
                        $('#stepImage3_2').removeClass('hide');
                        // activeIcon($('#stepIcon3'));

                        // 4
                        $('#stepper4').addClass('co_app_card');
                        $('#stepper4').removeClass('lo_app_card');
                        $('#stepImage4_1').addClass('hide');
                        $('#stepImage4_2').removeClass('hide');
                        // activeIcon($('#stepIcon4'));

                    }
                }
            }

        } else if (data['company_two'] === 'Fill') {

            if (index !== undefined) {
                if (index != null) {
                    if (index === "4") {
                        // 1
                        $('#stepper1').addClass('co_app_card');
                        $('#stepper1').removeClass('lo_app_card');
                        // activeIcon($('#stepIcon1'));

                        // 2
                        $('#stepper2').addClass('co_app_card');
                        $('#stepper2').removeClass('lo_app_card');
                        $('#stepImage2_1').addClass('hide');
                        $('#stepImage2_2').removeClass('hide');
                        // activeIcon($('#stepIcon2'));

                        // 3
                        $('#stepper3').addClass('co_app_card');
                        $('#stepper3').removeClass('lo_app_card');
                        // activeIcon($('#stepIcon3'));
                        $('#stepImage3_1').addClass('hide');
                        $('#stepImage3_2').removeClass('hide');

                        // 5
                        $('#stepper5').addClass('lo_app_card');
                        $('#stepper5').removeClass('lo_com_card');
                        $('#stepText5').addClass('stpText');
                        $('#stepImage5_1').addClass('hide');
                        $('#stepImage5_2').removeClass('hide');
                        activeStep($('#step5'));
                    } else if (index === "3") {
                        // 1
                        $('#stepper1').addClass('co_app_card');
                        $('#stepper1').removeClass('lo_app_card');
                        // activeIcon($('#stepIcon1'));

                        // 2
                        $('#stepper2').addClass('co_app_card');
                        $('#stepper2').removeClass('lo_app_card');
                        $('#stepImage2_1').addClass('hide');
                        $('#stepImage2_2').removeClass('hide');
                        // activeIcon($('#stepIcon2'));

                        // 3
                        $('#stepper3').addClass('co_app_card');
                        $('#stepper3').removeClass('lo_app_card');
                        $('#stepImage3_1').addClass('hide');
                        $('#stepImage3_2').removeClass('hide');
                        // activeIcon($('#stepIcon3'));

                        // 4
                        $('#stepper4').addClass('lo_app_card');
                        $('#stepper4').removeClass('lo_com_card');
                        $('#stepText4').addClass('stpText');
                        $('#stepImage4_1').addClass('hide');
                        $('#stepImage4_2').removeClass('hide');
                        activeStep($('#step4'));

                    } else if (index === "2") {
                        // 1
                        $('#stepper1').addClass('co_app_card');
                        $('#stepper1').removeClass('lo_app_card');
                        // activeIcon($('#stepIcon1'));

                        // 2
                        $('#stepper2').addClass('co_app_card');
                        $('#stepper2').removeClass('lo_app_card');
                        $('#stepImage2_1').addClass('hide');
                        $('#stepImage2_2').removeClass('hide');
                        // activeIcon($('#stepIcon2'));

                        // 3
                        $('#stepper3').addClass('lo_app_card');
                        $('#stepper3').removeClass('lo_com_card');
                        $('#stepImage3_1').addClass('hide');
                        $('#stepImage3_2').removeClass('hide');
                        $('#stepText3').addClass('stpText');
                        activeStep($('#step3'));



                    } else if (index === "1") {

                        // 1
                        $('#stepper1').addClass('co_app_card');
                        $('#stepper1').removeClass('lo_app_card');
                        // activeIcon($('#stepIcon1'));

                        // 2
                        $('#stepper2').addClass('lo_app_card');
                        $('#stepper2').removeClass('lo_com_card');
                        $('#stepImage2_1').addClass('hide');
                        $('#stepImage2_2').removeClass('hide');
                        $('#stepText2').addClass('stpText');
                        activeStep($('#step2'));

                        // 3
                        $('#stepper3').addClass('co_app_card');
                        $('#stepper3').removeClass('lo_app_card');
                        $('#stepImage3_1').addClass('hide');
                        $('#stepImage3_2').removeClass('hide');
                        // activeIcon($('#stepIcon3'));

                    } else if (index === "0") {

                        // 2
                        $('#stepper2').addClass('co_app_card');
                        $('#stepper2').removeClass('lo_app_card');
                        $('#stepImage2_1').addClass('hide');
                        $('#stepImage2_2').removeClass('hide');
                        // activeIcon($('#stepIcon2'));

                        // 3
                        $('#stepper3').addClass('co_app_card');
                        $('#stepper3').removeClass('lo_app_card');
                        $('#stepImage3_1').addClass('hide');
                        $('#stepImage3_2').removeClass('hide');
                        // activeIcon($('#stepIcon3'));

                    }
                }
            }

        } else if (data['company_one'] === 'Fill') {


            if (index !== undefined) {
                if (index != null) {
                    if (index === "4") {
                        // 1
                        $('#stepper1').addClass('co_app_card');
                        $('#stepper1').removeClass('lo_app_card');
                        // activeIcon($('#stepIcon1'));

                        // 2
                        $('#stepper2').addClass('co_app_card');
                        $('#stepper2').removeClass('lo_app_card');
                        $('#stepImage2_1').addClass('hide');
                        $('#stepImage2_2').removeClass('hide');
                        // activeIcon($('#stepIcon2'));



                        // 5
                        $('#stepper5').addClass('lo_app_card');
                        $('#stepper5').removeClass('lo_com_card');
                        $('#stepText5').addClass('stpText');
                        $('#stepImage5_1').addClass('hide');
                        $('#stepImage5_2').removeClass('hide');
                        activeStep($('#step5'));
                    } else if (index === "3") {
                        // 1
                        $('#stepper1').addClass('co_app_card');
                        $('#stepper1').removeClass('lo_app_card');
                        // activeIcon($('#stepIcon1'));

                        // 2
                        $('#stepper2').addClass('co_app_card');
                        $('#stepper2').removeClass('lo_app_card');
                        $('#stepImage2_1').addClass('hide');
                        $('#stepImage2_2').removeClass('hide');
                        // activeIcon($('#stepIcon2'));

                        // 4
                        $('#stepper4').addClass('lo_app_card');
                        $('#stepper4').removeClass('lo_com_card');
                        $('#stepText4').addClass('stpText');
                        $('#stepImage4_1').addClass('hide');
                        $('#stepImage4_2').removeClass('hide');
                        activeStep($('#step4'));

                    } else if (index === "2") {
                        // 1
                        $('#stepper1').addClass('co_app_card');
                        $('#stepper1').removeClass('lo_app_card');
                        // activeIcon($('#stepIcon1'));

                        // 2
                        $('#stepper2').addClass('co_app_card');
                        $('#stepper2').removeClass('lo_app_card');
                        $('#stepImage2_1').addClass('hide');
                        $('#stepImage2_2').removeClass('hide');
                        // activeIcon($('#stepIcon2'));

                        // 3
                        $('#stepper3').addClass('lo_app_card');
                        $('#stepper3').removeClass('lo_com_card');
                        $('#stepImage3_1').addClass('hide');
                        $('#stepImage3_2').removeClass('hide');
                        $('#stepText3').addClass('stpText');
                        activeStep($('#step3'));



                    } else if (index === "1") {

                        // 1
                        $('#stepper1').addClass('co_app_card');
                        $('#stepper1').removeClass('lo_app_card');
                        // activeIcon($('#stepIcon1'));

                        // 2
                        $('#stepper2').addClass('lo_app_card');
                        $('#stepper2').removeClass('lo_com_card');
                        $('#stepImage2_1').addClass('hide');
                        $('#stepImage2_2').removeClass('hide');
                        $('#stepText2').addClass('stpText');
                        activeStep($('#step2'));


                    } else if (index === "0") {

                        // 2
                        $('#stepper2').addClass('co_app_card');
                        $('#stepper2').removeClass('lo_app_card');
                        // activeIcon($('#stepIcon2'));
                        $('#stepImage2_1').addClass('hide');
                        $('#stepImage2_2').removeClass('hide');

                    }
                }
            }



        } else if (data['loan'] === 'Fill') {



            if (index !== undefined) {
                if (index != null) {
                    if (index === "4") {
                        // 1
                        $('#stepper1').addClass('co_app_card');
                        $('#stepper1').removeClass('lo_app_card');
                        // activeIcon($('#stepIcon1'));

                        // 5
                        $('#stepper5').addClass('lo_app_card');
                        $('#stepper5').removeClass('lo_com_card');
                        $('#stepText5').addClass('stpText');
                        $('#stepImage5_1').addClass('hide');
                        $('#stepImage5_2').removeClass('hide');
                        activeStep($('#step5'));
                    } else if (index === "3") {
                        // 1
                        $('#stepper1').addClass('co_app_card');
                        $('#stepper1').removeClass('lo_app_card');
                        // activeIcon($('#stepIcon1'));

                        // 4
                        $('#stepper4').addClass('lo_app_card');
                        $('#stepper4').removeClass('lo_com_card');
                        $('#stepText4').addClass('stpText');
                        $('#stepImage4_1').addClass('hide');
                        $('#stepImage4_2').removeClass('hide');
                        activeStep($('#step4'));

                    } else if (index === "2") {
                        // 1
                        $('#stepper1').addClass('co_app_card');
                        $('#stepper1').removeClass('lo_app_card');
                        // activeIcon($('#stepIcon1'));


                        // 3
                        $('#stepper3').addClass('lo_app_card');
                        $('#stepper3').removeClass('lo_com_card');
                        $('#stepImage3_1').addClass('hide');
                        $('#stepImage3_2').removeClass('hide');
                        $('#stepText3').addClass('stpText');
                        activeStep($('#step3'));



                    } else if (index === "1") {

                        // 1
                        $('#stepper1').addClass('co_app_card');
                        $('#stepper1').removeClass('lo_app_card');
                        // activeIcon($('#stepIcon1'));

                        // 2
                        $('#stepper2').addClass('lo_app_card');
                        $('#stepper2').removeClass('lo_com_card');
                        $('#stepImage2_1').addClass('hide');
                        $('#stepImage2_2').removeClass('hide');
                        $('#stepText2').addClass('stpText');
                        activeStep($('#step2'));


                    } else if (index === "0") {

                    }
                }
            }

        }
    }

}


function addClassNext1() {
    $('#stepper1').addClass('co_app_card');
    $('#stepper1').removeClass('lo_app_card');

    $('#stepper2').addClass('lo_app_card');
    $('#stepper2').removeClass('lo_com_card');

    $('#stepImage2_1').addClass('hide');
    $('#stepImage2_2').removeClass('hide');

    $('#stepText2').addClass('stpText');

    activeIcon($('#stepIcon1'));
    activeStep($('#step2'));
}


function addClassNext2() {
    $('#stepper2').addClass('co_app_card');
    $('#stepper2').removeClass('lo_app_card');

    $('#stepper3').addClass('lo_app_card');
    $('#stepper3').removeClass('lo_com_card');

    $('#stepImage3_1').addClass('hide');
    $('#stepImage3_2').removeClass('hide');

    $('#stepText3').addClass('stpText');

    activeIcon($('#stepIcon2'));
    activeStep($('#step3'));
}

function addClassNext3() {
    $('#stepper3').addClass('co_app_card');
    $('#stepper3').removeClass('lo_app_card');

    $('#stepper4').addClass('lo_app_card');
    $('#stepper4').removeClass('lo_com_card');

    $('#stepImage4_1').addClass('hide');
    $('#stepImage4_2').removeClass('hide');

    $('#stepText4').addClass('stpText');

    activeIcon($('#stepIcon3'));
    activeStep($('#step4'));
}

function addClassNext4() {
    $('#stepper4').addClass('co_app_card');
    $('#stepper4').removeClass('lo_app_card');

    $('#stepper5').addClass('lo_app_card');
    $('#stepper5').removeClass('lo_com_card');

    $('#stepImage5_1').addClass('hide');
    $('#stepImage5_2').removeClass('hide');

    $('#stepText5').addClass('stpText');

    activeIcon($('#stepIcon4'));
    activeStep($('#step5'));
}

function addClassNext5() {
    $('#stepper5').addClass('co_app_card');
    $('#stepper5').removeClass('lo_app_card');
    activeIcon($('#stepIcon5'));
}

function previous2() {
    $('#stepper2').addClass('lo_com_card');
    $('#stepper2').removeClass('co_app_card');

    $('#stepper1').addClass('lo_app_card');
    $('#stepper1').removeClass('co_app_card');
    $('#stepIcon1').addClass('hide');

    $('#stepImage2_1').removeClass('hide');
    $('#stepImage2_2').addClass('hide');

    $('#stepText2').removeClass('stpText');

    activeStep($('#step1'));
}

function previous3() {
    $('#stepper3').addClass('lo_com_card');
    $('#stepper3').removeClass('co_app_card');

    $('#stepper2').addClass('lo_app_card');
    $('#stepper2').removeClass('co_app_card');
    $('#stepIcon2').addClass('hide');

    $('#stepImage3_1').removeClass('hide');
    $('#stepImage3_2').addClass('hide');

    $('#stepText3').removeClass('stpText');

    activeStep($('#step2'));
}


function previous4() {
    $('#stepper4').addClass('lo_com_card');
    $('#stepper4').removeClass('co_app_card');

    $('#stepper3').addClass('lo_app_card');
    $('#stepper3').removeClass('co_app_card');
    $('#stepIcon3').addClass('hide');

    $('#stepImage4_1').removeClass('hide');
    $('#stepImage4_2').addClass('hide');

    $('#stepText4').removeClass('stpText');

    activeStep($('#step3'));
}

function previous5() {
    $('#stepper5').addClass('lo_com_card');
    $('#stepper5').removeClass('co_app_card');

    $('#stepper4').addClass('lo_app_card');
    $('#stepper4').removeClass('co_app_card');
    // activeIcon($('#stepIcon4'));
    activeStep($('#step4'));
    $('#stepIcon4').addClass('hide');
    $('#stepIcon5').addClass('hide');
    $('#stepIcon5').removeClass('icon');

    // $('#stepText4').removeClass('stpText');
    $('#stepText5').removeClass('stpText');

    $('#stepImage5_1').removeClass('hide');
    $('#stepImage5_2').addClass('hide');
}




function activeStep(stepDiv) {
    $('.step').addClass('hide');
    stepDiv.removeClass('hide');
}

function activeIcon(stepDiv) {
    $('.stepIcon').addClass('hide');
    stepDiv.removeClass('hide');
}

















$(document).ready(function () {

    document.querySelectorAll(".drop-zone__input").forEach((inputElement) => {
        const dropZoneElement = inputElement.closest(".drop-zone");

        dropZoneElement.addEventListener("click", (e) => {
            inputElement.click();
        });

        inputElement.addEventListener("change", (e) => {
            if (inputElement.files.length) {
                updateThumbnail(dropZoneElement, inputElement.files[0]);
            }
        });

        dropZoneElement.addEventListener("dragover", (e) => {
            e.preventDefault();
            dropZoneElement.classList.add("drop-zone--over");
        });

        ["dragleave", "dragend"].forEach((type) => {
            dropZoneElement.addEventListener(type, (e) => {
                dropZoneElement.classList.remove("drop-zone--over");
            });
        });

        dropZoneElement.addEventListener("drop", (e) => {
            e.preventDefault();

            if (e.dataTransfer.files.length) {
                inputElement.files = e.dataTransfer.files;
                updateThumbnail(dropZoneElement, e.dataTransfer.files[0]);
            }

            dropZoneElement.classList.remove("drop-zone--over");
        });
    });

    /**
     * Updates the thumbnail on a drop zone element.
     *
     * @param {HTMLElement} dropZoneElement
     * @param {File} file
     */
    function updateThumbnail(dropZoneElement, file) {
        let thumbnailElement = dropZoneElement.querySelector(".drop-zone__thumb");

        // First time - remove the prompt
        if (dropZoneElement.querySelector(".drop-zone__prompt")) {
            dropZoneElement.querySelector(".drop-zone__prompt").remove();
        }

        // First time - there is no thumbnail element, so lets create it
        if (!thumbnailElement) {
            thumbnailElement = document.createElement("div");
            thumbnailElement.classList.add("drop-zone__thumb");
            dropZoneElement.appendChild(thumbnailElement);
        }

        thumbnailElement.dataset.label = file.name;

        // Show thumbnail for image files
        if (file.type.startsWith("image/")) {
            const reader = new FileReader();

            reader.readAsDataURL(file);
            reader.onload = () => {
                thumbnailElement.style.backgroundImage = `url('${reader.result}')`;
            };
        } else {
            thumbnailElement.style.backgroundImage = null;
        }
    }
});


// -------------------------------------  Add more input field start -------------------------

$(document).ready(function () {
    $("#addmore").click(function () {
        $("#req_input").append('<div class="required_inp">  <input type="text" class="form-control lo_inpu" style="margin-top:15px" placeholder="New"  id=""> <input type="button" style="margin-top:10px;" class="inputRemove" value="Remove"/></div>');
    });
    $('body').on('click', '.inputRemove', function () {
        $(this).parent('div.required_inp').remove()
    });
});





$(document).ready(function () {
    $("#addmore1").click(function () {
        $("#req_input1").append('<div class="required_inp">  <input type="text" class="form-control lo_inpu" style="margin-top:15px" placeholder="New"  id=""> <input type="button" style="margin-top:10px;" class="inputRemove" value="Remove"/></div>');
    });
    $('body').on('click', '.inputRemove', function () {
        $(this).parent('div.required_inp').remove()
    });
});

$(document).ready(function () {
    $("#addmore2").click(function () {
        $("#req_input2").append('<div class="required_inp">  <input type="text" class="form-control lo_inpu" style="margin-top:15px" placeholder="New"  id=""> <input type="button" style="margin-top:10px;" class="inputRemove" value="Remove"/></div>');
    });
    $('body').on('click', '.inputRemove', function () {
        $(this).parent('div.required_inp').remove()
    });
});


// --------------------------- first file upload start  -------------------
$(document).ready(function () {
    const fileUploader = document.getElementById('file-uploader');
    const feedback = document.getElementById('feedback');
    const progress = document.getElementById('progress');

    const reader = new FileReader();

    if (fileUploader) {
        fileUploader.addEventListener('change', (event) => {
            const files = event.target.files;
            const file = files[0];
            reader.readAsDataURL(file);

            reader.addEventListener('progress', (event) => {

                if (event.loaded && event.total) {
                    const percent = (event.loaded / event.total) * 100;
                    progress.value = percent;
                    document.getElementById('progress-label').innerHTML = Math.round(percent) + '%';

                    if (percent === 100) {
                        let msg = `<span> ${file.name} </span>`;
                        feedback.innerHTML = msg;
                    }
                }
            });
        });
    }
});


//--------------- for button
$(document).ready(function () {
    $('.button_select').on('click', function () {
        $('.button_select').removeClass('selected');
        $(this).addClass('selected');
    });

    $('.button_select1').on('click', function () {
        $('.button_select1').removeClass('selected1');
        $(this).addClass('selected1');
    });
});
// import logo from './logo.svg';
import './App.css';
import Home from './pages/Home';
import Login from './auth/Login';
import About from './pages/About';
import Register from './auth/Register';
import Resetpasswordmail from './auth/resetpasswordmail';
import Conformpassword from './auth/conformpassword';
import Blog from './pages/blog';
import Sector from './user/company_sector'
import React from 'react'


import Calander from './pages/calander'
import Cta from './pages/cta';
import Creditscore from './pages/creditscore';
import Condition from './pages/Legalcondition';
import Conform from './pages/Legalconform';
import Cookies from './pages/Cookiespolicy';
import Companyregister from './user/company_register';

import Dataprotect from './pages/dataprotect';
import Dashboard from './pages/dashboard';
import DashboardApp from './pages/dashboardapplication';
import DashboardLoan from './pages/dashboardloan';

import Getloan from './pages/Getloan';
import GuestCompany from './user/company_detail';
import Myscore from './pages/Myscore';
import Lenderdashbo from './lender/lenderdashbo';
import UserDetial from './lender/user_detail';
import OfferConfirmed from './lender/offer_confirmed';
import Warning from './pages/Legalwarning';
import LenderProfile from './lender/lender_profile'
import UserProfile from './user/user_profile';
import LenderOffer from './lender/lender_offer';
import Visitor from './pages/visitor_conform';
import GuestConform from './pages/guest_conformation';
import Scoretips from './pages/score_tips';




import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Contact from './pages/contact';

function App() {
  const role_id = localStorage.getItem("role_id");
  const loginStatus = localStorage.getItem("creditScore_status");

  return (
    <Router>
      <Routes>
        {(role_id === null && loginStatus === null) ? (
          <Route path='/' element={<Home />}></Route>
        ) : (role_id !== null && loginStatus === null) ? (
          <Route path='/' element={<Home />}></Route>
        ) : (role_id !== null && role_id === "3") ? (
          <Route path='/lender/lenderdashbo' element={<Lenderdashbo />}></Route>
        ) : (
          <Route path='/' element={<Dashboard />}></Route>
        )
        }
        <Route path='/dashboard' element={<Dashboard />}></Route>
        <Route path='/auth/Login' element={<Login />}></Route>
        <Route path='/auth/resetpasswordmail' element={<Resetpasswordmail />}></Route>
        <Route path='/auth/conformpassword' element={<Conformpassword />}></Route>
        <Route path='/auth/register' element={<Register />}></Route>
        <Route path='/user/company_sector' element={<Sector />}></Route>
        <Route path='/about' element={<About />}></Route>
        <Route path='/calander' element={<Calander />}></Route>
        <Route path='/cta' element={<Cta />}></Route>
        <Route path='/blog' element={<Blog />}></Route>
        <Route path='/creditscore' element={<Creditscore />}></Route>
        <Route path='/warning' element={<Warning />}></Route>
        <Route path='/condition' element={<Condition />}></Route>
        <Route path='/conform' element={<Conform />}></Route>
        <Route path='/cookies' element={<Cookies />}></Route>
        <Route path='/contact' element={<Contact />}></Route>
        <Route path='/user/company_register' element={<Companyregister />}></Route>
        <Route path='/dashboardapp' element={<DashboardApp />}></Route>
        <Route path='/dashboardloan' element={<DashboardLoan />}></Route>
        <Route path='/getloan' element={<Getloan />} >     </Route>
        <Route path='/user/company_detail' element={<GuestCompany />}></Route>
        <Route path='/myscore' element={<Myscore />}></Route>
        <Route path='/lender/lenderdashbo' element={<Lenderdashbo />}></Route>
        <Route path='/lender/user_detial' element={<UserDetial />}></Route>
        <Route path='/lender/offer_confirmed' element={<OfferConfirmed />}></Route>
        <Route path='/dataprotect' element={<Dataprotect />}></Route>
        <Route path='/lender/lender_profile' element={<LenderProfile />}></Route>
        <Route path='/user/user_profile' element={<UserProfile />}></Route>
        <Route path='/lender/lender_offer' element={<LenderOffer />}></Route>
        <Route path='/visitor_conform' element={<Visitor />}></Route>
        <Route path='/guest_conformation' element={<GuestConform />}></Route>
        <Route path='/score_tips' element={<Scoretips />}></Route>
      </Routes>
    </Router>
  );
}

export default App;

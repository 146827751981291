// import $ from 'jquery';
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as con from '../repository/urls.js';
import * as apis from '../repository/api_calls.js';
import Select, { components } from 'react-select';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

export default function Sector() {

  // ----------------------------------------- company- sector  -----------
  const navigate = useNavigate();
  const user_id = localStorage.getItem("id");

const [companyName, setCompanyName] = React.useState("")
  const [company_start_date, setCompany_start_date] = React.useState("");
  const [sector_id, setSector_id] = React.useState("Seleccionar1");
  const [sectorsList, setSectorsList] = React.useState();
  const currentDate = new Date().toISOString().split('T')[0];

  // const [selectedSector, setSelectedSector] = React.useState();

  const fechaRef = React.useRef();
  const sectorRef = React.useRef();
  

  const handleSubmit = async (event) => {
    event.preventDefault();

    // useEffect(() => {
      getGuestCompany();
    // }, [])
  
    async function getGuestCompany() {
      const response = await apis.GuestCompany(JSON.stringify({
        "company_creation_date": company_start_date,
        "cnae_sector": sector_id,
        "user_id": user_id,
        "company_id": " ",
        "company_name":companyName
      }),);
      console.log(response)
      if (response['status'] === true) {
        toast.success(response['message'], {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo_bar_log_Success'
        });
        setTimeout(() => {
          navigate('/dashboard');
        }, 800);
      }
      else {
        swal.fire({
          title: response['message'],
          icon: 'info',
          // input: 'textarea',
          showCancelButton: true,
          confirmButtonText: 'OK',
          inputAttributes: {
            required: true
          }
        }).then((response) => {
          if (response.isConfirmed) {
            window.location.href = '/dashboard';
          }
        });
      }
    }

    // fetch(`${process.env.REACT_APP_BASE_URL}${con.guest2}`, {
    //   method: 'post',
    //   body: JSON.stringify({
    //     "company_creation_date": company_start_date,
    //     "cnae_sector": sector_id,
    //     "user_id": user_id,
    //     "company_id": " ",
    //     "company_name":companyName
    //   }),
    //   headers:
    //   {
    //     'Content-Type': 'application/json',
    //   }
    // }
    // ).then(async response => {
    //   const result = await response.json();
    //   console.log("--------------------------------")
    //   console.log(result);

    //   if (result['status'] === true) {
    //     toast.success(result['message'], {
    //       position: toast.POSITION.TOP_RIGHT,
    //       className: 'foo_bar_log_Success'
    //     });
    //     setTimeout(() => {
    //       navigate('/dashboard');
    //     }, 800);
    //   }
    //   else {
    //     swal.fire({
    //       title: result['message'],
    //       icon: 'info',
    //       // input: 'textarea',
    //       showCancelButton: true,
    //       confirmButtonText: 'OK',
    //       inputAttributes: {
    //         required: true
    //       }
    //     }).then((result) => {
    //       if (result.isConfirmed) {
    //         window.location.href = '/dashboard';
    //       }
    //     });
    //   }
    // }).catch(error => {
    //   console.error('There was an error!', error);
    // });
 
  }

  useEffect(() => {
    getSectors()
  }, [])

  async function getSectors() {
    //get loan fill details
    const response = await apis.getComponySectorsList();
    console.log(response)
    if (response['status'] === true) {
      setSectorsList(response['data'])
      // setSelectedSector(response['data'][0])
    }
    console.log("comapny filled data");
    console.log(response)
  }


  // --------------------------- select box dropdown icon---------
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <span style={{ paddingRight: '5px' }}>&#9662;</span>
      </components.DropdownIndicator>
    );
  };


  return (
    <React.Fragment>
      <section className="container">
        <div className="row" style={{ marginTop: "80px" }}>
          <div className="col-md-3"></div>
          <div className="col-md-6 card" style={{ padding: 34 }}>
            <div className="">
              <h3 className="ta_1_h1">Información de la empresa </h3>
              {/* <p>Fecha de creación de la empresa </p> */}

              <ToastContainer autoClose={3000} />
              <form method="post" onSubmit={handleSubmit}>
                
              <label className="ta_lab">Nombre de empresa</label>
                <input
                  type="text"
                  className="form-control "
                  placeholder=""
                  required
                  id=""
                  
                  onChange={(e) => setCompanyName(e.target.value)}
                  
                />
                
                
                <label className="ta_lab">Fecha de creación de la empresa</label>
                <input
                  type="date"
                  className="form-control "
                  placeholder=""
                  required
                  id=""
                  ref={fechaRef}
                  onChange={(e) => setCompany_start_date(e.target.value)}
                  value={company_start_date}
                  max={currentDate}
                />

                <div>
                  <p style={{ marginTop: 30, fontWeight: 600, fontSize: 14 }}>
                    Sector CNAE
                  </p>
                  {(sectorsList != null) ?
                    // <select
                    //   className="form-select"
                    //   id="sel10"
                    //   style={{ fontSize: 14, height: 34, width: "100%" }}
                    //   required
                    //   ref={sectorRef}
                    //   onChange={(e) => setSector_id(e.target.value)}
                    // >

                    //   {sectorsList.map((list, index) =>
                    //     <option value={list['sector_id']}>{list['sector']}</option>
                    //   )}

                    // </select>

                    <Select
                      id="sel10"
                      options={sectorsList.map((list) => ({
                        value: list['sector_id'],
                        label: list['sector'],
                      }))}
                      components={{ DropdownIndicator }}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          width: '100%',
                          fontSize: 14,
                          height: 34,
                        }),
                        menu: (provided) => ({
                          ...provided,
                          maxWidth: '500px',
                        }),
                      }}
                      required
                      ref={sectorRef}
                      onChange={(selectedOption) => setSector_id(selectedOption.value)}
                    />
                    : null}
                </div>

                <div style={{ display: "flex", marginTop: 30 }}>
                  <a href="/dashboard">
                    <button
                      type="button"
                      id=""
                      className="btn cresc_bt"
                    >
                      Ir al panel
                    </button>
                  </a>
                  <button type="submit" id="next10" className="btn cresc_bt1" style={{ marginLeft: 200 }}>
                    Siguiente
                  </button>
                </div>

              </form>

            </div>
          </div>
          <div className="col-md-3"></div>
        </div>
      </section>



    </React.Fragment>
  )
}
import React from "react";
import { Link } from "react-router-dom";
// import { Nav, NavDropdown, } from "react-bootstrap";
import { useEffect, useState } from "react";
import CircleProgressBar from "../circle/circle.jsx";
import * as images from './images.jsx';
import { useNavigate, Navigate } from "react-router-dom";
// import * as con from '../repository/urls.js';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import UserHeader from "../user/component/header.jsx";
import * as apis from '../repository/api_calls.js';
import CircleProgressBar1 from "../circle/dashboard_circle.jsx";



export default function Scoretips() {


  const red = 'red';
  const yellow = 'rgb(255, 193, 7)';
  const green = 'green';

  let liquidity = localStorage.getItem('liquidity_ratio');
  const roa = localStorage.getItem("roa_ratio");
  const sales_growth = localStorage.getItem("sales_evolution_ratio");
  const solvancy = localStorage.getItem("solvency_ratio");
  const age_ratio = localStorage.getItem('age_ratio');
  const debt_ratio = localStorage.getItem('debt_ratio');


  let solvancy_color = '';
  let sales_color = '';
  let roa_color = '';
  let liquidity_color = '';
  let debt_color=' ';




if (debt_ratio <=0.6)
{
  debt_color='green';
}
else if(debt_ratio>0.9)
{
  debt_color='red';
}
else{
  debt_color='rgb(255, 193, 7)';
}




  if (sales_growth >= 1) {
    sales_color = 'green';
  } else if (sales_growth < 0.9) {
    sales_color = 'red';
  } else {
    sales_color = 'rgb(255, 193, 7)';
  }

  if (roa > 12) {
    roa_color = 'green';
  } else if (roa < 10) {
    roa_color = 'red';
  } else {
    roa_color = 'rgb(255, 193, 7)';
  }

  if (liquidity > 0.8) {
    liquidity_color = 'green';
  } else if (liquidity < 0.7) {
    liquidity_color = 'red';
  } else {
    liquidity_color = 'rgb(255, 193, 7)';
  }


  if (solvancy > 1.5) {
    solvancy_color = 'green';
  } else if (solvancy < 1) {
    solvancy_color = 'red';
  } else {
    solvancy_color = 'rgb(255, 193, 7)';
  }



  // const language = 'Englishw';


  // const user_id = localStorage.getItem("id");
  const role_id = localStorage.getItem("role_id");
  const token = localStorage.getItem("token");
  const creditScoreStatus = localStorage.getItem("creditScore_status");

  // This field for FAQ-1
  const [isOpenA, setIsOpenA] = useState(false);
  const [isOpenB, setIsOpenB] = useState(false);
  const [isOpenC, setIsOpenC] = useState(false);



  const toggleOpenA = () => {
    setIsOpenA(!isOpenA);
    setIsOpenB(false);
    setIsOpenC(false);

  };

  const toggleOpenB = () => {
    setIsOpenB(!isOpenB);
    setIsOpenA(false);
    setIsOpenC(false);
  };

  const toggleOpenC = () => {
    setIsOpenC(!isOpenC);
    setIsOpenA(false);
    setIsOpenB(false);
  };


  // This field for FAQ
  const [isOpenD, setIsOpenD] = useState(false);
  const [isOpenE, setIsOpenE] = useState(false);
  const [isOpenF, setIsOpenF] = useState(false);


  const toggleOpenD = () => {
    setIsOpenD(!isOpenD);
    setIsOpenE(false);
    setIsOpenF(false);
  };

  const toggleOpenE = () => {
    setIsOpenE(!isOpenE);
    setIsOpenD(false);
    setIsOpenF(false);
  };

  const toggleOpenF = () => {
    setIsOpenF(!isOpenF);
    setIsOpenE(false);
    setIsOpenD(false);
  };


  // This field for FAQ
  const [isOpenG, setIsOpenG] = useState(false);
  const [isOpenH, setIsOpenH] = useState(false);
  const [isOpenI, setIsOpenI] = useState(false);
  const [isOpenJ, setIsOpenJ] = useState(false);
  const [isOpenK, setIsOpenK] = useState(false);
  const [isOpenL, setIsOpenL] = useState(false);
 


  const toggleOpenG = () => {
    setIsOpenG(!isOpenG);
    setIsOpenH(false);
    setIsOpenI(false);
    setIsOpenJ(false);
    setIsOpenK(false);
    setIsOpenL(false);

  };

  const toggleOpenH = () => {
    setIsOpenH(!isOpenH);
    setIsOpenG(false);
    setIsOpenI(false);
    setIsOpenJ(false);
    setIsOpenK(false);
    setIsOpenL(false);
  };

  const toggleOpenI = () => {
    setIsOpenI(!isOpenI);
    setIsOpenH(false);
    setIsOpenG(false);
    setIsOpenJ(false);
    setIsOpenK(false);
    setIsOpenL(false);
  };

  const toggleOpenJ = () => {
    setIsOpenJ(!isOpenI);
    setIsOpenH(false);
    setIsOpenG(false);
    setIsOpenI(false);
    setIsOpenK(false);
    setIsOpenL(false);
  };
  const toggleOpenK = () => {
    setIsOpenK(!isOpenI);
    setIsOpenH(false);
    setIsOpenG(false);
    setIsOpenI(false);
    setIsOpenJ(false);
    setIsOpenL(false);
  };
  const toggleOpenL = () => {
    setIsOpenL(!isOpenI);
    setIsOpenH(false);
    setIsOpenG(false);
    setIsOpenI(false);
    setIsOpenK(false);
    setIsOpenJ(false);
  };


  const [isOpenM, setIsOpenM] = useState(false);
  const [isOpenN, setIsOpenN] = useState(false);
  const [isOpenO, setIsOpenO] = useState(false);


  const toggleOpenM= () => {
    setIsOpenM(!isOpenI);
    setIsOpenN(false);
    setIsOpenO(false);
   
  };
  const toggleOpenN= () => {
    setIsOpenN(!isOpenI);
    setIsOpenM(false);
    setIsOpenO(false);
   
  };
  const toggleOpenO= () => {
    setIsOpenO(!isOpenI);
    setIsOpenN(false);
    setIsOpenM(false);
   
  };

  const [isOpenP, setIsOpenP] = useState(false);
  const [isOpenQ, setIsOpenQ] = useState(false);
  const [isOpenR, setIsOpenR] = useState(false);

  const toggleOpenP= () => {
    setIsOpenP(!isOpenI);
    setIsOpenQ(false);
    setIsOpenR(false);
     };
     const toggleOpenQ= () => {
      setIsOpenQ(!isOpenI);
      setIsOpenP(false);
      setIsOpenR(false);
       };
       const toggleOpenR= () => {
        setIsOpenR(!isOpenI);
        setIsOpenQ(false);
        setIsOpenP(false);
         };


  const [isOpenS, setIsOpenS] = useState(false);
  const [isOpenT, setIsOpenT] = useState(false);
  const [isOpenU, setIsOpenU] = useState(false);
       
  const toggleOpenS= () => {
    setIsOpenS(!isOpenI);
    setIsOpenT(false);
    setIsOpenU(false);
    };
 
    const toggleOpenT= () => {
      setIsOpenT(!isOpenI);
      setIsOpenS(false);
      setIsOpenU(false);
      };
 
      const toggleOpenU= () => {
        setIsOpenU(!isOpenI);
        setIsOpenT(false);
        setIsOpenS(false);
        };
     



  function getLoanPercent(score) {
    var percentage = '';
    if (score < 0) {
      percentage = 'Error - Data Incompleta';
    }
    else if (score > 0 && score <= 50) {
      percentage = 'Bajo';
    }
    else if (score > 50 && score <= 66) {
      percentage = 'Regular';
    }
    else if (score > 66 && score <= 79) {
      percentage = 'Bueno';
    }
    else if (score >= 80 && score < 90) {
      percentage = 'Muy Bueno';
    }
    else if (score >= 90 && score <= 100) {
      percentage = 'Excelente';
    } else {
      percentage = 'Cero';
    }
    return percentage;
  }




  // //--------------------------------- Accordion script
  // function acc(score) {
  //   var content = '';
  //   if (score < 0) {
  //     content = 'a ';
  //   }
  //   else if (score > 0 && score <= 50) {
  //     content = 'b';
  //   }
  //   else if (score > 50 && score <= 66) {
  //     content = 'c  ';
  //   }
  //   else if (score > 66 && score <= 79) {
  //     content = 'd';
  //   }
  //   else if (score >= 80 && score < 90) {
  //     content = 'e';
  //   }
  //   else if (score >= 90 && score <= 100) {
  //     content = 'f';
  //   } else {
  //     content = 'g';
  //   }
  //   return content;
  // }


  // const [value, setValue] = React.useState(0);
  // const handleTabs = (e, val) => {
  //   console.warn(val);
  //   setValue(val);
  // }


  // const navigate = useNavigate();


  // const location = useLocation();
  const userData = JSON.parse(localStorage.getItem('score_tips'));





  
  useEffect(() => {
    getScoreTipvalue();
  }, [])

  async function getScoreTipvalue() {
      const response = await apis.getScoreTipCalculation();
      console.log(response)
      if (response['status'] === true) {
        localStorage.setItem("liquidity_ratio", response['data']['liquidity_ratio'])
        localStorage.setItem("roa_ratio", response['data']['roa_ratio'])
        localStorage.setItem("sales_evolution_ratio", response['data']['sales_evolution_ratio'])
        localStorage.setItem("solvency_ratio", response['data']['solvency_ratio'])
        localStorage.setItem("age_ratio", response['data']['age_ratio'])
        localStorage.setItem("debt_ratio", response['data']['debt_ratio'])
        // console.log(localStorage.getItem("liquidity_ratio"))
        // console.log(localStorage.getItem("roa_ratio"))
        // console.log(localStorage.getItem("sales_evolution_ratio"))
        // console.log(localStorage.getItem("solvency_ratio"))
        // console.log(localStorage.getItem("age_ratio"))
        // console.log(localStorage.getItem("debt_ratio"))
      }
  }


  return (

    ((role_id !== null && role_id === "2") && (creditScoreStatus !== null)) ?
      <React.Fragment>


        <UserHeader />


        <ToastContainer autoClose={3000} />
        <section style={{ backgroundColor: "#FFFFFF", height: "65px", top: "50px", position: "sticky", zIndex: 2 }}>
          <div className="container">
            <div className="row" style={{ padding: 10 }}>
              <div className="col-md-3" style={{ display: "flex" }}>
                <a href="/dashboard" style={{ textDecoration: "none" }}>
                  <p style={{ color: "#0069CB", fontSize: "18px" }}>
                    <span>

                      <img className="" src={images.dash_blue} alt='dash_blue' />
                    </span>
                    Panel
                  </p>
                </a>
                <div
                  style={{
                    borderLeft: "2px solid #E0E0E0",
                    height: 45,
                    marginLeft: 80
                  }}
                ></div>
              </div>



              <div className="col-md-3" style={{ display: "flex" }}>
                <a href="/myscore" style={{ textDecoration: "none" }}>
                  <p style={{ color: "#183F4B", fontSize: "16px" }}>
                    <span>

                      <img className="" src={images.z_score_dark} alt='z_score_dark' />
                    </span>{" "}
                    Mi puntaje
                  </p>
                </a>
                <div
                  style={{
                    borderLeft: "2px solid #E0E0E0",
                    height: 45,
                    marginLeft: 80
                  }}
                ></div>
              </div>
              <div className="col-md-3" style={{ display: "flex" }}>
                <a href="/dashboardapp" style={{ textDecoration: "none" }}>
                  <p style={{ color: "#183F4B", fontSize: "18px", marginTop: "2px", marginLeft: "-20px" }}>
                    <span>

                      <img className="" src={images.applica_dark} alt='applica_dark' style={{ marginRight: 7 }} />
                    </span>{" "}
                    Solicitudes enviadas
                  </p>
                </a>
                <div
                  style={{
                    borderLeft: "2px solid #E0E0E0",
                    height: 45,
                    marginLeft: 80
                  }}
                ></div>
              </div>
              <div className="col-md-3">
                <a href="/dashboardloan" style={{ textDecoration: "none" }}>
                  <p style={{ color: "#183F4B", fontSize: "18px", }}>
                    <span>

                      <img className="" src={images.loan_offer_dark} alt='loan_offer_dark' />
                    </span>
                    Ofertas recibidas
                  </p>
                </a>
              </div>




            </div>
          </div>
        </section>

        <section className="container my-5"   >
          <div style={{ display: "flex" }}>
            <Link to="/score_tips" className="tip_1" style={{ textDecoration: "none" }}>
              <h4> Sugerencias Prácticas</h4>
            </Link>
            {/* <h3>{solvancy}</h3>
            <h3>{sales_growth}</h3>
            <h3>{roa}</h3>
            <h3>{liquidity}</h3> */}




            <Link to="/score_tips" className="ms-5 tip_2" style={{ textDecoration: "none", display: "none" }} >
              <h4> Editar Información Contable</h4>
            </Link>
          </div>
        </section>
         {/* <p style={{color:liquidity_color}}>liquidity = {liquidity} </p>
            <p style={{color:solvancy_color}}>solvancy = {solvancy}</p>
            <p style={{color:sales_color}}>sales_growth = {sales_growth}</p>
            <p style={{color:roa_color}}>roa = {roa}</p>  */}




        {/* <div >
{(userData===null) ?
        <CircleProgressBar percentage="0" />:<CircleProgressBar percentage={userData['user_score']} />}
                             </div> */}

        <section className="container">

          <div className="row">
            <div className="col-md-4">
              <div className="card" style={{ padding: "20px" }}>
                <p style={{ fontSize: "19px", fontWeight: "bold" }}>Tu score financiero es: <span style={{ color: "#4f4f4f", fontStyle: "italic" }}>{getLoanPercent(userData['user_score'])}</span></p>

                <div style={{ marginLeft: "100px", marginTop: '20px' }} >
                  {(userData === null) ?
                    <CircleProgressBar percentage="0" /> :
                    // <CircleProgressBar percentage={userData['user_score']} />}
                     <CircleProgressBar1 percentage={userData['user_score']} scolor={getLoanPercent(userData['user_score'])} />}
                </div>


                <div>

                  <p style={{ fontSize: "19px", fontWeight: "bold", marginTop: '20px' }}>Sugerencias Prácticas</p>
                  <p className="sco_t_p">¿Por qué es importante esforzarse por obtener un Credit Score más alto? En pocas palabras, aquellos con puntajes Credit Score más altos generalmente...</p>



                  <span className="sco_t_p1" style={{ cursor: "pointer" }} data-toggle="modal"
                    data-target="#Sugerencias">
                    Lee mas
                    <i className="fa fa-long-arrow-right" aria-hidden="true"  style={{marginLeft:"8px"}}/>
                  </span>

                </div>

                <div>

                  <p style={{ fontSize: "19px", fontWeight: "bold", marginTop: '20px' }}>¿Qué es el Credit Score?</p>
                  <p className=" sco_t_p">El Credit Score es una expresión numérica, del 0 al 100 que representaa el riesgo crediticio de una empresa, o la probabilidad de que pague sus...</p>

                  <span className="sco_t_p1" style={{ cursor: "pointer" }} data-toggle="modal"
                    data-target="#Credit_score">
                    Lee mas
                    <i className="fa fa-long-arrow-right" aria-hidden="true" style={{marginLeft:"8px"}} />
                  </span>
                </div>

              </div>

            </div>


            <div className="col-md-8">


              <div className="card">




                <div className="faq">
                  {(userData['user_score'] < 100) ?
                    (
                      <div className="card">




                        <div className="faq">
                          {/* <p>credit Score 0-50</p> */}
                          <p style={{ color: "red", textAlign: "left", fontSize: "20px", fontWeight: "bold" }}>REQUIERE ATENCION INMEDIATA</p>
                          <div style={{ backgroundColor: "#dc3545", height: "1.5px", width: "700px", opacity: '0.3' }}></div>

                          {(red == solvancy_color)
                            ?

                            <div>
                              <p className="my-4" style={{ textAlign: "left", fontSize: "14px", color: solvancy_color, fontWeight: "bold" }}>
                                Solvencia{" "}
                                <i
                                  className="fa fa-info-circle"
                                  aria-hidden="true"
                                  data-toggle="modal"
                                  data-target="#myModal"
                                  style={{ cursor: "pointer" }}
                                ></i>
                              </p>
                              <input
                                id="faq-a"
                                type="checkbox"
                                checked={isOpenA}
                                onChange={toggleOpenA}
                              />
                              <label htmlFor="faq-a">
                                <p className="faq-heading">Reducir activos menos rentables</p>
                                <div className="faq-arrow" />
                                {isOpenA && (
                                  <p className="faq-text">
                                    Al analizar los activos que tenemos, podríamos liquidar aquellos activos mantenidos en la empresa que
                                    no están relacionados con la operatoria habitual que mantiene la compañía y que no están siendo
                                    rentables para nosotros. Cómo personas encargadas de las finanzas debemos buscar la manera más
                                    conveniente de liquidarlo y que esto nos genere un aumento en nuestra liquidez que será utilizada
                                    para proporcionarnos mayor rentabilidad.
                                  </p>
                                )}
                              </label>

                              <input
                                id="faq-b"
                                type="checkbox"
                                checked={isOpenB}
                                onChange={toggleOpenB}
                              />
                              <label htmlFor="faq-b">
                                <p className="faq-heading">Simplificar procesos</p>
                                <div className="faq-arrow" />
                                {isOpenB && (
                                  <p className="faq-text">
                                    Buscar constantemente mejoras en nuestros sistemas puede hacer que lleguemos al mismo
                                    resultado con menor coste o en un menor tiempo. Ya sea consiguiendo los primero o los
                                    segundos resultados, mayormente si se consiguen ambos, la empresa dispondrá de una mayor
                                    cantidad de recursos disponibles para generar un aumento en nuestra capacidad productiva.
                                  </p>
                                )}
                              </label>
                              <input
                                id="faq-c"
                                type="checkbox"
                                checked={isOpenC}
                                onChange={toggleOpenC}
                              />
                              <label htmlFor="faq-c">
                                <p className="faq-heading">Buscar ingresos extra</p>
                                <div className="faq-arrow" />
                                {isOpenC && (
                                  <p className="faq-text">
                                    Tenemos posibilidades de complementar nuestros servicios o productos con algún subproducto
                                    o merma que no estemos considerando. Hay ocasiones en que las empresas desechan mermas sin
                                    saber qué podrían beneficiarse por la venta de las mismas. Asimismo, hay servicios que podríamos
                                    brindar de forma complementaria sin que esto nos haga incurrir en un coste significativo,
                                    siendo el beneficio mayor de lo que podemos considerar.
                                  </p>
                                )}
                              </label>
                            </div>
                            :
                            <p></p>
                          }

                          {(red == liquidity_color)
                            ?
                            <div>
                              <p className="my-4" style={{ textAlign: "left", fontSize: "14px", color: liquidity_color, fontWeight: "bold" }}>Liquidez  <i className="fa fa-info-circle" aria-hidden="true" data-toggle="modal"
                                data-target="#myModal1" style={{ cursor: "pointer" }}></i>
                              </p>

                              <input id="faq-d" type="checkbox"
                                checked={isOpenD}
                                onChange={toggleOpenD} />
                              <label htmlFor="faq-d">
                                <p className="faq-heading">
                                  Reducir gastos innecesarios
                                </p>
                                <div className="faq-arrow" />
                                {isOpenD && (
                                  <p className="faq-text">
                                    En este caso debemos hacer un análisis de los gastos que realizamos cómo empresa. En ciertas ocasiones, cómo empresas, realizamos gastos por el simple hecho de que siempre se hicieron de esa manera y/o siempre se hicieron con determinada entidad. Será nuestra misión cómo encargados de mejorar los ratios de nuestra compañía, observar aquellos que podríamos reducir, o suplantarlos por gestiones internas, sin que esto nos genere un egreso de fondos. Asimismo, es muy importante tener un registro de todas las erogaciones y gestiones que realizamos, ya que estos pueden servirnos para identificar en dónde estamos realizando desembolsos de dinero que podrían evitarse.
                                  </p>
                                )}
                              </label>
                              <input id="faq-e" type="checkbox"
                                checked={isOpenE}
                                onChange={toggleOpenE} />
                              <label htmlFor="faq-e">
                                <p className="faq-heading">
                                  Reducir ciclo de efectivo
                                </p>
                                <div className="faq-arrow" />
                                {isOpenE && (

                                  <div className="faq-text">
                                    <p >
                                      Definimos el ciclo de efectivo cómo aquel tiempo medio que transcurre desde que el dinero sale de nuestras cuentas para pagarle a nuestros proveedores por la compra de nuestro inventario, hasta su regreso al cobrar las deudas que cancelan nuestros clientes. Podemos separar este proceso en tres cuestiones. Primero tenemos una parte de mantenimiento de existencias o producción (en el caso de que nosotros produzcamos nuestro propio producto), un período medio de cobro y un período medio de pago.
                                    </p>
                                     </div>
                                )}
                              </label>

                                
                              <input id="faq-f" type="checkbox"
                                checked={isOpenF}
                                onChange={toggleOpenF} />
                              <label htmlFor="faq-f">
                                <p className="faq-heading">
                                Entendemos el ciclo de efectivo como:
                                </p>
                                <div className="faq-arrow" />
                                {isOpenF && (
                                  <p className="faq-text">
                                    Periodo de existencias + Período medio de cobro – Período medio de pago. Cuanto más se prolongue el proceso 
                                    de producción, la empresa debe mantener más fondos inmovilizados en existencias. De manera similar, cuanto más 
                                    tardan los clientes en pagar las cuentas, mayor es el valor de los efectos a cobrar o cuentas a cobrar. 
                                    Por el contrario, cuando una empresa puede demorar el pago de sus materiales, reduce la cantidad de fondos que necesita. Por tanto, cuanto mayor es el ciclo de efectivo, más necesidades de fondo tiene la empresa y más efectivo necesita mantener para desarrollar con normalidad su actividad diaria.
                                    </p>
                                )}
                              </label>
                              <input id="faq-f" type="checkbox"
                                checked={isOpenF}
                                onChange={toggleOpenF} />
                              <label htmlFor="faq-f">
                                <p className="faq-heading">
                                  Factoring
                                </p>
                                <div className="faq-arrow" />
                                {isOpenF && (
                                  <p className="faq-text">
                                    El factoring es una operación mediante la cual nosotros cómo empresa vendemos los derechos de cobro que tenemos sobre nuestros clientes, a un precio determinado, a una empresa especializada en dichas transacciones conocida cómo factor, quién se encargará oportunamente de realizar la gestión de cobro. El factor nos anticipa una cantidad que oscila entre el 80% y el 90%, depende la comisión y gastos que nos cobre por esta gestión, del importe de las cuentas cedidas. Por dicha razón, nos encontramos frente a una fuente de financiación ya que obtenemos el dinero antes de proceder al cobro (equivaldría al descuento comercial), y pagamos un interés por dicha gestión.
                                    Es una opción de financiación recomendable para las empresas con problemas de liquidez, ya que, puedes recibir el pago de tus facturas de manera inmediata y así evitar esperar al vencimiento.
                                  </p>
                                )}
                              </label>
                            </div>
                            :
                            <p></p>

                          }

                         
                          {(red == roa_color)
                            ?
                            <div>
                              <p className="my-4" style={{ textAlign: "left", fontSize: "14px", color: roa_color, fontWeight: "bold" }}>Retornos sobre activos   <i className="fa fa-info-circle" aria-hidden="true" data-toggle="modal"
                                data-target="#myModal3" style={{ cursor: "pointer" }}></i></p>


                              <input id="faq-g" type="checkbox"
                                checked={isOpenG}
                                onChange={toggleOpenG} />
                              <label htmlFor="faq-g">
                                <p className="faq-heading">
                                Desglose en rotación y margen operativo
                                </p>
                                <div className="faq-arrow" />
                                {isOpenG && (
                                  <p className="faq-text">
                                    De estos dos factores depende nuestro retorno sobre activos. Es decir, la rentabilidad obtenida por la gestión principal o el negocio de la empresa con este nivel de activos.
                                    </p>
                                )}
                              </label>


                              <input id="faq-h" type="checkbox"
                                checked={isOpenH}
                                onChange={toggleOpenH} />
                              <label htmlFor="faq-h">
                                <p className="faq-heading">
                                Margen operativo neto:
                                </p>
                                <div className="faq-arrow" />
                                {isOpenH && (
                                  <p className="faq-text">
                                    Nivel de aprovechamiento de las ventas de una empresa desde una perspectiva operativa. Se expresa en porcentaje
                                  </p>
                                )}
                              </label>
                              <input id="faq-i" type="checkbox"
                                checked={isOpenI}
                                onChange={toggleOpenI} />
                              <label htmlFor="faq-i">
                                <p className="faq-heading">
                                Rotación del activo operativo neto
                                </p>
                                <div className="faq-arrow" />
                                {isOpenI && (
                                  <p className="faq-text">
                                    Podemos analizar qué rotación viene teniendo la empresa en comparación con los ejercicios anteriores y ver qué utilidad le está dando, mediante su gestión, la empresa a los activos que utiliza en la operatoria
                                  </p>
                                )}
                              </label>
                              <input id="faq-j" type="checkbox"
                                checked={isOpenJ}
                                onChange={toggleOpenJ} />
                              <label htmlFor="faq-j">
                                <p className="faq-heading">
                                Descomponer el margen operativo: 
                                </p>
                                <div className="faq-arrow" />
                                {isOpenJ && (
                                  <p className="faq-text">
                                  Análisis vertical de la cuenta de resultados (Importe de la cuenta que queremos analizar/ventas) analizando los diferentes gastos e ingresos y los márgenes comenzando por el margen bruto:  Margen bruto = Ventas-Coste ventas /Ventas  Es una medida clave de rentabilidad porque captura la cantidad de beneficios generados por la actividad principal que permite cubrir otros costes de la firma como salarios, gastos de administración y generales, gastos de I+D, intereses e impuestos
                                  </p>
                                )}
                              </label>
                              <input id="faq-k" type="checkbox"
                                checked={isOpenK}
                                onChange={toggleOpenK} />
                              <label htmlFor="faq-k">
                                <p className="faq-heading">
                                Si los gastos están ordenados por funciones:
                                </p>
                                <div className="faq-arrow" />
                                {isOpenK && (
                                  <p className="faq-text">
                                  Margen de beneficio operativo= Margen bruto/Ventas - Gastos de administración/ventas - Gastos comerciales/Ventas - Gastos I+D/Ventas – Impuestos operativos/ventas
                                  </p>
                                )}
                              </label>
                              <input id="faq-l" type="checkbox"
                                checked={isOpenL}
                                onChange={toggleOpenL} />
                              <label htmlFor="faq-l">
                                <p className="faq-heading">
                                Rotación de activos: 
                                </p>
                                <div className="faq-arrow" />
                                {isOpenL && (
                                  <p className="faq-text">
                                  Indicador financiero mediante el cual podemos analizar qué rotación viene teniendo la empresa en comparación con los ejercicios anteriores y ver qué utilidad le está dando, mediante su gestión, la empresa a los activos que utiliza en la operatoria normal de la empresa. 
                                  </p>
                                )}
                              </label>

                              
                            </div>
                            :
                            <p></p>
                          }



                          {(red == sales_color)
                            ?
                            <div>
                              <p style={{ textAlign: "left", fontSize: "14px", color: sales_color, fontWeight: "bold", marginTop: "50px" }}>Evolución de Ventas  <i className="fa fa-info-circle" aria-hidden="true" data-toggle="modal"
                                data-target="#Evolución " style={{ cursor: "pointer" }}></i></p>

                             
                            <input id="faq-M" type="checkbox"
                                checked={isOpenM}
                                onChange={toggleOpenM} />
                              <label htmlFor="faq-M">
                                <p className="faq-heading">
                                Hacer uso de la tecnología moderna: 
                                </p>
                                <div className="faq-arrow" />
                                {isOpenM && (
                                  <p className="faq-text">
                                  Si cómo empresa deseamos aumentar las ventas, no podemos quedarnos fuera de la revolución tecnológica.
                                   Tanto las webs cómo las redes sociales han puesto a disposición del consumidor una gran cantidad de 
                                   información y nuevas formas de acceder a la compra de nuestros productos. Debemos realizar un plan
                                    comercial orientado a la consecución de nuestros objetivos cómo empresa, utilizando toda esta 
                                    tecnología para potenciar nuestras ventas. El desarrollo de una nueva APP, el Big Data, o los chatbots,
                                     como plataformas para mejorar la atención al cliente y la experiencia del usuario con la marca,
                                      deben estar en nuestros planes.
                                  </p>
                                )}
                              </label>


                              <input id="faq-N" type="checkbox"
                                checked={isOpenN}
                                onChange={toggleOpenN} />
                              <label htmlFor="faq-N">
                                <p className="faq-heading">
                                Proporcionar un servicio superior o  adicional: 
                                </p>
                                <div className="faq-arrow" />
                                {isOpenN && (
                                  <p className="faq-text">
                                   En casos de mercado donde nuestro producto no sea el único, ofrecer a los clientes un servicio adicional 
                                   o superior al de la competencia  puede ser definitivo para cerrar una operación de venta. Este tipo
                                    de estrategias ofrecen un atractivo a la oferta y determinan que el cliente se decante por nuestro 
                                    producto o servicio en relación al ofrecido por otra empresa. Entre estos servicios podemos mencionar
                                     la entrega, la garantía, el mantenimiento o actualizaciones, ya sean de forma adicional al precio 
                                     en el momento de la venta o de forma gratuita.
                                  </p>
                                )}
                              </label>


                              <input id="faq-O" type="checkbox"
                                checked={isOpenO}
                                onChange={toggleOpenO} />
                              <label htmlFor="faq-O">
                                <p className="faq-heading">
                                Tener un claro mensaje de venta 
                                </p>
                                <div className="faq-arrow" />
                                {isOpenO && (
                                  <p className="faq-text">
                                  El speech o el guión que utilicemos será una herramienta fundamental en el proceso de venta. Todo guión de 
                                  ventas debe partir de las necesidades del cliente, ya qué este es el verdadero centro de toda la
                                   estrategia. El primer paso qué debemos realizar es escuchar y así conocer lo que el consumidor
                                    necesita, solo así podremos ofrecerle las respuestas adecuadas. 
                                  </p>
                                )}
                              </label>

                             

                             
                            </div>
                            :
                            <p></p>

                          }

                            {/* ----------debt */}
                            {(red == sales_color)
                            ?
                            <div>
                              <p style={{ textAlign: "left", fontSize: "14px", color: sales_color, fontWeight: "bold", marginTop: "50px" }}>Endeudamiento  <i className="fa fa-info-circle" aria-hidden="true" data-toggle="modal"
                                data-target="#Evolución " style={{ cursor: "pointer" }}></i></p>

                             
                            <input id="faq-S" type="checkbox"
                                checked={isOpenS}
                                onChange={toggleOpenS} />
                              <label htmlFor="faq-S">
                                <p className="faq-heading">
                                Control de tesorería
                                </p>
                                <div className="faq-arrow" />
                                {isOpenS && (
                                  <p className="faq-text">
                                  Cómo sabemos, la tesorería y los derechos de cobro nos otorgan una liquidez inmediata.
                                   La liquidez nos da la seguridad y la solvencia a corto plazo. En muchas ocasiones tener 
                                   que endeudarse es consecuencia de un momento de falta de efectivo. Es importante entender
                                    que una gestión adecuada de los activos nos ayuda con el flujo de efectivo y nos evita
                                     riesgos de endeudamiento innecesario aumentando nuestra tesorería
                                  </p>
                                )}
                              </label>


                              <input id="faq-T" type="checkbox"
                                checked={isOpenT}
                                onChange={toggleOpenT} />
                              <label htmlFor="faq-T">
                                <p className="faq-heading">
                                Planificación de nuestra financiación
                                </p>
                                <div className="faq-arrow" />
                                {isOpenT && (
                                  <p className="faq-text">
                                  Saber cuánto vamos a necesitar, para qué lo vamos a utilizar y en qué plazo, nos ayuda a tener un 
                                  mayor control de la situación y nos da un mejor panorama a la hora de buscar una financiación externa.
                                   Sabemos que nuestra búsqueda  a largo plazo, será para inversiones bien planificadas y previstas con
                                    mayor antelación, y en el corto, para ganar una mayor liquidez debiendo tener un control en los plazos
                                     de pago y cobro
                                  </p>
                                )}
                              </label>
                              <input id="faq-U" type="checkbox"
                                checked={isOpenU}
                                onChange={toggleOpenU} />
                              <label htmlFor="faq-U">
                                <p className="faq-heading">
                                Mejorar la estrategia comercial
                                </p>
                                <div className="faq-arrow" />
                                {isOpenU && (
                                  <p className="faq-text">
                                  Un negocio que nos está generando pérdidas, tiene cómo consecuencia una reducción del patrimonio social
                                   y un aumento directo del importe de financiación externa que vamos a necesitar para poder continuar con
                                    nuestro negocio.
                                  </p>
                                )}
                              </label>

            
                           


                            

                             

                             
                            </div>
                            :
                            <p></p>

                          }
                       



                          <p style={{ color: "#FFC107", textAlign: "left", fontSize: "20px", fontWeight: "bold", marginTop: "50px" }}>Requiere Atencion Media</p>
                          <div style={{ backgroundColor: "#FFC107", height: "1.5px", width: "700px", opacity: '0.3' }}></div>

                          {(yellow == solvancy_color)
                            ?

                            <div>
                            <p className="my-4" style={{ textAlign: "left", fontSize: "14px", color: solvancy_color, fontWeight: "bold" }}>
                              Solvencia{" "}
                              <i
                                className="fa fa-info-circle"
                                aria-hidden="true"
                                data-toggle="modal"
                                data-target="#myModal"
                                style={{ cursor: "pointer" }}
                              ></i>
                            </p>
                            <input
                              id="faq-a"
                              type="checkbox"
                              checked={isOpenA}
                              onChange={toggleOpenA}
                            />
                            <label htmlFor="faq-a">
                              <p className="faq-heading">Reducir activos menos rentables</p>
                              <div className="faq-arrow" />
                              {isOpenA && (
                                <p className="faq-text">
                                  Al analizar los activos que tenemos, podríamos liquidar aquellos activos mantenidos en la empresa que
                                  no están relacionados con la operatoria habitual que mantiene la compañía y que no están siendo
                                  rentables para nosotros. Cómo personas encargadas de las finanzas debemos buscar la manera más
                                  conveniente de liquidarlo y que esto nos genere un aumento en nuestra liquidez que será utilizada
                                  para proporcionarnos mayor rentabilidad.
                                </p>
                              )}
                            </label>

                            <input
                              id="faq-b"
                              type="checkbox"
                              checked={isOpenB}
                              onChange={toggleOpenB}
                            />
                            <label htmlFor="faq-b">
                              <p className="faq-heading">Simplificar procesos</p>
                              <div className="faq-arrow" />
                              {isOpenB && (
                                <p className="faq-text">
                                  Buscar constantemente mejoras en nuestros sistemas puede hacer que lleguemos al mismo
                                  resultado con menor coste o en un menor tiempo. Ya sea consiguiendo los primero o los
                                  segundos resultados, mayormente si se consiguen ambos, la empresa dispondrá de una mayor
                                  cantidad de recursos disponibles para generar un aumento en nuestra capacidad productiva.
                                </p>
                              )}
                            </label>
                            <input
                              id="faq-c"
                              type="checkbox"
                              checked={isOpenC}
                              onChange={toggleOpenC}
                            />
                            <label htmlFor="faq-c">
                              <p className="faq-heading">Buscar ingresos extra</p>
                              <div className="faq-arrow" />
                              {isOpenC && (
                                <p className="faq-text">
                                  Tenemos posibilidades de complementar nuestros servicios o productos con algún subproducto
                                  o merma que no estemos considerando. Hay ocasiones en que las empresas desechan mermas sin
                                  saber qué podrían beneficiarse por la venta de las mismas. Asimismo, hay servicios que podríamos
                                  brindar de forma complementaria sin que esto nos haga incurrir en un coste significativo,
                                  siendo el beneficio mayor de lo que podemos considerar.
                                </p>
                              )}
                            </label>
                          </div>
                          :
                          <p></p>
                          }

                          {(yellow == liquidity_color)
                            ?
                            <div>
                            <p className="my-4" style={{ textAlign: "left", fontSize: "14px", color: liquidity_color, fontWeight: "bold" }}>Liquidez  <i className="fa fa-info-circle" aria-hidden="true" data-toggle="modal"
                              data-target="#myModal1" style={{ cursor: "pointer" }}></i>
                            </p>

                            <input id="faq-d" type="checkbox"
                              checked={isOpenD}
                              onChange={toggleOpenD} />
                            <label htmlFor="faq-d">
                              <p className="faq-heading">
                                Reducir gastos innecesarios
                              </p>
                              <div className="faq-arrow" />
                              {isOpenD && (
                                <p className="faq-text">
                                  En este caso debemos hacer un análisis de los gastos que realizamos cómo empresa. En ciertas ocasiones, cómo empresas, realizamos gastos por el simple hecho de que siempre se hicieron de esa manera y/o siempre se hicieron con determinada entidad. Será nuestra misión cómo encargados de mejorar los ratios de nuestra compañía, observar aquellos que podríamos reducir, o suplantarlos por gestiones internas, sin que esto nos genere un egreso de fondos. Asimismo, es muy importante tener un registro de todas las erogaciones y gestiones que realizamos, ya que estos pueden servirnos para identificar en dónde estamos realizando desembolsos de dinero que podrían evitarse.
                                </p>
                              )}
                            </label>
                            <input id="faq-e" type="checkbox"
                              checked={isOpenE}
                              onChange={toggleOpenE} />
                            <label htmlFor="faq-e">
                              <p className="faq-heading">
                                Reducir ciclo de efectivo
                              </p>
                              <div className="faq-arrow" />
                              {isOpenE && (

                                <div className="faq-text">
                                  <p >
                                    Definimos el ciclo de efectivo cómo aquel tiempo medio que transcurre desde que el dinero sale de nuestras cuentas para pagarle a nuestros proveedores por la compra de nuestro inventario, hasta su regreso al cobrar las deudas que cancelan nuestros clientes. Podemos separar este proceso en tres cuestiones. Primero tenemos una parte de mantenimiento de existencias o producción (en el caso de que nosotros produzcamos nuestro propio producto), un período medio de cobro y un período medio de pago.
                                  </p>
                                   </div>
                              )}
                            </label>

                              
                            <input id="faq-f" type="checkbox"
                              checked={isOpenF}
                              onChange={toggleOpenF} />
                            <label htmlFor="faq-f">
                              <p className="faq-heading">
                              Entendemos el ciclo de efectivo como:
                              </p>
                              <div className="faq-arrow" />
                              {isOpenF && (
                                <p className="faq-text">
                                  Periodo de existencias + Período medio de cobro – Período medio de pago. Cuanto más se prolongue el proceso 
                                  de producción, la empresa debe mantener más fondos inmovilizados en existencias. De manera similar, cuanto más 
                                  tardan los clientes en pagar las cuentas, mayor es el valor de los efectos a cobrar o cuentas a cobrar. 
                                  Por el contrario, cuando una empresa puede demorar el pago de sus materiales, reduce la cantidad de fondos que necesita. Por tanto, cuanto mayor es el ciclo de efectivo, más necesidades de fondo tiene la empresa y más efectivo necesita mantener para desarrollar con normalidad su actividad diaria.
                                  </p>
                              )}
                            </label>
                            <input id="faq-f" type="checkbox"
                              checked={isOpenF}
                              onChange={toggleOpenF} />
                            <label htmlFor="faq-f">
                              <p className="faq-heading">
                                Factoring
                              </p>
                              <div className="faq-arrow" />
                              {isOpenF && (
                                <p className="faq-text">
                                  El factoring es una operación mediante la cual nosotros cómo empresa vendemos los derechos de cobro que tenemos sobre nuestros clientes, a un precio determinado, a una empresa especializada en dichas transacciones conocida cómo factor, quién se encargará oportunamente de realizar la gestión de cobro. El factor nos anticipa una cantidad que oscila entre el 80% y el 90%, depende la comisión y gastos que nos cobre por esta gestión, del importe de las cuentas cedidas. Por dicha razón, nos encontramos frente a una fuente de financiación ya que obtenemos el dinero antes de proceder al cobro (equivaldría al descuento comercial), y pagamos un interés por dicha gestión.
                                  Es una opción de financiación recomendable para las empresas con problemas de liquidez, ya que, puedes recibir el pago de tus facturas de manera inmediata y así evitar esperar al vencimiento.
                                </p>
                              )}
                            </label>
                          </div>
                          :
                          <p></p>

                          }


                          {(yellow == roa_color)
                            ?
                            <div>
                              <p className="my-4" style={{ textAlign: "left", fontSize: "14px", color: roa_color, fontWeight: "bold" }}>Retornos sobre activos   <i className="fa fa-info-circle" aria-hidden="true" data-toggle="modal"
                                data-target="#Solvencia" style={{ cursor: "pointer" }}></i></p>


                              <input id="faq-g" type="checkbox"
                                checked={isOpenG}
                                onChange={toggleOpenG} />
                              <label htmlFor="faq-g">
                                <p className="faq-heading">
                                Desglose en rotación y margen operativo
                                </p>
                                <div className="faq-arrow" />
                                {isOpenG && (
                                  <p className="faq-text">
                                    De estos dos factores depende nuestro retorno sobre activos. Es decir, la rentabilidad obtenida por la gestión principal o el negocio de la empresa con este nivel de activos.
                                    </p>
                                )}
                              </label>


                              <input id="faq-h" type="checkbox"
                                checked={isOpenH}
                                onChange={toggleOpenH} />
                              <label htmlFor="faq-h">
                                <p className="faq-heading">
                                Margen operativo neto:
                                </p>
                                <div className="faq-arrow" />
                                {isOpenH && (
                                  <p className="faq-text">
                                    Nivel de aprovechamiento de las ventas de una empresa desde una perspectiva operativa. Se expresa en porcentaje
                                  </p>
                                )}
                              </label>
                              <input id="faq-i" type="checkbox"
                                checked={isOpenI}
                                onChange={toggleOpenI} />
                              <label htmlFor="faq-i">
                                <p className="faq-heading">
                                Rotación del activo operativo neto
                                </p>
                                <div className="faq-arrow" />
                                {isOpenI && (
                                  <p className="faq-text">
                                    Podemos analizar qué rotación viene teniendo la empresa en comparación con los ejercicios anteriores y ver qué utilidad le está dando, mediante su gestión, la empresa a los activos que utiliza en la operatoria
                                  </p>
                                )}
                              </label>
                              <input id="faq-j" type="checkbox"
                                checked={isOpenJ}
                                onChange={toggleOpenJ} />
                              <label htmlFor="faq-j">
                                <p className="faq-heading">
                                Descomponer el margen operativo: 
                                </p>
                                <div className="faq-arrow" />
                                {isOpenJ && (
                                  <p className="faq-text">
                                  Análisis vertical de la cuenta de resultados (Importe de la cuenta que queremos analizar/ventas) analizando los diferentes gastos e ingresos y los márgenes comenzando por el margen bruto:  Margen bruto = Ventas-Coste ventas /Ventas  Es una medida clave de rentabilidad porque captura la cantidad de beneficios generados por la actividad principal que permite cubrir otros costes de la firma como salarios, gastos de administración y generales, gastos de I+D, intereses e impuestos
                                  </p>
                                )}
                              </label>
                              <input id="faq-k" type="checkbox"
                                checked={isOpenK}
                                onChange={toggleOpenK} />
                              <label htmlFor="faq-k">
                                <p className="faq-heading">
                                Si los gastos están ordenados por funciones:
                                </p>
                                <div className="faq-arrow" />
                                {isOpenK && (
                                  <p className="faq-text">
                                  Margen de beneficio operativo= Margen bruto/Ventas - Gastos de administración/ventas - Gastos comerciales/Ventas - Gastos I+D/Ventas – Impuestos operativos/ventas
                                  </p>
                                )}
                              </label>
                              <input id="faq-l" type="checkbox"
                                checked={isOpenL}
                                onChange={toggleOpenL} />
                              <label htmlFor="faq-l">
                                <p className="faq-heading">
                                Rotación de activos: 
                                </p>
                                <div className="faq-arrow" />
                                {isOpenL && (
                                  <p className="faq-text">
                                  Indicador financiero mediante el cual podemos analizar qué rotación viene teniendo la empresa en comparación con los ejercicios anteriores y ver qué utilidad le está dando, mediante su gestión, la empresa a los activos que utiliza en la operatoria normal de la empresa. 
                                  </p>
                                )}
                              </label>

                              
                            </div>
                            :
                            <p></p>
                          }
                          {(yellow == sales_color)
                            ?
                            <div>
                              <p style={{ textAlign: "left", fontSize: "14px", color: sales_color, fontWeight: "bold", marginTop: "50px" }}>Evolución de Ventas  <i className="fa fa-info-circle" aria-hidden="true" data-toggle="modal"
                                data-target="#Evolución " style={{ cursor: "pointer" }}></i></p>

                             
                            <input id="faq-M" type="checkbox"
                                checked={isOpenM}
                                onChange={toggleOpenM} />
                              <label htmlFor="faq-M">
                                <p className="faq-heading">
                                Hacer uso de la tecnología moderna: 
                                </p>
                                <div className="faq-arrow" />
                                {isOpenM && (
                                  <p className="faq-text">
                                  Si cómo empresa deseamos aumentar las ventas, no podemos quedarnos fuera de la revolución tecnológica.
                                   Tanto las webs cómo las redes sociales han puesto a disposición del consumidor una gran cantidad de 
                                   información y nuevas formas de acceder a la compra de nuestros productos. Debemos realizar un plan
                                    comercial orientado a la consecución de nuestros objetivos cómo empresa, utilizando toda esta 
                                    tecnología para potenciar nuestras ventas. El desarrollo de una nueva APP, el Big Data, o los chatbots,
                                     como plataformas para mejorar la atención al cliente y la experiencia del usuario con la marca,
                                      deben estar en nuestros planes.
                                  </p>
                                )}
                              </label>


                              <input id="faq-N" type="checkbox"
                                checked={isOpenN}
                                onChange={toggleOpenN} />
                              <label htmlFor="faq-N">
                                <p className="faq-heading">
                                Proporcionar un servicio superior o  adicional: 
                                </p>
                                <div className="faq-arrow" />
                                {isOpenN && (
                                  <p className="faq-text">
                                   En casos de mercado donde nuestro producto no sea el único, ofrecer a los clientes un servicio adicional 
                                   o superior al de la competencia  puede ser definitivo para cerrar una operación de venta. Este tipo
                                    de estrategias ofrecen un atractivo a la oferta y determinan que el cliente se decante por nuestro 
                                    producto o servicio en relación al ofrecido por otra empresa. Entre estos servicios podemos mencionar
                                     la entrega, la garantía, el mantenimiento o actualizaciones, ya sean de forma adicional al precio 
                                     en el momento de la venta o de forma gratuita.
                                  </p>
                                )}
                              </label>


                              <input id="faq-O" type="checkbox"
                                checked={isOpenO}
                                onChange={toggleOpenO} />
                              <label htmlFor="faq-O">
                                <p className="faq-heading">
                                Tener un claro mensaje de venta 
                                </p>
                                <div className="faq-arrow" />
                                {isOpenO && (
                                  <p className="faq-text">
                                  El speech o el guión que utilicemos será una herramienta fundamental en el proceso de venta. Todo guión de 
                                  ventas debe partir de las necesidades del cliente, ya qué este es el verdadero centro de toda la
                                   estrategia. El primer paso qué debemos realizar es escuchar y así conocer lo que el consumidor
                                    necesita, solo así podremos ofrecerle las respuestas adecuadas. 
                                  </p>
                                )}
                              </label>

                             

                             
                            </div>
                            :
                            <p></p>

                          }
                             {/* ----------debt */}
                             {(yellow == sales_color)
                            ?
                            <div>
                              <p style={{ textAlign: "left", fontSize: "14px", color: sales_color, fontWeight: "bold", marginTop: "50px" }}>Endeudamiento  <i className="fa fa-info-circle" aria-hidden="true" data-toggle="modal"
                                data-target="#Evolución " style={{ cursor: "pointer" }}></i></p>

                             
                            <input id="faq-S" type="checkbox"
                                checked={isOpenS}
                                onChange={toggleOpenS} />
                              <label htmlFor="faq-S">
                                <p className="faq-heading">
                                Control de tesorería
                                </p>
                                <div className="faq-arrow" />
                                {isOpenS && (
                                  <p className="faq-text">
                                  Cómo sabemos, la tesorería y los derechos de cobro nos otorgan una liquidez inmediata.
                                   La liquidez nos da la seguridad y la solvencia a corto plazo. En muchas ocasiones tener 
                                   que endeudarse es consecuencia de un momento de falta de efectivo. Es importante entender
                                    que una gestión adecuada de los activos nos ayuda con el flujo de efectivo y nos evita
                                     riesgos de endeudamiento innecesario aumentando nuestra tesorería
                                  </p>
                                )}
                              </label>


                              <input id="faq-T" type="checkbox"
                                checked={isOpenT}
                                onChange={toggleOpenT} />
                              <label htmlFor="faq-T">
                                <p className="faq-heading">
                                Planificación de nuestra financiación
                                </p>
                                <div className="faq-arrow" />
                                {isOpenT && (
                                  <p className="faq-text">
                                  Saber cuánto vamos a necesitar, para qué lo vamos a utilizar y en qué plazo, nos ayuda a tener un 
                                  mayor control de la situación y nos da un mejor panorama a la hora de buscar una financiación externa.
                                   Sabemos que nuestra búsqueda  a largo plazo, será para inversiones bien planificadas y previstas con
                                    mayor antelación, y en el corto, para ganar una mayor liquidez debiendo tener un control en los plazos
                                     de pago y cobro
                                  </p>
                                )}
                              </label>
                              <input id="faq-U" type="checkbox"
                                checked={isOpenU}
                                onChange={toggleOpenU} />
                              <label htmlFor="faq-U">
                                <p className="faq-heading">
                                Mejorar la estrategia comercial
                                </p>
                                <div className="faq-arrow" />
                                {isOpenU && (
                                  <p className="faq-text">
                                  Un negocio que nos está generando pérdidas, tiene cómo consecuencia una reducción del patrimonio social
                                   y un aumento directo del importe de financiación externa que vamos a necesitar para poder continuar con
                                    nuestro negocio.
                                  </p>
                                )}
                              </label>

            
                           


                            

                             

                             
                            </div>
                            :
                            <p></p>

                          }











                          <p style={{ color: "#198754", textAlign: "left", fontSize: "20px", fontWeight: "bold", marginTop: "50px" }}>Buena Gestión (No requiere cambios)</p>
                          <div style={{ Color: "#198754", height: "1.5px", width: "700px", opacity: '0.3' }}></div>

                          {(green == solvancy_color)
                            ?
                            <div>
                            <p className="my-4" style={{ textAlign: "left", fontSize: "14px", color: solvancy_color, fontWeight: "bold" }}>
                              Solvencia{" "}
                              <i
                                className="fa fa-info-circle"
                                aria-hidden="true"
                                data-toggle="modal"
                                data-target="#myModal"
                                style={{ cursor: "pointer" }}
                              ></i>
                            </p>
                            <input
                              id="faq-a"
                              type="checkbox"
                              checked={isOpenA}
                              onChange={toggleOpenA}
                            />
                            <label htmlFor="faq-a">
                              <p className="faq-heading">Reducir activos menos rentables</p>
                              <div className="faq-arrow" />
                              {isOpenA && (
                                <p className="faq-text">
                                  Al analizar los activos que tenemos, podríamos liquidar aquellos activos mantenidos en la empresa que
                                  no están relacionados con la operatoria habitual que mantiene la compañía y que no están siendo
                                  rentables para nosotros. Cómo personas encargadas de las finanzas debemos buscar la manera más
                                  conveniente de liquidarlo y que esto nos genere un aumento en nuestra liquidez que será utilizada
                                  para proporcionarnos mayor rentabilidad.
                                </p>
                              )}
                            </label>

                            <input
                              id="faq-b"
                              type="checkbox"
                              checked={isOpenB}
                              onChange={toggleOpenB}
                            />
                            <label htmlFor="faq-b">
                              <p className="faq-heading">Simplificar procesos</p>
                              <div className="faq-arrow" />
                              {isOpenB && (
                                <p className="faq-text">
                                  Buscar constantemente mejoras en nuestros sistemas puede hacer que lleguemos al mismo
                                  resultado con menor coste o en un menor tiempo. Ya sea consiguiendo los primero o los
                                  segundos resultados, mayormente si se consiguen ambos, la empresa dispondrá de una mayor
                                  cantidad de recursos disponibles para generar un aumento en nuestra capacidad productiva.
                                </p>
                              )}
                            </label>
                            <input
                              id="faq-c"
                              type="checkbox"
                              checked={isOpenC}
                              onChange={toggleOpenC}
                            />
                            <label htmlFor="faq-c">
                              <p className="faq-heading">Buscar ingresos extra</p>
                              <div className="faq-arrow" />
                              {isOpenC && (
                                <p className="faq-text">
                                  Tenemos posibilidades de complementar nuestros servicios o productos con algún subproducto
                                  o merma que no estemos considerando. Hay ocasiones en que las empresas desechan mermas sin
                                  saber qué podrían beneficiarse por la venta de las mismas. Asimismo, hay servicios que podríamos
                                  brindar de forma complementaria sin que esto nos haga incurrir en un coste significativo,
                                  siendo el beneficio mayor de lo que podemos considerar.
                                </p>
                              )}
                            </label>
                          </div>
                          :
                          <p></p>
                          }

                          {(green == liquidity_color)
                            ?
                            <div>
                            <p className="my-4" style={{ textAlign: "left", fontSize: "14px", color: liquidity_color, fontWeight: "bold" }}>Liquidez  <i className="fa fa-info-circle" aria-hidden="true" data-toggle="modal"
                              data-target="#myModal1" style={{ cursor: "pointer" }}></i>
                            </p>

                            <input id="faq-d" type="checkbox"
                              checked={isOpenD}
                              onChange={toggleOpenD} />
                            <label htmlFor="faq-d">
                              <p className="faq-heading">
                                Reducir gastos innecesarios
                              </p>
                              <div className="faq-arrow" />
                              {isOpenD && (
                                <p className="faq-text">
                                  En este caso debemos hacer un análisis de los gastos que realizamos cómo empresa. En ciertas ocasiones, cómo empresas, realizamos gastos por el simple hecho de que siempre se hicieron de esa manera y/o siempre se hicieron con determinada entidad. Será nuestra misión cómo encargados de mejorar los ratios de nuestra compañía, observar aquellos que podríamos reducir, o suplantarlos por gestiones internas, sin que esto nos genere un egreso de fondos. Asimismo, es muy importante tener un registro de todas las erogaciones y gestiones que realizamos, ya que estos pueden servirnos para identificar en dónde estamos realizando desembolsos de dinero que podrían evitarse.
                                </p>
                              )}
                            </label>
                            <input id="faq-e" type="checkbox"
                              checked={isOpenE}
                              onChange={toggleOpenE} />
                            <label htmlFor="faq-e">
                              <p className="faq-heading">
                                Reducir ciclo de efectivo
                              </p>
                              <div className="faq-arrow" />
                              {isOpenE && (

                                <div className="faq-text">
                                  <p >
                                    Definimos el ciclo de efectivo cómo aquel tiempo medio que transcurre desde que el dinero sale de nuestras cuentas para pagarle a nuestros proveedores por la compra de nuestro inventario, hasta su regreso al cobrar las deudas que cancelan nuestros clientes. Podemos separar este proceso en tres cuestiones. Primero tenemos una parte de mantenimiento de existencias o producción (en el caso de que nosotros produzcamos nuestro propio producto), un período medio de cobro y un período medio de pago.
                                  </p>
                                   </div>
                              )}
                            </label>

                              
                            <input id="faq-f" type="checkbox"
                              checked={isOpenF}
                              onChange={toggleOpenF} />
                            <label htmlFor="faq-f">
                              <p className="faq-heading">
                              Entendemos el ciclo de efectivo como:
                              </p>
                              <div className="faq-arrow" />
                              {isOpenF && (
                                <p className="faq-text">
                                  Periodo de existencias + Período medio de cobro – Período medio de pago. Cuanto más se prolongue el proceso 
                                  de producción, la empresa debe mantener más fondos inmovilizados en existencias. De manera similar, cuanto más 
                                  tardan los clientes en pagar las cuentas, mayor es el valor de los efectos a cobrar o cuentas a cobrar. 
                                  Por el contrario, cuando una empresa puede demorar el pago de sus materiales, reduce la cantidad de fondos que necesita. Por tanto, cuanto mayor es el ciclo de efectivo, más necesidades de fondo tiene la empresa y más efectivo necesita mantener para desarrollar con normalidad su actividad diaria.
                                  </p>
                              )}
                            </label>
                            <input id="faq-f" type="checkbox"
                              checked={isOpenF}
                              onChange={toggleOpenF} />
                            <label htmlFor="faq-f">
                              <p className="faq-heading">
                                Factoring
                              </p>
                              <div className="faq-arrow" />
                              {isOpenF && (
                                <p className="faq-text">
                                  El factoring es una operación mediante la cual nosotros cómo empresa vendemos los derechos de cobro que tenemos sobre nuestros clientes, a un precio determinado, a una empresa especializada en dichas transacciones conocida cómo factor, quién se encargará oportunamente de realizar la gestión de cobro. El factor nos anticipa una cantidad que oscila entre el 80% y el 90%, depende la comisión y gastos que nos cobre por esta gestión, del importe de las cuentas cedidas. Por dicha razón, nos encontramos frente a una fuente de financiación ya que obtenemos el dinero antes de proceder al cobro (equivaldría al descuento comercial), y pagamos un interés por dicha gestión.
                                  Es una opción de financiación recomendable para las empresas con problemas de liquidez, ya que, puedes recibir el pago de tus facturas de manera inmediata y así evitar esperar al vencimiento.
                                </p>
                              )}
                            </label>
                          </div>
                          :
                          <p></p>

                          }


                          {(green == roa_color)
                            ?
                            <div>
                              <p className="my-4" style={{ textAlign: "left", fontSize: "14px", color: roa_color, fontWeight: "bold" }}>Retornos sobre activos   <i className="fa fa-info-circle" aria-hidden="true" data-toggle="modal"
                                data-target="#Solvencia" style={{ cursor: "pointer" }}></i></p>


                              <input id="faq-g" type="checkbox"
                                checked={isOpenG}
                                onChange={toggleOpenG} />
                              <label htmlFor="faq-g">
                                <p className="faq-heading">
                                Desglose en rotación y margen operativo
                                </p>
                                <div className="faq-arrow" />
                                {isOpenG && (
                                  <p className="faq-text">
                                    De estos dos factores depende nuestro retorno sobre activos. Es decir, la rentabilidad obtenida por la gestión principal o el negocio de la empresa con este nivel de activos.
                                    </p>
                                )}
                              </label>


                              <input id="faq-h" type="checkbox"
                                checked={isOpenH}
                                onChange={toggleOpenH} />
                              <label htmlFor="faq-h">
                                <p className="faq-heading">
                                Margen operativo neto:
                                </p>
                                <div className="faq-arrow" />
                                {isOpenH && (
                                  <p className="faq-text">
                                    Nivel de aprovechamiento de las ventas de una empresa desde una perspectiva operativa. Se expresa en porcentaje
                                  </p>
                                )}
                              </label>
                              <input id="faq-i" type="checkbox"
                                checked={isOpenI}
                                onChange={toggleOpenI} />
                              <label htmlFor="faq-i">
                                <p className="faq-heading">
                                Rotación del activo operativo neto
                                </p>
                                <div className="faq-arrow" />
                                {isOpenI && (
                                  <p className="faq-text">
                                    Podemos analizar qué rotación viene teniendo la empresa en comparación con los ejercicios anteriores y ver qué utilidad le está dando, mediante su gestión, la empresa a los activos que utiliza en la operatoria
                                  </p>
                                )}
                              </label>
                              <input id="faq-j" type="checkbox"
                                checked={isOpenJ}
                                onChange={toggleOpenJ} />
                              <label htmlFor="faq-j">
                                <p className="faq-heading">
                                Descomponer el margen operativo: 
                                </p>
                                <div className="faq-arrow" />
                                {isOpenJ && (
                                  <p className="faq-text">
                                  Análisis vertical de la cuenta de resultados (Importe de la cuenta que queremos analizar/ventas) analizando los diferentes gastos e ingresos y los márgenes comenzando por el margen bruto:  Margen bruto = Ventas-Coste ventas /Ventas  Es una medida clave de rentabilidad porque captura la cantidad de beneficios generados por la actividad principal que permite cubrir otros costes de la firma como salarios, gastos de administración y generales, gastos de I+D, intereses e impuestos
                                  </p>
                                )}
                              </label>
                              <input id="faq-k" type="checkbox"
                                checked={isOpenK}
                                onChange={toggleOpenK} />
                              <label htmlFor="faq-k">
                                <p className="faq-heading">
                                Si los gastos están ordenados por funciones:
                                </p>
                                <div className="faq-arrow" />
                                {isOpenK && (
                                  <p className="faq-text">
                                  Margen de beneficio operativo= Margen bruto/Ventas - Gastos de administración/ventas - Gastos comerciales/Ventas - Gastos I+D/Ventas – Impuestos operativos/ventas
                                  </p>
                                )}
                              </label>
                              <input id="faq-l" type="checkbox"
                                checked={isOpenL}
                                onChange={toggleOpenL} />
                              <label htmlFor="faq-l">
                                <p className="faq-heading">
                                Rotación de activos: 
                                </p>
                                <div className="faq-arrow" />
                                {isOpenL && (
                                  <p className="faq-text">
                                  Indicador financiero mediante el cual podemos analizar qué rotación viene teniendo la empresa en comparación con los ejercicios anteriores y ver qué utilidad le está dando, mediante su gestión, la empresa a los activos que utiliza en la operatoria normal de la empresa. 
                                  </p>
                                )}
                              </label>

                              
                            </div>
                            :
                            <p></p>
                          }
                          {(green == sales_color)
                            ?
                            <div>
                            <p style={{ textAlign: "left", fontSize: "14px", color: sales_color, fontWeight: "bold", marginTop: "50px" }}>Evolución de Ventas  <i className="fa fa-info-circle" aria-hidden="true" data-toggle="modal"
                              data-target="#Evolución " style={{ cursor: "pointer" }}></i></p>

                           
                          <input id="faq-M" type="checkbox"
                              checked={isOpenM}
                              onChange={toggleOpenM} />
                            <label htmlFor="faq-M">
                              <p className="faq-heading">
                              Hacer uso de la tecnología moderna: 
                              </p>
                              <div className="faq-arrow" />
                              {isOpenM && (
                                <p className="faq-text">
                                Si cómo empresa deseamos aumentar las ventas, no podemos quedarnos fuera de la revolución tecnológica.
                                 Tanto las webs cómo las redes sociales han puesto a disposición del consumidor una gran cantidad de 
                                 información y nuevas formas de acceder a la compra de nuestros productos. Debemos realizar un plan
                                  comercial orientado a la consecución de nuestros objetivos cómo empresa, utilizando toda esta 
                                  tecnología para potenciar nuestras ventas. El desarrollo de una nueva APP, el Big Data, o los chatbots,
                                   como plataformas para mejorar la atención al cliente y la experiencia del usuario con la marca,
                                    deben estar en nuestros planes.
                                </p>
                              )}
                            </label>


                            <input id="faq-N" type="checkbox"
                              checked={isOpenN}
                              onChange={toggleOpenN} />
                            <label htmlFor="faq-N">
                              <p className="faq-heading">
                              Proporcionar un servicio superior o  adicional: 
                              </p>
                              <div className="faq-arrow" />
                              {isOpenN && (
                                <p className="faq-text">
                                 En casos de mercado donde nuestro producto no sea el único, ofrecer a los clientes un servicio adicional 
                                 o superior al de la competencia  puede ser definitivo para cerrar una operación de venta. Este tipo
                                  de estrategias ofrecen un atractivo a la oferta y determinan que el cliente se decante por nuestro 
                                  producto o servicio en relación al ofrecido por otra empresa. Entre estos servicios podemos mencionar
                                   la entrega, la garantía, el mantenimiento o actualizaciones, ya sean de forma adicional al precio 
                                   en el momento de la venta o de forma gratuita.
                                </p>
                              )}
                            </label>


                            <input id="faq-O" type="checkbox"
                              checked={isOpenO}
                              onChange={toggleOpenO} />
                            <label htmlFor="faq-O">
                              <p className="faq-heading">
                              Tener un claro mensaje de venta 
                              </p>
                              <div className="faq-arrow" />
                              {isOpenO && (
                                <p className="faq-text">
                                El speech o el guión que utilicemos será una herramienta fundamental en el proceso de venta. Todo guión de 
                                ventas debe partir de las necesidades del cliente, ya qué este es el verdadero centro de toda la
                                 estrategia. El primer paso qué debemos realizar es escuchar y así conocer lo que el consumidor
                                  necesita, solo así podremos ofrecerle las respuestas adecuadas. 
                                </p>
                              )}
                            </label>

                           

                           
                          </div>
                          :
                          <p></p>

                          }

                           {/* ----------debt */}
                           {(green == sales_color)
                            ?
                            <div>
                              <p style={{ textAlign: "left", fontSize: "14px", color: sales_color, fontWeight: "bold", marginTop: "50px" }}>Endeudamiento  <i className="fa fa-info-circle" aria-hidden="true" data-toggle="modal"
                                data-target="#Evolución " style={{ cursor: "pointer" }}></i></p>

                             
                            <input id="faq-S" type="checkbox"
                                checked={isOpenS}
                                onChange={toggleOpenS} />
                              <label htmlFor="faq-S">
                                <p className="faq-heading">
                                Control de tesorería
                                </p>
                                <div className="faq-arrow" />
                                {isOpenS && (
                                  <p className="faq-text">
                                  Cómo sabemos, la tesorería y los derechos de cobro nos otorgan una liquidez inmediata.
                                   La liquidez nos da la seguridad y la solvencia a corto plazo. En muchas ocasiones tener 
                                   que endeudarse es consecuencia de un momento de falta de efectivo. Es importante entender
                                    que una gestión adecuada de los activos nos ayuda con el flujo de efectivo y nos evita
                                     riesgos de endeudamiento innecesario aumentando nuestra tesorería
                                  </p>
                                )}
                              </label>


                              <input id="faq-T" type="checkbox"
                                checked={isOpenT}
                                onChange={toggleOpenT} />
                              <label htmlFor="faq-T">
                                <p className="faq-heading">
                                Planificación de nuestra financiación
                                </p>
                                <div className="faq-arrow" />
                                {isOpenT && (
                                  <p className="faq-text">
                                  Saber cuánto vamos a necesitar, para qué lo vamos a utilizar y en qué plazo, nos ayuda a tener un 
                                  mayor control de la situación y nos da un mejor panorama a la hora de buscar una financiación externa.
                                   Sabemos que nuestra búsqueda  a largo plazo, será para inversiones bien planificadas y previstas con
                                    mayor antelación, y en el corto, para ganar una mayor liquidez debiendo tener un control en los plazos
                                     de pago y cobro
                                  </p>
                                )}
                              </label>
                              <input id="faq-U" type="checkbox"
                                checked={isOpenU}
                                onChange={toggleOpenU} />
                              <label htmlFor="faq-U">
                                <p className="faq-heading">
                                Mejorar la estrategia comercial
                                </p>
                                <div className="faq-arrow" />
                                {isOpenU && (
                                  <p className="faq-text">
                                  Un negocio que nos está generando pérdidas, tiene cómo consecuencia una reducción del patrimonio social
                                   y un aumento directo del importe de financiación externa que vamos a necesitar para poder continuar con
                                    nuestro negocio.
                                  </p>
                                )}
                              </label>

            
                           


                            

                             

                             
                            </div>
                            :
                            <p></p>

                          }


                          {/* <div>
                            <p style={{ textAlign: "left", fontSize: "14px", color: "#2E3F4B", fontWeight: "bold", marginTop: "50px" }}>Liquidez <i className="fa fa-info-circle" aria-hidden="true" data-toggle="modal"
                              data-target="#myModal1 " style={{ cursor: "pointer" }}></i></p>

                            <label htmlFor="faq-f1">
                              <p className="faq-heading">Reducir gastos innecesarios</p>

                            </label>

                            <label htmlFor="faq-g1">
                              <p className="faq-heading"> Reducir ciclo de efectivo</p>

                            </label>

                            <label htmlFor="faq-h1">
                              <p className="faq-heading"> Factoring
                              </p>

                            </label>
                          </div> */}

                        </div>
                      </div>

                    ) : null}












                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ----------------------------- 1 ----- */}
        <section>
          <div className="container">


            <div className="modal fade" id="myModal" role="dialog">
              <div className="po_sc">

                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title">Acerca de la Solvencia</h4>
                    <button type="button" className="close" style={{ marginRight: "-300px" }} data-dismiss="modal">
                      ×
                    </button>

                  </div>
                  <div className="modal-body">
                    <p className="p_fon">Conocemos cómo solvencia a la capacidad que tenemos cómo empresa para hacer frente a todos los compromisos que tenemos contraídos. En el análisis de la solvencia deben incluirse todos los pasivos que la empresa mantiene (a corto y a largo plazo) y todos los recursos (a corto y a largo plazo) para hacer frente a estos primeros.</p>
                    <p className="p_fon">La solvencia es posiblemente el área de evaluación de mayor peso en un análisis y esto se da cómo consecuencia de que una empresa que no puede hacer frente a sus compromisos, no será una empresa a la que las entidades financieras vean atractivas para prestarle fondos.</p>
                    <p className="p_fon">Asimismo, sabemos que una empresa que no es solvente, o no puede cumplir con sus obligaciones, es una empresa que se encuentra en un proceso de quiebra. Nuestro objetivo cómo encargados de las finanzas de la empresa es evitar que esto ocurra en todo momento.</p>

                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-default"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ----------------2 -------- */}

        <section>
          <div className="container">


            <div className="modal fade" id="myModal1" role="dialog">
              <div className="po_sc">

                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title">Acerca de la Liquidez</h4>
                    <button type="button" className="close" style={{ marginRight: "-300px" }} data-dismiss="modal">
                      ×
                    </button>

                  </div>
                  <div className="modal-body">
                    <p className="p_fon">La liquidez es la capacidad que tiene una entidad para obtener dinero en efectivo y así hacer frente a sus obligaciones a corto plazo. En otras palabras, es la facilidad con la que un activo puede convertirse en dinero en efectivo. No tener liquidez puede provocar que la empresa no pueda atender los pagos relativos a los proveedores y a los suministros. Ambos son vitales para el perfecto funcionamiento del negocio, ya que sin proveedores la empresa no dispone de las materias para fabricar su producto u ofrecer su servicio.</p>
                    <p className="p_fon">Contar con la liquidez suficiente permite a las empresas costear el proceso productivo y mantener su operación, por lo tanto, es de vital importancia mantener una excelente liquidez en tu empresa.</p>

                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-default"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

         {/* ----------------3 -------- */}

         <section>
          <div className="container">


            <div className="modal fade" id="myModal1" role="dialog">
              <div className="po_sc">

                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title">Acerca de la Retornos sobre activos </h4>
                    <button type="button" className="close" style={{ marginRight: "-300px" }} data-dismiss="modal">
                      ×
                    </button>

                  </div>
                  <div className="modal-body">
                    <p className="p_fon">Mediante este ratio podemos analizar la rentabilidad que la empresa le está dando a su activos totales ya sean estos financiados con capital propio o capital ajeno. Para analizarlo tendremos en cuenta el resultado operativo neto y los activos totales de la empresa durante el ejercicio en análisis. </p>
                    <p className="p_fon">Asimismo, podemos desglosar este ratio en rotación de activos y margen operativos para poder obtener mayores conclusiones.</p>

                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-default"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>


        {/* ----------------------------- >=70 ----- */}
        <section>
          <div className="container">


            <div className="modal fade" id="myModal3" role="dialog">
              <div className="po_sc">

                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title">Acerca del Retorno de Activos</h4>
                    <button type="button" className="close" style={{ marginRight: "-331px" }} data-dismiss="modal">
                      ×
                    </button>

                  </div>
                  <div className="modal-body">
                    <p className="p_fon">Mediante este ratio podemos analizar la rentabilidad que la empresa le está dando a su activos totales ya sean estos financiados con capital propio o capital ajeno. Para analizarlo tendremos en cuenta el resultado operativo neto y los activos totales de la empresa durante el ejercicio en análisis. Asimismo, podemos desglosar este ratio en rotación de activos y margen operativos para poder obtener mayores conclusiones.</p>
                    <p className="p_fon">Desglose en rotación y margen operativo: De estos dos factores depende nuestro resultado operativo. Es decir, el resultado obtenido por la gestión principal o el negocio de la empresa.</p>
                    <p className="p_fon">Margen operativo neto: Nivel de aprovechamiento de las ventas de una empresa desde una perspectiva operativa. Se expresa en porcentaje.</p>
                    <p className="p_fon">Rotación del activo operativo neto: Medida en que la empresa es capaz de utilizar sus activos operativos para generar ventas. Es una medida de la eficiencia en la utilización de los activos.</p>

                    <p className="p_fon">
                      <math display="block">
                        <mi>Retorno sobre Activos</mi>
                        <mo>=</mo>
                        <mtable frame="solid" rowlines="solid" align="axis 3">
                          <mfrac>
                            <mn>Resultado Operativo neto</mn>
                            <mn>Activos Operativos netos medios</mn>
                          </mfrac>
                        </mtable>
                      </math>

                    </p>

                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-default"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ----------------Solvencia-------- */}

        <section>
          <div className="container">


            <div className="modal fade" id="Solvencia" role="dialog">
              <div className="po_sc">

                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title">Acerca de la Solvencia</h4>
                    <button type="button" className="close" style={{ marginRight: "-300px" }} data-dismiss="modal">
                      ×
                    </button>

                  </div>
                  <div className="modal-body">
                    <p className="p_fon">Conocemos cómo solvencia a la capacidad que tenemos cómo empresa para hacer frente a todos los compromisos que tenemos contraídos. En el análisis de la solvencia deben incluirse todos los pasivos que la empresa mantiene (a corto y a largo plazo) y todos los recursos (a corto y a largo plazo) para hacer frente a estos primeros.</p>
                    <p className="p_fon">La solvencia es posiblemente el área de evaluación de mayor peso en un análisis y esto se da cómo consecuencia de que una empresa que no puede hacer frente a sus compromisos, no será una empresa a la que las entidades financieras vean atractivas para prestarle fondos.</p>
                    <p className="p_fon">Asimismo, sabemos que una empresa que no es solvente, o no puede cumplir con sus obligaciones, es una empresa que se encuentra en un proceso de quiebra. Nuestro objetivo cómo encargados de las finanzas de la empresa es evitar que esto ocurra en todo momento.</p>

                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-default"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>


        {/* ----------------Evolución-------- */}

        <section>
          <div className="container">


            <div className="modal fade" id="Evolución" role="dialog">
              <div className="po_sc">

                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title">Acerca del Crecimiento de Ventas</h4>
                    <button type="button" className="close" style={{ marginRight: "-320px" }} data-dismiss="modal">
                      ×
                    </button>

                  </div>
                  <div className="modal-body">
                    <p className="p_fon">El índice de crecimiento de las ventas nos permite medir la tasa de variación que ha tenido una empresa en los ingresos por ventas durante un periodo fijo. Comprender este índice de crecimiento es una tarea fundamental que nos permite como empresas actuar en consecuencia basándonos en estos datos. Si esta tasa se reduce en comparación con ejercicios previos, nos indica que el equipo de ventas debería adoptar un enfoque diferente para lograr un impulso en el crecimiento de las ventas. Por el contrario, un alto índice de aumento podemos considerarlo cómo una buena señal de que estamos en el camino correcto en nuestra gestión comercial.</p>


                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-default"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>


        {/* ----------------Sugerencias-------- */}

        <section>
          <div className="container">


            <div className="modal fade" id="Sugerencias" role="dialog">
              <div className="po_sc">

                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title">Sugerencias Prácticas</h4>
                    <button type="button" className="close" style={{ marginRight: "-374px" }} data-dismiss="modal">
                      ×
                    </button>

                  </div>
                  <div className="modal-body">
                    <p className="p_fon">¿Por qué es importante esforzarse por obtener un Credit Score más alto? En pocas palabras, aquellos con puntajes Credit Score más altos generalmente reciben condiciones crediticias más favorables, lo que puede traducirse en pagos más bajos y menos pago en intereses durante la vida de la cuenta.</p>
                    <p className="p_fon">En esta sección te damos una lista de sugerencias prácticas, especialmente focalizadas y personalizadas en tus cuentas. Siguelas, a pié de letra y verás una evolución favorable en tu puntuación Credit Score.</p>


                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-default"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>



        {/* ----------------Sugerencias-------- */}

        <section>
          <div className="container">


            <div className="modal fade" id="Credit_score" role="dialog">
              <div className="po_sc">

                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title">¿Qué es el Credit Score?</h4>
                    <button type="button" className="close" style={{ marginRight: "-374px" }} data-dismiss="modal">
                      ×
                    </button>

                  </div>
                  <div className="modal-body">
                    <p className="p_fon">El Credit Score es una expresión numérica, del 0 al 100 que representaa el riesgo crediticio de una empresa, o la probabilidad de que pague sus facturas/deudas a tiempo.</p>
                    <p className="p_fon">Una puntuación Credit Score alta, significaa que la empresa ha demostrado un comportamiento crediticio responsable en el pasado, lo que puede hacer que los prestamistas potenciales tengan más confianza al evaluar una solicitud de crédito.</p>
                    <p className="p_fon">Este es un vistazo general de los rangos de puntuación Credit Score:</p>

                    <div className="lis_or">
                   
                      <p> <b>Pobre</b>     <span style={{marginLeft:"58px"}}>:</span> <span style={{marginLeft:"20px",fontStyle:"italic"}}>  0=50% </span>      </p>
                      <p> <b>Regular</b>   <span style={{marginLeft:"42px"}}>:</span> <span style={{marginLeft:"20px",fontStyle:"italic"}}>51-66%  </span>     </p>
                      <p> <b>Bueno</b>     <span style={{marginLeft:"52px"}}>:</span> <span style={{marginLeft:"20px",fontStyle:"italic"}}> 67-80% </span>     </p>
                      <p> <b>Muy bueno</b> <span  style={{marginLeft:"10px"}}>:</span> <span style={{marginLeft:"20px",fontStyle:"italic"}}> 80-89% </span>     </p>
                      <p> <b>Excelente</b> <span style={{marginLeft:"26px"}}>:</span> <span style={{marginLeft:"20px",fontStyle:"italic"}}> 90-100%</span>     </p>
                    </div>



                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-default"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>





      </React.Fragment>
      : <Navigate to="/" replace />
  )


}